import MaintenanceOrganizationTable from '@/components/report/building/MaintenanceOrganizationTable';
import MaintenanceWorkerTable from '@/components/report/building/MaintenanceWorkerTable';
import React from 'react';

import styled from '@emotion/styled';
import BuildingInfoTable from '@/components/report/building/BuildinginfoTable';
import GreyBox from '@/components/common/GreyBox';

const BuildingAndMaintenanceGroup = () => {
  return (
    <GreyBox width='58.125rem'>
      <Container>
        <BuildingInfoTable />
        <MaintenanceWorkerTable />
        <MaintenanceOrganizationTable />
      </Container>
    </GreyBox>
  );
};

export default BuildingAndMaintenanceGroup;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;
