import { Theme } from '@/styles/Theme';
import Button from '@/components/common/Button';
import { TbTrash } from 'react-icons/tb';
import { useState } from 'react';
import UnsubscribeWorkersModal from '@/components/newPayment/WorkerPaymentInfoBox/ManageWorkerPaymentModal/UnsubscribeWorkersModal';
import { useSelectedWorkerListStore } from '@/store/selectedWorkerList';
import { toast } from 'react-toastify';

const UnsubscribeWorkersBtn = () => {
  const [isShowUnsubscribeWorkersModal, setIsShowUnsubscribeWorkersModal] =
    useState(false);

  const { selectedUnsubscribeWorkerList, resetSelectedUnsubscribeWorkerList } =
    useSelectedWorkerListStore();

  const onUnsubscribeClick = () => {
    if (selectedUnsubscribeWorkerList.length > 0) {
      setIsShowUnsubscribeWorkersModal(true);
    } else {
      toast.error('중단할 작업자를 선택하세요.');
    }
  };

  return (
    <>
      {isShowUnsubscribeWorkersModal && (
        <UnsubscribeWorkersModal
          setIsShowModal={setIsShowUnsubscribeWorkersModal}
          selectedWorkers={selectedUnsubscribeWorkerList}
          resetSelectedUnsubscribeWorkerList={
            resetSelectedUnsubscribeWorkerList
          }
        />
      )}
      <Button
        height='2.125rem'
        onClick={onUnsubscribeClick}
        border={`0.0625rem solid ${Theme.colors.G_3}`}
        color={Theme.colors.BLACK}
        backgroundColor={Theme.colors.WHITE}
      >
        <TbTrash size='1.25rem' />
        선택 중단 신청
      </Button>
    </>
  );
};

export default UnsubscribeWorkersBtn;
