import styled from '@emotion/styled';
import { Theme } from '@/styles/Theme';
import { css } from '@emotion/react';
export const Table = styled.table`
  width: 100%;
  min-width: 56.25rem;
  max-width: 93.75rem;
  margin: 0.625rem 0;

  th,
  td {
    border: 0.0625rem solid ${Theme.colors.G_3};
  }

  thead {
    tr {
      border-bottom: 0.1875rem solid ${Theme.colors.G_2};
    }
  }

  tbody {
    tr:hover {
      background-color: ${Theme.colors.G_6};
    }
  }
`;

export const Tr = styled.tr<{ isAdmin?: boolean }>`
  background-color: ${Theme.colors.WHITE};

  ${({ isAdmin }) =>
    isAdmin &&
    css`
      background-color: ${Theme.colors.B_5};
      :hover {
        background-color: ${Theme.colors.B_5} !important;
      }
    `}
`;

export const Th = styled.th`
  position: relative;

  font-weight: 700;
  padding: 0.9375rem 0.3125rem;
  white-space: nowrap;

  & > span {
    cursor: pointer;
  }

  :hover {
    div {
      opacity: 1;
    }
  }
`;

export const Td = styled.td`
  padding: 0.9375rem 0.3125rem;
  text-align: center;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  max-width: 0;
`;

export const CountContainer = styled.div`
  margin: 0.625rem 0;

  display: flex;
  align-items: center;
  gap: 1.25rem;
`;

export const ResizerLine = styled.div<{ isResizing: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 0.3125rem;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  cursor: col-resize;
  user-select: none;
  touch-action: none;

  ${({ isResizing }) =>
    isResizing &&
    css`
      background: blue;
      opacity: 1;
    `}
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3125rem;
`;
