import styled from '@emotion/styled';
import Text from '../Text';
import { Theme } from '@/styles/Theme';
import { logout } from '@/utils/logout';

const GnbLogoutBtn = () => {
  return (
    <LogoutWrapper onClick={() => logout()}>
      <Text fontSize='0.9375rem' fontWeight={500} color={Theme.colors.G_1}>
        로그아웃
      </Text>
    </LogoutWrapper>
  );
};

export default GnbLogoutBtn;

const LogoutWrapper = styled.div`
  cursor: pointer;
`;
