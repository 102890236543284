import * as S from './style';

const Section4 = () => {
  return (
    <S.Container>
      <S.Container2>
        <S.H2>
          기계설비 성능점검을 위한
          <br />웹 + 앱 솔루션
        </S.H2>
        <S.ItemContainer>
          <S.ItemRow>
            <S.ItemWrapper>
              <S.ItemIcon src='landing_icon01.svg' alt='mobile icon' />
              <S.ItemBox>
                <S.ItemTitle>온라인 점검</S.ItemTitle>
                <S.ItemContent>
                  기록, 사진, 보관...따로?
                  <br />
                  앱 하나로 모든 점검 진행.
                  <br />
                  간편해진 현장 점검을 경험하세요.
                </S.ItemContent>
              </S.ItemBox>
            </S.ItemWrapper>

            <S.ItemWrapper>
              <S.ItemIcon src='landing_icon02.svg' alt='standard form icon' />
              <S.ItemBox>
                <S.ItemTitle>표준 양식 보고서 자동 작성</S.ItemTitle>
                <S.ItemContent>
                  간편하게 앱으로 점검만 하세요.
                  <br />
                  국토교통부 기계설비법 제16조에
                  <br />
                  맞는 보고서를 클릭 한 번으로
                  <br />
                  자동 완성할 수 있습니다.
                </S.ItemContent>
              </S.ItemBox>
            </S.ItemWrapper>
          </S.ItemRow>

          <S.ItemRow>
            <S.ItemWrapper>
              <S.ItemIcon src='landing_icon03.svg' alt='worker icon' />
              <S.ItemBox>
                <S.ItemTitle>작업자 관리</S.ItemTitle>
                <S.ItemContent>
                  관리자는 작업자에게 연락하지 않고도
                  <br />
                  점검지를 바로 할당할 수 있으며,
                  <br />
                  작업자는 앱을 통해 할당된 점검지를
                  <br />
                  즉시 열람할 수 있습니다.
                </S.ItemContent>
              </S.ItemBox>
            </S.ItemWrapper>

            <S.ItemWrapper>
              <S.ItemIcon src='landing_icon04.svg' alt='cloud icon' />
              <S.ItemBox>
                <S.ItemTitle>점검 기록 / 정보 보관</S.ItemTitle>
                <S.ItemContent>
                  별도 저장 없이 안전하게 점검 기록을
                  <br />
                  보관하고 언제든 바로 확인하세요.
                  <br />
                  필요한 점검지 정보도 기록하고
                  <br />
                  언제든 열람하세요.
                </S.ItemContent>
              </S.ItemBox>
            </S.ItemWrapper>
          </S.ItemRow>
        </S.ItemContainer>
      </S.Container2>
    </S.Container>
  );
};
export default Section4;
