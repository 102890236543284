import React from 'react';
import styled from '@emotion/styled';
import { AiOutlineClose } from 'react-icons/ai';

import Button from '@/components/common/Button';

import { Theme } from '@/styles/Theme';

interface Props {
  width: string;
  height: string;
  imgSrc?: string;
  children?: React.ReactNode;
  leftPosition?: string;
  buttonContent?: string;
  handleButtonClick?: () => void;
  setIsPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Popup = ({
  width,
  height,
  imgSrc,
  children,
  leftPosition = '1.25rem',
  buttonContent,
  handleButtonClick,
  setIsPopupOpen,
}: Props) => {
  return (
    <Container leftPosition={leftPosition}>
      <Wrapper>
        <CloseIconWrapper onClick={() => setIsPopupOpen(false)}>
          <AiOutlineClose color={Theme.colors.G_1} size='1.5rem' />
        </CloseIconWrapper>
        <ContentWrapper width={width} height={height}>
          {imgSrc && <img src={imgSrc} alt='popup' />}
          {children}
        </ContentWrapper>
        {buttonContent && (
          <ButtonWrapper>
            <Button
              width='18.125rem'
              height='3.125rem'
              onClick={handleButtonClick}
            >
              {buttonContent}
            </Button>
          </ButtonWrapper>
        )}
      </Wrapper>
    </Container>
  );
};

export default Popup;

const Container = styled.div<{
  leftPosition: string;
}>`
  z-index: 99;

  position: fixed;
  box-shadow: rgb(33, 43, 56) -0.1875rem 0.1875rem 0.9375rem;
  border-radius: 0.9375rem;
  overflow: hidden;

  top: 8.75rem;
  left: ${({ leftPosition }) => leftPosition};
`;

const Wrapper = styled.div`
  position: relative;
`;

const ContentWrapper = styled.div<{ width: string; height: string }>`
  background-color: ${Theme.colors.WHITE};

  width: ${({ width }) => width};
  height: ${({ height }) => height};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* background-color: ${Theme.colors.WHITE}; */
  }
`;

const CloseIconWrapper = styled.div`
  cursor: pointer;

  position: absolute;
  top: 0.9375rem;
  right: 0.625rem;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 0.9375rem;
  left: 2.1875rem;
`;
