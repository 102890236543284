import styled from '@emotion/styled';
import { Theme } from '@/styles/Theme';
import { css } from '@emotion/react';
export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.9375rem;
`;

export const ProfileImageContainer = styled.div`
  margin-top: 1.25rem;

  display: flex;
  flex-direction: column;
  gap: 0.9375rem;
  align-items: center;
`;
export const ImgWrapper = styled.img`
  width: 6.25rem;
  height: 6.25rem;
  padding: 0.3125rem;
  background-color: ${Theme.colors.WHITE};
  object-fit: contain;
  border-radius: 50%;
`;
export const VerticalLine = styled.div`
  width: 0.0625rem;
  background-color: ${Theme.colors.G_4};
`;
export const FileInput = styled.input`
  display: none;
`;
export const FileLabel = styled.label`
  width: 5.3125rem;
  height: 2rem;
  background-color: ${Theme.colors.WHITE};
  color: ${Theme.colors.BLACK};
  border: 0.0625rem solid ${Theme.colors.G_4};
  font-weight: 400;
  border-radius: 0.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ImgContainer = styled.div`
  position: relative;
`;

export const ImgRemoveIconWrapper = styled.div`
  position: absolute;
  top: 0.3125rem;
  right: 0.3125rem;

  border-radius: 50%;

  background-color: ${Theme.colors.WHITE};
  color: ${Theme.colors.WARNING_RED};

  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ToggleWrapper = styled.div<{
  isActive: boolean;
  isDetailPage: boolean;
}>`
  cursor: pointer;

  width: 2.8125rem;
  height: 1.5625rem;
  border-radius: 1.25rem;

  box-shadow: 0rem 0rem 0.25rem 0rem rgba(0, 0, 0, 0.25);

  position: relative;

  ${({ isDetailPage }) =>
    isDetailPage &&
    css`
      pointer-events: none;
      opacity: 0.3;
    `}

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${Theme.colors.B_3};
    `}


  div {
    position: absolute;

    width: 1.1875rem;
    height: 1.1875rem;

    border-radius: 50%;
    box-shadow: 0rem 0rem 0.25rem 0rem rgba(0, 0, 0, 0.25);
    background-color: ${Theme.colors.WHITE};

    top: 50%;
    left: 10%;
    transform: translateY(-50%);
    transition: left 0.2s ease-in 0s;

    ${({ isActive }) =>
      isActive &&
      css`
        left: 50%;
      `}
  }
`;

export const GreyLine = styled.div`
  width: 100%;
  height: 0.0625rem;

  margin: 1.875rem 0;

  background-color: ${Theme.colors.G_3};
`;
