import usePaymentMethodInfo from '@/hooks/usePaymentMethodInfo';
import * as styled from './style';
import { PaymentMethodNumFormat } from '@/utils/convertNumberFormat';
import { GoPlus } from 'react-icons/go';
import { useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

import DeletePaymentMethodModal from './DeletePaymentMethodModal';
import { Theme } from '@/styles/Theme';

const RegPaymentMethod = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: paymentMethodInfo } = usePaymentMethodInfo();

  const { PCD_PAY_CARDNAME, PCD_PAY_CARDNUM } = paymentMethodInfo?.data || {};

  return (
    <>
      {isModalOpen && (
        <DeletePaymentMethodModal setIsModalOpen={setIsModalOpen} />
      )}
      {paymentMethodInfo ? (
        <styled.RegContainer>
          <styled.CardHeader>
            <styled.FinancialCompanyName>
              {PCD_PAY_CARDNAME}
            </styled.FinancialCompanyName>
            <styled.DeletePaymentMethodIcon>
              <GoPlus
                onClick={() => setIsModalOpen(true)}
                size='1.5rem'
                style={{ rotate: '45deg' }}
              />
            </styled.DeletePaymentMethodIcon>
          </styled.CardHeader>
          <styled.PaymentMethodNumber>
            {PaymentMethodNumFormat(PCD_PAY_CARDNUM!)}
          </styled.PaymentMethodNumber>
        </styled.RegContainer>
      ) : (
        <styled.LoadingCardContainer>
          <ClipLoader color={Theme.colors.WHITE} />
        </styled.LoadingCardContainer>
      )}
    </>
  );
};

export default RegPaymentMethod;
