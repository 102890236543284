import { Theme } from '@/styles/Theme';
import styled from '@emotion/styled';

interface FlexContainerProps {
  gap?: string;
  cursor?: 'pointer';
}
export const FlexContainer = styled.div<FlexContainerProps>`
  display: inline-flex;
  gap: ${({ gap }) => (gap ? gap : '0.3125rem')};
  align-items: center;
  cursor: ${({ cursor }) => cursor};
`;

interface DetailContainerProps {
  isActive: boolean;
}
export const DetailContainer = styled.div<DetailContainerProps>`
  height: ${({ isActive }) => (isActive ? 'auto' : '0rem')};
  transition: all 0.3s ease-in-out;
  overflow: hidden;
`;
export const InnerDetailContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.3125rem;
  border-radius: 0.625rem;
  padding: 1.25rem;
  background-color: ${Theme.colors.G_6};
  margin-top: 0.625rem;
  /* max-width: 20rem; */
  justify-content: space-between;
`;
export const DetailLine = styled.div`
  display: flex;
  justify-content: space-between;
  span {
    font-weight: 500;
  }
`;

interface IconWrapperProps {
  paddingTop?: string;
}

export const IconWrapper = styled.div<IconWrapperProps>`
  padding-top: ${({ paddingTop }) => paddingTop};
  cursor: pointer;
`;

export const TrialLabel = styled.span`
  font-size: 0.875rem;
  color: ${Theme.colors.WHITE};
  font-weight: 500;
  background-color: #f67b37;
  padding: 0.1875rem 0.5625rem;
  border-radius: 0.5625rem;
  margin: 0.0625rem 0 0 0rem;
`;
