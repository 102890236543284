import * as ExcelJS from 'exceljs';

import { getAppendixExcelData } from '@/apis/appendix';
import { copyTemplate } from '@/utils/excelExport/excelExportUtils';

export const exportAppendixTable = async (
  workbook: ExcelJS.Workbook,
  inspectionAreaId: number
) => {
  const response = await fetch('/report/4.2 결과보고서.xlsx', {
    cache: 'no-store',
  });
  const arrayBuffer = await response.arrayBuffer();
  const newWorkBook = new ExcelJS.Workbook();
  await newWorkBook.xlsx.load(arrayBuffer);

  // 복사 하려는 워크시트
  const copySheet = newWorkBook.getWorksheet(1);
  // 복사 받을 워크시트
  let mainSheet = workbook.addWorksheet('Ⅳ. 결과보고서');

  copyTemplate(mainSheet, copySheet, [43, 80]);

  // 데이터 넣어주는 부분
  const appendixData = await getAppendixExcelData(inspectionAreaId);

  if (mainSheet && appendixData) {
    // 결과 보고서 작성 부분
    const {
      inspectionAreaInfo: {
        inspectionAreaName,
        qualification,
        address,
        purpose,
        floorArea,
        households,
        structure,
        standardDate,
        manager,
        telephoneNumber,
      },
      deviceInfo,
      date: { formattedDate, inspectionPeriod },
      userInfo: { userCompany, representativeName, registrationNumber },
      workerList,
    } = appendixData.data.appendixReport;

    const parsedAddress = address.replace('/', ' ');

    mainSheet.getCell('C48').value = inspectionAreaName;
    if (qualification) {
      mainSheet.getCell(
        QUALIFICATION[qualification as keyof typeof QUALIFICATION]
      ).value = '[ ✓ ]';
    }
    mainSheet.getCell('C50').value = parsedAddress;
    mainSheet.getCell('C51').value = purpose;

    if (households) {
      mainSheet.getCell('F51').value = `${floorArea}\n${households}`;
    } else {
      mainSheet.getCell('E51').value = '연면적';
      mainSheet.getCell('F51').value = floorArea;
    }

    mainSheet.getCell('C52').value = structure;
    mainSheet.getCell('F52').value = standardDate;
    mainSheet.getCell('C54').value = manager;
    mainSheet.getCell('F54').value = telephoneNumber;
    mainSheet.getCell('C56').value = parsedAddress;
    mainSheet.getCell('C57').value = deviceInfo.join(', ');
    mainSheet.getCell(
      'C58'
    ).value = `${formattedDate} (총 점검일수 : ${inspectionPeriod})`;
    mainSheet.getCell('C62').value = userCompany;
    mainSheet.getCell('E62').value = representativeName;
    mainSheet.getCell('F62').value = registrationNumber;

    for (let i = 0; i < workerList.length; i++) {
      // 우선 4명까지만
      if (i > 3) {
        break;
      }

      const { workerName, workerGrade, workerNoteNumber } = workerList[i];

      mainSheet.getCell(65 + i, 1).value = workerName;
      mainSheet.getCell(65 + i, 3).value = workerGrade;
      mainSheet.getCell(65 + i, 5).value = workerNoteNumber;
      mainSheet.getCell(65 + i, 6).value = formattedDate;
    }

    // 점검결과 내역서 부분
    const {
      history: { areaName, resultText, company, representatorName },
      deviceList,
    } = appendixData.data.appendixHistory;

    mainSheet.getCell('B83').value = areaName;
    mainSheet.getCell('E83').value = parsedAddress;
    mainSheet.getCell('B84').value = resultText;
    mainSheet.getCell('C85').value = company;
    mainSheet.getCell('F85').value = representatorName;

    deviceList.forEach((device, idx) => {
      mainSheet.getCell(88 + idx, 2).value = device.result;
      mainSheet.getCell(88 + idx, 3).value = device.failText;
    });
  }
};

const QUALIFICATION = {
  특급: 'G48',
  고급: 'I48',
  중급: 'G49',
  초급: 'I49',
};
