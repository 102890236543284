import React, { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { getInspectionPlan, postReportPlan } from '@/apis/report/plan';
import type { InspectionPlanWorker } from '@/types/report/plan.types';
import type { ErrorResponse } from '@/types/client.types';

import { QUERY_KEY } from '@/constants/queryKey';

import GreyBox from '@/components/common/GreyBox';
import Text from '@/components/common/Text';
import EditButton from '@/components/common/EditButton';
import Input from '@/components/common/Input';
import Button from '@/components/common/Button';
import Spacer from '@/components/common/Spacer';
import ReportDeviceCountTable from '@/components/report/reportPlan/ReportDeviceCountTable';

import { Theme } from '@/styles/Theme';

import * as S from './style';
import { StyledCol } from '@/styles/common';

const ReportPlanBlock = () => {
  const params = useParams();
  const inspectionAreaId = Number(params.id);

  const [isDetailPage, setIsDetailPage] = useState<boolean>(true);
  const [workerList, setWorkerList] = useState<InspectionPlanWorker[]>([]);
  const [selectedWorkerList, setSelectedWorkerList] = useState<number[]>([]);

  const queryClient = useQueryClient();

  const { data } = useQuery({
    queryKey: [QUERY_KEY.REPORT.PLAN, inspectionAreaId],
    queryFn: () => getInspectionPlan(inspectionAreaId),
  });

  const { mutate } = useMutation({
    mutationFn: postReportPlan,
    onSuccess: (data) => {
      setIsDetailPage(true);
      toast.success('수정하였습니다.');
      queryClient.invalidateQueries([QUERY_KEY.REPORT.PLAN, inspectionAreaId]);
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      toast.error(error.response?.data.message);
    },
  });

  useEffect(() => {
    if (data) {
      setWorkerList(data.data.workerList);
    }
  }, [data]);

  const handleEditCancel = () => {
    if (data) {
      setWorkerList(data.data.workerList);
    }
    setSelectedWorkerList([]);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    const { name, value } = e.target;
    setWorkerList((prevState) => {
      const newState = [...prevState];
      newState[idx] = { ...newState[idx], [name]: value };
      return newState;
    });
  };

  const handleCheckBox = (idx: number) => {
    if (selectedWorkerList.includes(idx)) {
      const filterdArr = selectedWorkerList.filter((v) => v !== idx);
      setSelectedWorkerList(filterdArr);
      return;
    }
    setSelectedWorkerList((prev) => [...prev, idx]);
  };

  const handleAddWorker = () => {
    setWorkerList((prev) => [
      ...prev,
      {
        name: '',
        category: '',
        grade: '',
      },
    ]);
  };

  const handleDeleteWorker = () => {
    setWorkerList((prev) =>
      prev.filter((_, index) => !selectedWorkerList.includes(index))
    );
    setSelectedWorkerList([]);
  };

  if (!data) {
    return null;
  }

  const {
    date: { formattedDate, inspectionPeriod },
  } = data.data;

  return (
    <GreyBox title='성능점검 계획' width='58.125rem'>
      <EditButton
        isDetailPage={isDetailPage}
        setIsDetailPage={setIsDetailPage}
        onClickCancel={handleEditCancel}
      />
      <S.Container>
        <S.Block>
          <S.BlockHeader>
            <Text fontSize='1.25rem' fontWeight={600}>
              1. 성능점검 대상 및 기계설비 수량
            </Text>
          </S.BlockHeader>
          <ReportDeviceCountTable />
        </S.Block>
        <S.Block>
          <Text fontSize='1.25rem' fontWeight={600}>
            2. 성능점검 일정
          </Text>
          <Text>{`${formattedDate} (총 ${inspectionPeriod}일)`}</Text>
        </S.Block>
        <S.Block>
          <S.BlockHeader>
            <Text fontSize='1.25rem' fontWeight={600}>
              3. 성능점검 인력
            </Text>
            {!isDetailPage && (
              <S.ButtonContainer>
                <Button onClick={handleAddWorker}>추가하기</Button>
                <Button
                  onClick={handleDeleteWorker}
                  backgroundColor={Theme.colors.WARNING_RED}
                >
                  삭제하기
                </Button>
              </S.ButtonContainer>
            )}
          </S.BlockHeader>
          <S.StyledTable>
            <colgroup>
              <StyledCol width='3.125rem' />
              <StyledCol width='9.375rem' />
              <StyledCol width='9.375rem' />
              <StyledCol width='9.375rem' />
            </colgroup>
            <thead>
              <tr>
                <th></th>
                <th>구분</th>
                <th>명단</th>
                <th>등급 구분</th>
              </tr>
            </thead>
            <tbody>
              {workerList.map((worker, idx) => (
                <tr key={idx}>
                  <td>
                    <S.CheckboxWrapper>
                      <input
                        type='checkbox'
                        disabled={isDetailPage}
                        onChange={() => handleCheckBox(idx)}
                        checked={selectedWorkerList.includes(idx)}
                      />
                    </S.CheckboxWrapper>
                  </td>
                  <td>
                    <Input
                      name='category'
                      width='100%'
                      height='2.5rem'
                      value={worker.category}
                      onChange={(e) => handleInputChange(e, idx)}
                      placeholder='점검 본부장'
                      disabled={isDetailPage}
                    />
                  </td>
                  <td>
                    <Input
                      name='name'
                      width='100%'
                      height='2.5rem'
                      value={worker.name}
                      onChange={(e) => handleInputChange(e, idx)}
                      placeholder='홍길동'
                      disabled={isDetailPage}
                    />
                  </td>
                  <td>
                    <Input
                      name='grade'
                      width='100%'
                      height='2.5rem'
                      value={worker.grade}
                      onChange={(e) => handleInputChange(e, idx)}
                      placeholder='특급'
                      disabled={isDetailPage}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </S.StyledTable>
        </S.Block>
      </S.Container>
      {!isDetailPage && (
        <>
          <Spacer y='1.25rem' />
          <S.ButtonContainer>
            <Button
              width='9.375rem'
              onClick={() => mutate({ inspectionAreaId, workerList })}
            >
              수정하기
            </Button>
          </S.ButtonContainer>
        </>
      )}
    </GreyBox>
  );
};

export default ReportPlanBlock;
