import type { ErrorResponse } from '@/types/client.types';
import { putUndoUnsubscribeWorker } from '@/apis/payment';
import { QUERY_KEY } from '@/constants/queryKey';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

const usePutUndoUnsubscribeWorker = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: putUndoUnsubscribeWorker,
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEY.PAYMENT_INFO]);
      toast.success('작업자 사용이 해지가 취소되었습니다.');
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      toast.error(error.response?.data.message);
    },
  });
};

export default usePutUndoUnsubscribeWorker;
