import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AiOutlineRight } from 'react-icons/ai';

import { getPrimaryCategory, getSubCategory } from '@/apis/template';
import type { DeviceInfo } from '@/types/InspectionArea.types';
import type { TargetCategory } from '@/types/template.types';

import { QUERY_KEY } from '@/constants/queryKey';

import * as S from '../WorkAddCategoryListSelector/style';

interface Props {
  selectedDeviceList: DeviceInfo[];
  setSelectedDeviceList: React.Dispatch<React.SetStateAction<DeviceInfo[]>>;
}

const WorkEditCategorySelector = ({
  selectedDeviceList,
  setSelectedDeviceList,
}: Props) => {
  const [selectedPrimaryCategory, setSelectedPrimaryCategory] =
    useState<number>(0);

  const { data: primaryCategoryData } = useQuery([QUERY_KEY.PRIMARY_CATEGORY], {
    queryFn: getPrimaryCategory,
  });

  const { data: subCategoryData } = useQuery(
    [QUERY_KEY.SUB_CATEGORY, selectedPrimaryCategory],
    {
      queryFn: () => getSubCategory(selectedPrimaryCategory),
    }
  );

  const handleSubCategory = (category: TargetCategory) => {
    const isDeviceListSelected = selectedDeviceList.some(
      (c) => c.template_device_id === category.id
    );

    if (isDeviceListSelected) {
      const answer = window.confirm(
        category.name +
          '에 대한 점검 대상들이 삭제됩니다(복구 가능).\n정말 삭제하시겠습니까?'
      );

      if (answer) {
        const arr = selectedDeviceList
          .map((item) => {
            if (item.template_device_id === category.id) {
              // 중간에 추가된 대상은 통째로 삭제
              const info = [...item.info]
                .filter(
                  (infoDetail) =>
                    infoDetail.device_id !== 0 && infoDetail.report_id !== 0
                )
                .map((infoDetail) => {
                  return {
                    ...infoDetail,
                    deleted: 1,
                  };
                });

              return {
                ...item,
                selected: 0,
                info,
              };
            }
            return item;
          })
          .filter(
            (item) =>
              !(item.template_device_id === category.id && item.info.length < 1)
          );
        setSelectedDeviceList(arr);
      }

      return;
    }

    setSelectedDeviceList((prev) => [
      ...prev,
      {
        template_device_id: category.id,
        name: category.name,
        total: 0,
        selected: 0,
        info: [],
      },
    ]);
  };

  return (
    <S.Container>
      <S.SearchContainer />
      <S.CategoryContainer>
        <S.PrimaryCategoryContainer>
          <S.PrimaryCategoryItemContainer
            isActive={selectedPrimaryCategory === 0}
            onClick={() => setSelectedPrimaryCategory(0)}
          >
            <div>전체 설비</div>
            {selectedPrimaryCategory === 0 && <AiOutlineRight />}
          </S.PrimaryCategoryItemContainer>
          {primaryCategoryData &&
            primaryCategoryData.data.result.map((category) => (
              <S.PrimaryCategoryItemContainer
                key={category.id}
                isActive={selectedPrimaryCategory === category.id}
                onClick={() => setSelectedPrimaryCategory(category.id)}
              >
                <div>{category.name}</div>
                {selectedPrimaryCategory === category.id && <AiOutlineRight />}
              </S.PrimaryCategoryItemContainer>
            ))}
        </S.PrimaryCategoryContainer>
        <S.SubCategoryContainer>
          {subCategoryData &&
            subCategoryData.data.result.map((subCategory) => (
              <S.SubCategoryWrapper
                key={subCategory.id}
                isSelected={selectedDeviceList.some(
                  (c) => c.template_device_id === subCategory.id
                )}
                onClick={() => handleSubCategory(subCategory)}
              >
                {subCategory.name}
              </S.SubCategoryWrapper>
            ))}
        </S.SubCategoryContainer>
      </S.CategoryContainer>
    </S.Container>
  );
};

export default WorkEditCategorySelector;
