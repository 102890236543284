import styled from '@emotion/styled';
import { Theme } from '@/styles/Theme';

export const BoxWrapper = styled.div`
  margin-bottom: 1.25rem;
`;

export const SubTitleWrapper = styled.div`
  position: relative;
`;

export const SubTitle = styled.h2`
  font-size: 1.375rem;
  font-weight: 600;

  margin-left: 0.625rem;
  margin-bottom: 0.625rem;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;

  background-color: white;
  padding: 1.875rem;
  border: 0.0625rem solid ${Theme.colors.G_3};
  border-radius: 0.625rem;
`;
