export const Theme = {
  colors: {
    // Main Color
    B_1: '#030E20',
    B_2: '#1B3E77',
    B_3: '#137FDA',
    B_3_light: '#419dff',
    B_4: '#6BA5F2',
    B_5: '#94B0DC',
    B_6: '#E9F3FD',

    // Sub Color
    WHITE: '#FFFFFF',
    BLACK: '#000000',
    WARNING_RED: '#F6374B',
    GREEN: '#4FB477',

    // Grey
    G_1: '#707070',
    G_2: '#A5A5A5',
    G_3: '#CACACA',
    G_4: '#DFDFDF',
    G_5: '#EBEBEB',
    G_6: '#F5F5F5',
    G_7: '#FAFAFA',

    //추가 사항
    DG_1: '#363636',
    DG_2: '#4f4f4f',

    YELLOW: '#fff8e2ab',
  },
};
