import styled from '@emotion/styled';

import { Theme } from '@/styles/Theme';
import { css } from '@emotion/react';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  gap: 1.25rem;
`;

export const TableWrapper = styled.div<{ width: string; flex?: boolean }>`
  position: relative;
  width: ${({ width }) => width};
  height: 25rem;
  overflow-y: auto;

  border: 0.0625rem solid ${Theme.colors.G_3};
  background-color: ${Theme.colors.WHITE};

  ${({ flex }) =>
    flex &&
    css`
      flex: 1;
    `}

  thead {
    position: sticky;
    top: 0;
    z-index: 10;

    height: 2.75rem;
    background-color: ${Theme.colors.WHITE};

    font-size: 1rem;
    font-weight: bold;
    color: ${Theme.colors.BLACK};

    tr {
      th {
        border-bottom: 0.125rem solid ${Theme.colors.G_5};
      }
    }
  }

  tbody {
    tr {
      height: 3.1812rem;

      background-color: ${Theme.colors.WHITE};

      :hover {
        background-color: ${Theme.colors.G_7};
      }
    }
    td {
      border-bottom: 0.0625rem solid ${Theme.colors.G_5};

      text-align: center;
      vertical-align: middle;

      svg {
        display: inline-block;
      }
    }
  }
`;

export const LeftTable = styled.table`
  width: 100%;
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: separate;

  tr {
    th:first-of-type {
      width: 6.25rem;
    }
    th:nth-of-type(3) {
      width: 7.8125rem;
    }
    th:nth-of-type(4) {
      width: 7.8125rem;
    }
    th:nth-of-type(5) {
      width: 6.25rem;
    }

    td:nth-of-type(2) {
      cursor: pointer;
    }
  }
`;

export const RightTable = styled.table`
  width: 100%;
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: separate;

  tr {
    th:nth-of-type(4) {
      width: 7.8125rem;
    }
    th:last-of-type {
      width: 7.8125rem;
    }
  }
`;

export const StyledTr = styled.tr<{ isDeleted: boolean }>`
  td:nth-of-type(5) {
    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  ${({ isDeleted }) =>
    isDeleted &&
    css`
      :hover {
        background-color: ${Theme.colors.WHITE} !important;
      }

      td {
        color: ${Theme.colors.G_4};
      }

      td:nth-of-type(4) {
        pointer-events: none;
      }

      input {
        pointer-events: none;
        color: ${Theme.colors.G_4};
        border: 0.0625rem solid ${Theme.colors.G_4};
      }
    `}
`;

export const PlusIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.625rem;
`;

export const StyledInput = styled.input`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-color: ${Theme.colors.WHITE};
  border-radius: 0.25rem;

  border: 0.0625rem solid ${Theme.colors.G_2};

  padding: 0 0.9375rem;

  color: ${Theme.colors.BLACK};
  font-size: 1rem;
  font-weight: 400;
  text-align: center;

  :disabled {
    background-color: ${Theme.colors.G_5};
  }

  ::placeholder {
    color: ${Theme.colors.G_2};
  }

  :focus {
    outline: none;
  }
`;

export const BottomTableContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;

export const BottomTableName = styled.div`
  width: fit-content;
  border: 0.0625rem solid ${Theme.colors.G_3};
  border-bottom: none;
  background-color: ${Theme.colors.WHITE};

  padding: 0.625rem 0.9375rem;
`;
