import client from '@/apis/client';
import type { Response } from '@/types/client.types';
import type {
  GetPaymentPriceRequestProps,
  PaymentMethodInfo,
  PostPaymentResultRequestProps,
  PostRegisterCardRequestProps,
  PutUnsubscribeWorkerRequestProps,
} from '@/types/payment.types';

export const postCancelCard = (billingKey: string) =>
  client
    .post('/payment/delete', { PCD_PAYER_ID: billingKey })
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error posting cancel card:', error);
      throw error;
    });

export const postRegisterCard = (body: PostRegisterCardRequestProps) =>
  client
    .post('/payment/register', body)
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error posting register card:', error);
      throw error;
    });

export const postPaymentResult = (body: PostPaymentResultRequestProps) =>
  client
    .post('/payment/result', body)
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error posting payment result:', error);
      throw error;
    });

export const getCardInfo = () =>
  client
    .get<Response<PaymentMethodInfo>>('/payment/card')
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error fetching card info:', error);
      throw error;
    });

export const getPaymentPrice = ({
  userId,
  newWorkerCount,
  workerCount,
}: GetPaymentPriceRequestProps) =>
  client
    .get<Response<{ price: number }>>('/payment/price', {
      params: {
        userId,
        newWorkerCount,
        workerCount,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error fetching payment price:', error);
      throw error;
    });

export const putUnsubscribeWorker = (body: PutUnsubscribeWorkerRequestProps) =>
  client
    .put('/user/worker/unsub', body)
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error updating unsubscribe worker:', error);
      throw error;
    });

export const putUndoUnsubscribeWorker = (
  body: PutUnsubscribeWorkerRequestProps
) =>
  client
    .put('/user/worker/cancel-unsub', body)
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error updating undoUnsubscribe worker:', error);
      throw error;
    });
