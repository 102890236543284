import React, { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import type { ErrorResponse } from '@/types/client.types';
import Text from '@/components/common/Text';

import Button from '@/components/common/Button';
import * as S from './style';
import { Theme } from '@/styles/Theme';

import GreyBox from '@/components/common/GreyBox';

import { QUERY_KEY } from '@/constants/queryKey';
import {
  getSpecificImprovement,
  postSpecificImprovement,
} from '@/apis/report/improvePlan';
import type { SpecificImprovementItem } from '@/types/report/improvePlan.types';
import { StyledCol } from '@/styles/common';

interface SpecificImproveCategoryItem {
  category: string;
  deviceList: SpecificImprovementItem[];
}

const YearDetailImproveTable = () => {
  const _ = require('lodash');

  const [isEdit, setEdit] = useState(false);
  const [editableImproveData, setEditableImproveData] =
    useState<Map<number, SpecificImprovementItem>>();
  const queryClient = useQueryClient();
  const params = useParams();
  const inspectionAreaId = Number(params.id);

  const { data: specificData } = useQuery({
    queryKey: [QUERY_KEY.REPORT.SPECIFIC_IMPROVEMENT, inspectionAreaId],
    queryFn: () => getSpecificImprovement(inspectionAreaId),
  });

  const { mutate: updateSpecificData } = useMutation({
    mutationFn: postSpecificImprovement,
    onSuccess: (data) => {
      toast.success('정보가 수정되었습니다.');
      queryClient.invalidateQueries([
        QUERY_KEY.REPORT.SPECIFIC_IMPROVEMENT,
        inspectionAreaId,
      ]);
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      toast.error(error.response?.data.message);
    },
  });

  useEffect(() => {
    let newState = new Map();
    specificData?.data.forEach((element: SpecificImprovementItem) => {
      newState.set(element.templateDeviceId, element);
    });
    setEditableImproveData(newState);
  }, [specificData]);

  const handleUpdate = () => {
    if (editableImproveData === undefined) return;

    const request = Array.from(editableImproveData.values()).map(
      (item: SpecificImprovementItem, index: number) => {
        return {
          templateDeviceId: item.templateDeviceId,
          improvement: item.improvement,
          specificPlan: {
            first: item.firstSpecificPlan,
            second: item.secondSpecificPlan,
            third: item.thirdSpecificPlan,
            fourth: item.fourthSpecificPlan,
            fifth: item.fifthSpecificPlan,
          },
        };
      }
    );

    updateSpecificData({
      inspectionAreaId: inspectionAreaId,
      planList: request,
    });
    setEdit(!isEdit);
  };

  const handleImprovement = (id: number, value: string) => {
    setEditableImproveData((prev) => {
      let device = editableImproveData?.get(id);
      const newState = new Map(prev);
      if (device !== undefined) {
        device.improvement = value;
        newState?.set(id, device);
      }
      return newState;
    });
  };

  const handleSpecificMemo = (id: number, seq: number, value: string) => {
    setEditableImproveData((prev) => {
      let device = editableImproveData?.get(id);
      const newState = new Map(prev);
      if (device !== undefined) {
        switch (seq) {
          case 1:
            device.firstSpecificPlan = value;
            break;
          case 2:
            device.secondSpecificPlan = value;
            break;
          case 3:
            device.thirdSpecificPlan = value;
            break;
          case 4:
            device.fourthSpecificPlan = value;
            break;
          case 5:
            device.fifthSpecificPlan = value;
            break;
        }
        newState?.set(id, device);
      }
      return newState;
    });
  };

  const getSpecificMemo = (id: number, seq: number): string => {
    let device = editableImproveData?.get(id);

    if (device !== undefined) {
      switch (seq) {
        case 1:
          return device.firstSpecificPlan;
        case 2:
          return device.secondSpecificPlan;
        case 3:
          return device.thirdSpecificPlan;
        case 4:
          return device.fourthSpecificPlan;
        case 5:
          return device.fifthSpecificPlan;
      }
    }
    return '';
  };

  return (
    <GreyBox width='56.25rem'>
      <S.Container>
        <S.TopContainer>
          <Text fontSize='1.25rem' fontWeight={600}>
            성능개선 필요성 및 연도별 세부개선계획
          </Text>
          <Button
            backgroundColor={isEdit ? Theme.colors.BLACK : Theme.colors.B_3}
            onClick={() => {
              if (isEdit) handleUpdate();
              else setEdit(true);
            }}
          >
            {isEdit ? '저장하기' : '수정하기'}
          </Button>
        </S.TopContainer>
        <S.Table>
          <colgroup>
            <StyledCol width='7.5rem' />
            <StyledCol width='9.375rem' />
            <StyledCol width='12.5rem' />
            <StyledCol width='3.125rem' />
            <StyledCol width='3.125rem' />
            <StyledCol width='3.125rem' />
            <StyledCol width='3.125rem' />
            <StyledCol width='3.125rem' />
          </colgroup>
          <tbody>
            <tr>
              <S.TableHeader colSpan={2} rowSpan={2}>
                점검대상 기계설비
              </S.TableHeader>
              <S.TableHeader rowSpan={2}>성능개선 필요성</S.TableHeader>
              <S.TableHeader colSpan={5}>
                세부개선 계획(성능점검일 기준)
              </S.TableHeader>
            </tr>
            <tr>
              <S.TableHeader>1년차</S.TableHeader>
              <S.TableHeader>2년차</S.TableHeader>
              <S.TableHeader>3년차</S.TableHeader>
              <S.TableHeader>4년차</S.TableHeader>
              <S.TableHeader>5년차</S.TableHeader>
            </tr>
          </tbody>

          {specificData &&
            specificData.data &&
            editableImproveData &&
            _.chain(Array.from(editableImproveData.values()))
              .groupBy('category')
              .map(function (v: SpecificImprovementItem, i: number) {
                return {
                  category: _.get(_.find(v, 'category'), 'category'),
                  deviceList: _.chain(v).value(),
                };
              })
              .value()
              .map((item: SpecificImproveCategoryItem, index: number) => {
                return (
                  <S.InfoContainer key={index}>
                    {item.deviceList.map((device, idx) => {
                      return (
                        <>
                          <tr key={idx}>
                            {idx === 0 ? (
                              <td rowSpan={item.deviceList.length}>
                                {item.category}
                              </td>
                            ) : (
                              <></>
                            )}
                            <td>{device.templateDeviceName}</td>
                            <td>
                              {isEdit ? (
                                <S.StyledTextarea
                                  name='성능개선 필요성'
                                  height='3.125rem'
                                  value={
                                    editableImproveData.get(
                                      device.templateDeviceId
                                    )?.improvement
                                  }
                                  onChange={(e) => {
                                    handleImprovement(
                                      device.templateDeviceId,
                                      e.target.value
                                    );
                                  }}
                                ></S.StyledTextarea>
                              ) : (
                                device.improvement
                              )}
                            </td>
                            {[
                              device.firstSpecificPlan,
                              device.secondSpecificPlan,
                              device.thirdSpecificPlan,
                              device.fourthSpecificPlan,
                              device.fifthSpecificPlan,
                            ].map((plan, planIndex) => {
                              return (
                                <td>
                                  {isEdit ? (
                                    <S.StyledTextarea
                                      name='세부계획'
                                      height='3.125rem'
                                      value={getSpecificMemo(
                                        device.templateDeviceId,
                                        planIndex + 1
                                      )}
                                      onChange={(e) => {
                                        handleSpecificMemo(
                                          device.templateDeviceId,
                                          planIndex + 1,
                                          e.target.value
                                        );
                                      }}
                                    ></S.StyledTextarea>
                                  ) : (
                                    plan
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        </>
                      );
                    })}
                  </S.InfoContainer>
                );
              })}
        </S.Table>
      </S.Container>
    </GreyBox>
  );
};

export default YearDetailImproveTable;
