import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';
import PropagateLoader from 'react-spinners/PropagateLoader';

import useCompanyInfo from '@/hooks/useCompanyInfo';

import { logout } from '@/utils/logout';

import { ROUTER } from '@/constants/constants';

import NoticePopup from '@/components/common/NoticePopup';

import { useAccessTokenStore } from '@/store/auth';
import { useLoadingStore } from '@/store/loading';

import { LoadingBackground } from '@/styles/common';
import { Theme } from '@/styles/Theme';

const UserRoute = () => {
  const { accessToken } = useAccessTokenStore();
  const { loading } = useLoadingStore();
  const { data: companyInfo, isLoading } = useCompanyInfo();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!accessToken) {
      navigate(ROUTER.LOGIN, {
        replace: true,
      });
      toast.error('로그인이 필요합니다.');
      return;
    }

    if (isMobile) {
      logout('모바일에서 사용하실 수 없습니다.');
      return;
    }

    if (companyInfo) {
      const {
        data: { isApproved },
      } = companyInfo;
      // 처음 가입, 카드 등록 전 경우
      if (!isApproved) {
        navigate(ROUTER.CARD_REGISTER, { replace: true });
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, companyInfo, pathname]);

  if (isLoading || !companyInfo) {
    return null;
  }

  return (
    <>
      {loading && (
        <LoadingBackground>
          <PropagateLoader color={Theme.colors.B_3} />
        </LoadingBackground>
      )}
      {companyInfo.data.isApproved && <NoticePopup />}
      <Outlet />
    </>
  );
};

export default UserRoute;
