import styled from '@emotion/styled';
import { Theme } from '@/styles/Theme';
import { CardContainer } from '../style';

export const UnregContainer = styled(CardContainer)`
  justify-content: center;
  align-items: center;
  gap: 1.25rem;

  border: 0.125rem dashed ${Theme.colors.G_3};
  background-color: ${Theme.colors.G_7};

  cursor: pointer;
`;

export const Title = styled.span`
  font-weight: bold;
  color: ${Theme.colors.B_3};
`;

export const RegisterPaymentMethodBtn = styled.button`
  width: 3.125rem;
  height: 3.125rem;

  border-radius: 50%;
  background-color: ${Theme.colors.B_6};

  font-size: 1.75rem;
  font-weight: bold;
  color: ${Theme.colors.B_3};
`;
