import type { AddWorkerInfo } from '@/types/worker.types';
import { create } from 'zustand';

interface AddWorkerListStore {
  addWorkerList: AddWorkerInfo[];
  setAddWorkerList: (list: AddWorkerInfo[]) => void;
  addWorker: (worker: AddWorkerInfo) => void;
  findAddWorkerByName: (name: string) => AddWorkerInfo | undefined;
  resetAddWorkerList: VoidFunction;
}

export const useAddWorkerListStore = create<AddWorkerListStore>((set, get) => ({
  addWorkerList: [],
  setAddWorkerList: (list) => set({ addWorkerList: list }),
  addWorker: (addWorker) =>
    set((state) => ({ addWorkerList: [...state.addWorkerList, addWorker] })),
  findAddWorkerByName: (name) => {
    const { addWorkerList } = get();
    return addWorkerList.find((worker) => worker.name === name);
  },
  resetAddWorkerList: () => set({ addWorkerList: [] }),
}));
