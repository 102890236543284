import React, { useRef, useState } from 'react';
import { HiPlusSm } from 'react-icons/hi';
import { BiTrash } from 'react-icons/bi';
import { toast } from 'react-toastify';

import type { PostWorkDeviceInfo } from '@/types/InspectionArea.types';

import { TOAST_ID } from '@/constants/toastId';

import * as S from './style';

interface Props {
  selectedDeviceList: PostWorkDeviceInfo[];
  setSelectedDeviceList: React.Dispatch<
    React.SetStateAction<PostWorkDeviceInfo[]>
  >;
}

const WorkAddCategoryListTable = ({
  selectedDeviceList,
  setSelectedDeviceList,
}: Props) => {
  const [currentPrimaryCategoryId, setCurrentPrimaryCategoryId] =
    useState<number>(0);

  const sectionRef = useRef<HTMLDivElement>(null);

  const handleDeleteDeviceList = (device: PostWorkDeviceInfo) => {
    const answer = window.confirm(
      device.name +
        '에 대해 입력된 값이 모두 사라집니다.\n정말 삭제하시겠습니까?'
    );
    if (answer) {
      const filteredList = selectedDeviceList.filter(
        (item) => item.template_device_id !== device.template_device_id
      );
      setSelectedDeviceList(filteredList);
    }
  };

  const handleTotalCount = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const total = Number(e.target.value.replace(/[^\d.-]+/g, ''));

    if (total > 500) {
      return;
    }

    const array = selectedDeviceList.map((device) => {
      if (device.template_device_id === id) {
        return {
          ...device,
          total,
        };
      }
      return device;
    });

    setSelectedDeviceList(array);
  };

  const handleAddCount = (
    id: number,
    totalCount: number,
    currentCount: number
  ) => {
    setCurrentPrimaryCategoryId(id);

    if (currentCount >= totalCount) {
      toast.error('점검수량은 전체수량을 초과할 수 없습니다.', {
        toastId: TOAST_ID.CATEGORY_LIST_TABLE,
      });
      return;
    }

    const array = selectedDeviceList.map((device) => {
      if (device.template_device_id === id) {
        const info = [...device.info];
        info.push({
          device_num: '',
          location: '',
          status: '',
          deleted: 0,
        });

        return {
          ...device,
          selected: ++device.selected,
          info,
        };
      }
      return device;
    });

    setSelectedDeviceList(array);
  };

  const handleDeleteDevice = (id: number, idx: number) => {
    const array = selectedDeviceList.map((device) => {
      if (device.template_device_id === id) {
        const answer = window.confirm(
          device.name +
            (idx + 1) +
            '에 대해 입력된 값이 모두 사라집니다.\n정말 삭제하시겠습니까?'
        );
        if (answer) {
          const info = [...device.info];
          info.splice(idx, 1);
          return {
            ...device,
            selected: --device.selected,
            info,
          };
        }
      }
      return device;
    });
    setSelectedDeviceList(array);
  };

  const handleChangeDeviceNum = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number,
    idx: number
  ) => {
    const array = selectedDeviceList.map((device) => {
      if (device.template_device_id === id) {
        let info = [...device.info];
        info[idx].device_num = e.target.value;

        return {
          ...device,
          info,
        };
      }
      return device;
    });
    setSelectedDeviceList(array);
  };

  const handleChangeLocation = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number,
    idx: number
  ) => {
    const array = selectedDeviceList.map((device) => {
      if (device.template_device_id === id) {
        let info = [...device.info];
        info[idx].location = e.target.value;

        return {
          ...device,
          info,
        };
      }
      return device;
    });
    setSelectedDeviceList(array);
  };

  const handleSelectPrimaryCategoryId = (id: number) => {
    setCurrentPrimaryCategoryId(id);

    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <S.Container>
      <S.TableWrapper width='100%' ref={sectionRef}>
        <S.LeftTable>
          <thead>
            <tr>
              <th>번호</th>
              <th>점검대상</th>
              <th>전체수량</th>
              <th>점검수량</th>
              <th>삭제</th>
            </tr>
          </thead>
          <tbody>
            {selectedDeviceList.map((device, idx) => (
              <tr key={device.template_device_id}>
                <td>{idx + 1}</td>
                <td
                  onClick={() =>
                    handleSelectPrimaryCategoryId(device.template_device_id)
                  }
                >
                  {device.name}
                </td>
                <td>
                  <S.PlusIconContainer>
                    <S.StyledInput
                      width='4.0625rem'
                      height='1.875rem'
                      value={device.total}
                      onChange={(e) =>
                        handleTotalCount(e, device.template_device_id)
                      }
                    />
                  </S.PlusIconContainer>
                </td>
                <td>
                  <S.PlusIconContainer>
                    {device.selected}
                    <HiPlusSm
                      cursor='pointer'
                      size='1.5rem'
                      onClick={() =>
                        handleAddCount(
                          device.template_device_id,
                          device.total,
                          device.selected
                        )
                      }
                    />
                  </S.PlusIconContainer>
                </td>
                <td>
                  <BiTrash
                    size='1.25rem'
                    cursor='pointer'
                    onClick={() => handleDeleteDeviceList(device)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </S.LeftTable>
      </S.TableWrapper>
      <S.TableWrapper width='100%'>
        <S.RightTable>
          <thead>
            <tr>
              <th>점검대상</th>
              <th>장비번호</th>
              <th>위치</th>
              <th>삭제</th>
            </tr>
          </thead>
          <tbody>
            {selectedDeviceList.map((device) => {
              if (device.template_device_id === currentPrimaryCategoryId) {
                return device.info.map((info, index) => (
                  <S.StyledTr isDeleted={!!info.deleted} key={index}>
                    <td>{device.name + (index + 1)}</td>
                    <td>
                      <S.StyledInput
                        width='9.375rem'
                        height='1.875rem'
                        value={info.device_num}
                        onChange={(e) =>
                          handleChangeDeviceNum(
                            e,
                            device.template_device_id,
                            index
                          )
                        }
                      />
                    </td>
                    <td>
                      <S.StyledInput
                        width='9.375rem'
                        height='1.875rem'
                        value={info.location}
                        onChange={(e) =>
                          handleChangeLocation(
                            e,
                            device.template_device_id,
                            index
                          )
                        }
                      />
                    </td>
                    <td>
                      <BiTrash
                        size='1.25rem'
                        cursor='pointer'
                        onClick={() =>
                          handleDeleteDevice(device.template_device_id, index)
                        }
                      />
                    </td>
                  </S.StyledTr>
                ));
              }
              return null;
            })}
          </tbody>
        </S.RightTable>
      </S.TableWrapper>
    </S.Container>
  );
};

export default WorkAddCategoryListTable;
