import React from 'react';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { AiFillCamera } from 'react-icons/ai';
import { MdOutlineCancel } from 'react-icons/md';

import type { ErrorResponse } from '@/types/client.types';
import { postPresignedUrlImg } from '@/apis/img';

import { TOAST_ID } from '@/constants/toastId';

import Text from '@/components/common/Text';

import { Theme } from '@/styles/Theme';

import * as S from './style';
import { useLoadingStore } from '@/store/loading';

interface Props {
  isMultiple?: boolean;
  title: string;
  subTitle?: string;
  imgList: string[];
  setImgList: React.Dispatch<React.SetStateAction<string[]>>;
  selectedImgLength: number;
  selectedImgList: string[];
  setSelectedImgList: React.Dispatch<React.SetStateAction<string[]>>;
}

const WorkReportPresignedImgList = ({
  isMultiple = false,
  title,
  subTitle,
  imgList,
  setImgList,
  selectedImgLength,
  selectedImgList,
  setSelectedImgList,
}: Props) => {
  const handleSelectImg = (src: string) => {
    if (selectedImgList.includes(src)) {
      const filtered = selectedImgList.filter((img) => img !== src);
      setSelectedImgList(filtered);
      return;
    }

    if (isMultiple && selectedImgLength) {
      if (selectedImgList.length >= selectedImgLength) {
        toast.error(`이미지는 ${selectedImgLength}개만 선택 가능합니다.`, {
          toastId: TOAST_ID.WORK_TARGET_IMG_LIST,
        });
        return;
      }
      setSelectedImgList((prev) => [...prev, src]);
      return;
    }
    setSelectedImgList([src]);
  };

  const handleRemoveImg = (src: string) => {
    const filtered = imgList.filter((img) => img !== src);
    setImgList(filtered);

    const selectedFiltered = selectedImgList.filter((img) => img !== src);
    setSelectedImgList(selectedFiltered);
  };

  const { setLoading, beginRequest, endRequest } = useLoadingStore.getState();

  const handleImgChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      beginRequest();
      if (e.target.files && e.target.files.length > 0) {
        const files = e.target.files;
        const fileNames = Array.from(files).map((file) => file.name);
        const uploadedUrls: string[] = [];

        for (let i = 0; i < fileNames.length; i++) {
          const { data } = await postPresignedUrlImg({ files: [fileNames[i]] });

          const url = data[0];
          const s3Res = await axios({
            method: 'put',
            url: url,
            data: files[i],
            timeout: 120000,
          });
          if (s3Res.status === 200) {
            uploadedUrls.push(url.split('?')[0]);
          }
        }
        setImgList((prev) => [...prev, ...uploadedUrls]);

        const remainingSlots = selectedImgLength - selectedImgList.length;
        if (remainingSlots > 0) {
          const newSelectedImgs = uploadedUrls.slice(0, remainingSlots);
          setSelectedImgList((prev) => [...prev, ...newSelectedImgs]);
        }
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError<ErrorResponse> = error;
        toast.error(axiosError.response?.data.message);
      } else {
        console.log(error);
      }
    } finally {
      endRequest();
      setLoading(false);
    }
  };

  return (
    <S.Container>
      <S.TextContainer>
        <Text fontSize='1.125rem' fontWeight={500}>
          {title} (총 {imgList.length}개)
        </Text>
        {subTitle && (
          <Text fontSize='0.9375rem' color={Theme.colors.G_2}>
            {subTitle}
          </Text>
        )}
      </S.TextContainer>
      <S.ImgContainer>
        <S.FileLabel htmlFor={`img_input_${title}`}>
          <AiFillCamera size='3.125rem' />
          이미지 업로드
        </S.FileLabel>
        <S.FileInput
          type='file'
          id={`img_input_${title}`}
          accept='.jpg, .jpeg, .png'
          multiple
          onChange={handleImgChange}
        />
        {imgList.map((img, idx) => (
          <S.ImgWrapper key={idx} isSelected={selectedImgList.includes(img)}>
            <S.Img onClick={() => handleSelectImg(img)} src={img} />
            <S.ImgRemoveIconWrapper onClick={() => handleRemoveImg(img)}>
              <MdOutlineCancel size='1.75rem' />
            </S.ImgRemoveIconWrapper>
          </S.ImgWrapper>
        ))}
      </S.ImgContainer>
    </S.Container>
  );
};

export default WorkReportPresignedImgList;
