import * as ExcelJS from 'exceljs';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { getExcelData } from '@/apis/InspectionArea';
import type { ErrorResponse } from '@/types/client.types';

import { useLoadingStore } from '@/store/loading';

import { exportPrimaryTable } from '@/utils/excelExport/exportPrimaryTable';
import { templateImg6CopySheet } from '@/utils/excelExport/templateImg6/templateImg6CopySheet';
import { templateImg3CopySheet } from '@/utils/excelExport/templateImg3/templateImg3CopySheet';
import { exportAppendixTable } from '@/utils/excelExport/exportAppendixTable';
import { exportReportOutline } from '@/utils/excelExport/report/exportReportOutline';
import { exportReportPlan } from '@/utils/excelExport/report/exportReportPlan';
import { exportReportStatus } from '@/utils/excelExport/report/exportReportStatus';
import { exportReportConsider } from '@/utils/excelExport/report/exportReportConsider';

export const exportWorkExcel = async (
  inspectionAreaId: number,
  imgCountTemplate: number,
  isTotalReport: boolean
) => {
  const { setLoading, beginRequest, endRequest } = useLoadingStore.getState();

  try {
    beginRequest();

    const workData = await getExcelData(inspectionAreaId);

    const { device_list, total_report } = workData.data;

    // 첫Excel 파일 로드
    const response = await fetch('/report/0. 목차.xlsx', {
      cache: 'no-store',
    });
    const arrayBuffer = await response.arrayBuffer();
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(arrayBuffer);

    // 전체보고서로 받을 때
    if (isTotalReport) {
      // 1. 성능점검 개요
      await exportReportOutline(workbook, inspectionAreaId);
      // 2.성능점검 계획
      await exportReportPlan(workbook, inspectionAreaId);
      // 3. 대상 건축물 현황
      await exportReportStatus(workbook, inspectionAreaId);

      // 4.1 성능점검표 (별지 3호)
      await exportPrimaryTable(total_report, workbook);

      // 점검 세부 내역
      // 템플릿 엑셀파일 불러와서 시트 복사
      // 삭제된 빈 배열은 없애기
      const deviceListData = device_list.filter((arr) => arr.length > 0);
      // 엑셀 사진 3개 템플릿 선택한 경우
      if (imgCountTemplate === 3) {
        for (let i = 0; i < deviceListData.length; i++) {
          if (deviceListData[i].length > 0) {
            await templateImg3CopySheet(deviceListData[i], i, workbook);
          }
        }
      }
      // 엑셀 사진 6개 템플릿 선택한 경우
      if (imgCountTemplate === 6) {
        for (let i = 0; i < deviceListData.length; i++) {
          if (deviceListData[i].length > 0) {
            await templateImg6CopySheet(deviceListData[i], i, workbook);
          }
        }
      }

      // 4.2 결과보고서 (별지 4호)
      await exportAppendixTable(workbook, inspectionAreaId);

      // 5. 성능점검 시 검토사항
      await exportReportConsider(workbook, inspectionAreaId, total_report);

      // 예전 버전으로 받을 때
    } else {
      // 4.1 성능점검표 (별지 3호)
      await exportPrimaryTable(total_report, workbook);

      // 4.2 결과보고서 (별지 4호)
      await exportAppendixTable(workbook, inspectionAreaId);

      // 점검 세부 내역
      // 템플릿 엑셀파일 불러와서 시트 복사
      // 삭제된 빈 배열은 없애기
      const deviceListData = device_list.filter((arr) => arr.length > 0);
      // 엑셀 사진 3개 템플릿 선택한 경우
      if (imgCountTemplate === 3) {
        for (let i = 0; i < deviceListData.length; i++) {
          if (deviceListData[i].length > 0) {
            await templateImg3CopySheet(deviceListData[i], i, workbook);
          }
        }
      }
      // 엑셀 사진 6개 템플릿 선택한 경우
      if (imgCountTemplate === 6) {
        for (let i = 0; i < deviceListData.length; i++) {
          if (deviceListData[i].length > 0) {
            await templateImg6CopySheet(deviceListData[i], i, workbook);
          }
        }
      }

      // 목차 지우기
      workbook.removeWorksheet(workbook.worksheets[0].id);
      workbook.worksheets[0].name = '대상점검표';
      workbook.worksheets[0].getCell(1, 1).value = '기계설비 성능점검 세부내역';
      workbook.worksheets[1].name = '결과보고서';
    }

    // 수정된 파일 저장 (브라우저에서 다운로드)
    const updatedBuffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([updatedBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${workData.data.total_report.name} 기계설비 성능점검 보고서.xlsx`;

    link.click();
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      const axiosError: AxiosError<ErrorResponse> = error;
      toast.error(axiosError.response?.data.message);
    } else {
      console.log(error);
      toast.error('엑셀 다운로드 중 오류가 발생하였습니다.');
    }
  } finally {
    endRequest();
    setLoading(false);
  }
};
