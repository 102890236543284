import { NavLink } from 'react-router-dom';
import Text from '../Text';
import styled from '@emotion/styled';
import { Theme } from '@/styles/Theme';

interface Props {
  name: string;
  router: string;
  children: JSX.Element;
}

const GnbItem = ({ name, router, children }: Props) => {
  return (
    <LinkWrapper to={router}>
      <IconWrapper>{children}</IconWrapper>
      <Text fontSize='0.9375rem' fontWeight={500}>
        {name}
      </Text>
    </LinkWrapper>
  );
};

export default GnbItem;

const LinkWrapper = styled(NavLink)`
  width: 100%;

  display: flex;
  align-items: center;
  gap: 0.9375rem;

  /* border-top-right-radius: 1.875rem;
  border-bottom-right-radius: 1.875rem; */

  padding: 0.625rem 1.25rem;

  transition: 0.15s ease-in-out;

  /* border-left:0.25rem solid ${Theme.colors.G_5}; */

  :hover {
    span {
      font-weight: 700;
    }
  }

  &.active {
    /* border-left: 0.25rem solid ${Theme.colors.B_3};  */
    background-color: ${Theme.colors.B_3};
    span {
      color: ${Theme.colors.WHITE};
      font-weight: 700;
    }
  }
`;

const IconWrapper = styled.div`
  padding: 0.4375rem;
  border-radius: 50%;
  background-color: ${Theme.colors.G_4};
`;
