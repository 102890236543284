export const QUERY_KEY = {
  TERMS: 'terms',
  WORKERS: 'workers',
  COMPANY_INFO: 'company_info',
  PAYMENT_INFO: 'payment_info',
  PRIMARY_CATEGORY: 'primary_category',
  SUB_CATEGORY: 'sub_category',
  DASHBOARD: 'dashboard',
  WORK_INFO: 'work_info',
  WORK_TARGET_RESULT: 'work_target_result',
  WORK_TARGET_ITEM_RESULT: 'work_target_item_result',
  METHOD_STANDARD: 'method_standard',
  CARD_INFO: 'card_info',
  PAYMENT_PRICE: 'payment_price',
  NOTICE_POPUP: 'notice_popup',
  APPENDIX_REPORT: 'appendix_report',
  APPENDIX_HISTORY: 'appendix_history',
  APPENDIX_HISTORY_DEVICELIST: 'appendix_history_devicelist',
  UPDATE_INFO: 'update_info',
  INSPECTION_HISTORY: 'inspection_history',

  REPORT: {
    PLAN: 'report_plan',
    EQUIPMENT: 'report_equipment',
    BUILDING: {
      INFO: 'report_building_info',
      WORKER: 'report_building_worker',
      TARGET_DEVICE: 'report_target_device',
      GUIDELINE: 'report_building_guildeline',
      ORGANIZATION: 'report_building_organization',
      PRESERVATION: 'report_preservation',
    },
    AGE: 'report_age',
    IMPROVEMENT: 'report_improvement',
    SPECIFIC_IMPROVEMENT: 'report_specific_improvement',
    ENERGY: 'report_energy',
    GUIDELINE: 'report_guideline',
    OUTLINE: 'report_outline',
  },

  ADMIN: {
    USER_LIST: 'admin_user_list',
  },
};
