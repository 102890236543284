import styled from '@emotion/styled';

import { Theme } from '@/styles/Theme';

interface Props {
  type?: 'button' | 'submit' | 'reset';
  width?: string;
  height?: string;
  padding?: string;
  color?: string;
  fontSize?: string;
  fontWeight?: number;
  backgroundColor?: string;
  borderRadius?: string;
  border?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  children: React.ReactNode;
}

const Button = ({
  type = 'button',
  width,
  height,
  padding = '0.625rem',
  color = Theme.colors.WHITE,
  fontSize = '1rem',
  fontWeight = 500,
  backgroundColor = Theme.colors.B_3,
  borderRadius = '0.375rem',
  border = 'none',
  onClick,
  disabled,
  children,
}: Props) => {
  return (
    <Wrapper
      type={type}
      width={width}
      height={height}
      padding={padding}
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
      border={border}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Wrapper>
  );
};

export default Button;

const Wrapper = styled.button<Props>`
  cursor: pointer;

  border: ${({ border }) => border};

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3125rem;

  padding: ${({ padding }) => padding};
  width: ${({ width }) => width};
  height: ${({ height }) => height};

  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};

  border-radius: ${({ borderRadius }) => borderRadius};
  background-color: ${({ backgroundColor }) => backgroundColor};

  :disabled {
    cursor: not-allowed;

    color: ${Theme.colors.G_2};
    background-color: ${Theme.colors.G_5};
  }

  transition: 0.2s ease-in-out;
`;
