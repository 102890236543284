import { Theme } from '@/styles/Theme';
import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  gap: 1.875rem;
`;

export const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 0.625rem;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;

  .react-datepicker__input-container {
    input {
      cursor: pointer;

      width: 25.625rem;
      height: 2.5rem;

      font-size: 1rem;
      font-weight: 400;
      border-radius: 0.375rem;
      box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25);

      text-align: center;

      :focus {
        outline: none;
      }

      :disabled {
        background-color: ${Theme.colors.G_5};
      }
    }
  }

  .react-datepicker-popper {
    z-index: 20 !important;
  }

  .react-datepicker {
    font-family: 'Noto Sans KR', sans-serif;

    .react-datepicker__triangle {
      left: -15rem !important;
    }
  }
`;

export const LabelWrapper = styled.label`
  font-size: 1.25rem;
  font-weight: 500;

  margin-bottom: 0.375rem;
`;

export const StyledTextarea = styled.textarea`
  width: 25.625rem;
  height: 15.625rem;

  background-color: ${Theme.colors.WHITE};
  border-radius: 0.375rem;
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25);

  border: none;

  padding: 0.9375rem;

  color: ${Theme.colors.BLACK};
  font-size: 1rem;
  font-weight: 400;
  resize: none;

  :read-only {
    background-color: ${Theme.colors.G_5};
  }

  ::placeholder {
    color: ${Theme.colors.G_2};
  }

  :focus {
    outline: none;
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: separate;

  tr {
    th:first-of-type {
      width: 6.25rem;
    }
    th:nth-of-type(3) {
      width: 9.375rem;
    }
    th:last-of-type {
      width: 9.375rem;
    }
  }
`;
