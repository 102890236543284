import styled from '@emotion/styled';
import { Theme } from '@/styles/Theme';
import { css } from '@emotion/react';

export const Thead = styled.thead`
  width: 100%;

  background-color: ${Theme.colors.G_6};
  border-bottom: 0.0625rem solid ${Theme.colors.G_2};

  font-weight: bold;
`;

export const TheadTr = styled.tr`
  display: table;
  width: 100%;
`;

export const Th = styled.th<{ width: string }>`
  position: relative;
  width: ${({ width }) => width};

  color: ${Theme.colors.BLACK};

  padding: 0.75rem 0.3125rem;

  cursor: pointer;

  :hover {
    div {
      opacity: 1;
    }
  }
`;

export const ThItemWrapper = styled.div`
  display: flex;
  gap: 0.625rem;
  justify-content: center;
  align-items: center;
`;

export const ResizerLine = styled.div<{ isResizing: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 0.125rem;
  opacity: 0;
  background-color: ${Theme.colors.B_5};
  cursor: col-resize;
  user-select: none;
  touch-action: none;

  ${({ isResizing }) =>
    isResizing &&
    css`
      background: ${Theme.colors.B_2};
      opacity: 1;
    `}
`;
