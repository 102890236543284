import { ReactNode, useState } from 'react';
import { FaCheck } from 'react-icons/fa6';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import type {
  GetCompanyInfoResponseProps,
  PaymentInfo,
} from '@/types/company.types';
import { TRIAL_PREIOD } from '@/constants/constants';
import Text from '@/components/common/Text';
import Spacer from '@/components/common/Spacer';
import { Theme } from '@/styles/Theme';
import * as S from './style';
import { WorkerPaymentInfoBox } from '@/components/newPayment';
import SubscribeWorkersModal from '@/components/newPayment/WorkerPaymentInfoBox/ManageWorkerPaymentModal/SubscribeWorkersModal';
import { useSelectedWorkerListStore } from '@/store/selectedWorkerList';
import usePostTrialWorker from '@/hooks/usePostTrialWorker';
import { postStartTrial } from '@/apis/company';

type CardRegisterInfoProps = {
  companyInfoData: GetCompanyInfoResponseProps;
  paymentInfoData: PaymentInfo;
};

type BoxTextType = {
  children: ReactNode;
};

export const BoxText = ({ children }: BoxTextType) => {
  return (
    <S.BoxText>
      <FaCheck size='1.0625rem' />
      {children}
    </S.BoxText>
  );
};

const CardRegisterInfo = ({
  companyInfoData,
  paymentInfoData,
}: CardRegisterInfoProps) => {
  const currentDate = new Date();
  const trialStartDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );

  const trialEndDate = new Date(trialStartDate);
  trialEndDate.setDate(trialStartDate.getDate() + TRIAL_PREIOD);

  const { isTrial } = companyInfoData;

  const [isShowSubscribeWorkersModal, setIsShowSubscribeWorkersModal] =
    useState(false);

  const { selectedAddWorkerList, selectedRestoreWorkerList } =
    useSelectedWorkerListStore();

  const { mutate: registerTrialWorker } = usePostTrialWorker();

  const onStartClick = () => {
    if (
      selectedAddWorkerList.length > 0 ||
      selectedRestoreWorkerList.length > 0
    ) {
      if (isTrial) {
        const data = {
          worker_list: {
            add: selectedAddWorkerList,
            restore: selectedRestoreWorkerList,
          },
        };
        registerTrialWorker(data, {
          onSuccess() {
            postStartTrial();
          },
        });
      } else {
        setIsShowSubscribeWorkersModal(true);
      }
    } else {
      if (isTrial) {
        toast.error('추가할 작업자를 한 명 이상 추가 후 선택해 주세요.');
      } else {
        toast.error('추가, 복구할 작업자를 한 명 이상 선택해 주세요.');
      }
    }
  };

  // const selectedWorkerCount =
  //   selectedAddWorkerList.length + selectedRestoreWorkerList.length;

  return (
    <S.Container>
      {isShowSubscribeWorkersModal && (
        <SubscribeWorkersModal
          setIsShowModal={setIsShowSubscribeWorkersModal}
          selectedAddWorkerList={selectedAddWorkerList}
          selectedRestoreWorkerList={selectedRestoreWorkerList}
        />
      )}
      <>
        <S.BoxWrapper>
          <Text fontSize='1.0625rem' fontWeight={700}>
            1. 앱을 사용 할 작업자를 추가하거나 복구할 작업자를 선택해주세요.
          </Text>
          <Spacer y='0.625rem' />
          <WorkerPaymentInfoBox paymentData={paymentInfoData} />
        </S.BoxWrapper>
        <S.BoxWrapper>
          <Text fontSize='1.0625rem' fontWeight={700}>
            {isTrial
              ? '2. 무료체험 종료 후, 결제될 정기금액입니다.'
              : '2. 요금제를 확인해주세요.'}
          </Text>
          <Spacer y='0.625rem' />
          <S.PayBoxContainer>
            <S.PayBox>
              <S.ContainerBox>
                <S.InfoBox>
                  <Text
                    fontSize='1.875rem'
                    fontWeight={700}
                    color={Theme.colors.B_2}
                  >
                    월간 결제
                  </Text>
                  <S.BoxDesc>작업자당 다음 요금이 부과됩니다.</S.BoxDesc>
                  <S.BoxPrice>
                    {/* {(
                      paymentInfoData.pricePerWorker * selectedWorkerCount
                    ).toLocaleString()} */}
                    {paymentInfoData.pricePerWorker.toLocaleString()}원
                    <Text color={Theme.colors.G_2} fontSize='0.875rem'>
                      (부가세 10% 포함)
                    </Text>
                  </S.BoxPrice>
                  <S.BoxTextContainer>
                    <Text fontSize='1.0625rem' fontWeight={700}>
                      사용가능 기능
                    </Text>
                    <BoxText>점검지 관리 기능</BoxText>
                    <BoxText>작업자 관리 기능</BoxText>
                    <BoxText>웹/앱 보고서 작성 기능</BoxText>
                    <BoxText>보고서 다운로드 기능</BoxText>
                  </S.BoxTextContainer>
                </S.InfoBox>
                <S.InfoBox>
                  <Text
                    fontSize='1.875rem'
                    fontWeight={700}
                    color={Theme.colors.B_3}
                  >
                    연간 결제
                  </Text>
                  <S.BoxDesc>연간 결제 시, 별도 문의가 필요합니다.</S.BoxDesc>
                  <S.BoxPrice>별도 문의</S.BoxPrice>
                  <S.BoxTextContainer>
                    <Text fontSize='1.0625rem' fontWeight={700}>
                      추가제공 혜택
                    </Text>
                    <BoxText>1:1 서비스 기술지원</BoxText>
                  </S.BoxTextContainer>
                </S.InfoBox>
              </S.ContainerBox>
              <S.Line />
              <ul style={{ marginLeft: '1.25rem' }}>
                {isTrial && (
                  <>
                    <li>
                      <Text fontWeight={500} color='#f67b37'>
                        작업자 추가 시점부터 3주 간 서비스 무료체험이
                        가능합니다.
                      </Text>
                    </li>
                    <li>
                      무료 체험 종료 후 서비스를 이어서 이용하시려면 카드를
                      등록해주시면 됩니다.
                    </li>
                    {/* <li>
                      무료 체험 종료 시점에 카드가 등록되어 있으면 유료 서비스
                      이용으로 간주하고 자동으로 작업자별 정기 결제 후 서비스
                      이용이 가능합니다.
                    </li> */}
                    <li>
                      결제일 전에 카드 등록을 해지하여도 서비스 무료체험이
                      가능합니다.
                    </li>
                    {/* TODO 작업자 수를 자유롭게 가능하게 하는게 낫지 않을까? */}
                    <li>
                      {/* 작업자 수는 서비스 무료체험 기간 동안 자유롭게 변경 */}
                      서비스 무료체험 기간 동안 작업자 수는 최대 3명까지 추가
                      가능합니다.
                    </li>
                    {/* <li>마지막으로 반영된 작업자 수로 결제가 청구됩니다.</li> */}
                    <br />
                  </>
                )}
                <li>
                  <Text fontWeight={700}>
                    {/* {isTrial
                      ? `매월 ${format(trialEndDate, 'd')}일에`
                      : `금일(${format(currentDate, 'yyyy-MM-dd')})에`}
                  </Text>{' '} */}
                    {isTrial
                      ? `무료 체험기간 동안 추가한 작업자는 ${format(
                          trialEndDate,
                          'yyyy-MM-dd'
                        )}에 첫 결제 후 매월 ${format(trialEndDate, 'd')}일에 `
                      : `금일 추가/복구한 작업자는 금일(${format(
                          currentDate,
                          'yyyy-MM-dd'
                        )})에 `}
                    정기금액이 자동으로 청구됩니다.
                  </Text>
                </li>
                <li>
                  서비스를 아직 사용하지 않은 경우, 구매일로부터 3일 이내에
                  청약철회권을 행사할 수 있습니다.{' '}
                  <a
                    href='https://www.notion.so/806f4653d0854585beb5ef87f7f1a1f7?pvs=4'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <Text color={Theme.colors.B_3} hasUnderline={true}>
                      취소와 환불에 관해 자세히 알아보기
                    </Text>
                  </a>
                </li>
              </ul>
              <br />
              <br />
              계속 진행함으로써 본 약관에 동의함을 확인합니다.
              <S.BuyButton onClick={onStartClick}>
                {isTrial ? '무료체험 시작하기' : '결제 후 서비스 사용하기'}
              </S.BuyButton>
            </S.PayBox>
          </S.PayBoxContainer>
        </S.BoxWrapper>
      </>
    </S.Container>
  );
};
export default CardRegisterInfo;
