import styled from '@emotion/styled';
import { Theme } from '@/styles/Theme';

export const Table = styled.table`
  background-color: ${Theme.colors.WHITE};
  text-align: center;
  td {
    height: 2.5rem;
    border: 0.0625rem solid ${Theme.colors.BLACK};
  }
  tbody {
    width: 100%;
  }
`;

export const TableHeader = styled.td`
  background-color: ${Theme.colors.G_5};
  text-align: center;
  font-weight: 500;
`;

export const TableFooter = styled.td`
  text-align: left;
  padding: 0.625rem;
`;

export const ToggleButtonContainer = styled.td`
  padding: 0 !important;
`;

export const IconWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${Theme.colors.G_5 + 80};
  }
`;

export const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 0.1875rem;
  align-items: center;
  margin-top: 0.625rem;
`;

export const FlexContainer = styled.div`
  display: flex;
  gap: 0.1875rem;
  flex-direction: column;
  margin-top: 0.625rem;
`;

export const BoxContainer = styled.div`
  width: 50%;
  display: flex;
  height: 100%;

  div {
    border-right: 0.0625rem solid ${Theme.colors.BLACK};
  }
`;

export const InfoContainer = styled.tbody`
  tr:first-of-type {
    td:first-of-type {
      background-color: ${Theme.colors.G_5};
    }
  }
`;

export const TopContainer = styled.div`
  display: flex;
  margin-bottom: 1.25rem;
  justify-content: space-between;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const ToggleButtonWrapper = styled.div<{ isSelected: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ isSelected }) =>
    isSelected ? Theme.colors.B_6 : Theme.colors.WHITE};
  color: ${({ isSelected }) =>
    isSelected ? Theme.colors.B_3 : Theme.colors.G_3};
  cursor: ${({ isSelected }) => (isSelected ? '' : 'pointer')};
`;
export const StyledTextarea = styled.textarea<{
  height?: string;
  textAlign?: string;
}>`
  display: block;

  width: 100%;
  height: ${({ height }) => height};
  background-color: rgb(255, 248, 226, 0.7);

  border: none;

  padding: 0.3125rem;

  color: ${Theme.colors.BLACK};
  font-size: 1rem;
  font-weight: 400;
  text-align: ${({ textAlign }) => textAlign};
  resize: none;

  :disabled {
    background-color: ${Theme.colors.WHITE};
  }

  ::placeholder {
    color: ${Theme.colors.G_2};
  }

  :focus {
    outline: none;
  }
`;
