import React, { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';

import type { InspectionItemValue } from '@/types/deviceReport.types';

import { calculationRules } from '@/utils/calculateValues';

import Input from '@/components/common/Input';
import Text from '@/components/common/Text';
import Spacer from '@/components/common/Spacer';

import { Theme } from '@/styles/Theme';

import * as S from './style';

interface Props {
  isEdit: boolean;
  valueInfo: InspectionItemValue[];
  setValueInfo: React.Dispatch<React.SetStateAction<InspectionItemValue[]>>;
}

const ResultItemValueList = ({ isEdit, valueInfo, setValueInfo }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  // 렌더링을 위해 데이터 변형
  const transFormedData = transformData(valueInfo);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    templateId: number
  ) => {
    const inputValue = e.target.value;

    if (inputValue === '') {
      const updatedValueInfo = updateCalculatedValues(valueInfo, templateId, 0);
      setValueInfo(updatedValueInfo);
    } else if (!isNaN(Number(inputValue))) {
      const newValue = Number(inputValue);
      const updatedValueInfo = updateCalculatedValues(
        valueInfo,
        templateId,
        newValue
      );
      setValueInfo(updatedValueInfo);
    }
  };

  return (
    <S.Container>
      <S.TitleContainer onClick={() => setIsOpen((prev) => !prev)}>
        <Text fontSize='1.125rem' fontWeight={500}>
          설비 측정값
        </Text>
        <IoIosArrowDown
          size='1.5rem'
          color={Theme.colors.G_1}
          style={{
            transform: `rotate(${isOpen ? '180deg' : '0'})`,
          }}
        />
      </S.TitleContainer>
      {isOpen && (
        <S.DetailContainer>
          {transFormedData.map((info, idx) => (
            <div key={idx}>
              <Text
                fontSize='1.0625rem'
                color={Theme.colors.B_3}
                fontWeight={700}
              >
                {info.title
                  ? `${info.title} : ${info.subtitle}`
                  : info.subtitle}
              </Text>
              <Spacer y='0.3125rem' />
              <S.InputContainer>
                {info.values.map((value) => (
                  <Input
                    type='number'
                    name={
                      value.unit
                        ? `${value.description}(${value.unit})`
                        : value.description
                    }
                    label={
                      value.unit
                        ? `${value.description}(${value.unit})`
                        : value.description
                    }
                    key={value.template_id}
                    width='7.5rem'
                    height='2.1875rem'
                    isOnFocus
                    value={value.value}
                    onChange={(e) => handleInputChange(e, value.template_id)}
                    disabled={
                      calculationRules.hasOwnProperty(value.template_id) ||
                      !isEdit
                    }
                  />
                ))}
              </S.InputContainer>
            </div>
          ))}
        </S.DetailContainer>
      )}
    </S.Container>
  );
};

export default ResultItemValueList;

interface TransformedDataItem {
  template_id: number;
  unit: string;
  value: number;
  description: string;
}

interface TransformedData {
  title?: string;
  subtitle?: string;
  values: TransformedDataItem[];
}

function transformData(data: InspectionItemValue[]): TransformedData[] {
  const result: TransformedData[] = [];
  const map: { [key: string]: TransformedData } = {};

  data.forEach((item) => {
    const parts = item.label.split('/');
    let title: string | undefined,
      subtitle: string | undefined,
      description: string;

    if (parts.length === 3) {
      // 'title/subtitle/description' 형태
      [title, subtitle, description] = parts;
    } else if (parts.length === 2) {
      // 'subtitle/description' 형태
      [subtitle, description] = parts;
      title = undefined; // title 없음
    } else {
      // 'description' 형태
      description = parts[0];
      title = undefined; // title 없음
      subtitle = undefined; // subtitle 없음
    }

    const key = title
      ? `${title}|${subtitle}`
      : subtitle
      ? `|${subtitle}`
      : `||${description}`; // title, subtitle, 또는 description 기준으로 key 생성

    if (!map[key]) {
      map[key] = { title, subtitle, values: [] };
      result.push(map[key]);
    }

    // 현재 item을 적절한 그룹에 추가
    map[key].values.push({
      template_id: item.template_id,
      unit: item.unit,
      value: item.value,
      description,
    });
  });

  return result;
}

// 변경된 input 값을 기반으로 계산값을 업데이트하는 함수
const updateCalculatedValues = (
  valueInfo: InspectionItemValue[],
  changedTemplateId: number,
  newValue: number
): InspectionItemValue[] => {
  // 입력 값 업데이트
  let updatedValues = valueInfo.map((item) =>
    item.template_id === changedTemplateId ? { ...item, value: newValue } : item
  );

  Object.entries(calculationRules).forEach(([calcTemplateId, rule]) => {
    if (rule.dependencies.includes(changedTemplateId)) {
      // 변경된 template_id가 의존성 목록에 있으면 계산 실행
      const calculatedValue = rule.calculate(updatedValues);
      updatedValues = updatedValues.map((item) =>
        item.template_id === parseInt(calcTemplateId)
          ? { ...item, value: calculatedValue }
          : item
      );
    }
  });

  return updatedValues;
};
