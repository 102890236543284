import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { FiChevronLeft } from 'react-icons/fi';

import Text from '@/components/common/Text';
import Spacer from '@/components/common/Spacer';

import { Theme } from '@/styles/Theme';

interface Props {
  width?: string;
  height?: string;
  title?: string;
  padding?: string;
  children: React.ReactNode;
  onClickBack?: () => void;
}

const Box = ({
  width,
  height,
  title,
  padding = '1.875rem',
  onClickBack,
  children,
}: Props) => {
  const navigate = useNavigate();

  const handleBack = () => {
    if (onClickBack) {
      onClickBack();
      return;
    }
    navigate(-1);
  };

  return (
    <Container width={width} height={height} padding={padding}>
      {title && (
        <TitleWrapper>
          <FiChevronLeft cursor='pointer' size='1.5rem' onClick={handleBack} />
          <Text fontSize='2rem' fontWeight={700}>
            {title}
          </Text>
          <Spacer x='1.5rem' />
        </TitleWrapper>
      )}
      {children}
    </Container>
  );
};

export default Box;

const Container = styled.div<Props>`
  padding: ${({ padding }) => padding};

  width: ${({ width }) => width};
  height: ${({ height }) => height};

  border-radius: 1.25rem;
  background-color: ${Theme.colors.G_6};
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 1.875rem;
`;
