import { useQuery } from '@tanstack/react-query';

import { getPaymentInfo } from '@/apis/company';

import { QUERY_KEY } from '@/constants/queryKey';

const usePaymentInfo = () => {
  const query = useQuery([QUERY_KEY.PAYMENT_INFO], {
    queryFn: getPaymentInfo,
  });

  return query;
};

export default usePaymentInfo;
