import type { InspectionLastHistory } from '@/types/deviceReport.types';
import * as styled from '@/components/inspectionHistory/LastEditedHistory/style';

interface Props extends InspectionLastHistory {
  size?: 'small' | 'large';
}

const LastEditedHistoryText = ({
  workerName,
  updatedAt,
  size = 'large',
}: Props) => {
  return (
    <>
      {workerName && updatedAt && (
        <styled.LastHistoryWrapper size={size}>
          {workerName && <styled.LastEditor>{workerName}</styled.LastEditor>}
          <time>{updatedAt}</time>
        </styled.LastHistoryWrapper>
      )}
    </>
  );
};

export default LastEditedHistoryText;
