import client from '@/apis/client';
import type { Response } from '@/types/client.types';
import type {
  GetPersistingPeriodAgeProps,
  PerformanceImprovementItem,
  PostPerformanceImprovementProps,
  PostPersistingPeriodAgeProps,
  PostSpecificImprovementItemProps,
  SpecificImprovementItem,
} from '@/types/report/improvePlan.types';

// Ⅴ. 성능점검 시 검토사항
// 2. 성능개선 계획수립

// 1) 기계설비 내구연한에 따른 노후도
export const getPersistingPeriodAge = (inspectionAreaId: number) =>
  client
    .get<Response<GetPersistingPeriodAgeProps>>(
      '/report/performance-improvement/old-age',
      {
        params: { inspectionAreaId },
      }
    )
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error fetching persisting period age:', error);
      throw error;
    });

export const postPersistingPeriodAge = (body: PostPersistingPeriodAgeProps) =>
  client
    .post<Response<PostPersistingPeriodAgeProps>>(
      '/report/performance-improvement/old-age/upload',
      body
    )
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error posting persisting period age:', error);
      throw error;
    });

// 2) 성능점검표에 따른 부적합 및 개선사항
export const getPerformanceImprovement = (inspectionAreaId: number) =>
  client
    .get<Response<PerformanceImprovementItem[]>>(
      '/report/performance-improvement/improvement',
      {
        params: { inspectionAreaId },
      }
    )
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error fetching performance improvement:', error);
      throw error;
    });

export const postPerformanceImprovement = (
  body: PostPerformanceImprovementProps
) =>
  client
    .post<Response<PostPerformanceImprovementProps>>(
      '/report/performance-improvement/improvement/upload',
      body
    )
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error posting performance improvement:', error);
      throw error;
    });

// 3) 성능개선 필요성 및 연도별 세부개선계획
export const getSpecificImprovement = (inspectionAreaId: number) =>
  client
    .get<Response<SpecificImprovementItem[]>>(
      '/report/performance-improvement/specific-plan',
      {
        params: { inspectionAreaId },
      }
    )
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error fetching specific improvement:', error);
      throw error;
    });

export const postSpecificImprovement = (
  body: PostSpecificImprovementItemProps
) =>
  client
    .post<Response<PostSpecificImprovementItemProps>>(
      '/report/performance-improvement/specific-plan/upload',
      body
    )
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error posting specific improvement:', error);
      throw error;
    });
