import { Theme } from '@/styles/Theme';
import styled from '@emotion/styled';
import { Swiper, SwiperSlide } from 'swiper/react';

export const StyledSwiper = styled(Swiper)`
  width: 100%;
  height: 42.5rem;

  @media (max-width: 48rem) {
    height: 25.625rem;
  }

  .swiper-pagination-bullets {
    bottom: 1.25rem;
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }

  .swiper-pagination-progressbar-fill {
    background: ${Theme.colors.B_3_light};
  }

  .swiper-pagination-progressbar {
    background: ${Theme.colors.G_6};
  }
`;

export const StyledSwiperSlide = styled(SwiperSlide)``;

export const ImgWrapper = styled.img`
  width: 100%;
  height: 100%;
  max-height: 42.5rem;

  object-fit: contain;
`;
