import { useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { HiOutlineSortDescending } from 'react-icons/hi';
import { IoSearchOutline } from 'react-icons/io5';
import { TiDelete } from 'react-icons/ti';

import { ORDERING_LIST, WORK_STATUS_LIST } from '@/constants/constants';

import useDetectOutsideClick from '@/hooks/useDetectOutsideClick';
import useDebounce from '@/hooks/useDebounce';

import Text from '@/components/common/Text';

import { Theme } from '@/styles/Theme';

import * as S from './style';

const DashboardFilterBar = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const ref = useRef<HTMLDivElement>(null);
  const [isOrderingOpen, setIsOrderingOpen] = useDetectOutsideClick(ref, false);
  const [query, setQuery] = useState<string>(searchParams.get('q') ?? '');

  const status = searchParams.get('status') ?? 'all';
  const seq = searchParams.get('seq') ?? 'DESC';
  const deleted = searchParams.get('deleted') ?? '0';

  const handleStatusFilter = (status: string) => {
    searchParams.set('status', status);
    searchParams.set('page', '1');
    setSearchParams(searchParams);
    navigate({ search: searchParams.toString() });
  };

  const handleListOrdering = (seq: string) => {
    searchParams.set('seq', seq);
    searchParams.set('page', '1');
    setSearchParams(searchParams);
    navigate({ search: searchParams.toString() });
  };

  const handleCheckbox = () => {
    if (deleted === '1') {
      searchParams.set('deleted', '0');
      searchParams.set('page', '1');
      setSearchParams(searchParams);
      navigate({ search: searchParams.toString() });
      return;
    }
    searchParams.set('deleted', '1');
    searchParams.set('page', '1');
    setSearchParams(searchParams);
    navigate({ search: searchParams.toString() });
  };

  const debouncedSearch = useDebounce((searchValue: string) => {
    const trimmedSearchValue = searchValue.trim();

    if (trimmedSearchValue === '' && searchValue !== '') {
      return;
    }

    searchParams.set('q', trimmedSearchValue);
    searchParams.set('page', '1');
    setSearchParams(searchParams);
    navigate({ search: searchParams.toString() });
  }, 500);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    debouncedSearch(e.target.value);
  };

  const handleInputClear = () => {
    setQuery('');
    searchParams.delete('q');
    searchParams.set('page', '1');
    setSearchParams(searchParams);
    navigate({ search: searchParams.toString() });
  };

  return (
    <S.TopContainer>
      <S.FilterContainer>
        {WORK_STATUS_LIST.map((item, idx) => (
          <S.FilterContainer key={idx}>
            <S.StatusFilter
              isSelected={status === item.status}
              onClick={() => handleStatusFilter(item.status)}
            >
              {item.name}
            </S.StatusFilter>
            {idx !== WORK_STATUS_LIST.length - 1 && (
              <Text fontSize='1.0625rem' color={Theme.colors.G_4}>
                |
              </Text>
            )}
          </S.FilterContainer>
        ))}
        <S.InputContainer>
          <IoSearchOutline
            size='1.5rem'
            style={{
              position: 'absolute',
              top: '20%',
              left: '0.625rem',
              color: Theme.colors.G_2,
            }}
          />
          <S.StyledInput
            type='text'
            name='query'
            value={query}
            onChange={handleInputChange}
            placeholder='점검지 목록 내에서 검색'
          />
          {query.length > 0 && (
            <TiDelete
              size='1.5rem'
              cursor='pointer'
              style={{
                position: 'absolute',
                top: '20%',
                right: '0.3125rem',
                color: Theme.colors.G_2,
              }}
              onClick={handleInputClear}
            />
          )}
        </S.InputContainer>
      </S.FilterContainer>
      <S.FilterContainer>
        <S.FilterContainer>
          <input
            type='checkbox'
            checked={deleted === '1'}
            onChange={handleCheckbox}
          />
          <Text fontSize='1.0625rem'>삭제 항목 보기</Text>
        </S.FilterContainer>
        <S.OrderingWrapper
          ref={ref}
          onClick={() => setIsOrderingOpen((prev) => !prev)}
        >
          <HiOutlineSortDescending size='1.5rem' />
          <span>
            {ORDERING_LIST.filter((item) => item.seq === seq)[0].name}
          </span>
          <S.OrderdingContainer isOpen={isOrderingOpen}>
            {ORDERING_LIST.map((item, idx) => (
              <S.OrderingItem
                key={idx}
                onClick={() => handleListOrdering(item.seq)}
              >
                {item.name}
              </S.OrderingItem>
            ))}
          </S.OrderdingContainer>
        </S.OrderingWrapper>
      </S.FilterContainer>
    </S.TopContainer>
  );
};

export default DashboardFilterBar;
