import styled from '@emotion/styled';
import { Theme } from '@/styles/Theme';

export const Table = styled.table`
  background-color: ${Theme.colors.WHITE};
  text-align: center;

  td {
    width: 11.25rem;
    height: 2.5rem;
    border: 0.0625rem solid ${Theme.colors.BLACK};
  }

  tr:first-of-type {
    background-color: ${Theme.colors.G_5};
    height: 3.125rem;
  }

  td:nth-of-type(3) {
    width: 9.375rem;
    align: center;
  }

  td:nth-of-type(8) {
    width: 5rem;
    align: center;
  }

  td:nth-of-type(7) {
    width: 5rem;
    align: center;
  }
`;

export const IconWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${Theme.colors.G_5 + 80};
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  margin-bottom: 1.25rem;
  justify-content: space-between;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;
