import styled from '@emotion/styled';

export const Container = styled.div`
  width: 80%;
  margin-bottom: 1.875rem;
  display: flex;
  flex-direction: column;
  gap: 0.9375rem;
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
`;
