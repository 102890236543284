import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import type { InspectionAreaInfo } from '@/types/InspectionArea.types';
import { deleteInspectionArea } from '@/apis/InspectionArea';
import type { ErrorResponse } from '@/types/client.types';

import { ROUTER, WORK_STATUS_LIST } from '@/constants/constants';
import { QUERY_KEY } from '@/constants/queryKey';

import Text from '@/components/common/Text';
import Button from '@/components/common/Button';

import { Theme } from '@/styles/Theme';

import * as S from './style';

const DashboardItem = ({
  id,
  name,
  address,
  worker_list,
  start_date,
  end_date,
  status,
  progress,
  deleted,
}: InspectionAreaInfo) => {
  const navigate = useNavigate();

  const renderStatusChip = (status: string) => {
    const array = WORK_STATUS_LIST.filter((item) => item.status === status);
    const { color, name } = array[0];
    return <S.StatusChip backgroundColor={color}>{name}</S.StatusChip>;
  };

  const queryClient = useQueryClient();

  const { mutate: workRecoverMutate } = useMutation({
    mutationFn: deleteInspectionArea,
    onSuccess: (data) => {
      queryClient.invalidateQueries([QUERY_KEY.DASHBOARD]);
      toast.success('점검지 복구가 완료되었습니다.');
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      toast.error(error.response?.data.message);
    },
  });

  const handleRecover = () => {
    workRecoverMutate({ inspection_area_id: id, deleted: 0, reason: '' });
  };

  return (
    <>
      <S.Wrapper>
        {!!deleted && (
          <S.ButtonWrapper>
            <Button
              onClick={handleRecover}
              width='6.25rem'
              backgroundColor={Theme.colors.GREEN}
            >
              복구하기
            </Button>
          </S.ButtonWrapper>
        )}
        <S.Container isDeleted={!!deleted}>
          <S.LeftContainer
            onClick={() => navigate(`${ROUTER.WORK.DETAIL}/${id}`)}
          >
            <S.NameContainer>
              {renderStatusChip(status)}
              <Text fontSize='1.25rem' fontWeight={500}>
                {name}
              </Text>
            </S.NameContainer>
            <Text fontSize='1.125rem' color={Theme.colors.G_2}>
              완료된 점검 {progress}
            </Text>
          </S.LeftContainer>
          <S.RightContainer>
            <S.TextHide>
              <Text color={Theme.colors.G_1}>점검자 : </Text>
              {worker_list.length > 0 ? (
                <Text color={Theme.colors.G_1}>{worker_list.join(', ')}</Text>
              ) : (
                <Text color={Theme.colors.G_2}>
                  <span style={{ marginLeft: '0.625rem' }}>-</span>
                </Text>
              )}
            </S.TextHide>
            <S.TextHide>
              <Text color={Theme.colors.G_1}>
                현장주소 : {address.split('/')[0]}
              </Text>
            </S.TextHide>
            <Text color={Theme.colors.G_1}>
              점검기간 : {start_date.toString()} ~ {end_date.toString()}
            </Text>
          </S.RightContainer>
        </S.Container>
      </S.Wrapper>
    </>
  );
};

export default DashboardItem;
