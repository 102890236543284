import axios, { AxiosError } from 'axios';

import { useAccessTokenStore, useRefreshTokenStore } from '@/store/auth';
import { logout } from '@/utils/logout';
import { getTokenRefresh } from '@/apis/auth';
import { useLoadingStore } from '@/store/loading';

const client = axios.create();

client.defaults.baseURL = process.env.REACT_APP_SERVER_URL;
client.defaults.withCredentials = true;

// interceptors request
client.interceptors.request.use(
  (config) => {
    useLoadingStore.getState().beginRequest();

    if (!config.headers) return config;

    const { accessToken } = useAccessTokenStore.getState();

    if (accessToken) {
      config.headers.Authorization = accessToken;
    }

    return config;
  },
  (error) => {
    useLoadingStore.getState().endRequest();
    return Promise.reject(error);
  }
);

// interceptors response
client.interceptors.response.use(
  (response) => {
    useLoadingStore.getState().endRequest();
    return response;
  },
  async (error) => {
    useLoadingStore.getState().endRequest();

    if (!error.response) {
      return Promise.reject(error);
    }

    const { statusCode } = error.response.data;

    // 30분 간격으로 approved false 일 때 로그아웃 시키기
    if (statusCode === 402) {
      return logout('결제 승인되지 않은 계정입니다. 고객센터에 문의해주세요.');
    }

    // refreshToken 만료, 로그아웃 시키기
    if (statusCode === 403) {
      return logout('사용자 정보가 만료되었습니다. 로그아웃 합니다.');
    }

    // DB의 maintenance true면 점검 중
    if (statusCode === 503) {
      return logout('현재 점검 중 입니다.');
    }

    // accessToken 재발급
    if (statusCode === 419) {
      return await tokenRefresh(error);
    }

    return Promise.reject(error);
  }
);

let isAlreadyFetchingAccessToken = false;
let subscribers: ((accessToken: string) => void)[] = [];

const subscribeTokenRefresh = (cb: (accessToken: string) => void) => {
  subscribers.push(cb);
};

const onAccessTokenFetched = (accessToken: string) => {
  subscribers.forEach((callback) => {
    callback(accessToken);
  });
  subscribers = [];
};

const tokenRefresh = async (error: AxiosError): Promise<any> => {
  const { response } = error;

  if (!response) {
    return Promise.reject(error);
  }

  const retryOriginalRequest = new Promise((resolve) => {
    subscribeTokenRefresh((accessToken: string) => {
      if (response.config.headers) {
        response.config.headers['Authorization'] = `Bearer ${accessToken}`;
      }
      resolve(client(response.config));
    });
  });

  if (!isAlreadyFetchingAccessToken) {
    isAlreadyFetchingAccessToken = true;
    const { refreshToken } = useRefreshTokenStore.getState();
    try {
      const response = await getTokenRefresh(refreshToken);

      useAccessTokenStore.setState({
        accessToken: response.data.accessToken,
      });
      useRefreshTokenStore.setState({
        refreshToken: response.data.refreshToken,
      });

      onAccessTokenFetched(response.data.accessToken);
    } catch (refreshError: any) {
      // if (refreshError.response.data.statusCode === 403) {
      //   logout('사용자 정보가 만료되었습니다. 로그아웃 합니다.');
      //   return;
      // }
      throw refreshError;
    } finally {
      isAlreadyFetchingAccessToken = false;
    }
  }
  return retryOriginalRequest;
};

export default client;
