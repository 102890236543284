import { useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from '@emotion/styled';

import type { ErrorResponse } from '@/types/client.types';
import { postFindPwdChangePwd } from '@/apis/company';
import type { PostFindPwdSendEmailRequestProps } from '@/types/company.types';

import { INPUT_MESSAGE, REGEXP, ROUTER } from '@/constants/constants';

import Box from '@/components/common/Box';
import Button from '@/components/common/Button';
import FormInput from '@/components/common/FormInput';

import { StyledForm } from '@/styles/common';

const ChangePwdModal = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  useEffect(() => {
    if (!token) {
      toast.error('비 정상적인 접근입니다.');
      navigate(ROUTER.LOGIN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const { control, handleSubmit, watch } =
    useForm<PostFindPwdSendEmailRequestProps>({
      defaultValues: {
        password: '',
        passwordConfirm: '',
      },
    });

  const { mutate } = useMutation({
    mutationFn: postFindPwdChangePwd,
    onSuccess: (data) => {
      toast.success('비밀번호가 재설정되었습니다.');
      navigate(ROUTER.LOGIN);
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      toast.error(error.response?.data.message);
    },
  });

  const onSubmitHandler: SubmitHandler<PostFindPwdSendEmailRequestProps> = (
    data
  ) => {
    const { password, passwordConfirm } = data;

    if (token) {
      mutate({ password, passwordConfirm, token });
    }
  };

  return (
    <Box title='비밀번호 재설정'>
      <StyledForm onSubmit={handleSubmit(onSubmitHandler)}>
        <PasswordContainer>
          <FormInput
            control={control}
            rules={{
              required: INPUT_MESSAGE.REQUIRED,
              pattern: {
                value: REGEXP.PASSWORD,
                message: INPUT_MESSAGE.PASSWORD.MESSAGE,
              },
              minLength: {
                value: 8,
                message: INPUT_MESSAGE.PASSWORD.MIN,
              },
              maxLength: {
                value: 30,
                message: INPUT_MESSAGE.PASSWORD.MAX,
              },
            }}
            type='password'
            name='password'
            width='25.625rem'
            height='2.5rem'
            placeholder={INPUT_MESSAGE.PASSWORD.PLACEHOLDER}
            label={INPUT_MESSAGE.PASSWORD.LABEL}
            subscription={INPUT_MESSAGE.PASSWORD.SUBSCRIPTION}
          />
          <FormInput
            control={control}
            rules={{
              required: INPUT_MESSAGE.REQUIRED,
              validate: (value) =>
                value === watch('password') ||
                INPUT_MESSAGE.CONFIRM_PASSWORD.MESSAGE,
            }}
            type='password'
            name='passwordConfirm'
            width='25.625rem'
            height='2.5rem'
            placeholder={INPUT_MESSAGE.CONFIRM_PASSWORD.PLACEHOLDER}
          />
        </PasswordContainer>
        <Button width='25.625rem' height='3.25rem' type='submit'>
          비밀번호 재설정
        </Button>
      </StyledForm>
    </Box>
  );
};

export default ChangePwdModal;

const PasswordContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 0.9375rem;
`;
