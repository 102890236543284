import { useQuery } from '@tanstack/react-query';

import { QUERY_KEY } from '@/constants/queryKey';

import { getCardInfo } from '@/apis/payment';

const usePaymentMethodInfo = () => {
  const query = useQuery([QUERY_KEY.CARD_INFO], {
    queryFn: getCardInfo,
  });

  return query;
};

export default usePaymentMethodInfo;
