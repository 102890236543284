import styled from '@emotion/styled';
import { Theme } from '@/styles/Theme';

export const TableWrapper = styled.div`
  width: 100%;

  border: 0.0625rem solid ${Theme.colors.G_2};
  border-radius: 0.625rem;

  overflow-x: auto;
`;

export const Table = styled.table`
  width: 100%;
  text-align: center;
`;
