import React, { useRef, useState } from 'react';
import { HiOutlineDocumentText } from 'react-icons/hi';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { putReportStatus } from '@/apis/deviceReport';
import type { DeviceInfo } from '@/types/InspectionArea.types';
import type { ErrorResponse } from '@/types/client.types';

import { ROUTER } from '@/constants/constants';
import { QUERY_KEY } from '@/constants/queryKey';

import Button from '@/components/common/Button';

import { Theme } from '@/styles/Theme';

import * as S from '../WorkAddCategoryListTable/style';

interface Props {
  selectedDeviceList: DeviceInfo[];
}

const WorkDetailCategoryListTable = ({ selectedDeviceList }: Props) => {
  const params = useParams();
  const inspectionAreaId = Number(params.id ?? 0);

  const [currentPrimaryCategoryId, setCurrentPrimaryCategoryId] =
    useState<number>(0);

  const sectionRef = useRef<HTMLDivElement>(null);

  const queryClient = useQueryClient();

  const { mutate: putModifyWorkTargetStatusMutate } = useMutation({
    mutationFn: putReportStatus,
    onSuccess: (data) => {
      queryClient.invalidateQueries([QUERY_KEY.WORK_INFO, inspectionAreaId]);
      toast.success('최종 점검 상태가 변경되었습니다.');
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      toast.error(error.response?.data.message);
    },
  });

  const targetResultTranslate = (targetStatus: string) => {
    if (targetStatus === 'before') {
      return {
        color: Theme.colors.G_2,
        value: '점검전',
      };
    }

    if (targetStatus === 'in-progress') {
      return {
        color: Theme.colors.B_4,
        value: '점검중',
      };
    }

    if (targetStatus === 'done') {
      return {
        color: Theme.colors.GREEN,
        value: '점검완료',
      };
    }
  };

  const handleTargetStatus = (
    name: string,
    targetStatus: string,
    reportId: number
  ) => {
    let question;
    if (targetStatus === 'done') {
      question = '점검중으';
    } else {
      question = '점검완료';
    }

    const answer = window.confirm(
      `${name}의 최종 점검 상태를 ${question}로 변경하시겠습니까?`
    );

    if (answer) {
      if (targetStatus === 'done') {
        putModifyWorkTargetStatusMutate({
          status: 'in-progress',
          report_id: reportId,
        });
        return;
      }

      putModifyWorkTargetStatusMutate({
        status: 'done',
        report_id: reportId,
      });
      return;
    }
  };

  const handleSelectPrimaryCategoryId = (id: number) => {
    setCurrentPrimaryCategoryId(id);

    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <S.Container>
      <S.TableWrapper width='100%' ref={sectionRef}>
        <S.LeftTable>
          <thead>
            <tr>
              <th>번호</th>
              <th>점검대상</th>
              <th>전체수량</th>
              <th>점검수량</th>
            </tr>
          </thead>
          <tbody>
            {selectedDeviceList.map((device, idx) => (
              <tr key={device.template_device_id}>
                <td>{idx + 1}</td>
                <td
                  onClick={() =>
                    handleSelectPrimaryCategoryId(device.template_device_id)
                  }
                >
                  {device.name}
                </td>
                <td>
                  <S.PlusIconContainer>
                    <S.StyledInput
                      width='4.0625rem'
                      height='1.875rem'
                      value={device.total}
                      disabled
                    />
                  </S.PlusIconContainer>
                </td>
                <td>
                  <S.PlusIconContainer>{device.selected}</S.PlusIconContainer>
                </td>
              </tr>
            ))}
          </tbody>
        </S.LeftTable>
      </S.TableWrapper>
      <S.TableWrapper width='100%'>
        <S.RightTable>
          <thead>
            <tr>
              <th>점검대상</th>
              <th>장비번호</th>
              <th>위치</th>
              <th>점검표</th>
              <th>최종 점검 상태</th>
            </tr>
          </thead>
          <tbody>
            {selectedDeviceList.map((device) => {
              if (device.template_device_id === currentPrimaryCategoryId) {
                return device.info.map((info, index) => (
                  <S.StyledTr isDeleted={!!info.deleted} key={index}>
                    <td>{device.name + (index + 1)}</td>
                    <td>
                      <S.StyledInput
                        width='9.375rem'
                        height='1.875rem'
                        value={info.device_num}
                        disabled
                      />
                    </td>
                    <td>
                      <S.StyledInput
                        width='9.375rem'
                        height='1.875rem'
                        value={info.location}
                        disabled
                      />
                    </td>
                    <td>
                      <Link
                        to={`${ROUTER.WORK.REPORT_DETAIL}/${info.report_id}`}
                        target='_blank'
                      >
                        <HiOutlineDocumentText size='1.25rem' />
                      </Link>
                    </td>
                    <td>
                      {!!!info.deleted && (
                        <div>
                          <Button
                            width='4.5rem'
                            height='1.75rem'
                            fontSize='0.875rem'
                            fontWeight={500}
                            backgroundColor={
                              targetResultTranslate(info.status)?.color
                            }
                            onClick={() =>
                              handleTargetStatus(
                                device.name + (index + 1),
                                info.status,
                                info.report_id
                              )
                            }
                          >
                            {targetResultTranslate(info.status)?.value}
                          </Button>
                        </div>
                      )}
                    </td>
                  </S.StyledTr>
                ));
              }
              return null;
            })}
          </tbody>
        </S.RightTable>
      </S.TableWrapper>
    </S.Container>
  );
};

export default WorkDetailCategoryListTable;
