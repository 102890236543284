import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { AiOutlineEdit } from 'react-icons/ai';
import { MdThumbUp, MdThumbDown, MdOutlineCancel } from 'react-icons/md';
import { PiProhibit } from 'react-icons/pi';

import { getInspectionItem, putInspectionItem } from '@/apis/deviceReport';
import type { InspectionItemValue } from '@/types/deviceReport.types';
import type { ErrorResponse } from '@/types/client.types';

import { QUERY_KEY } from '@/constants/queryKey';

import Modal from '@/components/common/Modal';
import Text from '@/components/common/Text';
import Spacer from '@/components/common/Spacer';
import Button from '@/components/common/Button';
import Input from '@/components/common/Input';
import ResultItemValueList from '@/components/work/ResultItemValueList';

import { Theme } from '@/styles/Theme';

import * as S from './style';
import LastEditedHistory from '@/components/inspectionHistory/LastEditedHistory';
import WorkReportPresignedImgList from '@/components/work/WorkReportPresignedImgList';
import { StyledCol, StyledTd } from '@/styles/common';

interface Props {
  itemId: number;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const WorkReportResultItemModal = ({ itemId, setIsModalOpen }: Props) => {
  const location = useLocation();
  const params = useParams();
  const reportId = Number(params.id);

  const queryClient = useQueryClient();

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [result, setResult] = useState<string>('');
  const [resultText, setResultText] = useState<string>('');
  const [reason, setReason] = useState<string>('');
  const [image, setImage] = useState<string[]>([]);
  const [selectedImage, setSelectedImage] = useState<string[]>([]);
  const [valueInfo, setValueInfo] = useState<InspectionItemValue[]>([]);

  const { data: itemData } = useQuery(
    [QUERY_KEY.WORK_TARGET_ITEM_RESULT, itemId],
    {
      queryFn: () => getInspectionItem(itemId),
      enabled: !!itemId,
    }
  );

  // const itemId = itemData?.data.id ?? 0;

  // const { data: methodData } = useQuery([QUERY_KEY.METHOD_STANDARD, itemId], {
  //   queryFn: () => getMethodStandard(itemId),
  //   enabled: !!itemId,
  // });

  const { mutate } = useMutation({
    mutationFn: putInspectionItem,
    onSuccess: () => {
      toast.success('수정되었습니다.');
      queryClient.invalidateQueries([
        QUERY_KEY.WORK_TARGET_ITEM_RESULT,
        itemId,
      ]);
      queryClient.invalidateQueries([QUERY_KEY.WORK_TARGET_RESULT, reportId]);
      setIsEdit(false);
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      toast.error(error.response?.data.message);
    },
  });

  // edit 페이지에서 모달 키면 edit 모드로
  useEffect(() => {
    if (location.pathname.includes('edit')) {
      setIsEdit(true);
      return;
    }
    setIsEdit(false);
  }, [location.pathname]);

  useEffect(() => {
    if (itemData) {
      const {
        data: { result, result_text, image, selected_image, reason, value },
      } = itemData;

      setResult(result ?? '');
      setResultText(result_text ?? '');
      setImage(image ?? []);
      setSelectedImage(selected_image ?? []);
      setReason(isEdit ? '' : reason ?? '');
      setValueInfo(value ?? []);
    }
  }, [itemData, isEdit]);

  const handleSelect = (selectedResult: string) => {
    if (result === selectedResult) {
      setResult('');
      return;
    }

    setResult(selectedResult);
  };

  const handleRemoveImg = (src: string) => {
    const selectedFiltered = selectedImage.filter((img) => img !== src);
    setSelectedImage(selectedFiltered);
  };

  const handleConfirmEdit = () => {
    const value = valueInfo.map((info) => ({ [info.template_id]: info.value }));

    // 아무것도 선택 안했을 때
    if (result === '-' || result === '') {
      mutate({
        result: '~',
        result_text: resultText,
        image,
        selected_image: selectedImage,
        reason,
        item_id: itemId,
        value,
      });

      return;
    }

    mutate({
      result,
      result_text: resultText,
      image,
      selected_image: selectedImage,
      reason,
      item_id: itemId,
      value,
    });

    return;
  };

  return (
    <Modal
      width='61.875rem'
      closeText='닫기'
      confirmText='수정하기'
      setIsModalOpen={setIsModalOpen}
      hasButton={isEdit}
      onClickConfirm={handleConfirmEdit}
    >
      <S.Container>
        <Spacer y='0.0625rem' />
        <S.TopContainer>
          <S.FlexContainer>
            <S.NumberWrapper>{itemData?.data.seq}</S.NumberWrapper>
            <Text fontSize='1.125rem' fontWeight={700}>
              {itemData?.data.content}
            </Text>
          </S.FlexContainer>
          {isEdit ? (
            <Button height='2.1875rem' onClick={() => setIsEdit(false)}>
              <AiOutlineEdit size='1.25rem' />
              취소하기
            </Button>
          ) : (
            <S.EditContainer>
              {itemData && (
                <LastEditedHistory
                  workerName={itemData?.data.log.workerName}
                  updatedAt={itemData?.data.log.updatedAt}
                />
              )}

              <Button height='2.1875rem' onClick={() => setIsEdit(true)}>
                <AiOutlineEdit size='1.25rem' />
                수정하기
              </Button>
            </S.EditContainer>
          )}
        </S.TopContainer>
        {valueInfo.length > 0 && (
          <ResultItemValueList
            isEdit={isEdit}
            valueInfo={valueInfo}
            setValueInfo={setValueInfo}
          />
        )}
        {/* {methodData && (
          <S.Table>
            <colgroup>
              <StyledCol width='9.375rem' />
              <StyledCol width'46.875rem' />
            </colgroup>
            <tbody>
              <tr>
                <td>구분</td>
                <td>점검 내용</td>
              </tr>
              {methodData.data.methodList.map((method, idx) => {
                if (idx === 0) {
                  return (
                    <tr key={method.seq}>
                      <td rowSpan={methodData.data.methodList.length}>
                        점검 방법
                      </td>
                      <StyledTd textAlign='left'>
                        <li>{method.content}</li>
                      </StyledTd>
                    </tr>
                  );
                }
                return (
                  <tr key={method.seq}>
                    <StyledTd textAlign='left'>
                      <li>{method.content}</li>
                    </StyledTd>
                  </tr>
                );
              })}
              {methodData.data.standardList.map((standard, idx) => {
                if (idx === 0) {
                  return (
                    <tr key={standard.seq}>
                      <td rowSpan={methodData.data.standardList.length}>
                        점검 기준
                      </td>
                      <StyledTd textAlign='left'>
                        <li>{standard.content}</li>
                      </StyledTd>
                    </tr>
                  );
                }
                return (
                  <tr key={standard.seq}>
                    <StyledTd textAlign='left'>
                      <li>{standard.content}</li>
                    </StyledTd>
                  </tr>
                );
              })}
            </tbody>
          </S.Table>
        )} */}
        <S.Table>
          <colgroup>
            <StyledCol width='18.75rem' />
            <StyledCol width='18.75rem' />
            <StyledCol width='18.75rem' />
          </colgroup>
          <tbody>
            <tr>
              <td colSpan={3}>점검 사진</td>
            </tr>
            <tr>
              <StyledTd width='18.75rem' height='18.75rem'>
                {result === '/'
                  ? '해당사항없음'
                  : selectedImage[0] && (
                      <S.ImgContainer>
                        <S.ImgWrapper src={selectedImage[0]} alt='점검사진_1' />
                        {isEdit && (
                          <S.ImgRemoveIconWrapper
                            onClick={() => handleRemoveImg(selectedImage[0])}
                          >
                            <MdOutlineCancel size='1.75rem' />
                          </S.ImgRemoveIconWrapper>
                        )}
                      </S.ImgContainer>
                    )}
              </StyledTd>
              <StyledTd width='18.75rem' height='18.75rem'>
                {result === '/'
                  ? '해당사항없음'
                  : selectedImage[1] && (
                      <S.ImgContainer>
                        <S.ImgWrapper src={selectedImage[1]} alt='점검사진_2' />
                        {isEdit && (
                          <S.ImgRemoveIconWrapper
                            onClick={() => handleRemoveImg(selectedImage[1])}
                          >
                            <MdOutlineCancel size='1.75rem' />
                          </S.ImgRemoveIconWrapper>
                        )}
                      </S.ImgContainer>
                    )}
              </StyledTd>
              <StyledTd width='18.75rem' height='18.75rem'>
                {result === '/'
                  ? '해당사항없음'
                  : selectedImage[2] && (
                      <S.ImgContainer>
                        <S.ImgWrapper src={selectedImage[2]} alt='점검사진_3' />
                        {isEdit && (
                          <S.ImgRemoveIconWrapper
                            onClick={() => handleRemoveImg(selectedImage[2])}
                          >
                            <MdOutlineCancel size='1.75rem' />
                          </S.ImgRemoveIconWrapper>
                        )}
                      </S.ImgContainer>
                    )}
              </StyledTd>
            </tr>
            <tr>
              <StyledTd width='18.75rem' height='18.75rem'>
                {result === '/'
                  ? '해당사항없음'
                  : selectedImage[3] && (
                      <S.ImgContainer>
                        <S.ImgWrapper src={selectedImage[3]} alt='점검사진_4' />
                        {isEdit && (
                          <S.ImgRemoveIconWrapper
                            onClick={() => handleRemoveImg(selectedImage[3])}
                          >
                            <MdOutlineCancel size='1.75rem' />
                          </S.ImgRemoveIconWrapper>
                        )}
                      </S.ImgContainer>
                    )}
              </StyledTd>
              <StyledTd width='18.75rem' height='18.75rem'>
                {result === '/'
                  ? '해당사항없음'
                  : selectedImage[4] && (
                      <S.ImgContainer>
                        <S.ImgWrapper src={selectedImage[4]} alt='점검사진_5' />
                        {isEdit && (
                          <S.ImgRemoveIconWrapper
                            onClick={() => handleRemoveImg(selectedImage[4])}
                          >
                            <MdOutlineCancel size='1.75rem' />
                          </S.ImgRemoveIconWrapper>
                        )}
                      </S.ImgContainer>
                    )}
              </StyledTd>
              <StyledTd width='18.75rem' height='18.75rem'>
                {result === '/'
                  ? '해당사항없음'
                  : selectedImage[5] && (
                      <S.ImgContainer>
                        <S.ImgWrapper src={selectedImage[5]} alt='점검사진_6' />
                        {isEdit && (
                          <S.ImgRemoveIconWrapper
                            onClick={() => handleRemoveImg(selectedImage[5])}
                          >
                            <MdOutlineCancel size='1.75rem' />
                          </S.ImgRemoveIconWrapper>
                        )}
                      </S.ImgContainer>
                    )}
              </StyledTd>
            </tr>
            <tr>
              <StyledTd
                height='2.5rem'
                colSpan={3}
                textAlign='left'
                padding='0 0 0 0.3125rem'
              >
                <S.FlexContainer>
                  사유 :{' '}
                  {isEdit ? (
                    <S.StyledInput
                      height='2.5rem'
                      value={resultText}
                      onChange={(e) => setResultText(e.target.value)}
                    />
                  ) : (
                    resultText
                  )}
                </S.FlexContainer>
              </StyledTd>
            </tr>
          </tbody>
        </S.Table>
        {!isEdit && (
          <Input
            labelFontSize='1.125rem'
            label='수정 사유'
            name='text'
            width='31.25rem'
            height='2.5rem'
            value={reason}
            disabled
            onChange={(e) => setReason(e.target.value)}
          />
        )}
        {isEdit && (
          <>
            <S.SelectContainer>
              <S.Selector
                backgroundColor={Theme.colors.B_3}
                isSelected={result === 'O'}
                onClick={() => handleSelect('O')}
              >
                <MdThumbUp size='1.5rem' />
                적합
              </S.Selector>
              <S.Selector
                backgroundColor={Theme.colors.WARNING_RED}
                isSelected={result === 'X'}
                onClick={() => handleSelect('X')}
              >
                <MdThumbDown size='1.5rem' />
                미흡
              </S.Selector>
              <S.Selector
                backgroundColor={Theme.colors.G_3}
                isSelected={result === '/'}
                onClick={() => handleSelect('/')}
              >
                <PiProhibit size='1.5rem' />
                해당없음
              </S.Selector>
            </S.SelectContainer>
            {result !== '/' && (
              <WorkReportPresignedImgList
                title='점검 사진 선택'
                selectedImgLength={6}
                isMultiple
                imgList={image}
                setImgList={setImage}
                selectedImgList={selectedImage}
                setSelectedImgList={setSelectedImage}
              />
            )}
            <Input
              labelFontSize='1.125rem'
              label='수정 사유'
              name='text'
              width='31.25rem'
              height='2.5rem'
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </>
        )}
        <Spacer y='1.25rem' />
      </S.Container>
    </Modal>
  );
};

export default WorkReportResultItemModal;
