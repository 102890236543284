import { useGnbStore } from '@/store/gnb';
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from 'react-icons/ai';
import styled from '@emotion/styled';
import { Theme } from '@/styles/Theme';

const FoldGnbIcon = () => {
  const { isFoldGnb, setFoldGnb } = useGnbStore();

  return (
    <MenuFoldWrapper onClick={setFoldGnb}>
      {isFoldGnb ? (
        <AiOutlineMenuUnfold size='1.875rem' />
      ) : (
        <AiOutlineMenuFold size='1.875rem' />
      )}
    </MenuFoldWrapper>
  );
};

export default FoldGnbIcon;

const MenuFoldWrapper = styled.div`
  width: 2.625rem;
  height: 2.625rem;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 0.0625rem solid black;
  border-radius: 50%;
  background-color: ${Theme.colors.WHITE};

  position: fixed;
  right: 1.25rem;
  bottom: 6.25rem;

  cursor: pointer;

  z-index: 201;
`;
