import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { QUERY_KEY } from '@/constants/queryKey';
import { getInspectionEquipment } from '@/apis/report/equipment';
import type { InspectionEquipmentItem } from '@/types/report/equipment.types';

import EquipmenEdittModal from '@/components/report/equipment/EquipmentEditModal';
import Text from '@/components/common/Text';
import Button from '@/components/common/Button';
import EquipmentAddModal from '@/components/report/equipment/EquipmentAddModal';
import GreyBox from '@/components/common/GreyBox';

import * as S from './style';
import { StyledCol } from '@/styles/common';

const EquipmentTable = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [item, setItem] = useState<InspectionEquipmentItem | undefined>();

  const { data: equipmentData } = useQuery({
    queryKey: [QUERY_KEY.REPORT.EQUIPMENT],
    queryFn: () => getInspectionEquipment(),
  });

  const handleOpenModal = (item: InspectionEquipmentItem) => {
    setItem(item);
    setIsModalOpen(true);
  };

  const handleAddEquipment = () => {
    setIsAddModalOpen(true);
  };

  return (
    <GreyBox width='58.125rem' title='성능점검 장비'>
      <S.EditButtonWrapper>
        <Button onClick={handleAddEquipment}> 추가하기</Button>
      </S.EditButtonWrapper>
      <S.Container>
        {isModalOpen && item !== undefined && (
          <EquipmenEdittModal
            item={item}
            setIsModalOpen={setIsModalOpen}
          ></EquipmenEdittModal>
        )}

        {isAddModalOpen && (
          <EquipmentAddModal
            setIsModalOpen={setIsAddModalOpen}
          ></EquipmentAddModal>
        )}
        <>
          {equipmentData && equipmentData.data && (
            <div>
              <S.Table>
                <colgroup>
                  <StyledCol width='6.25rem' />
                  <StyledCol width='6.25rem' />
                  <StyledCol width='6.25rem' />
                  <StyledCol width='6.25rem' />
                  <StyledCol width='6.25rem' />
                </colgroup>
                <tbody>
                  <tr>
                    <td colSpan={1}>
                      <Text fontWeight={500}>장비 번호</Text>
                    </td>
                    <td colSpan={1}>
                      <Text fontWeight={500}>장비 사진</Text>
                    </td>
                    <td colSpan={3}>
                      <Text fontWeight={500}>장비 정보</Text>
                    </td>
                  </tr>
                </tbody>

                {equipmentData.data.map(
                  (item: InspectionEquipmentItem, index: number) => {
                    return (
                      <S.DeviceWrapper
                        key={index}
                        onClick={() => handleOpenModal(item)}
                      >
                        <tr>
                          <td rowSpan={4} colSpan={1}>
                            <Text>{index + 1}</Text>
                          </td>
                          <td rowSpan={4} colSpan={1}>
                            <div>
                              {item.image.length > 0 ? (
                                <S.ImgWrapper
                                  src={item.image}
                                  alt='equip_image'
                                ></S.ImgWrapper>
                              ) : (
                                <></>
                              )}
                            </div>
                          </td>

                          <td colSpan={1}>
                            <Text> 장비명 </Text>
                          </td>
                          <td colSpan={2}>
                            <Text>{item.name} </Text>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={1}>
                            <Text> 모델명 </Text>
                          </td>
                          <td colSpan={2}>
                            <Text> {item.model} </Text>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={1}>
                            <Text> 제조사 </Text>
                          </td>
                          <td colSpan={2}>
                            <Text> {item.manufacturingCompany} </Text>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={1}>
                            <Text> 법정 등록 </Text>
                            <br />
                            <Text> 장비 여부 </Text>
                          </td>
                          <td colSpan={2}>
                            <Text>
                              {' '}
                              {item.isLegalRegistration === 1
                                ? '등록'
                                : '미등록'}{' '}
                            </Text>
                          </td>
                        </tr>
                      </S.DeviceWrapper>
                    );
                  }
                )}
              </S.Table>
            </div>
          )}
        </>
      </S.Container>
    </GreyBox>
  );
};

export default EquipmentTable;
