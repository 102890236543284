import client from '@/apis/client';
import type { Response } from '@/types/client.types';
import type {
  EnergyConsumptionItem,
  PostEnergyConsumptionRequestProps,
} from '@/types/report/energy.types';

// Ⅴ. 성능점검 시 검토사항
// 3. 에너지 사용량 검토 API
export const getEnergyConsumption = (inspectionAreaId: number) =>
  client
    .get<Response<EnergyConsumptionItem[]>>('/report/energy', {
      params: { inspectionAreaId },
    })
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error fetching inspection equipment:', error);
      throw error;
    });

export const postEnergyConsumption = (
  body: PostEnergyConsumptionRequestProps
) =>
  client
    .post<Response<PostEnergyConsumptionRequestProps>>(
      '/report/energy/upload',
      body
    )
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error posting inspection equipment:', error);
      throw error;
    });
