import React from 'react';
import styled from '@emotion/styled';
import { AiOutlineClose } from 'react-icons/ai';

import Button from '@/components/common/Button';

import { Theme } from '@/styles/Theme';

import Text from './Text';

interface Props {
  children: React.ReactNode;
  title?: string;
  width?: string;
  height?: string;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClickConfirm?: () => void;
  isWarning?: boolean;
  hasButton?: boolean;
  closeText?: string;
  confirmText?: string;
}

const Modal = ({
  children,
  title,
  width,
  height,
  setIsModalOpen,
  onClickConfirm,
  isWarning,
  hasButton = true,
  closeText = '취소',
  confirmText = '확인',
}: Props) => {
  const handleModalOff = () => {
    setIsModalOpen(false);
  };

  return (
    <Background>
      <Overlay onClick={handleModalOff} />
      <ModalContainer width={width} height={height}>
        <CloseWrapper>
          <Text fontSize='1.375rem' fontWeight={700}>
            {title}
          </Text>
          <AiOutlineClose
            onClick={handleModalOff}
            size='1.5rem'
            cursor='pointer'
          />
        </CloseWrapper>
        {children}
        {hasButton && (
          <ButtonContainer>
            <Button
              backgroundColor={Theme.colors.G_5}
              color={Theme.colors.BLACK}
              width='12.5rem'
              height='3.125rem'
              onClick={handleModalOff}
            >
              {closeText}
            </Button>
            <Button
              backgroundColor={
                isWarning ? Theme.colors.WARNING_RED : Theme.colors.B_3
              }
              width='12.5rem'
              height='3.125rem'
              onClick={onClickConfirm}
            >
              {confirmText}
            </Button>
          </ButtonContainer>
        )}
      </ModalContainer>
    </Background>
  );
};

export default Modal;

const Background = styled.div`
  position: fixed;

  width: 100%;
  height: 100%;

  left: 0;
  top: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 1000;

  background: rgba(3, 14, 32, 0.36);
`;

export const Overlay = styled.div`
  position: absolute;

  width: 100%;
  height: 100%;
`;

const ModalContainer = styled.div<Partial<Props>>`
  position: relative;

  padding: 1.875rem;

  width: ${({ width }) => width};
  max-height: ${({ height }) => (height ? height : '90%')};
  overflow: auto;
  border-radius: 1.25rem;

  background-color: ${Theme.colors.WHITE};

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CloseWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
`;
