import React, { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { getAppendixReport, postAppendixReport } from '@/apis/appendix';
import type { PostAppendixReportRequestProps } from '@/types/appendix.types';
import type { ErrorResponse } from '@/types/client.types';

import { QUERY_KEY } from '@/constants/queryKey';

import Text from '@/components/common/Text';
import Spacer from '@/components/common/Spacer';
import Button from '@/components/common/Button';
import {
  ButtonWrapper,
  StyledTextarea,
} from '@/components/work/WorkReportResultTable/style';
import EditButton from '@/components/common/EditButton';

import { Theme } from '@/styles/Theme';
import * as S from './style';
import { StyledCol, StyledTd } from '@/styles/common';

const QUALIFICATION = ['특급', '고급', '중급', '초급'];

const WorkAppendixReportTable = () => {
  const params = useParams();
  const queryClient = useQueryClient();

  const inspectionAreaId = Number(params.id);

  const [appendixReportInfo, setAppendixReportInfo] = useState<
    Omit<PostAppendixReportRequestProps, 'inspectionAreaId'>
  >({
    qualification: '',
    purpose: '',
    floorArea: '',
    households: '',
    structure: '',
    date: '',
    manager: '',
    telephoneNumber: '',
  });
  const [isDetailPage, setIsDetailPage] = useState<boolean>(true);
  const [hasHouseHolds, setHasHouseHolds] = useState<boolean>(false);

  const { data: appendixReportData } = useQuery({
    queryKey: [QUERY_KEY.APPENDIX_REPORT, inspectionAreaId],
    queryFn: () => getAppendixReport(inspectionAreaId),
  });

  const { mutate } = useMutation({
    mutationFn: postAppendixReport,
    onSuccess: (data) => {
      setIsDetailPage(true);
      toast.success('결과보고서가 수정되었습니다.');
      queryClient.invalidateQueries([
        QUERY_KEY.APPENDIX_REPORT,
        inspectionAreaId,
      ]);
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      toast.error(error.response?.data.message);
    },
  });

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setAppendixReportInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleHouseholdsCheck = () => {
    setHasHouseHolds((prev) => !prev);
    setAppendixReportInfo((prev) => ({ ...prev, households: '' }));
  };

  const handleCancel = () => {
    setIsDetailPage(true);
    if (appendixReportData) {
      const {
        inspectionAreaInfo: {
          qualification,
          purpose,
          floorArea,
          households,
          structure,
          standardDate,
          manager,
          telephoneNumber,
        },
      } = appendixReportData.data;
      setAppendixReportInfo({
        qualification,
        purpose,
        floorArea,
        households,
        structure,
        date: standardDate,
        manager,
        telephoneNumber,
      });

      if (households) {
        setHasHouseHolds(true);

        return;
      }
      setHasHouseHolds(false);
    }
  };

  const handleSubmit = () => {
    mutate({ inspectionAreaId, ...appendixReportInfo });
  };

  useEffect(() => {
    if (appendixReportData) {
      const {
        inspectionAreaInfo: {
          qualification,
          purpose,
          floorArea,
          households,
          structure,
          standardDate,
          manager,
          telephoneNumber,
        },
      } = appendixReportData.data;
      setAppendixReportInfo({
        qualification,
        purpose,
        floorArea,
        households,
        structure,
        date: standardDate,
        manager,
        telephoneNumber,
      });

      if (households) {
        setHasHouseHolds(true);
      }
    }
  }, [appendixReportData]);

  if (!appendixReportData) {
    return null;
  }

  const {
    inspectionAreaInfo: { inspectionAreaName, address },
    deviceInfo,
    date: { formattedDate, inspectionPeriod },
    userInfo: { userCompany, representativeName, registrationNumber },
    workerList,
  } = appendixReportData.data;

  const parsedAddress = address.replace('/', ' ');

  return (
    <S.Container>
      <S.TextWrapper>
        <Text fontWeight={700} fontSize='1.75rem' textAlign='center'>
          기계설비 성능점검 결과보고서
        </Text>
        <EditButton
          top='7.5rem'
          isDetailPage={isDetailPage}
          setIsDetailPage={setIsDetailPage}
          onClickCancel={handleCancel}
        />
      </S.TextWrapper>
      <Spacer y='1.25rem' />
      <S.Table>
        <colgroup>
          <StyledCol width='6.25rem' />
          <StyledCol width='6.25rem' />
          <col />
          <StyledCol width='9.375rem' />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <td rowSpan={4}>
              점검현장
              <br />
              개요
            </td>
            <td>
              명칭
              <br />
              (상호)
            </td>
            <td>{inspectionAreaName}</td>
            <td>선임자격</td>
            <td>
              <S.QualificationContainer isEditPage={!isDetailPage}>
                {QUALIFICATION.map((value, idx) => (
                  <S.QualificationCheckboxContainer key={idx}>
                    {value}
                    <input
                      type='checkbox'
                      name='qualification'
                      checked={appendixReportInfo.qualification === value}
                      value={value}
                      onChange={handleInputChange}
                      disabled={isDetailPage}
                    />
                  </S.QualificationCheckboxContainer>
                ))}
              </S.QualificationContainer>
            </td>
          </tr>
          <tr>
            <td>주소</td>
            <td colSpan={3}>{parsedAddress}</td>
          </tr>
          <tr>
            <td>용도</td>
            <td>
              <StyledTextarea
                height='2.5rem'
                name='purpose'
                textAlign='center'
                value={appendixReportInfo.purpose ?? ''}
                onChange={handleInputChange}
                disabled={isDetailPage}
              />
            </td>
            <td>
              연면적
              <br />
              <S.QualificationCheckboxContainer>
                (세대수
                <input
                  type='checkbox'
                  disabled={isDetailPage}
                  checked={hasHouseHolds}
                  onChange={handleHouseholdsCheck}
                />
                )
              </S.QualificationCheckboxContainer>
            </td>
            <td>
              <StyledTextarea
                height={hasHouseHolds ? '1.875rem' : '2.5rem'}
                style={{ overflowY: hasHouseHolds ? 'hidden' : 'auto' }}
                textAlign='center'
                name='floorArea'
                value={appendixReportInfo.floorArea ?? ''}
                onChange={handleInputChange}
                disabled={isDetailPage}
              />
              {hasHouseHolds && (
                <>
                  <Spacer y='0.3125rem' />
                  <StyledTextarea
                    height='1.875rem'
                    style={{ overflowY: 'hidden' }}
                    textAlign='center'
                    name='households'
                    value={appendixReportInfo.households ?? ''}
                    onChange={handleInputChange}
                    disabled={isDetailPage}
                  />
                </>
              )}
            </td>
          </tr>
          <tr>
            <td>건물구조</td>
            <td>
              <StyledTextarea
                height='2.5rem'
                name='structure'
                textAlign='center'
                value={appendixReportInfo.structure ?? ''}
                onChange={handleInputChange}
                disabled={isDetailPage}
              />
            </td>
            <td>
              성능점검
              <br />
              기준일
            </td>
            <td>
              <StyledTextarea
                height='2.5rem'
                name='date'
                textAlign='center'
                value={appendixReportInfo.date ?? ''}
                onChange={handleInputChange}
                disabled={isDetailPage}
              />
            </td>
          </tr>
          <tr>
            <td rowSpan={2}>관리주체</td>
            <td>
              성명
              <br />
              (대표자)
            </td>
            <td>
              <StyledTextarea
                height='2.5rem'
                name='manager'
                textAlign='center'
                value={appendixReportInfo.manager ?? ''}
                onChange={handleInputChange}
                disabled={isDetailPage}
              />
            </td>
            <td>전화번호</td>
            <td>
              <StyledTextarea
                name='telephoneNumber'
                value={appendixReportInfo.telephoneNumber ?? ''}
                onChange={handleInputChange}
                disabled={isDetailPage}
              />
            </td>
          </tr>
          <tr>
            <td>주소</td>
            <td colSpan={4}>{parsedAddress}</td>
          </tr>
          <tr>
            <td colSpan={2}>점검대상 기계설비 종류</td>
            <StyledTd colSpan={3} height='6.25rem'>
              {deviceInfo.join(', ')}
            </StyledTd>
          </tr>
          <tr>
            <td colSpan={2}>점검기간</td>
            <td
              colSpan={3}
            >{`${formattedDate} (총 점검일수 : ${inspectionPeriod})`}</td>
          </tr>
          <tr>
            <td colSpan={2}>점검방법</td>
            <td colSpan={3}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                }}
              >
                <span>[ ] 자체점검</span>
                <span>[ ✓ ] 성능점검업체 대행</span>
              </div>
            </td>
          </tr>
          <tr>
            <td rowSpan={2} colSpan={2}>
              점검 참여 <br /> 성능점검업체
            </td>
            <td>상호(명칭)</td>
            <td>대표자</td>
            <td>성능점검업(사업자) 등록번호</td>
          </tr>
          <tr>
            <td>{userCompany}</td>
            <td>{representativeName}</td>
            <td>{registrationNumber}</td>
          </tr>
          <tr>
            <td colSpan={5}>점검 참여 기계설비유지관리자</td>
          </tr>
          <tr>
            <td colSpan={2}>성명</td>
            <td>등급</td>
            <td>수첩발급번호</td>
            <td>점검기간</td>
          </tr>
          {workerList.map((worker, idx) => (
            <tr key={idx}>
              <td colSpan={2}>{worker.workerName}</td>
              <td>{worker.workerGrade}</td>
              <td>{worker.workerNoteNumber}</td>
              <td>{formattedDate}</td>
            </tr>
          ))}
        </tbody>
      </S.Table>
      {!isDetailPage && (
        <>
          <Spacer y='1.25rem' />
          <ButtonWrapper>
            <Button
              width='9.375rem'
              height='2.5rem'
              backgroundColor={Theme.colors.G_2}
              onClick={handleCancel}
            >
              취소하기
            </Button>
            <Button width='9.375rem' height='2.5rem' onClick={handleSubmit}>
              수정하기
            </Button>
          </ButtonWrapper>
        </>
      )}
    </S.Container>
  );
};

export default WorkAppendixReportTable;
