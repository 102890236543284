export const phoneFormat = (phone: string) => {
  if (phone.length === 11) {
    return phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  } else if (phone.length === 8) {
    return phone.replace(/(\d{4})(\d{4})/, '$1-$2');
  } else {
    if (phone.indexOf('02') === 0) {
      if (phone.length === 9) {
        return phone.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3');
      } else {
        return phone.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
      }
    } else {
      return phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }
  }
};

export const PaymentMethodNumFormat = (cardNum: string) => {
  return cardNum.replaceAll('-', ' ');
};
