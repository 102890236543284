import { Theme } from '@/styles/Theme';
import Button from '@/components/common/Button';
import { TbTrashOff } from 'react-icons/tb';
import { useState } from 'react';
import UndoUnsubscribeWorkersModal from '@/components/newPayment/WorkerPaymentInfoBox/ManageWorkerPaymentModal/UndoUnsubscribeWorkersModal';
import { toast } from 'react-toastify';
import { useSelectedWorkerListStore } from '@/store/selectedWorkerList';

const UndoUnsubscribeWorkersBtn = () => {
  const [
    isShowUndoUnsubscribeWorkersModal,
    setIsShowUndoUnsubscribeWorkersModal,
  ] = useState(false);

  const {
    selectedUndoUnsubscribeWorkerList,
    resetSelectedUndoUnsubscribeWorkerList,
  } = useSelectedWorkerListStore();

  const onUndoUnsubscribeClick = () => {
    if (selectedUndoUnsubscribeWorkerList.length > 0) {
      setIsShowUndoUnsubscribeWorkersModal(true);
    } else {
      toast.error('중단할 작업자를 선택하세요.');
    }
  };

  return (
    <>
      {isShowUndoUnsubscribeWorkersModal && (
        <UndoUnsubscribeWorkersModal
          setIsShowModal={setIsShowUndoUnsubscribeWorkersModal}
          selectedWorkers={selectedUndoUnsubscribeWorkerList}
          resetSelectedUndoUnsubscribeWorkerList={
            resetSelectedUndoUnsubscribeWorkerList
          }
        />
      )}
      <Button
        height='2.125rem'
        onClick={onUndoUnsubscribeClick}
        color={Theme.colors.WHITE}
        backgroundColor={Theme.colors.WARNING_RED}
      >
        <TbTrashOff size='1.25rem' />
        선택 중단 취소
      </Button>
    </>
  );
};

export default UndoUnsubscribeWorkersBtn;
