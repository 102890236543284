import { Theme } from '@/styles/Theme';
import styled from '@emotion/styled';

export const StyledTable = styled.table`
  tr {
    height: 3.75rem;

    td:nth-of-type(1) {
      background-color: ${Theme.colors.G_3};
    }
  }

  td {
    background-color: ${Theme.colors.WHITE};
    border: 0.0625rem solid black;
    border-collapse: collapse;

    text-align: center;
  }
`;
