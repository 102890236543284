import { Theme } from '@/styles/Theme';
import Text from '../Text';

interface Props {
  children: React.ReactNode;
}

const GnbTitle = ({ children }: Props) => {
  return (
    <Text
      margin='0 0 0 0.9375rem'
      fontSize='0.9375rem'
      fontWeight={500}
      color={Theme.colors.G_1}
    >
      {children}
    </Text>
  );
};

export default GnbTitle;
