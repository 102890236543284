import client from '@/apis/client';
import type { Response } from '@/types/client.types';

export const postImg = (files: FormData) =>
  client
    .post<Response<string[]>>('/work/image-upload', files, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error posting img:', error);
      throw error;
    });

export const postPresignedUrlImg = (body: { files: string[] }) =>
  client
    .post<Response<string[]>>('/v2/work/image-upload', body)
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error posting presigned url img:', error);
      throw error;
    });
