import { Theme } from '@/styles/Theme';
import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  gap: 2.5rem;
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;

  gap: 0.625rem;
`;

export const BlockHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
`;

export const StyledTable = styled.table`
  border: 0.0625rem solid ${Theme.colors.BLACK};

  td,
  th {
    border: 0.0625rem solid ${Theme.colors.BLACK};
  }

  thead {
    /* background-color: ${Theme.colors.WHITE}; */
    height: 3.125rem;
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
