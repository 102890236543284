import { ROUTER } from '@/constants/constants';
import { useAccessTokenStore, useRefreshTokenStore } from '@/store/auth';

export const logout = (alertMessage?: string) => {
  useAccessTokenStore.setState({ accessToken: '' });
  useRefreshTokenStore.setState({ refreshToken: '' });

  window.location.href = ROUTER.LOGIN;

  alert(alertMessage ?? '로그아웃 되었습니다.');
};
