import client from '@/apis/client';
import type { Response } from '@/types/client.types';
import type {
  CompanyList,
  GetCompanyInfoRequestProps,
} from '@/types/admin.types';

export const getCompanyList = ({
  page,
  limit,
  order,
  keyword,
  isAdmin,
}: GetCompanyInfoRequestProps) =>
  client
    .get<Response<CompanyList>>('/admin/getUserList', {
      params: {
        page,
        limit,
        order,
        keyword,
        isAdmin,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error fetching company list:', error);
      throw error;
    });
