import { Theme } from '@/styles/Theme';
import styled from '@emotion/styled';

export const HistoryUl = styled.ul`
  width: 100%;
  height: 37.5rem;

  list-style: disc;
  padding-left: 1.5rem;

  margin-top: 0.625rem;
`;

export const HistoryLi = styled.li`
  margin-bottom: 0.3125rem;
`;

export const HistoryInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HistoryContent = styled.span`
  max-width: calc(100% - 9.375rem);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-weight: 500;
`;

export const HistoryCreatedAt = styled.time`
  color: ${Theme.colors.G_1};
`;

export const EmptyHistoryList = styled.div`
  margin-top: 0.625rem;
  height: 37.5rem;
`;
