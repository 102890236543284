import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import styled from '@emotion/styled';

import type { ErrorResponse } from '@/types/client.types';
import { postFindPwdSendEmail } from '@/apis/company';
import type { PostAuthEmailRequestProps } from '@/types/company.types';

import { INPUT_MESSAGE, REGEXP } from '@/constants/constants';

import Box from '@/components/common/Box';
import FormInput from '@/components/common/FormInput';
import Button from '@/components/common/Button';
import Text from '@/components/common/Text';

import { StyledForm } from '@/styles/common';
import { Theme } from '@/styles/Theme';

const EmailModal = () => {
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);

  const { control, handleSubmit, setError } = useForm<
    Pick<PostAuthEmailRequestProps, 'email'>
  >({
    defaultValues: {
      email: '',
    },
  });

  const { mutate } = useMutation({
    mutationFn: postFindPwdSendEmail,
    onSuccess: (data) => {
      setIsEmailSent(false);
      toast.success('이메일이 전송되었습니다.');
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      setError('email', { message: error.response?.data.message });
    },
  });

  const onSubmitHandler: SubmitHandler<
    Pick<PostAuthEmailRequestProps, 'email'>
  > = (data) => {
    const { email } = data;

    setIsEmailSent(true);
    mutate(email);
  };

  return (
    <Box title='비밀번호 재설정'>
      <StyledForm onSubmit={handleSubmit(onSubmitHandler)}>
        <FormInput
          control={control}
          rules={{
            required: INPUT_MESSAGE.REQUIRED,
            pattern: {
              value: REGEXP.EMAIL,
              message: INPUT_MESSAGE.EMAIL.MESSAGE,
            },
          }}
          name='email'
          width='25.625rem'
          height='2.5rem'
          placeholder={INPUT_MESSAGE.EMAIL.PLACEHOLDER}
          label={INPUT_MESSAGE.EMAIL.LABEL}
        />
        <Button
          width='25.625rem'
          height='3.25rem'
          type='submit'
          disabled={isEmailSent}
        >
          이메일 전송하기
        </Button>
        <TextContainer>
          <Text color={Theme.colors.G_1}>오류가 발생하시나요?</Text>
          <Text color={Theme.colors.G_1}>고객센터 (1670-1204)</Text>
        </TextContainer>
      </StyledForm>
    </Box>
  );
};

export default EmailModal;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
