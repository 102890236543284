import styled from '@emotion/styled';
import { Theme } from '@/styles/Theme';

export const LastHistoryWrapper = styled.div<{ size?: 'small' | 'large' }>`
  display: flex;
  align-items: center;
  gap: 0.25rem;

  font-size: ${({ size }) => size === 'small' && '0.875rem'};
`;
export const LastHistoryLabel = styled.span`
  color: ${Theme.colors.B_2};
  font-weight: 700;
`;

export const LastEditor = styled.span`
  font-weight: 700;
`;
