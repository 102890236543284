import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
} from 'react-router-dom';

import { ROUTER } from '@/constants/constants';

import {
  LandingPage,
  LoginPage,
  SignUpPage,
  EmailPage,
  ChangePwdPage,
  CardRegisterPage,
  DashboardPage,
  WorkerPage,
  PaymentManagementPage,
  MyCompanyPage,
  AdminUserManagePage,
  ReportPage,
  WorkAddPage,
  WorkReportDetailPage,
  WorkReportEditPage,
  InspectionHistoryPage,
} from './pages';

import GNB from '@/components/common/GNB';
import Footer from '@/components/common/Footer';
import UserRoute from '@/components/common/UserRoute';

import { PageCenter } from '@/styles/common';

// const LoginPage = lazy(() => import('@/pages/LoginPage'));
// const SignUpPage = lazy(() => import('@/pages/SignUpPage'));
// const EmailPage = lazy(() => import('@/pages/FindPwd/EmailPage'));
// const ChangePwdPage = lazy(() => import('@/pages/FindPwd/ChangePwdPage'));
// const DashboardPage = lazy(() => import('@/pages/DashboardPage'));
// const WorkerPage = lazy(() => import('@/pages/WorkerPage'));
// const MyCompanyPage = lazy(() => import('@/pages/MyCompanyPage'));
// const NotificationPage = lazy(() => import('@/pages/NotificationPage'));
// const StoragePage = lazy(() => import('@/pages/StoragePage'));
// const WorkAddPage = lazy(() => import('@/pages/WorkAddPage'));
// const WorkDetailPage = lazy(() => import('@/pages/WorkDetailPage'));
// const WorkEditPage = lazy(() => import('@/pages/WorkEditPage'));
// const WorkReportDetailPage = lazy(
//   () => import('@/pages/WorkReportDetailPage')
// );
// const WorkReportEditPage = lazy(() => import('@/pages/WorkReportEditPage'));
// const PaymentPage = lazy(() => import('@/pages/PaymentPage'));

const Router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        element={
          <>
            <PageCenter>
              <Outlet />
            </PageCenter>
            <Footer padding='2.5rem' />
          </>
        }
      >
        <Route path={ROUTER.LANDING} element={<LandingPage />} />
        <Route path={ROUTER.LOGIN} element={<LoginPage />} />
        <Route path={ROUTER.SIGNUP} element={<SignUpPage />} />
        <Route path={ROUTER.FIND_PWD.EMAIL} element={<EmailPage />} />
        <Route path={ROUTER.FIND_PWD.CHANGE_PWD} element={<ChangePwdPage />} />
      </Route>

      <Route element={<UserRoute />}>
        <Route path={ROUTER.CARD_REGISTER} element={<CardRegisterPage />} />
        <Route element={<GNB />}>
          <Route path={ROUTER.DASHBOARD} element={<DashboardPage />} />
          <Route
            path={ROUTER.WORK.INSPECTION_HISTORY + '/:id'}
            element={<InspectionHistoryPage />}
          />
          <Route path={ROUTER.WORKER} element={<WorkerPage />} />
          {/* <Route path={ROUTER.NOTIFICATION} element={<NotificationPage />} /> */}
          <Route path={ROUTER.PAYMENT} element={<PaymentManagementPage />} />
          <Route path={ROUTER.MYCOMPANY} element={<MyCompanyPage />} />
          <Route path={ROUTER.WORK.DETAIL + '/:id'} element={<ReportPage />} />
          <Route path={ROUTER.WORK.ADD} element={<WorkAddPage />} />
          <Route
            path={ROUTER.WORK.REPORT_DETAIL + '/:id'}
            element={<WorkReportDetailPage />}
          />
          <Route
            path={ROUTER.WORK.REPORT_EDIT + '/:id'}
            element={<WorkReportEditPage />}
          />

          <Route
            path={ROUTER.ADMIN.USER_MANAGE}
            element={<AdminUserManagePage />}
          />
          {/* 서지수 TODO 사용자 접속 현황 통계 <Route
            path={ROUTER.ADMIN.USER_STATISTICS + '/:id'}
            element={<AdminUserStatisticsPage />}
          /> */}
        </Route>
      </Route>
      <Route path='/*' element={<div>올바르지 않은 접근입니다.</div>} />
    </>
  )
);

export default Router;
