import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { deleteInspectionArea } from '@/apis/InspectionArea';
import type { ErrorResponse } from '@/types/client.types';

import { QUERY_KEY } from '@/constants/queryKey';
import { ROUTER } from '@/constants/constants';
import { TOAST_ID } from '@/constants/toastId';

import Modal from '@/components/common/Modal';
import Spacer from '@/components/common/Spacer';
import Text from '@/components/common/Text';
import Input from '@/components/common/Input';

interface Props {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  inspectionAreaId: number;
}

const WorkDeleteModal = ({ setIsModalOpen, inspectionAreaId }: Props) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [reason, setReason] = useState<string>('');

  const { mutate: workDeleteMutate } = useMutation({
    mutationFn: deleteInspectionArea,
    onSuccess: (data) => {
      queryClient.invalidateQueries([QUERY_KEY.DASHBOARD]);
      navigate({ pathname: ROUTER.DASHBOARD, search: searchParams.toString() });
      toast.success('점검지 삭제가 완료되었습니다.');
      setIsModalOpen(false);
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      toast.error(error.response?.data.message);
    },
  });

  const handleConfirmDelete = () => {
    if (reason.length < 1) {
      toast.error('사유를 작성해주세요.', {
        toastId: TOAST_ID.WORK_DELETE_MODAL,
      });
      return;
    }
    workDeleteMutate({
      inspection_area_id: inspectionAreaId,
      deleted: 1,
      reason,
    });
  };

  return (
    <Modal
      setIsModalOpen={setIsModalOpen}
      isWarning
      onClickConfirm={handleConfirmDelete}
    >
      <Spacer y='1.875rem' />
      <Text fontSize='1.375rem' fontWeight={700} textAlign='center'>
        점검지를 정말 삭제하시겠습니까?
      </Text>
      <Spacer y='1.25rem' />
      <Text fontSize='1.125rem' fontWeight={500}>
        사유를 작성해주세요.
      </Text>
      <Spacer y='1.25rem' />
      <Input
        name='reason'
        value={reason}
        onChange={(e) => setReason(e.target.value)}
        width='25.625rem'
        height='2.5rem'
      />
      <Spacer y='1.875rem' />
    </Modal>
  );
};

export default WorkDeleteModal;
