import styled from '@emotion/styled';

export const TableHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const BtnWrapper = styled.div`
  display: flex;
  gap: 0.625rem;
  justify-content: flex-end;
  align-items: center;
`;
