import React from 'react';
import styled from '@emotion/styled';

import Spacer from '@/components/common/Spacer';

import { StyledH1 } from '@/styles/common';
import { Theme } from '@/styles/Theme';

interface Props {
  title?: string;
  children: React.ReactNode;
  width?: string;
  maxWidth?: string;
  minWidth?: string;
  height?: string;
  hasBackground?: boolean;
}

const GreyBox = ({
  title,
  children,
  width,
  maxWidth,
  minWidth,
  height,
  hasBackground = true,
}: Props) => {
  return (
    <Container
      width={width}
      maxWidth={maxWidth}
      minWidth={minWidth}
      height={height}
      hasBackground={hasBackground}
    >
      {title && (
        <>
          <StyledH1>{title}</StyledH1>
          <Spacer y='1.875rem' />
        </>
      )}
      {children}
    </Container>
  );
};

export default GreyBox;

export const Container = styled.section<Props>`
  position: relative;

  background-color: ${({ hasBackground }) => hasBackground && Theme.colors.G_6};
  border-radius: 0.625rem;

  padding: 2.5rem;

  margin: 0 auto;

  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};
  min-width: ${({ minWidth }) => minWidth};

  height: ${({ height }) => height};
`;
