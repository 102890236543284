export const TOAST_ID = {
  SIGN_UP: 'sign_up',
  SIGN_UP_ROUTE: 'sign_up_route',
  CATEGORY_LIST_TABLE: 'category_list_table',
  WORK_DELETE_MODAL: 'work_delete_modal',
  WORK_TARGET_IMG_LIST: 'work_target_img_list',
  WORK_TARGET_REPORT_ITEM_MODAL_O: 'work_target_report_item_modal_o',
  WORK_TARGET_REPORT_ITEM_MODAL_X: 'work_target_report_item_modal_x',
  WORKER_LIST_TABLE: 'worker_list_table',
  WORK_ADD: 'work_add',
  WORK_COUNT: 'work_count',
};
