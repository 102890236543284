import React from 'react';

import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import Section1 from '@/components/landing/Section1';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import * as S from './style';

const LandingSwiper = () => {
  return (
    <S.StyledSwiper
      id='header_part1'
      className='header'
      slidesPerView={1}
      loop
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      pagination={true}
      navigation={true}
      modules={[Autoplay, Pagination, Navigation]}
    >
      <S.StyledSwiperSlide key={1}>
        <Section1 />
      </S.StyledSwiperSlide>
      <S.StyledSwiperSlide key={2}>
        <S.ImgWrapper src='/bm_banner.png' />
      </S.StyledSwiperSlide>
    </S.StyledSwiper>
  );
};

export default LandingSwiper;
