import type { InspectionItemValue } from '@/types/deviceReport.types';

interface ValueInfo {
  template_id: number;
  value: number;
}

interface CalculationRule {
  (values: ValueInfo[]): number;
}

// 유틸 함수 생성
export const calculateValue = (
  templateId: number,
  value: number,
  allValues: any[]
) => {
  // 계산 규칙이 있는지 확인
  const calculate = calculationRules[templateId];
  if (calculate) {
    // 계산 규칙에 따라 값을 계산
    return calculate.calculate(allValues);
  }
  // 계산 규칙이 없는 경우, 입력된 값을 그대로 반환
  return value;
};

// 나누기 퍼센트 경우
const divideCalculation =
  (id1: number, id2: number): CalculationRule =>
  (values) => {
    const val1 = values.find((v) => v.template_id === id1)?.value ?? 0;
    const val2 = values.find((v) => v.template_id === id2)?.value ?? 0;

    if (val1 === 0) {
      return 0;
    }

    return Number(((val2 / val1) * 100).toFixed(2));
  };

const minusCalculation =
  (id1: number, id2: number): CalculationRule =>
  (values) => {
    const val1 = values.find((v) => v.template_id === id1)?.value ?? 0;
    const val2 = values.find((v) => v.template_id === id2)?.value ?? 0;
    return Number((val1 - val2).toFixed(2));
  };

// 계산 규칙 정의
export const calculationRules: {
  [key: number]: {
    dependencies: number[];
    calculate: (values: InspectionItemValue[]) => number;
  };
} = {
  // 냉각탑
  5: {
    dependencies: [3, 4],
    calculate: divideCalculation(3, 4),
  },
  8: {
    dependencies: [6, 7],
    calculate: divideCalculation(6, 7),
  },
  11: {
    dependencies: [9, 10],
    calculate: divideCalculation(9, 10),
  },

  // 열교환기
  16: {
    dependencies: [14, 15],
    calculate: minusCalculation(14, 15),
  },
  19: {
    dependencies: [17, 18],
    calculate: minusCalculation(17, 18),
  },

  // 펌프
  26: {
    dependencies: [24, 25],
    calculate: divideCalculation(24, 25),
  },
  29: {
    dependencies: [27, 28],
    calculate: divideCalculation(27, 28),
  },
  32: {
    dependencies: [30, 31],
    calculate: divideCalculation(30, 31),
  },

  // 공기조화기
  35: {
    dependencies: [33, 34],
    calculate: divideCalculation(33, 34),
  },
  38: {
    dependencies: [36, 37],
    calculate: divideCalculation(36, 37),
  },
  41: {
    dependencies: [39, 40],
    calculate: divideCalculation(39, 40),
  },
  44: {
    dependencies: [42, 43],
    calculate: divideCalculation(42, 43),
  },
  47: {
    dependencies: [45, 46],
    calculate: divideCalculation(45, 46),
  },
  50: {
    dependencies: [48, 49],
    calculate: divideCalculation(48, 49),
  },

  // 환기설비
  55: {
    dependencies: [53, 54],
    calculate: divideCalculation(53, 54),
  },
  58: {
    dependencies: [56, 57],
    calculate: divideCalculation(56, 57),
  },
  61: {
    dependencies: [59, 60],
    calculate: divideCalculation(59, 60),
  },
};
