import { Theme } from '@/styles/Theme';
import styled from '@emotion/styled';

export const Container = styled.div``;

export const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Table = styled.table`
  border-top: 0.125rem solid ${Theme.colors.BLACK};
  border-bottom: 0.125rem solid ${Theme.colors.BLACK};

  background-color: ${Theme.colors.WHITE};

  text-align: center;

  tr {
    height: 2.5rem;
  }

  tr:first-of-type {
    border-bottom: 0.0625rem solid ${Theme.colors.BLACK};

    td + td {
      border-left: 0.0625rem solid ${Theme.colors.BLACK};
    }

    td:nth-of-type(2n-1) {
      background-color: ${Theme.colors.G_4};
      font-weight: bold;
    }
  }
  tr:nth-of-type(2) {
    border-bottom: 0.125rem solid ${Theme.colors.BLACK};

    td + td {
      border-left: 0.0625rem solid ${Theme.colors.BLACK};
    }

    td {
      background-color: ${Theme.colors.G_4};
      font-weight: bold;
    }
  }
`;

export const NumberWrapper = styled.div`
  width: 1.5rem;
  height: 1.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 0.0625rem solid ${Theme.colors.BLACK};
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;

  cursor: pointer;
`;

export const LastHistoryWrapper = styled.div`
  position: absolute;
  right: 12.5rem;
`;

export const StyledTextarea = styled.textarea<{
  height?: string;
  textAlign?: string;
}>`
  display: block;

  width: 100%;
  height: ${({ height }) => height};
  background-color: rgb(255, 248, 226, 0.7);

  border: none;

  padding: 0.3125rem;

  color: ${Theme.colors.BLACK};
  font-size: 1rem;
  font-weight: 400;
  text-align: ${({ textAlign }) => textAlign};
  resize: none;

  :disabled {
    background-color: ${Theme.colors.WHITE};
  }

  ::placeholder {
    color: ${Theme.colors.G_2};
  }

  :focus {
    outline: none;
  }
`;

export const DotsWrapper = styled.div`
  position: absolute;

  top: 2.5rem;
  right: 2.5rem;

  display: flex;
  align-items: center;
  gap: 0.625rem;
`;

export const TooltipContainer = styled.div<{ isOpen: boolean }>`
  position: absolute;

  right: 0;

  border: 0.0625rem solid ${Theme.colors.G_3};
  border-radius: 0.25rem;
  background-color: ${Theme.colors.WHITE};

  z-index: 10;

  transition: opacity 0.2s ease, transform 0.2s ease;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transform: translateY(${({ isOpen }) => (isOpen ? '0' : '-0.625rem')});
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};

  div:last-of-type {
    border-bottom: none;
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 0.0625rem;
  background-color: ${Theme.colors.G_2};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.25rem;
`;

export const ImgContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const ImgWrapper = styled.img`
  display: block;

  width: 100%;
  height: 100%;
`;

export const ImgRemoveIconWrapper = styled.div`
  position: absolute;
  top: 0.3125rem;
  right: 0.3125rem;

  border-radius: 50%;

  background-color: ${Theme.colors.WHITE};
  color: ${Theme.colors.WARNING_RED};

  cursor: pointer;
`;
