import { useMemo, useState } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
  createColumnHelper,
} from '@tanstack/react-table';
import { useQuery } from '@tanstack/react-query';

import { getCompanyList } from '@/apis/admin';
import type { CompanyInfo } from '@/types/admin.types';

import { QUERY_KEY } from '@/constants/queryKey';

import Text from '@/components/common/Text';

import * as S from './style';

import { phoneFormat } from '@/utils/convertNumberFormat';

const convertKRTime = (utcTime: string) => {
  const date = new Date(utcTime);
  const krTime = date.toLocaleString('ko-KR', {
    timeZone: 'Asia/Seoul',
    hour12: false,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
  return krTime;
};

const columnhelper = createColumnHelper<CompanyInfo>();

const columns = [
  columnhelper.accessor((row, index) => index + 1, {
    id: 'index',
    header: '번호',
    cell: (info) => info.getValue(),
    size: 60,
  }),
  columnhelper.accessor('company', {
    header: '회사명',
    size: 190,
  }),
  columnhelper.accessor('email', {
    header: '이메일',
    size: 250,
  }),
  columnhelper.accessor('phone', {
    header: '연락처',
    cell: (info) => phoneFormat(info.getValue()),
    size: 143,
  }),

  columnhelper.accessor('worker_count', {
    header: '작업자 수',
    cell: (info) => `${info.getValue()}명`,
    size: 80,
  }),
  columnhelper.accessor('is_trial', {
    header: '무료체험',
    cell: (info) => (info.getValue() ? '체험판' : '-'),
    size: 80,
  }),
  columnhelper.accessor('is_approved', {
    header: '접속허용',
    cell: (info) => (info.getValue() ? 'O' : 'X'),
    size: 80,
  }),
  columnhelper.accessor('signup_source', {
    header: '회원가입 경로',
    size: 143,
  }),
  columnhelper.accessor('created_at', {
    header: '가입일',
    cell: (info) => convertKRTime(info.getValue()),
    size: 225,
  }),
  columnhelper.accessor('updated_at', {
    header: '최근 접속일',
    cell: (info) => convertKRTime(info.getValue()),
    size: 225,
  }),
  columnhelper.accessor('dailyActiveWorker', {
    header: '금일 접속 작업자 수',
    cell: (info) => `${info.getValue()}명`,
    // 서지수 TODO 사용자 접속 현황 통계
    // cell: ({ row, getValue }) => (
    //   <Link to={`${ROUTER.ADMIN.USER_STATISTICS}/${row.original.id}`}>
    //     {`${getValue()}명`}
    //   </Link>
    // ),
    size: 150,
  }),
  columnhelper.accessor('weeklyActiveWorker', {
    header: '주간 접속 작업자 수',
    cell: (info) => `${info.getValue()}명`,
    // 서지수 TODO 사용자 접속 현황 통계
    // cell: ({ row, getValue }) => (
    //   <Link
    //     to={`${ROUTER.ADMIN.USER_STATISTICS}/${row.original.id}`}
    //   >{`${getValue()}명`}</Link>
    // ),
    size: 150,
  }),
];

const UserTable = () => {
  const [isAdmin, setIsAdmin] = useState<number>(0);
  const { data: userData } = useQuery({
    queryKey: [QUERY_KEY.ADMIN.USER_LIST, isAdmin],
    queryFn: () => getCompanyList({ isAdmin }),
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedColumn = useMemo(() => columns, [columns]);
  const memoizedData = useMemo(
    () => userData?.data.companyList ?? [],
    [userData]
  );

  const { getHeaderGroups, getRowModel, getCenterTotalSize } = useReactTable({
    columns: memoizedColumn,
    data: memoizedData,
    sortDescFirst: true,
    enableSorting: true,
    enableMultiSort: true,
    enableSortingRemoval: true,
    columnResizeMode: 'onChange',
    columnResizeDirection: 'ltr',
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <>
      <Text fontWeight={700} fontSize='1.5625rem'>
        전체 사용자
      </Text>
      <S.CountContainer>
        <Text fontWeight={600}>
          구독중인 업체 수 : {userData?.data.count.subscribed}
        </Text>
        <Text fontWeight={600}>
          총 결제된 작업자 수 : {userData?.data.count.copy}
        </Text>
        <S.InputContainer>
          <Text>어드민 포함하여 보기</Text>
          <input
            type='checkbox'
            checked={!!isAdmin}
            onChange={() => setIsAdmin((prev) => Number(!!!prev))}
          />
        </S.InputContainer>
      </S.CountContainer>
      <S.Table style={{ width: getCenterTotalSize() }}>
        <thead>
          {getHeaderGroups().map((headerGroup) => (
            <S.Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <S.Th
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{ width: header.getSize() }}
                >
                  <span onClick={header.column.getToggleSortingHandler()}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </span>
                  {header.column.getIsSorted()
                    ? header.column.getIsSorted() === 'desc'
                      ? ' 🔽'
                      : ' 🔼'
                    : ''}
                  <S.ResizerLine
                    onDoubleClick={() => header.column.resetSize()}
                    onMouseDown={header.getResizeHandler()}
                    onTouchStart={header.getResizeHandler()}
                    isResizing={header.column.getIsResizing()}
                  />
                </S.Th>
              ))}
            </S.Tr>
          ))}
        </thead>
        <tbody>
          {getRowModel().rows.map((row) => (
            <S.Tr key={row.id} isAdmin={row.original.is_admin}>
              {row.getVisibleCells().map((cell) => (
                <S.Td key={cell.id} style={{ width: cell.column.getSize() }}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </S.Td>
              ))}
            </S.Tr>
          ))}
        </tbody>
      </S.Table>
    </>
  );
};

export default UserTable;
