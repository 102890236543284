import * as ExcelJS from 'exceljs';

import {
  addImageToSheet,
  copyTemplate,
} from '@/utils/excelExport/excelExportUtils';
import { getGuideline } from '@/apis/report/system';
import {
  getPerformanceImprovement,
  getPersistingPeriodAge,
  getSpecificImprovement,
} from '@/apis/report/improvePlan';
import type { ExcelTotalReport } from '@/types/InspectionArea.types';
import { getEnergyConsumption } from '@/apis/report/energy';

export const exportReportConsider = async (
  workbook: ExcelJS.Workbook,
  inspectionAreaId: number,
  total_report: ExcelTotalReport
) => {
  const response = await fetch('/report/5. 성능점검 시 검토사항.xlsx', {
    cache: 'no-store',
  });
  const arrayBuffer = await response.arrayBuffer();
  const newWorkBook = new ExcelJS.Workbook();
  await newWorkBook.xlsx.load(arrayBuffer);

  // 복사 하려는 워크시트
  const copySheet = newWorkBook.getWorksheet(1);
  // 복사 받을 워크시트
  let mainSheet = workbook.addWorksheet('Ⅴ. 성능점검 시 검토사항');

  copyTemplate(
    mainSheet,
    copySheet,
    [43, 77, 99, 121, 155, 183, 217, 250, 282]
  );

  // 데이터 넣어주는 부분
  const guideLineData = await getGuideline(inspectionAreaId);
  const persistingPeriodAgeData = await getPersistingPeriodAge(
    inspectionAreaId
  );
  const performanceImprovementData = await getPerformanceImprovement(
    inspectionAreaId
  );
  const specificImprovementData = await getSpecificImprovement(
    inspectionAreaId
  );
  const energyPlanData = await getEnergyConsumption(inspectionAreaId);

  if (
    guideLineData &&
    persistingPeriodAgeData &&
    performanceImprovementData &&
    specificImprovementData &&
    energyPlanData
  ) {
    // 성능점검 시 검토사항 - 기계설비 시스템 검토 - 유지관리지침서의 적정성
    const {
      asBuiltDrawing,
      specification,
      calculation,
      manual,
      report,
      beforeChecklist,
      performanceConfirmation,
      cautionConfirmation,
      suitabilityConfirmation,
    } = guideLineData.data;

    asBuiltDrawing
      ? (mainSheet.getCell(50, 10).value = '■')
      : (mainSheet.getCell(50, 11).value = '■');
    specification
      ? (mainSheet.getCell(51, 10).value = '■')
      : (mainSheet.getCell(51, 11).value = '■');
    calculation
      ? (mainSheet.getCell(52, 10).value = '■')
      : (mainSheet.getCell(52, 11).value = '■');
    manual
      ? (mainSheet.getCell(53, 10).value = '■')
      : (mainSheet.getCell(53, 11).value = '■');
    report
      ? (mainSheet.getCell(54, 10).value = '■')
      : (mainSheet.getCell(54, 11).value = '■');

    beforeChecklist
      ? (mainSheet.getCell(55, 5).value = '■ 해당')
      : (mainSheet.getCell(55, 8).value = '■ 해당없음');
    performanceConfirmation
      ? (mainSheet.getCell(57, 5).value = '■ 해당')
      : (mainSheet.getCell(57, 8).value = '■ 해당없음');
    cautionConfirmation
      ? (mainSheet.getCell(59, 5).value = '■ 해당')
      : (mainSheet.getCell(59, 8).value = '■ 해당없음');
    suitabilityConfirmation
      ? (mainSheet.getCell(61, 5).value = '■ 해당')
      : (mainSheet.getCell(61, 8).value = '■ 해당없음');

    // 성능점검 시 검토사항 - 기계설비 시스템 검토 - 기계설비 시스템의 작동 상태, 점검대상 현황표 상의 설계값과 측정값 일치 여부
    const { result_list } = total_report;

    mainSheet.eachRow((row, rowNumber) => {
      if (rowNumber >= 82 && rowNumber <= 118) {
        const leftCellContent = row.getCell(2).value;
        const rightCellContent = row.getCell(8).value;

        // array에서 일치하는 content 찾기
        const leftMatchingItem = result_list.find(
          (item: any) => item.content === leftCellContent
        );

        const rightMatchingItem = result_list.find(
          (item: any) => item.content === rightCellContent
        );

        if (leftMatchingItem) {
          row.getCell(4).value = leftMatchingItem.result;
        }
        if (rightMatchingItem) {
          row.getCell(10).value = rightMatchingItem.result;
        }
      }
    });

    // 성능점검 시 검토사항 - 성능개선 계획수립 - 기계설비 내구연한에 따른 노후도
    const { oldAgeList, inspectionResult } = persistingPeriodAgeData.data;

    mainSheet.eachRow((row, rowNumber) => {
      if (rowNumber >= 126 && rowNumber <= 153) {
        const leftCellContent = row.getCell(3).value;

        // array에서 일치하는 content 찾기
        const leftMatchingItem = oldAgeList.find(
          (item) => item.templateDeviceName === leftCellContent
        );

        if (leftMatchingItem) {
          row.getCell(6).value = leftMatchingItem.deviceNumber;
          row.getCell(8).value = leftMatchingItem.durability;
          row.getCell(9).value = leftMatchingItem.useYear;
          row.getCell(10).value = leftMatchingItem.explanatory;
        }
      }
    });

    mainSheet.getCell('A155').value = inspectionResult;

    // 성능점검 시 검토사항 - 성능개선 계획수립 - 성능점검표에 따른 부적합 및 개선사항
    mainSheet.eachRow((row, rowNumber) => {
      if (rowNumber >= 220 && rowNumber <= 247) {
        const leftCellContent = row.getCell(3).value;

        // array에서 일치하는 content 찾기
        const leftMatchingItem = performanceImprovementData.data.find(
          (item) => item.templateDeviceName === leftCellContent
        );

        if (leftMatchingItem) {
          row.getCell(6).value = leftMatchingItem.insufficiencies;
          row.getCell(9).value = leftMatchingItem.improvement;
        }
      }
    });

    // 성능점검 시 검토사항 - 성능개선 계획수립 - 성능개선 필요성 및 연도별 세부개선계획
    mainSheet.eachRow((row, rowNumber) => {
      if (rowNumber >= 254 && rowNumber <= 281) {
        const leftCellContent = row.getCell(3).value;

        // array에서 일치하는 content 찾기
        const leftMatchingItem = specificImprovementData.data.find(
          (item) => item.templateDeviceName === leftCellContent
        );

        if (leftMatchingItem) {
          row.getCell(5).value = leftMatchingItem.improvement;
          row.getCell(7).value = leftMatchingItem.firstSpecificPlan;
          row.getCell(8).value = leftMatchingItem.secondSpecificPlan;
          row.getCell(9).value = leftMatchingItem.thirdSpecificPlan;
          row.getCell(10).value = leftMatchingItem.fourthSpecificPlan;
          row.getCell(11).value = leftMatchingItem.fifthSpecificPlan;
        }
      }
    });

    // 성능점검 시 검토사항 - 에너지 사용량 검토
    let startDataRow = 285;
    const imageRowCount = 18;
    const emptyRowGap = 3;

    for (let i = 0; i < energyPlanData.data.length; i++) {
      const { image, explanation } = energyPlanData.data[i];
      const dataStartRow = startDataRow + (imageRowCount + emptyRowGap) * i;

      mainSheet.mergeCells(dataStartRow, 1, dataStartRow, 11);
      mainSheet.getCell(dataStartRow, 1).value = explanation;

      if (image) {
        const imageId = await addImageToSheet(image, workbook);
        mainSheet.addImage(imageId, {
          tl: { col: 0.8, row: dataStartRow + 0.4 },
          ext: { width: 620, height: 400 },
        });
      }
    }
  }
};
