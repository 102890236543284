import GreyBox from '@/components/common/GreyBox';
import WorkReportResultTable from '@/components/work/WorkReportResultTable';

const WorkReportEditPage = () => {
  return (
    <GreyBox title='성능점검표 수정' width='58.125rem'>
      <WorkReportResultTable />
    </GreyBox>
  );
};

export default WorkReportEditPage;
