import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AiOutlineEdit } from 'react-icons/ai';
import { MdOutlineCancel } from 'react-icons/md';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { getDeviceReport, putDeviceReport } from '@/apis/deviceReport';
import type { ErrorResponse } from '@/types/client.types';

import { QUERY_KEY } from '@/constants/queryKey';
import { ROUTER } from '@/constants/constants';

import WorkReportPresignedImgList from '@/components/work/WorkReportPresignedImgList';
import WorkReportResultItemModal from '@/components/work/WorkReportResultItemModal';
import Text from '@/components/common/Text';
import Spacer from '@/components/common/Spacer';
import Button from '@/components/common/Button';
import Input from '@/components/common/Input';
import Prompt from '@/components/common/Prompt';

import { Theme } from '@/styles/Theme';

import * as S from './style';
import LastEditedHistory from '@/components/inspectionHistory/LastEditedHistory';
import LastEditedHistoryText from '@/components/inspectionHistory/LastEditedHistory/LastEditedHistoryText';
import { StyledCol, StyledTd } from '@/styles/common';

interface ReportResultFormProps {
  deviceNum: string;
  location: string;
  detailOne: string;
  detailTwo: string;
  reason: string;
  memo: string;
}

export interface WorkTableProps {
  isDetailPage?: boolean;
}

const WorkReportResultTable = ({ isDetailPage = false }: WorkTableProps) => {
  const params = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const reportId = Number(params.id);

  const [reportResultForm, setReportResultForm] =
    useState<ReportResultFormProps>({
      deviceNum: '',
      location: '',
      detailOne: '',
      detailTwo: '',
      reason: '',
      memo: '',
    });

  const [imageList, setImageList] = useState<string[]>([]);
  const [selectedImage, setSelectedImage] = useState<string[]>([]);

  const [itemId, setItemId] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  const handleRemoveImg = (src: string) => {
    const selectedFiltered = selectedImage.filter((img) => img !== src);
    setSelectedImage(selectedFiltered);
  };

  const { data } = useQuery([QUERY_KEY.WORK_TARGET_RESULT, reportId], {
    queryFn: () => getDeviceReport(reportId),
  });

  const { mutate } = useMutation({
    mutationFn: putDeviceReport,
    onSuccess: () => {
      toast.success('수정되었습니다.');
      queryClient.invalidateQueries([QUERY_KEY.WORK_TARGET_RESULT, reportId]);
      navigate(`${ROUTER.WORK.REPORT_DETAIL}/${reportId}`);
      window.scrollTo({ top: 0 });
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      toast.error(error.response?.data.message);
    },
  });

  useEffect(() => {
    if (data) {
      const {
        detail_one,
        detail_two,
        memo,
        image_list,
        reason,
        selected_image,
        device_num,
        location,
      } = data.data.device_info;

      setReportResultForm({
        deviceNum: device_num,
        location,
        detailOne: detail_one,
        detailTwo: detail_two,
        reason: isDetailPage ? reason ?? '' : '',
        memo,
      });

      setImageList(image_list ?? []);
      setSelectedImage(selected_image ?? []);
    }
  }, [data, isDetailPage]);

  // 작성 중 새로고침, 페이지 이탈 방지 (정보 페이지 제외)
  let isWriting = !!(
    reportResultForm.detailOne ||
    reportResultForm.detailTwo ||
    reportResultForm.memo ||
    selectedImage.length > 0 ||
    imageList.length > 0
  );

  useEffect(() => {
    const onBeforeUnload = (event: BeforeUnloadEvent) => {
      if (!isDetailPage && isWriting) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [isWriting, isDetailPage]);

  if (!data) {
    return null;
  }

  const { worker_list, start_date, end_date, seq, name, log } =
    data.data.device_info;

  const date = `${start_date.replaceAll('-', '.')}~${end_date
    .replaceAll('-', '.')
    .slice(5)}`;

  const handleOpenModal = (itemId: number) => {
    setItemId(itemId);
    setIsModalOpen(true);
  };

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setReportResultForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const { deviceNum, location, detailOne, detailTwo, memo, reason } =
    reportResultForm;

  const handleConfirmEdit = () => {
    setIsSubmit(true);
    mutate({
      report_id: reportId,
      device_num: deviceNum,
      location,
      detail_one: detailOne,
      detail_two: detailTwo,
      memo,
      reason,
      image_list: imageList,
      selected_image: selectedImage,
    });
  };

  const statusTranslate = (status: string) => {
    if (status === '-') {
      return '점검전';
    }
    if (status === '~') {
      return '점검중';
    }
    return status;
  };

  return (
    <>
      <S.Container>
        <Prompt
          when={!isDetailPage && !isSubmit && isWriting}
          message={`페이지에서 나가시겠습니까?\n변경사항이 저장되지 않을 수 있습니다.`}
        />
        {isModalOpen && (
          <WorkReportResultItemModal
            itemId={itemId}
            setIsModalOpen={setIsModalOpen}
          />
        )}
        <S.TextWrapper>
          <Text fontWeight={700} fontSize='1.75rem'>
            {name}
          </Text>
          <Text fontWeight={700} fontSize='1.75rem'>
            {isDetailPage ? (
              deviceNum && `(${deviceNum})`
            ) : (
              <S.StyledTextarea
                name='deviceNum'
                height='2.5rem'
                textAlign='center'
                disabled={isDetailPage}
                value={deviceNum}
                onChange={handleInputChange}
              />
            )}
          </Text>
          <Text fontWeight={700} fontSize='1.75rem'>
            &nbsp;성능점검표 #{seq}
          </Text>
        </S.TextWrapper>
        <Spacer y='1.25rem' />
        <S.Table>
          <colgroup>
            <StyledCol width='6.25rem' />
            <StyledCol width='9.375rem' />
            <StyledCol width='9.375rem' />
            <StyledCol width='9.375rem' />
            <StyledCol width='9.375rem' />
            <StyledCol width='9.375rem' />
          </colgroup>
          <tbody>
            <tr>
              <td>점검자</td>
              <td>{worker_list.length > 0 ? worker_list.join(', ') : '-'}</td>
              <td>점검일자</td>
              <td>{date}</td>
              <td>설치위치(No.)</td>
              <td>
                {isDetailPage ? (
                  location
                ) : (
                  <S.StyledTextarea
                    name='location'
                    height='2.5rem'
                    textAlign='center'
                    disabled={isDetailPage}
                    value={location}
                    onChange={handleInputChange}
                  />
                )}
              </td>
            </tr>
            <tr>
              <td>구 분</td>
              <td colSpan={4}>점검내용</td>
              <td>점검결과</td>
            </tr>
            {data.data.item_list.map((item, idx) => {
              if (idx === 0) {
                return (
                  <tr key={item.id}>
                    <StyledTd
                      rowSpan={data.data.item_list.length}
                      fontWeight={700}
                      borderRight='0.0625rem solid black'
                      borderBottom='0.0625rem solid black'
                    >
                      점검항목
                    </StyledTd>
                    <StyledTd
                      colSpan={4}
                      textAlign='left'
                      borderRight='0.0625rem solid black'
                      borderBottom='0.0625rem dotted black'
                      padding='0 0.625rem'
                    >
                      <S.FlexContainer onClick={() => handleOpenModal(item.id)}>
                        <S.NumberWrapper>{idx + 1}</S.NumberWrapper>{' '}
                        {item.content}
                        <S.LastHistoryWrapper>
                          <LastEditedHistoryText
                            workerName={item.log.workerName}
                            updatedAt={item.log.updatedAt}
                            size='small'
                          />
                        </S.LastHistoryWrapper>
                      </S.FlexContainer>
                    </StyledTd>
                    <StyledTd borderBottom='0.0625rem dotted black'>
                      {statusTranslate(item.result)}
                    </StyledTd>
                  </tr>
                );
              }
              return (
                <tr key={item.id}>
                  <StyledTd
                    colSpan={4}
                    textAlign='left'
                    borderRight='0.0625rem solid black'
                    borderBottom='0.0625rem dotted black'
                    padding='0 0.625rem'
                  >
                    <S.FlexContainer onClick={() => handleOpenModal(item.id)}>
                      <S.NumberWrapper>{idx + 1}</S.NumberWrapper>{' '}
                      {item.content}
                      <S.LastHistoryWrapper>
                        <LastEditedHistoryText
                          workerName={item.log.workerName}
                          updatedAt={item.log.updatedAt}
                          size='small'
                        />
                      </S.LastHistoryWrapper>
                    </S.FlexContainer>
                  </StyledTd>
                  <StyledTd borderBottom='0.0625rem dotted black'>
                    {statusTranslate(item.result)}
                  </StyledTd>
                </tr>
              );
            })}
            <tr>
              <StyledTd
                rowSpan={2}
                fontWeight={700}
                borderRight='0.0625rem solid black'
                borderBottom='0.0625rem solid black'
              >
                조치사항
              </StyledTd>
              <StyledTd
                colSpan={5}
                height='6.25rem'
                verticalAlign='top'
                textAlign='left'
                borderTop='0.0625rem solid black'
                borderBottom='0.0625rem solid black'
                padding='0.3125rem'
                whiteSpace='pre-wrap'
              >
                {'<'}미흡사항{'>'}
                {isDetailPage ? (
                  <div style={{ height: '4.0625rem', overflow: 'auto' }}>
                    {detailOne}
                  </div>
                ) : (
                  <S.StyledTextarea
                    name='detailOne'
                    height='4.6875rem'
                    disabled={isDetailPage}
                    value={detailOne}
                    onChange={handleInputChange}
                  />
                )}
              </StyledTd>
            </tr>
            <tr>
              <StyledTd
                colSpan={5}
                height='6.25rem'
                verticalAlign='top'
                textAlign='left'
                borderBottom='0.0625rem solid black'
                padding='0.3125rem'
                whiteSpace='pre-wrap'
              >
                {'<'}조치필요사항{'>'}
                {isDetailPage ? (
                  <div style={{ height: '4.0625rem', overflow: 'auto' }}>
                    {detailTwo}
                  </div>
                ) : (
                  <S.StyledTextarea
                    name='detailTwo'
                    height='4.6875rem'
                    disabled={isDetailPage}
                    value={detailTwo}
                    onChange={handleInputChange}
                  />
                )}
              </StyledTd>
            </tr>
            <tr>
              <StyledTd
                fontWeight={700}
                borderRight='0.0625rem solid black'
                borderBottom='0.0625rem solid black'
              >
                현황사진
              </StyledTd>
              <StyledTd
                colSpan={5}
                height='23.4375rem'
                borderBottom='0.0625rem solid black'
              >
                <div style={{ display: 'flex' }}>
                  <div
                    style={{
                      width: '23.4375rem',
                      height: '23.4375rem',
                      borderRight: '0.0625rem solid black',
                    }}
                  >
                    {selectedImage[0] && (
                      <S.ImgContainer>
                        <S.ImgWrapper src={selectedImage[0]} alt='현황사진_1' />
                        {!isDetailPage && (
                          <S.ImgRemoveIconWrapper
                            onClick={() => handleRemoveImg(selectedImage[0])}
                          >
                            <MdOutlineCancel size='1.75rem' />
                          </S.ImgRemoveIconWrapper>
                        )}
                      </S.ImgContainer>
                    )}
                  </div>
                  <div style={{ width: '23.4375rem', height: '23.4375rem' }}>
                    {selectedImage[1] && (
                      <S.ImgContainer>
                        <S.ImgWrapper src={selectedImage[1]} alt='현황사진_1' />
                        {!isDetailPage && (
                          <S.ImgRemoveIconWrapper
                            onClick={() => handleRemoveImg(selectedImage[1])}
                          >
                            <MdOutlineCancel size='1.75rem' />
                          </S.ImgRemoveIconWrapper>
                        )}
                      </S.ImgContainer>
                    )}
                  </div>
                </div>
              </StyledTd>
            </tr>
            <tr>
              <StyledTd fontWeight={700} borderRight='0.0625rem solid black'>
                비 고
              </StyledTd>
              <StyledTd
                colSpan={5}
                height='2.5rem'
                textAlign='left'
                padding='0.3125rem'
                whiteSpace='pre-wrap'
              >
                {isDetailPage ? (
                  <div style={{ height: '1.875rem', overflow: 'auto' }}>
                    {memo}
                  </div>
                ) : (
                  <S.StyledTextarea
                    name='memo'
                    height='2.5rem'
                    disabled={isDetailPage}
                    value={memo}
                    onChange={handleInputChange}
                  />
                )}
              </StyledTd>
            </tr>
          </tbody>
        </S.Table>
      </S.Container>
      {isDetailPage && (
        <>
          <Spacer y='1.25rem' />
          <Input
            labelFontSize='1.125rem'
            label='수정 사유'
            name='reason'
            width='31.25rem'
            height='2.5rem'
            value={reason}
            disabled
            onChange={handleInputChange}
          />
        </>
      )}
      {!isDetailPage && (
        <>
          <Spacer y='1.25rem' />
          <WorkReportPresignedImgList
            title='현황사진 선택'
            selectedImgLength={2}
            isMultiple
            subTitle='이미지 업로드 후, 2개 이하의 이미지를 선택해주세요.'
            imgList={imageList}
            setImgList={setImageList}
            selectedImgList={selectedImage}
            setSelectedImgList={setSelectedImage}
          />
          <Spacer y='1.25rem' />
          <Input
            labelFontSize='1.125rem'
            label='수정 사유'
            name='reason'
            width='31.25rem'
            height='2.5rem'
            value={reason}
            onChange={handleInputChange}
          />
          <Spacer y='1.875rem' />
          <S.ButtonWrapper>
            <Button
              width='9.375rem'
              height='2.5rem'
              backgroundColor={Theme.colors.G_2}
              onClick={() =>
                navigate(`${ROUTER.WORK.REPORT_DETAIL}/${reportId}`)
              }
            >
              취소하기
            </Button>
            <Button
              width='9.375rem'
              height='2.5rem'
              onClick={handleConfirmEdit}
            >
              수정하기
            </Button>
          </S.ButtonWrapper>
        </>
      )}
      {isDetailPage && (
        <S.DotsWrapper>
          <LastEditedHistory
            workerName={log.workerName}
            updatedAt={log.updatedAt}
          />
          <Button
            height='2.1875rem'
            onClick={() => navigate(`${ROUTER.WORK.REPORT_EDIT}/${reportId}`)}
          >
            <AiOutlineEdit size='1.25rem' />
            수정하기
          </Button>
        </S.DotsWrapper>
      )}
    </>
  );
};

export default WorkReportResultTable;
