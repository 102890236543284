import styled from '@emotion/styled';

import { Theme } from '@/styles/Theme';
import { Link } from 'react-router-dom';

export const InspectionHistoryLink = styled(Link)`
  position: absolute;

  z-index: 10;

  top: 2.8125rem;
  right: 10rem;

  padding: 0.3125rem 0.625rem;

  color: ${Theme.colors.WHITE};
  font-weight: 500;
  background-color: ${Theme.colors.G_1};
  border-radius: 0.375rem;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3125rem;
`;
