import { Theme } from '@/styles/Theme';

import Text from '@/components/common/Text';
import { Link } from 'react-router-dom';
import { ROUTER } from '@/constants/constants';

interface Props {
  period: number;
}

const DaysUntilNextPayment = ({ period }: Props) => {
  return (
    <>
      {period > 0 && (
        <Link to={ROUTER.PAYMENT}>
          <Text
            color={Theme.colors.G_1}
            textAlign='center'
            fontSize='1rem'
            fontWeight={700}
          >
            다음 결제일{' '}
            <Text
              color={Theme.colors.B_3}
              textAlign='center'
              fontSize='1rem'
              fontWeight={700}
            >
              D-{period}
            </Text>
          </Text>
        </Link>
      )}
    </>
  );
};

export default DaysUntilNextPayment;
