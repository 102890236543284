import * as styled from './style';

import type { HeaderGroup } from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';

import type { WorkerPaymentInfo } from '@/types/company.types';
import SortIcon from '@/components/common/SortIcon';

interface Props {
  getHeaderGroups: () => HeaderGroup<WorkerPaymentInfo>[];
}

const WorkerPaymentInfoTableHeader = ({ getHeaderGroups }: Props) => {
  return (
    <styled.Thead>
      {getHeaderGroups().map((headerGroup) => (
        <styled.TheadTr key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <styled.Th
              key={header.id}
              colSpan={header.colSpan}
              width={`${header.getSize() / 16}rem`}
            >
              <styled.ThItemWrapper
                onClick={header.column.getToggleSortingHandler()}
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
                {header.column.getCanSort() &&
                  (header.column.getIsSorted() ? (
                    <SortIcon
                      isSorted
                      desc={header.column.getIsSorted() === 'desc'}
                    />
                  ) : (
                    <SortIcon isSorted={false} />
                  ))}
              </styled.ThItemWrapper>

              {header.column.getCanResize() && (
                <styled.ResizerLine
                  onDoubleClick={() => header.column.resetSize()}
                  onMouseDown={header.getResizeHandler()}
                  onTouchStart={header.getResizeHandler()}
                  isResizing={header.column.getIsResizing()}
                />
              )}
            </styled.Th>
          ))}
        </styled.TheadTr>
      ))}
    </styled.Thead>
  );
};

export default WorkerPaymentInfoTableHeader;
