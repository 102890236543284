import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

interface GnbStoreState {
  isFoldGnb: boolean;
  setFoldGnb: () => void;
}

export const useGnbStore = create(
  persist<GnbStoreState>(
    (set) => ({
      isFoldGnb: false,
      setFoldGnb: () => set((prev) => ({ isFoldGnb: !prev.isFoldGnb })),
    }),
    {
      name: 'isGnbFold',
      storage: createJSONStorage(() => localStorage),
    }
  )
);
