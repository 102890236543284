import { Navigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { TbAlertSquareFilled } from 'react-icons/tb';
import styled from '@emotion/styled';

import { getMaintenanceCheck } from '@/apis/auth';

import { ROUTER } from '@/constants/constants';

import { useAccessTokenStore } from '@/store/auth';

import LoginModal from '@/components/auth/LoginModal';
import Text from '@/components/common/Text';
import Spacer from '@/components/common/Spacer';
import Box from '@/components/common/Box';

import { Theme } from '@/styles/Theme';

const LoginPage = () => {
  const { accessToken } = useAccessTokenStore();

  const { data: maintenanceData } = useQuery(['maintenance'], {
    queryFn: getMaintenanceCheck,
  });

  if (!maintenanceData) {
    return null;
  }

  const { statusCode, message, time } = maintenanceData;

  if (statusCode === 503) {
    return (
      <Box padding='3.125rem'>
        <MaintenanceContainer>
          <TbAlertSquareFilled
            size='4.5rem'
            color={Theme.colors.B_3}
            stroke={Theme.colors.WHITE}
          />
          <Spacer y='0.625rem' />
          <Text fontSize='2rem' fontWeight={500}>
            서비스{' '}
            <Text color={Theme.colors.B_3} fontSize='2rem' fontWeight={700}>
              점검중
            </Text>{' '}
            입니다.
          </Text>
          <Spacer y='1.25rem' />
          <Text fontWeight={500}>
            점검 시간 : <Text fontWeight={700}>{time}</Text> <br /> 점검 사유 :{' '}
            {message}
          </Text>
          <Spacer y='1.25rem' />
          <Text color={Theme.colors.G_2} fontWeight={500}>
            점검시간 동안 서비스 이용이 제한되는 점 양해 부탁드립니다.
          </Text>
        </MaintenanceContainer>
      </Box>
    );
  }

  if (accessToken) {
    return <Navigate replace to={ROUTER.DASHBOARD} />;
  }

  return (
    <>
      <LoginModal />
    </>
  );
};

export default LoginPage;

const MaintenanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
