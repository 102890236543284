import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FiChevronLeft } from 'react-icons/fi';
import { FiChevronRight } from 'react-icons/fi';

import usePagination, { UsePaginationProps } from '@/hooks/usePagination';

import { Theme } from '@/styles/Theme';

const Pagination = ({ page, totalCount, size }: UsePaginationProps) => {
  const {
    pageNumberArray,
    handleNumberClick,
    handleRightArrowClick,
    handleLeftArrowClick,
    isFirstList,
    isLastList,
  } = usePagination({
    page,
    totalCount,
    size,
  });

  return (
    <Container>
      <IconWrapper disabled={isFirstList} onClick={handleLeftArrowClick}>
        <FiChevronLeft size='1.5rem' />
      </IconWrapper>
      <NumberContainer>
        {pageNumberArray.map((number) => (
          <Number
            key={number}
            isSelected={page === number}
            onClick={handleNumberClick}
          >
            {number}
          </Number>
        ))}
      </NumberContainer>
      <IconWrapper disabled={isLastList} onClick={handleRightArrowClick}>
        <FiChevronRight size='1.5rem' />
      </IconWrapper>
    </Container>
  );
};

export default Pagination;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
`;

const IconWrapper = styled.button`
  cursor: pointer;

  :disabled {
    cursor: initial;
    color: ${Theme.colors.G_3};
  }
`;

const NumberContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
`;

const Number = styled.div<{ isSelected: boolean }>`
  cursor: pointer;

  font-weight: 500;
  font-size: 1.125rem;

  ${({ isSelected }) =>
    isSelected &&
    css`
      font-weight: 700;
      font-size: 1.5rem;
    `}
`;
