import styled from '@emotion/styled';
import { Theme } from '@/styles/Theme';
export const Container = styled.div`
  width: 100%;
  padding: 6.25rem 3.125rem;
  align-items: center;
`;
export const Container2 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3.75rem;
  margin: 0 auto;
  max-width: 75rem;
`;
export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2.5rem;
`;
export const ItemRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2.5rem;
`;
export const ItemWrapper = styled.div`
  width: 34.375rem;
  display: flex;
  align-items: center;
  gap: 1.875rem;
  @media (max-width: 650px) {
    width: 100%;
    align-items: flex-start;
    justify-content: center;
  }
`;
export const ItemIcon = styled.img`
  height: 12.25rem;
  @media (max-width: 600px) {
    height: 5rem;
  }
`;
export const ItemBox = styled.div`
  width: 100%;
  max-width: 20.625rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.9375rem;
`;
export const ItemTitle = styled.div`
  font-size: 1.4375rem;
  font-weight: 700;
`;
export const ItemContent = styled.div`
  font-size: 1.25rem;
  line-height: 1.5rem;
`;
export const H2 = styled.div`
  font-size: 1.875rem;
  color: ${Theme.colors.DG_1};
  font-weight: 900;
  text-align: center;
`;
