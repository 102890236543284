import client from '@/apis/client';
import type { Response } from '@/types/client.types';
import type {
  BuildingStatusInfo,
  FacilityMaintainer,
  FacilityMaintainerList,
  PostFacilityMaintainerRequestProps,
  FacilityMaintenanceOrganizationItem,
  FacilityMaintenanceOrganization,
  PostFacilityMaintenanceOrganizationProps,
  InspectionTargetDeviceList,
  PreservationMethod,
  PostPreservationMethodRequestProps,
} from '@/types/report/building.types';

// Ⅲ. 대상 건축물 현황
// 1. 점검대상 건축물 및 유지관리자 선임 현황
// 관리주체 API
export const getBuildingInfo = (inspectionAreaId: number) =>
  client
    .get<Response<BuildingStatusInfo>>('/report/status/building', {
      params: { inspectionAreaId },
    })
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error fetching building info:', error);
      throw error;
    });

// 기계설비 유지관리자 API
export const getFacilityMaintainer = (inspectionAreaId: number) =>
  client
    .get<Response<FacilityMaintainerList>>(
      '/report/status/facility-maintainer',
      { params: { inspectionAreaId } }
    )
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error fetching facility maintainer:', error);
      throw error;
    });

export const postFacilityMaintainer = (
  body: PostFacilityMaintainerRequestProps
) =>
  client
    .post('/report/status/facility-maintainer', body)
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error posting facility maintainer:', error);
      throw error;
    });

export const deleteFacilityMaintainer = (workerId: number) =>
  client
    .delete('/report/status/facility-maintainer', { data: { id: workerId } })
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error deleting facility maintainer:', error);
      throw error;
    });

export const putFacilityMaintainer = (body: FacilityMaintainer) =>
  client
    .put('/report/status/facility-maintainer', body)
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error updating facility maintainer:', error);
      throw error;
    });

// 기계설비 유지관리 조직 API
export const getFacilityMaintenanceOrganization = (inspectionAreaId: number) =>
  client
    .get<Response<FacilityMaintenanceOrganization>>(
      '/report/status/maintenance-organization',
      { params: { inspectionAreaId } }
    )
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error fetching facility maintenance organization:', error);
      throw error;
    });

export const postFacilityMaintenanceOrganization = (
  body: PostFacilityMaintenanceOrganizationProps
) =>
  client
    .post('/report/status/maintenance-organization', body)
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error posting facility maintenance organization:', error);
      throw error;
    });

export const putFacilityMaintenanceOrganization = (
  body: FacilityMaintenanceOrganizationItem
) =>
  client
    .put('/report/status/maintenance-organization', body)
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error updating facility maintenance organization:', error);
      throw error;
    });

export const deleteFacilityMaintenanceOrganization = (orgId: number) =>
  client
    .delete('/report/status/maintenance-organization', { data: { id: orgId } })
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error deleting facility maintenance organization:', error);
      throw error;
    });

// 2. 점검대상 기계설비의 종류 및 항목 API
export const getInspectionTarget = (inspectionAreaId: number) =>
  client
    .get<Response<InspectionTargetDeviceList>>('/report/status/device', {
      params: { inspectionAreaId },
    })
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error fetching inspection target:', error);
      throw error;
    });

// 3. 유지관리 점검표 작성 및 보존 방법 API
export const getPreservationMethod = (inspectionAreaId: number) =>
  client
    .get<Response<PreservationMethod>>('/report/status/preservation-method', {
      params: { inspectionAreaId },
    })
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error fetching preservation method:', error);
      throw error;
    });

export const postPreservationMethod = (
  body: PostPreservationMethodRequestProps
) =>
  client
    .post('/report/status/preservation-method', body)
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error posting preservation method:', error);
      throw error;
    });
