import styled from '@emotion/styled';
import { Theme } from '@/styles/Theme';
import { CardContainer } from '../style';

export const RegContainer = styled(CardContainer)`
  background-color: ${Theme.colors.B_3_light};
`;

export const LoadingCardContainer = styled(CardContainer)`
  background-color: ${Theme.colors.B_3_light};
  justify-content: center;
  align-items: center;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FinancialCompanyName = styled.span`
  color: ${Theme.colors.WHITE};
`;

export const DeletePaymentMethodIcon = styled.div`
  color: ${Theme.colors.WHITE};
  cursor: pointer;
`;

export const PaymentMethodNumber = styled.span`
  color: ${Theme.colors.WHITE};
  text-align: right;
`;
