import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3.75rem;
  padding: 6.25rem 3.125rem;
  align-items: center;
  background-color: #f6f6f6;
`;
export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;
export const ImageWrapper = styled.div`
  width: 100%;
  max-width: 62.5rem;
`;
