import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import Modal from '@/components/common/Modal';
import Input from '@/components/common/Input';
import Text from '@/components/common/Text';
import {
  postFacilityMaintainer,
  putFacilityMaintainer,
} from '@/apis/report/building';
import type { FacilityMaintainer } from '@/types/report/building.types';

import type { ErrorResponse } from '@/types/client.types';
import { QUERY_KEY } from '@/constants/queryKey';

import * as S from '../commonStyles';
interface Props {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  inspectionAreaId: number;
  worker: FacilityMaintainer | null;
}

const MaintenanceWorkerAddModal = ({
  inspectionAreaId,
  setIsModalOpen,
  worker,
}: Props) => {
  const [name, setName] = useState<string>(worker ? worker.name : '');
  const [grade, setGrade] = useState<string>(worker ? worker.grade : '');
  const [category, setCategory] = useState<string>(
    worker ? worker.category : ''
  );
  const [seniorityDate, setSeniorityDate] = useState<string>(
    worker ? worker.seniorityDate : ''
  );
  const [trainingDate, setTrainingDate] = useState<string>(
    worker ? worker.trainingCompletionDate : ''
  );
  const [refresherDate, setRefresherDate] = useState<string>(
    worker ? worker.refresherTrainingDate : ''
  );

  const queryClient = useQueryClient();

  const { mutate: workerMuate } = useMutation({
    mutationFn: postFacilityMaintainer,
    onSuccess: (data) => {
      queryClient.invalidateQueries([
        QUERY_KEY.REPORT.BUILDING.WORKER,
        inspectionAreaId,
      ]);
      setIsModalOpen(false);
      toast.success('관리자 정보가 등록되었습니다.');
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      toast.error(error.response?.data.message);
    },
  });

  const { mutate: updateWorker } = useMutation({
    mutationFn: putFacilityMaintainer,
    onSuccess: (data) => {
      queryClient.invalidateQueries([
        QUERY_KEY.REPORT.BUILDING.WORKER,
        inspectionAreaId,
      ]);
      setIsModalOpen(false);
      toast.success('관리자 정보가 변경되었습니다.');
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      toast.error(error.response?.data.message);
    },
  });

  const handleConfirmData = () => {
    if (name === '') {
      toast.error('등록 정보를 입력해주세요.');
      return;
    }
    if (worker === null) {
      /*관리자 등록*/
      workerMuate({
        inspectionAreaId: inspectionAreaId,
        category: category,
        name: name,
        grade: grade,
        seniorityDate: seniorityDate,
        trainingCompletionDate: trainingDate,
        refresherTrainingDate: refresherDate,
      });
    } else {
      /* 관리자 정보 수정 */
      updateWorker({
        id: worker.id,
        category: category,
        name: name,
        grade: grade,
        seniorityDate: seniorityDate,
        trainingCompletionDate: trainingDate,
        refresherTrainingDate: refresherDate,
      });
    }
  };

  return (
    <Modal
      setIsModalOpen={setIsModalOpen}
      onClickConfirm={handleConfirmData}
      width='31.25rem'
      confirmText={worker !== undefined ? '수정' : '확인'}
    >
      <S.Container>
        <S.InputWrapper>
          <Text> 구분 </Text>
          <Input
            name='구분'
            width='100%'
            height='2.5rem'
            onChange={(e) => {
              e.preventDefault();
              setCategory(e.target.value);
            }}
            value={category}
          ></Input>
        </S.InputWrapper>
        <S.InputWrapper>
          <Text> 성명 </Text>
          <Input
            name='성명'
            width='100%'
            height='2.5rem'
            onChange={(e) => {
              e.preventDefault();
              setName(e.target.value);
            }}
            value={name}
          ></Input>
        </S.InputWrapper>

        <S.InputWrapper>
          <Text> 등급 </Text>
          <Input
            name='등급'
            width='100%'
            height='2.5rem'
            onChange={(e) => {
              e.preventDefault();
              setGrade(e.target.value);
            }}
            value={grade}
          ></Input>
        </S.InputWrapper>

        <S.InputWrapper>
          <Text> 선임일 </Text>
          <Input
            name='선임일'
            width='100%'
            height='2.5rem'
            onChange={(e) => {
              e.preventDefault();
              setSeniorityDate(e.target.value);
            }}
            value={seniorityDate}
          ></Input>
        </S.InputWrapper>

        <S.InputWrapper>
          <Text> 유지관리자 교육 수료일 </Text>{' '}
          <Input
            name=' 교육수료'
            width='100%'
            height='2.5rem'
            onChange={(e) => {
              e.preventDefault();
              setTrainingDate(e.target.value);
            }}
            value={trainingDate}
          ></Input>{' '}
        </S.InputWrapper>

        <S.InputWrapper>
          <Text> 보수교육일 </Text>{' '}
          <Input
            name='보수교육'
            width='100%'
            height='2.5rem'
            onChange={(e) => {
              e.preventDefault();
              setRefresherDate(e.target.value);
            }}
            value={refresherDate}
          ></Input>
        </S.InputWrapper>
      </S.Container>
    </Modal>
  );
};

export default MaintenanceWorkerAddModal;
