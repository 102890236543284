import React, { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import type { ErrorResponse } from '@/types/client.types';
import Text from '@/components/common/Text';

import Button from '@/components/common/Button';
import * as S from './style';
import Input from '@/components/common/Input';
import { Theme } from '@/styles/Theme';

import GreyBox from '@/components/common/GreyBox';

import { QUERY_KEY } from '@/constants/queryKey';

import {
  getPersistingPeriodAge,
  postPersistingPeriodAge,
} from '@/apis/report/improvePlan';
import { StyledCol } from '@/styles/common';
import type { PersistingPeriodAgeItem } from '@/types/report/improvePlan.types';

interface PersistingPeriodAgeCategoryItem {
  category: string;
  deviceList: PersistingPeriodAgeItem[];
}

const PersistingPeriodAgeTable = () => {
  const _ = require('lodash');

  const [isEdit, setEdit] = useState(false);
  const [editableAgeData, setEditableAgeData] =
    useState<Map<number, PersistingPeriodAgeItem>>();

  const [memo, setMemo] = useState<string>('');

  const queryClient = useQueryClient();
  const params = useParams();
  const inspectionAreaId = Number(params.id);

  const { data: ageData } = useQuery({
    queryKey: [QUERY_KEY.REPORT.AGE, inspectionAreaId],
    queryFn: () => getPersistingPeriodAge(inspectionAreaId),
  });

  const { mutate: updateAgeData } = useMutation({
    mutationFn: postPersistingPeriodAge,
    onSuccess: (data) => {
      toast.success('정보가 수정되었습니다.');
      queryClient.invalidateQueries([QUERY_KEY.REPORT.AGE, inspectionAreaId]);
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      toast.error(error.response?.data.message);
    },
  });

  useEffect(() => {
    let newState = new Map();
    ageData?.data.oldAgeList.forEach((element: PersistingPeriodAgeItem) => {
      newState.set(element.templateDeviceId, element);
    });
    setEditableAgeData(newState);
    setMemo(ageData?.data?.inspectionResult ?? '');
  }, [ageData]);

  const handleDeviceNumber = (id: number, value: string) => {
    setEditableAgeData((prev) => {
      let device = editableAgeData?.get(id);
      const newState = new Map(prev);
      if (device !== undefined) {
        newState?.set(id, { ...device, deviceNumber: value });
      }
      return newState;
    });
  };

  const handleDurability = (id: number, value: string) => {
    setEditableAgeData((prev) => {
      let device = editableAgeData?.get(id);
      const newState = new Map(prev);
      if (device !== undefined) {
        device.durability = value;
        newState?.set(id, device);
      }
      return newState;
    });
  };

  const handleUseYear = (id: number, value: string) => {
    setEditableAgeData((prev) => {
      let device = editableAgeData?.get(id);
      const newState = new Map(prev);
      if (device !== undefined) {
        device.useYear = value;
        newState?.set(id, device);
      }
      return newState;
    });
  };

  const handleMemo = (id: number, value: string) => {
    setEditableAgeData((prev) => {
      let device = editableAgeData?.get(id);
      const newState = new Map(prev);
      if (device !== undefined) {
        device.explanatory = value;
        newState?.set(id, device);
      }
      return newState;
    });
  };

  const handleUpdate = () => {
    if (editableAgeData === undefined) return;

    const request = Array.from(editableAgeData.values()).map(
      (item: PersistingPeriodAgeItem, index: number) => {
        return {
          templateDeviceId: item.templateDeviceId,
          durability: item.durability,
          deviceNumber: item.deviceNumber,
          useYear: item.useYear,
          explanatory: item.explanatory,
        };
      }
    );

    updateAgeData({
      inspectionAreaId,
      oldAgeList: request,
      inspectionResult: memo,
    });
    setEdit(!isEdit);
  };

  return (
    <GreyBox width='58.125rem'>
      <S.Container>
        <S.TopContainer>
          <Text fontSize='1.25rem' fontWeight={600}>
            기계설비 내구연한에 따른 노후도
          </Text>
          <Button
            backgroundColor={isEdit ? Theme.colors.BLACK : Theme.colors.B_3}
            onClick={() => {
              if (isEdit) handleUpdate();
              else setEdit(true);
            }}
          >
            {isEdit ? '저장하기' : '수정하기'}
          </Button>
        </S.TopContainer>
        <S.Table>
          <colgroup>
            <StyledCol width='6.25rem' />
            <StyledCol width='6.25rem' />
            <StyledCol width='6.25rem' />
            <StyledCol width='4.375rem' />
            <StyledCol width='4.375rem' />
            <StyledCol width='6.25rem' />
          </colgroup>
          <tbody>
            <tr>
              <S.TableHeader colSpan={2}>점검대상 기계설비</S.TableHeader>
              <S.TableHeader>장비번호, 계통명</S.TableHeader>
              <S.TableHeader>내구연한</S.TableHeader>
              <S.TableHeader>사용연수</S.TableHeader>
              <S.TableHeader>비고</S.TableHeader>
            </tr>
          </tbody>

          {ageData &&
            ageData.data &&
            editableAgeData &&
            _.chain(Array.from(editableAgeData.values()))
              .groupBy('category')
              .map(function (v: PersistingPeriodAgeItem, i: number) {
                return {
                  category: _.get(_.find(v, 'category'), 'category'),
                  deviceList: _.chain(v).value(),
                };
              })
              .value()
              .map((item: PersistingPeriodAgeCategoryItem, index: number) => {
                return (
                  <S.InfoContainer key={index}>
                    <>
                      {item.deviceList.map((device, index) => (
                        <>
                          <tr key={device.templateDeviceId}>
                            {index === 0 ? (
                              <td rowSpan={item.deviceList.length}>
                                {item.category}
                              </td>
                            ) : (
                              <></>
                            )}
                            <td>{device.templateDeviceName}</td>
                            <td>
                              {isEdit ? (
                                <>
                                  <Input
                                    width='100%'
                                    height='2.5rem'
                                    name='name'
                                    borderRadius='0rem'
                                    backgroundColor={Theme.colors.YELLOW}
                                    value={
                                      editableAgeData.get(
                                        device.templateDeviceId
                                      )?.deviceNumber
                                    }
                                    onChange={(e) => {
                                      handleDeviceNumber(
                                        device.templateDeviceId,
                                        e.target.value
                                      );
                                    }}
                                  ></Input>
                                </>
                              ) : (
                                device.deviceNumber
                              )}
                            </td>
                            <td>
                              {isEdit ? (
                                <>
                                  <Input
                                    width='100%'
                                    height='2.5rem'
                                    name='내구연한'
                                    borderRadius='0rem'
                                    backgroundColor={Theme.colors.YELLOW}
                                    value={
                                      editableAgeData.get(
                                        device.templateDeviceId
                                      )?.durability
                                    }
                                    onChange={(e) => {
                                      handleDurability(
                                        device.templateDeviceId,
                                        e.target.value
                                      );
                                    }}
                                  ></Input>
                                </>
                              ) : (
                                device.durability
                              )}
                            </td>
                            <td>
                              {' '}
                              {isEdit ? (
                                <>
                                  <Input
                                    width='100%'
                                    height='2.5rem'
                                    name='사용연수'
                                    borderRadius='0rem'
                                    backgroundColor={Theme.colors.YELLOW}
                                    value={
                                      editableAgeData.get(
                                        device.templateDeviceId
                                      )?.useYear
                                    }
                                    onChange={(e) => {
                                      handleUseYear(
                                        device.templateDeviceId,
                                        e.target.value
                                      );
                                    }}
                                  ></Input>
                                </>
                              ) : (
                                device.useYear
                              )}
                            </td>
                            <td>
                              {' '}
                              {isEdit ? (
                                <>
                                  <Input
                                    width='100%'
                                    height='2.5rem'
                                    name='비고'
                                    borderRadius='0rem'
                                    backgroundColor={Theme.colors.YELLOW}
                                    value={
                                      editableAgeData.get(
                                        device.templateDeviceId
                                      )?.explanatory
                                    }
                                    onChange={(e) => {
                                      handleMemo(
                                        device.templateDeviceId,
                                        e.target.value
                                      );
                                    }}
                                  ></Input>
                                </>
                              ) : (
                                device.explanatory
                              )}
                            </td>
                          </tr>
                        </>
                      ))}
                    </>
                  </S.InfoContainer>
                );
              })}
          <tbody>
            <tr>
              <S.TableHeader colSpan={6}> 점검결과</S.TableHeader>
            </tr>
            <tr>
              <S.TableFooter colSpan={6}>
                {isEdit ? (
                  <S.StyledTextarea
                    name='비고란'
                    value={memo}
                    onChange={(e) => {
                      setMemo(e.target.value);
                    }}
                  ></S.StyledTextarea>
                ) : (
                  memo
                )}
              </S.TableFooter>
            </tr>
          </tbody>
        </S.Table>
      </S.Container>
    </GreyBox>
  );
};

export default PersistingPeriodAgeTable;
