import { Theme } from '@/styles/Theme';
import Button from '@/components/common/Button';
import { useState } from 'react';
import SubscribeWorkersModal from '../../../ManageWorkerPaymentModal/SubscribeWorkersModal';
import { useSelectedWorkerListStore } from '@/store/selectedWorkerList';
import usePostTrialWorker from '@/hooks/usePostTrialWorker';

interface Props {
  isTrial: 1 | 0;
}

const SubscribeWorkersBtn = ({ isTrial }: Props) => {
  const [isShowSubscribeWorkersModal, setIsShowSubscribeWorkersModal] =
    useState(false);

  const { selectedAddWorkerList, selectedRestoreWorkerList } =
    useSelectedWorkerListStore();

  const { mutate: registerTrialWorker } = usePostTrialWorker();

  const subscribeWorkersHandler = () => {
    if (isTrial) {
      const data = {
        worker_list: {
          add: selectedAddWorkerList,
          restore: selectedRestoreWorkerList,
        },
      };
      registerTrialWorker(data);
    } else {
      setIsShowSubscribeWorkersModal(true);
    }
  };

  return (
    <>
      {isShowSubscribeWorkersModal && (
        <SubscribeWorkersModal
          setIsShowModal={setIsShowSubscribeWorkersModal}
          selectedAddWorkerList={selectedAddWorkerList}
          selectedRestoreWorkerList={selectedRestoreWorkerList}
        />
      )}
      <Button
        height='2.125rem'
        onClick={subscribeWorkersHandler}
        backgroundColor={Theme.colors.GREEN}
      >
        {isTrial === 0 ? '선택 작업자 추가/복구 완료' : '선택 작업자 추가 완료'}
      </Button>
    </>
  );
};

export default SubscribeWorkersBtn;
