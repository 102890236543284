import styled from '@emotion/styled';

export const Container = styled.div``;

export const TitleContainer = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 0.625rem;

  cursor: pointer;
`;

export const DetailContainer = styled.section`
  margin: 0.625rem 0;

  display: flex;
  flex-wrap: wrap;

  /* display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto;
  grid-auto-flow: column; */
  gap: 2.5rem;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
`;
