import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { QUERY_KEY } from '@/constants/queryKey';

import * as S from './style';
import { getInspectionTarget } from '@/apis/report/building';
import type { InspectionTargetDevice } from '@/types/report/building.types';

import GreyBox from '@/components/common/GreyBox';
import Text from '@/components/common/Text';

interface TargetDeviceCategoryItem {
  category: string;
  deviceList: InspectionTargetDevice[];
}

const TargetDeviceTable = () => {
  const params = useParams();
  const inspectionAreaId = Number(params.id);

  const _ = require('lodash');

  const { data: deviceData } = useQuery({
    queryKey: [QUERY_KEY.REPORT.BUILDING.TARGET_DEVICE],
    queryFn: () => getInspectionTarget(inspectionAreaId),
  });

  return (
    <GreyBox width='58.125rem'>
      <S.Container>
        {deviceData &&
          deviceData.data &&
          deviceData.data.inspectionDeviceList && (
            <>
              <Text fontSize='1.25rem' fontWeight={600}>
                기계설비 성능점검 대상 현황표
              </Text>
              <S.Table>
                <colgroup>
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <td>구분</td>
                    <td>점검대상</td>
                    <td colSpan={2}>설치여부</td>
                    <td>점검수량</td>
                    <td>성능점검대상연도</td>
                  </tr>
                </tbody>
                {_.chain(Array.from(deviceData.data.inspectionDeviceList))
                  .groupBy('category')
                  .map(function (v: InspectionTargetDevice, i: number) {
                    return {
                      category: _.get(_.find(v, 'category'), 'category'),
                      deviceList: _.chain(v).value(),
                    };
                  })
                  .value()
                  .map((deviceGroup: TargetDeviceCategoryItem, idx: number) => (
                    <S.InfoContainer key={idx}>
                      {deviceGroup.deviceList.map((device, index) => (
                        <>
                          <tr key={index}>
                            {index === 0 ? (
                              <td rowSpan={deviceGroup.deviceList.length}>
                                {deviceGroup.category}
                              </td>
                            ) : (
                              <></>
                            )}
                            <td>{device.name}</td>
                            <td>
                              <S.ToggleButtonWrapper
                                isSelected={device.isInstalled}
                                onClick={() => {}}
                              >
                                설치
                              </S.ToggleButtonWrapper>
                            </td>
                            <td>
                              <S.ToggleButtonWrapper
                                isSelected={!device.isInstalled}
                                onClick={() => {}}
                              >
                                미설치
                              </S.ToggleButtonWrapper>
                            </td>

                            <td>{device.selected}</td>
                            <td>{new Date().getFullYear()}</td>
                          </tr>
                        </>
                      ))}
                    </S.InfoContainer>
                  ))}
              </S.Table>
            </>
          )}
      </S.Container>
    </GreyBox>
  );
};

export default TargetDeviceTable;
