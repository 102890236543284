import * as ExcelJS from 'exceljs';

import { getReportOutline } from '@/apis/report/outline';
import { copyTemplate } from '@/utils/excelExport/excelExportUtils';

// 1. 성능점검 개요 export
export const exportReportOutline = async (
  workbook: ExcelJS.Workbook,
  inspectionAreaId: number
) => {
  const response = await fetch('/report/1. 성능점검 개요.xlsx', {
    cache: 'no-store',
  });
  const arrayBuffer = await response.arrayBuffer();
  const newWorkBook = new ExcelJS.Workbook();
  await newWorkBook.xlsx.load(arrayBuffer);

  // 복사 하려는 워크시트
  const copySheet = newWorkBook.getWorksheet(1);
  // 복사 받을 워크시트
  let mainSheet = workbook.addWorksheet('I. 성능점검 개요');

  copyTemplate(mainSheet, copySheet, [43, 66, 97]);

  const data = await getReportOutline(inspectionAreaId);

  if (mainSheet && data) {
    const { inspectionAreaName, inspectionAreaLocation } = data.data;
    const parsedLocation = inspectionAreaLocation.replace('/', ' ');
    mainSheet.getCell('C57').value = inspectionAreaName;
    mainSheet.getCell('C58').value = parsedLocation;
  }
};
