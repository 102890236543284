import client from '@/apis/client';
import type { Response } from '@/types/client.types';
import type {
  GetMaintenanceResponseProps,
  PostLoginRequestProps,
  PostLoginResponseProps,
} from '@/types/auth.types';

export const postLogin = (body: PostLoginRequestProps) =>
  client
    .post<Response<PostLoginResponseProps>>('/user/auth/login', body)
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error login:', error);
      throw error;
    });

export const postLogout = () =>
  client
    .post('/user/auth/logout')
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error logout:', error);
      throw error;
    });

export const getTokenRefresh = (refreshToken: string) =>
  client
    .get<Response<PostLoginResponseProps>>('/auth/refresh-token', {
      headers: {
        Refresh: refreshToken,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error refreshing token:', error);
      throw error;
    });

export const getMaintenanceCheck = () =>
  client
    .get<GetMaintenanceResponseProps>('/maintenance')
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error maintenance check:', error);
      throw error;
    });
