import { unstable_usePrompt as usePrompt } from 'react-router-dom';

interface Props {
  when?: boolean;
  message: string;
}

function Prompt({ when = true, message }: Props) {
  usePrompt({
    when,
    message,
  });

  return <div key={'' + when} style={{ display: 'none' }} />;
}

export default Prompt;
