import { CiCirclePlus } from 'react-icons/ci';
import { CiCircleMinus } from 'react-icons/ci';

import { PURCHASABLE_WORKER } from '@/constants/constants';

import Text from '@/components/common/Text';

import * as S from './style';
import { Theme } from '@/styles/Theme';

type PurchaseCountType = {
  count: number;
  setCount: React.Dispatch<React.SetStateAction<number>>;
  isBorder?: boolean;
};
const PurchaseCount = ({ count, setCount, isBorder }: PurchaseCountType) => {
  const handleDecrement = () => {
    setCount((prev) => (prev > 1 ? prev - 1 : 1));
  };

  const handleIncrement = () => {
    setCount((prev) =>
      prev < PURCHASABLE_WORKER ? prev + 1 : PURCHASABLE_WORKER
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = Number(e.target.value);

    if (isNaN(value)) {
      value = 1;
    } else if (value < 1) {
      value = 1;
    } else if (value > 50) {
      value = 50;
    }

    setCount(value);
  };

  return (
    <S.Container isBorder={isBorder}>
      <S.IconWrapper>
        <CiCircleMinus
          size='2.8125rem'
          color={Theme.colors.G_1}
          onClick={handleDecrement}
        />
      </S.IconWrapper>
      <S.InputContainer>
        <S.Input value={count} onChange={handleChange} />
        <Text fontSize='1.0625rem' color={Theme.colors.G_1}>
          작업자 수
        </Text>
      </S.InputContainer>
      <S.IconWrapper>
        <CiCirclePlus
          size='2.8125rem'
          color={Theme.colors.G_1}
          onClick={handleIncrement}
        />
      </S.IconWrapper>
    </S.Container>
  );
};

export default PurchaseCount;
