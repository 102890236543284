import client from '@/apis/client';
import type { Response } from '@/types/client.types';
import type {
  GetCompanyInfoResponseProps,
  GetSignUpTermsResponseProps,
  PaymentInfo,
  PostAuthEmailRequestProps,
  PostFindPwdSendEmailRequestProps,
  PostSendEmailResponseProps,
  PostSignUpRequestProps,
  PutCompanyInfoRequestProps,
} from '@/types/company.types';

export const postSendEmail = (email: string) =>
  client
    .post<Response<PostSendEmailResponseProps>>(
      '/user/auth/mem-join/send-email',
      {
        email,
      }
    )
    .then((res) => res.data);

export const postAuthEmail = ({
  email,
  userCode,
  joinToken,
}: PostAuthEmailRequestProps) =>
  client
    .post(
      '/user/auth/mem-join/auth-email',
      { email, userCode },
      { headers: { Authorization: joinToken } }
    )
    .then((res) => res.data);

export const postSignUp = (body: PostSignUpRequestProps) =>
  client.post('/user/auth/mem-join', body).then((res) => res.data);

export const postFindPwdSendEmail = (email: string) =>
  client
    .post('/user/auth/find-pwd/send-email', { email })
    .then((res) => res.data);

export const postFindPwdChangePwd = ({
  password,
  passwordConfirm,
  token,
}: PostFindPwdSendEmailRequestProps) =>
  client
    .post(
      '/user/auth/find-pwd/change-pwd',
      { password, passwordConfirm },
      { headers: { Authorization: token } }
    )
    .then((res) => res.data);

export const getSignUpTerms = () =>
  client
    .get<Response<GetSignUpTermsResponseProps>>('/user/terms')
    .then((res) => res.data);

export const getCompanyInfo = () =>
  client
    .get<Response<GetCompanyInfoResponseProps>>('/user/info')
    .then((res) => res.data);

export const putCompanyInfo = (body: PutCompanyInfoRequestProps) =>
  client.put('/user/info/modify', body).then((res) => res.data);

export const getPaymentInfo = () =>
  client.get<Response<PaymentInfo>>('/user/v2/payment').then((res) => res.data);

export const putWorkerEditAuth = (is_edit_all: boolean) =>
  client
    .put('/user/setting/editAll/update', { is_edit_all })
    .then((res) => res.data);

export const postStartTrial = () =>
  client.post('/user/trial/start').then((res) => res.data);
