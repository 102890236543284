import type { ErrorResponse } from '@/types/client.types';
import { postCancelCard } from '@/apis/payment';
import { QUERY_KEY } from '@/constants/queryKey';
import usePaymentInfo from '@/hooks/usePaymentInfo';
import Modal from '@/components/common/Modal';
import Text from '@/components/common/Text';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

interface Props {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const DeletePaymentMethodModal = ({ setIsModalOpen }: Props) => {
  const queryClient = useQueryClient();

  const { mutate: deletePaymentMethodMutate } = useMutation({
    mutationFn: postCancelCard,
    onSuccess: () => {
      setIsModalOpen(false);
      queryClient.invalidateQueries([QUERY_KEY.PAYMENT_INFO]);
      toast.success('카드 해지가 완료되었습니다.');
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      toast.error(error.response?.data.message);
    },
  });

  const { data: paymentData } = usePaymentInfo();
  const billingKey = paymentData?.data.billingKey;

  const onClickCancelCard = () => {
    if (!billingKey) {
      return;
    }
    deletePaymentMethodMutate(billingKey);
  };

  return (
    <Modal
      isWarning
      setIsModalOpen={setIsModalOpen}
      onClickConfirm={onClickCancelCard}
    >
      <Text margin='2.5rem 0' fontSize='1.375rem' fontWeight={700}>
        정말 카드를 해지하시겠습니까?
      </Text>
    </Modal>
  );
};

export default DeletePaymentMethodModal;
