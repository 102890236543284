import { MdOutlineWorkHistory } from 'react-icons/md';
import { InspectionHistoryLink } from './style';
import { ROUTER } from '@/constants/constants';

interface Props {
  inspectionAreaId: number;
}

const InspectionHistoryBtn = ({ inspectionAreaId }: Props) => {
  return (
    <InspectionHistoryLink
      to={`/${ROUTER.WORK.INSPECTION_HISTORY}/${inspectionAreaId}`}
    >
      <MdOutlineWorkHistory size='1.25rem' />
      점검지 수정 내역
    </InspectionHistoryLink>
  );
};

export default InspectionHistoryBtn;
