import { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';

import Router from '@/Router';
import ChannelService from '@/ChannelTalk';

const App = () => {
  // 체널톡 삽입
  useEffect(() => {
    const CT = new ChannelService();
    CT.boot({ pluginKey: process.env.REACT_APP_CHANNEL_TALK_KEY ?? '' });

    return () => {
      CT.shutdown();
    };
  }, []);

  return <RouterProvider router={Router} />;
};

export default App;
