import { postRegisterCard } from '@/apis/payment';
import type { PostRegisterCardRequestProps } from '@/types/payment.types';
import type { ErrorResponse } from '@/types/client.types';

import * as styled from './style';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEY } from '@/constants/queryKey';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import useCompanyInfo from '@/hooks/useCompanyInfo';

const UnregPaymentMethod = () => {
  const { data: companyData } = useCompanyInfo();
  const { companyId, companyName, phone, email } = companyData?.data || {};

  const queryClient = useQueryClient();

  const { mutate: cardRegisterMutate } = useMutation({
    mutationFn: postRegisterCard,
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEY.PAYMENT_INFO]);
      queryClient.invalidateQueries([QUERY_KEY.CARD_INFO]);
      toast.success('카드 등록이 완료되었습니다.');
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      toast.error(error.response?.data.message);
    },
  });

  const getRegisterResult = (response: any) => {
    const { PCD_PAY_MSG, PCD_PAY_RST } = response;

    alert(PCD_PAY_MSG);

    if (PCD_PAY_RST === 'success') {
      const body: PostRegisterCardRequestProps = {
        callbackData: response,
      };

      cardRegisterMutate(body);
    }
  };

  const registerPaymentMethod = () => {
    if (!window.PaypleCpayAuthCheck) return;

    const data = {
      PCD_PAY_TYPE: 'card',
      PCD_PAY_WORK: 'AUTH',
      PCD_CARD_VER: '01',
      PCD_RST_URL: '/example',
      clientKey: process.env.REACT_APP_PAYPLE_CLIENTKEY,
      PCD_PAYER_NO: companyId,
      PCD_PAYER_NAME: companyName,
      PCD_PAYER_HP: phone,
      PCD_PAYER_EMAIL: email,
      callbackFunction: getRegisterResult,
    };

    window.PaypleCpayAuthCheck(data);
  };

  return (
    <styled.UnregContainer onClick={registerPaymentMethod}>
      <styled.Title>결제 수단 등록하기</styled.Title>
      <styled.RegisterPaymentMethodBtn>+</styled.RegisterPaymentMethodBtn>
    </styled.UnregContainer>
  );
};

export default UnregPaymentMethod;
