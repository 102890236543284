import GreyBox from '@/components/common/GreyBox';
import HistoryList from '@/components/inspectionHistory/HistoryList';
import WorkerDropdown from '@/components/inspectionHistory/WorkerDropdown';
import { useState } from 'react';

const InspectionHistoryPage = () => {
  const [selectedWorker, setSelectedWorker] = useState<number | null>(null);
  return (
    <GreyBox title='점검지 수정 내역'>
      <WorkerDropdown setSelectedWorker={setSelectedWorker} />
      <HistoryList selectedWorker={selectedWorker} />
    </GreyBox>
  );
};

export default InspectionHistoryPage;
