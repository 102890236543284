import styled from '@emotion/styled';
import { Theme } from '@/styles/Theme';

export const Table = styled.table`
  border-top: 0.125rem solid ${Theme.colors.BLACK};
  border-bottom: 0.125rem solid ${Theme.colors.BLACK};
  text-align: center;

  td {
    width: 25rem;
    border: 0.0625rem solid ${Theme.colors.BLACK};
    border-bottom: 0.0625rem solid ${Theme.colors.BLACK};
  }

  tr:first-of-type {
    height: 3.125rem;
    td:first-of-type {
      width: 6.25rem;
    }
  }
`;

export const Col = styled.col`
  width: 6.25rem;
`;

export const ImgWrapper = styled.img`
  padding: 0.3125rem;
  margin: 0 auto;
  background-color: ${Theme.colors.WHITE};
  /* object-fit: contain; */
  height: 15.625rem;
`;

export const EditButtonWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 2.5rem;
  right: 2.5rem;
  display: flex;
  justify-content: end;
  margin-bottom: 1.25rem;
`;

export const DeviceWrapper = styled.tbody`
  cursor: pointer;
  width: 100%;

  td {
    background-color: ${Theme.colors.WHITE};
  }

  td:first-of-type {
    width: 5rem;
  }

  tr:nth-of-type(2n) {
    td:nth-of-type(1) {
      background-color: ${Theme.colors.G_5};
      width: 6.25rem;
    }
    td:nth-of-type(3) {
      background-color: ${Theme.colors.G_5};
      width: 6.25rem;
    }
    width: 6.25rem;
  }

  tr:nth-of-type(2n-1) {
    td:nth-of-type(3) {
      background-color: ${Theme.colors.G_5};
      width: 6.25rem;
    }
    td:nth-of-type(1) {
      background-color: ${Theme.colors.G_5};
      width: 6.25rem;
    }

    width: 6.25rem;
  }

  &:hover {
    td {
      background-color: ${Theme.colors.B_4 + 10};
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  max-height: 62.5rem;
  overflow: scroll;
`;
