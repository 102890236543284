import WorkerActiveManageBtn from '@/components/newPayment/WorkerPaymentInfoBox/WorkerPaymentInfoTable/WorkerPaymentInfoTableBody/WorkerActiveManageBtn';
import WorkerManageCheckBox from '@/components/newPayment/WorkerPaymentInfoBox/WorkerPaymentInfoTable/WorkerPaymentInfoTableBody/WorkerManageCheckBox';
import { useWorkerPaymentListStore } from '@/store/paymentWorkerList';
import useSubscribeStore from '@/store/subscribeMode';
import type { WorkerPaymentInfo } from '@/types/company.types';
import { dateFormat } from '@/utils/convertDateFormat';
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useMemo } from 'react';

interface Props {
  workerList: WorkerPaymentInfo[];
  isApproved: 1 | 0;
  isTrial: 1 | 0;
}

const useWorkerPaymentInfoTable = ({
  workerList,
  isApproved,
  isTrial,
}: Props) => {
  const { isSubscribeMode } = useSubscribeStore();
  const columnHelper = createColumnHelper<WorkerPaymentInfo>();

  const workerPaymentColumns = [
    columnHelper.display({
      id: 'select',
      // cell: ({ row, table }) => (
      //   <input
      //     type='checkbox'
      //     checked={row.getIsSelected()}
      //     onChange={row.getToggleSelectedHandler()}
      //     disabled={
      //       !row.getCanSelect() ||
      //       (table.getSelectedRowModel().rows.length > 0 &&
      //         (isSubscribeMode
      //           ? row.getValue('isActive') === '생성' &&
      //             row.getValue('isActive') === '비활성'
      //           : row.getValue('isActive') !==
      //             table.getSelectedRowModel().rows[0].original.isActive))
      //     }
      //   />
      // ),
      cell: ({ row }) => (
        <WorkerManageCheckBox rowData={row.original} isTrial={isTrial} />
      ),
      size: 50,
      enableResizing: false,
      enableSorting: false,
    }),
    columnHelper.accessor('name', {
      header: '아이디(이름)',
      size: 130,
    }),
    columnHelper.accessor('isActive', {
      id: 'isActive',
      header: '사용 현황',
      size: 100,
    }),
    columnHelper.accessor(
      (row) =>
        `${dateFormat(row.nextPaymentDate, '.')} ${
          row.usePeriod !== null ? `(D-${row.usePeriod})` : ''
        }`,
      {
        id: 'nextPaymentDate',
        header: '다음 결제일(D-day)',
        size: 150,
      }
    ),
    columnHelper.display({
      header: '관리',
      id: 'actions',
      cell: ({ row }) => {
        const isActive = row.original.isActive;
        if (isApproved === 1) {
          if (isSubscribeMode) {
            if (isActive === '비활성') {
              return (
                <WorkerActiveManageBtn isActive={isActive} workerId={row.id} />
              );
            }
          } else {
            if (isActive === '활성' || isActive === '비활성 예정') {
              return (
                <WorkerActiveManageBtn isActive={isActive} workerId={row.id} />
              );
            }
          }
        }
      },
      size: 80,
      enableResizing: false,
      enableSorting: false,
    }),
  ];
  const { workerPaymentList, setWorkerPaymentList } =
    useWorkerPaymentListStore();

  useEffect(() => {
    setWorkerPaymentList(workerList);
  }, [setWorkerPaymentList, workerList]);

  const memoizedData = useMemo(
    () => workerPaymentList ?? [],
    [workerPaymentList]
  );

  const workerPaymentReactTable = useReactTable({
    data: memoizedData,
    columns: workerPaymentColumns,

    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    sortDescFirst: true,
    enableSorting: true,
    enableMultiSort: true,
    initialState: {
      sorting: [
        {
          id: 'isActive',
          desc: true,
        },
        {
          id: 'nextPaymentDate',
          desc: false,
        },
        {
          id: 'name',
          desc: false,
        },
      ],
    },

    getRowId: (row) => String(row.id),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return { workerPaymentReactTable };
};

export default useWorkerPaymentInfoTable;
