import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { AxiosError } from 'axios';
import {
  deleteFacilityMaintainer,
  getFacilityMaintainer,
} from '@/apis/report/building';
import type { FacilityMaintainer } from '@/types/report/building.types';

import type { ErrorResponse } from '@/types/client.types';
import { QUERY_KEY } from '@/constants/queryKey';

import { Theme } from '@/styles/Theme';
import Text from '@/components/common/Text';
import Button from '@/components/common/Button';
import MaintenanceWorkerAddModal from '@/components/report/building/MaintenanceWorkerAddModal';

import * as S from './style';

const MaintenanceWorkerTable = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [workers, setWorkers] = useState<FacilityMaintainer[]>([]);
  const [editWorker, setEditWorker] = useState<FacilityMaintainer | null>(null);

  const params = useParams();
  const inspectionAreaId = Number(params.id);

  const queryClient = useQueryClient();

  const { data: workerData } = useQuery({
    queryKey: [QUERY_KEY.REPORT.BUILDING.WORKER, inspectionAreaId],
    queryFn: () => getFacilityMaintainer(inspectionAreaId),
  });

  const { mutate: deleteWorker } = useMutation({
    mutationFn: deleteFacilityMaintainer,
    onSuccess: (data) => {
      toast.success('관리자 정보가 삭제되었습니다.');
      queryClient.invalidateQueries([
        QUERY_KEY.REPORT.BUILDING.WORKER,
        inspectionAreaId,
      ]);
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      toast.error(error.response?.data.message);
    },
  });

  useEffect(() => {
    if (workerData?.data) {
      setWorkers([...workerData.data.facilityMaintainerList]);
      setEditWorker(null);
    }
  }, [workerData]);

  useEffect(() => {
    if (isModalOpen === false) {
      setEditWorker(null);
    }
  }, [isModalOpen]);

  const handleDelete = (id: number) => {
    deleteWorker(id);
  };

  const handleUpdate = (id: number) => {
    let worker = workers.find((item) => item.id === id);
    if (worker !== undefined) {
      setEditWorker(worker);
      setIsModalOpen(true);
    }
  };

  const handleAddWorkerButton = () => {
    // 2명 까지만
    if (workers.length < 2) {
      setIsModalOpen(true);
      return;
    }
    toast.error('2명까지 등록 가능합니다.', {
      toastId: '2명까지 등록 가능합니다.',
    });
  };

  return (
    <>
      {workerData && workerData.data && workers && (
        <S.Container>
          {isModalOpen && (
            <MaintenanceWorkerAddModal
              setIsModalOpen={setIsModalOpen}
              inspectionAreaId={inspectionAreaId}
              worker={editWorker}
            />
          )}
          <S.TextWrapper>
            <Text fontSize='1.25rem' fontWeight={600}>
              기계설비 유지관리자 현황 및 법정교육 이수 현황 (2명 까지 등록
              가능)
            </Text>
            <Button height='2.1875rem' onClick={handleAddWorkerButton}>
              추가하기
            </Button>
          </S.TextWrapper>
          <S.Table>
            <colgroup>
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <td>구분</td>
                <td>성명</td>
                <td>등급</td>
                <td>선임일</td>
                <td>유지관리자 교육 수료일</td>
                <td>보수교육일</td>
                <td>수정</td>
                <td>삭제</td>
              </tr>
              {workers.map((worker, index) => (
                <tr key={index}>
                  <td>{worker.category}</td>
                  <td>{worker.name}</td>
                  <td>{worker.grade}</td>
                  <td>{worker.seniorityDate}</td>
                  <td>{worker.trainingCompletionDate}</td>
                  <td>{worker.refresherTrainingDate}</td>
                  <td>
                    <S.IconWrapper
                      onClick={() => {
                        handleUpdate(worker.id);
                      }}
                    >
                      <AiOutlineEdit></AiOutlineEdit>
                    </S.IconWrapper>
                  </td>
                  <td>
                    <S.IconWrapper
                      onClick={() => {
                        handleDelete(worker.id);
                      }}
                    >
                      <AiOutlineDelete
                        color={Theme.colors.WARNING_RED}
                      ></AiOutlineDelete>
                    </S.IconWrapper>
                  </td>
                </tr>
              ))}
            </tbody>
          </S.Table>
        </S.Container>
      )}
    </>
  );
};

export default MaintenanceWorkerTable;
