import { Theme } from '@/styles/Theme';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;

  width: 100%;
  max-width: 56.25rem;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ImgContainer = styled.div`
  border: 0.0625rem solid ${Theme.colors.BLACK};
  background-color: ${Theme.colors.WHITE};

  display: flex;
  align-items: center;
  gap: 0.625rem;
  padding: 0.625rem;

  overflow: auto;
`;

export const ImgWrapper = styled.div<{ isSelected: boolean }>`
  position: relative;

  overflow: hidden;
  width: 9.375rem;
  height: 9.375rem;

  border: 0.125rem solid ${Theme.colors.G_3};
  border-radius: 0.625rem;

  cursor: pointer;

  ${({ isSelected }) =>
    isSelected &&
    css`
      border: 0.125rem solid ${Theme.colors.B_3};
      img {
        filter: brightness(50%);
      }
    `}
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;

  transition: 0.2s ease-in-out;
  :hover {
    transform: scale(1.1);
  }
`;

export const FileLabel = styled.label`
  cursor: pointer;
  width: 9.375rem;
  height: 9.375rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border: 0.125rem solid ${Theme.colors.G_3};
  border-radius: 0.625rem;
`;

export const FileInput = styled.input`
  display: none;
`;

export const ImgRemoveIconWrapper = styled.div`
  position: absolute;
  top: 0.3125rem;
  right: 0.3125rem;

  border-radius: 50%;

  background-color: ${Theme.colors.WHITE};
  color: ${Theme.colors.WARNING_RED};

  cursor: pointer;
`;
