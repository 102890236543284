import axios from 'axios';
import * as ExcelJS from 'exceljs';

export const copyTemplate = (
  mainSheet: ExcelJS.Worksheet | undefined,
  copySheet: ExcelJS.Worksheet | undefined,
  pageBreaks?: number[]
) => {
  if (copySheet && mainSheet) {
    // 병합된 셀 정보 복사
    // @ts-ignore
    copySheet.model.merges.forEach((mergedCell) => {
      const [topLeft, bottomRight] = mergedCell.split(':');
      const [t, l] = cellAddressToNumbers(topLeft);
      const [b, r] = cellAddressToNumbers(bottomRight);
      mainSheet.mergeCells(t, l, b, r);
    });

    // 열 너비 복사
    copySheet.columns.forEach((column) => {
      if (column.width) {
        // @ts-ignore
        mainSheet.getColumn(column.number).width = column.width;
      }
    });

    copySheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
      // 행의 높이 복사
      if (row.height) {
        mainSheet.getRow(rowNumber).height = row.height;
      }
      row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
        let newCell = mainSheet.getCell(rowNumber, colNumber);

        // 병합된 셀인지 확인
        if (cell.isMerged) {
          const masterCell = cell.master;

          // 마스터 셀인 경우에만 처리
          if (cell.address === masterCell.address) {
            if (masterCell.type === ExcelJS.ValueType.Formula) {
              // 수식이 있는 경우
              newCell.value = {
                formula: updateFormula(masterCell.formula, 0),
                result: masterCell.result,
              };
            } else {
              // 수식이 없는 경우
              newCell.value = masterCell.value;
            }
          }
          // 마스터 셀이 아니면 처리하지 않음
        } else {
          // 병합되지 않은 셀의 경우 값을 복사
          newCell.value = cell.value;
        }

        newCell.style = { ...cell.style };
        newCell.font = { ...cell.font };
        newCell.border = { ...cell.border };
        newCell.alignment = {
          ...cell.alignment,
        };
      });
    });

    // A4 양식으로 페이지 세팅
    mainSheet.pageSetup = {
      paperSize: 9,
      scale: 100,
    };
    mainSheet.views = [{ style: 'pageBreakPreview' }];

    if (pageBreaks) {
      pageBreaks.forEach((row) => {
        mainSheet.getRow(row).addPageBreak();
      });
    }
  }
};

export const addImageToSheet = async (
  imgUrl: string,
  workbook: ExcelJS.Workbook
) => {
  const response = await axios.get(imgUrl.replace('test', 'thumb'), {
    headers: {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      Pragma: 'no-cache',
      Expires: '0',
    },
    responseType: 'arraybuffer',
  });

  const imageId = workbook.addImage({
    buffer: response.data,
    extension: 'jpeg',
  });

  return imageId;
};

export const cellAddressToNumbers = (cellAddress: string): [number, number] => {
  const colLetter = cellAddress.replace(/[0-9]/g, '');
  const rowNumber = parseInt(cellAddress.replace(/\D/g, ''));

  let colNumber = 0;
  for (let i = 0; i < colLetter.length; i++) {
    colNumber +=
      (colLetter.charCodeAt(i) - 64) * Math.pow(26, colLetter.length - i - 1);
  }

  return [rowNumber, colNumber];
};

// 간단한 수식 업데이트 함수
export const updateFormula = (formula: string, colOffset: number): string => {
  return formula.replace(/([A-Z]+)(\d+)/g, (match, col, row) => {
    // 열 문자를 숫자로 변환
    const colNum = colRefToNum(col);
    // 숫자를 업데이트하고 문자로 다시 변환
    const newCol = numToColRef(colNum + colOffset);
    return `${newCol}${row}`;
  });
};

// 열 참조를 숫자로 변환
const colRefToNum = (ref: string) => {
  let num = 0;
  for (let i = 0; i < ref.length; i++) {
    num = num * 26 + (ref.charCodeAt(i) - 'A'.charCodeAt(0) + 1);
  }
  return num;
};

// 숫자를 열 참조로 변환
const numToColRef = (num: number) => {
  let ref = '';
  while (num > 0) {
    let remainder = num % 26;
    if (remainder === 0) {
      remainder = 26;
      num--;
    }
    ref = String.fromCharCode(remainder + 64) + ref;
    num = Math.floor(num / 26);
  }
  return ref;
};

export const copyRangeToNewLocation = (
  mainSheet: ExcelJS.Worksheet,
  startRow: number,
  endRow: number,
  emptyRowCount: number
) => {
  // 활성화된 마지막 행 찾기
  let lastRow = mainSheet.lastRow ? mainSheet.lastRow.number : 1;
  const startRowForPaste = lastRow + 1; // 바로 다음 행부터 시작

  // 병합된 셀 복사
  mainSheet.model.merges.forEach((mergedCell) => {
    const [topLeft, bottomRight] = mergedCell.split(':');
    const [topRow, leftCol] = cellAddressToNumbers(topLeft);
    const [bottomRow, rightCol] = cellAddressToNumbers(bottomRight);

    // 병합 정보가 지정 범위 내에 있는 경우에만 복사
    if (topRow >= startRow && bottomRow <= endRow) {
      const newTopRow = topRow - startRow + startRowForPaste;
      const newBottomRow = bottomRow - startRow + startRowForPaste;
      mainSheet.mergeCells(newTopRow, leftCol, newBottomRow, rightCol);
    }
  });

  // 복사할 행의 데이터와 스타일 복사
  for (let i = startRow; i <= endRow; i++) {
    const sourceRow = mainSheet.getRow(i);
    const targetRow = mainSheet.getRow(i - startRow + startRowForPaste);

    targetRow.height = sourceRow.height; // 행 높이 복사
    sourceRow.eachCell({ includeEmpty: true }, (cell, colNumber) => {
      const newCell = targetRow.getCell(colNumber);
      newCell.value = cell.value;
      newCell.style = { ...cell.style };
      newCell.font = { ...cell.font };
      newCell.border = { ...cell.border };
      newCell.alignment = { ...cell.alignment };
    });
  }

  // 빈 행 삽입
  for (let i = 0; i < emptyRowCount; i++) {
    mainSheet.insertRow(startRowForPaste + (endRow - startRow + 1) + i + 1, '');
  }
};
