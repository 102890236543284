import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { getReportOutline } from '@/apis/report/outline';

import { QUERY_KEY } from '@/constants/queryKey';

import Text from '@/components/common/Text';
import GreyBox from '@/components/common/GreyBox';

import * as S from './style';
import { StyledCol } from '@/styles/common';

const InspectionTarget = () => {
  const params = useParams();
  const inspectionAreaId = Number(params.id);

  const { data: outlineData } = useQuery({
    queryKey: [QUERY_KEY.REPORT.OUTLINE, inspectionAreaId],
    queryFn: () => getReportOutline(inspectionAreaId),
  });

  return (
    <GreyBox title='성능점검 개요' width='58.125rem'>
      <Text fontWeight={600} fontSize='1.25rem'>
        3. 점검대상
      </Text>
      <br />
      <br />
      <S.StyledTable>
        <colgroup>
          <StyledCol width='12.5rem' />
          <StyledCol width='25rem' />
        </colgroup>
        <tbody>
          <tr>
            <td>대 상</td>
            <td>{outlineData?.data.inspectionAreaName}</td>
          </tr>
          <tr>
            <td>위 치</td>
            <td>
              {outlineData?.data.inspectionAreaLocation.replace('/', ' ')}
            </td>
          </tr>
          <tr>
            <td>용 역 명</td>
            <td>기계설비 성능점검</td>
          </tr>
        </tbody>
      </S.StyledTable>
    </GreyBox>
  );
};

export default InspectionTarget;
