import styled from '@emotion/styled';
import { Theme } from '@/styles/Theme';
export const Container = styled.div`
  width: 100%;
  padding: 6.25rem 3.125rem;
  display: flex;
  gap: 3.125rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: ${Theme.colors.G_6};
`;
export const ImageWrapper = styled.div`
  max-width: 28.125rem;
  min-width: 9.375rem;
  border-radius: 0.75rem;
  border: 0.0625rem solid ${Theme.colors.G_4};
  overflow: hidden;
  padding: 0.9375rem 0.3125rem;
  box-shadow: 0rem 0.75rem 1.5625rem 0.0063rem rgba(0, 0, 0, 0.19);
  @media (max-width: 600px) {
    width: 100%;
  }
`;
export const TextContainer = styled.div`
  width: 100%;
  max-width: 25rem;
  display: flex;
  flex-direction: column;
`;
