import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from '@emotion/styled';

import Modal from '@/components/common/Modal';
import FormInput from '@/components/common/FormInput';
import Text from '@/components/common/Text';
import Button from '@/components/common/Button';

import { postWorkerInfoModification } from '@/apis/worker';
import type { PostWorkerModifyRequestProps } from '@/types/worker.types';
import type { ErrorResponse } from '@/types/client.types';

import { INPUT_MESSAGE } from '@/constants/constants';
import { QUERY_KEY } from '@/constants/queryKey';

import { StyledForm } from '@/styles/common';
import { Theme } from '@/styles/Theme';

interface Props {
  workerInfo: PostWorkerModifyRequestProps;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const WorkerModifyModal = ({ workerInfo, setIsModalOpen }: Props) => {
  const queryClient = useQueryClient();

  const { control, handleSubmit } = useForm<PostWorkerModifyRequestProps>({
    defaultValues: {
      id: workerInfo.id,
      name: workerInfo.name,
      password: workerInfo.password,
      memo: workerInfo.memo,
      noteNumber: workerInfo.noteNumber,
      grade: workerInfo.grade,
    },
  });

  const { mutate: addWorkerMutate } = useMutation({
    mutationFn: postWorkerInfoModification,
    onSuccess: (data) => {
      queryClient.invalidateQueries([QUERY_KEY.WORKERS]);
      toast.success('작업자 수정이 완료되었습니다.');
      setIsModalOpen(false);
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      toast.error(error.response?.data.message);
    },
  });

  const onSubmitHandler: SubmitHandler<PostWorkerModifyRequestProps> = (
    data
  ) => {
    addWorkerMutate(data);
  };

  return (
    <Modal setIsModalOpen={setIsModalOpen} hasButton={false}>
      <Container>
        <TitleContainer>
          <Text fontSize='1.375rem' fontWeight={700}>
            작업자 수정
          </Text>
          <Text color={Theme.colors.G_2}>기존 작업자를 수정합니다.</Text>
          <Text color={Theme.colors.G_2}>
            (작업자 추가 후, 아이디 수정은 불가합니다.)
          </Text>
        </TitleContainer>

        <StyledForm onSubmit={handleSubmit(onSubmitHandler)}>
          <FormInput
            control={control}
            rules={{ required: INPUT_MESSAGE.REQUIRED }}
            name='name'
            width='25.625rem'
            height='2.5rem'
            placeholder={INPUT_MESSAGE.NAME.PLACEHOLDER}
            label={INPUT_MESSAGE.NAME.LABEL}
            disabled
          />
          <FormInput
            control={control}
            rules={{ required: INPUT_MESSAGE.REQUIRED }}
            name='password'
            width='25.625rem'
            height='2.5rem'
            placeholder={INPUT_MESSAGE.PASSWORD.PLACEHOLDER}
            label={INPUT_MESSAGE.PASSWORD.LABEL}
          />
          <FormInput
            control={control}
            name='grade'
            width='25.625rem'
            height='2.5rem'
            placeholder={INPUT_MESSAGE.GRADE.PLACEHOLDER}
            label={INPUT_MESSAGE.GRADE.LABEL}
          />
          <FormInput
            control={control}
            name='noteNumber'
            width='25.625rem'
            height='2.5rem'
            placeholder={INPUT_MESSAGE.NOTE_NUMBER.PLACEHOLDER}
            label={INPUT_MESSAGE.NOTE_NUMBER.LABEL}
          />
          <FormInput
            control={control}
            name='memo'
            width='25.625rem'
            height='2.5rem'
            placeholder={INPUT_MESSAGE.MEMO.PLACEHOLDER}
            label={INPUT_MESSAGE.MEMO.LABEL}
          />

          <ButtonContainer>
            <Button
              backgroundColor={Theme.colors.G_5}
              color={Theme.colors.BLACK}
              width='12.5rem'
              height='3.125rem'
              onClick={() => setIsModalOpen(false)}
            >
              취소
            </Button>
            <Button
              type='submit'
              backgroundColor={Theme.colors.B_3}
              width='12.5rem'
              height='3.125rem'
            >
              확인
            </Button>
          </ButtonContainer>
        </StyledForm>
      </Container>
    </Modal>
  );
};

export default WorkerModifyModal;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  gap: 1.875rem;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
`;
