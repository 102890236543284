import React from 'react';
import styled from '@emotion/styled';
import { AiOutlineClose, AiOutlineEdit } from 'react-icons/ai';

import Button from '@/components/common/Button';

import { Theme } from '@/styles/Theme';

interface Props {
  isDetailPage: boolean;
  setIsDetailPage: React.Dispatch<React.SetStateAction<boolean>>;
  top?: string;
  right?: string;
  onClickCancel?: () => void;
}

const EditButton = ({
  isDetailPage,
  setIsDetailPage,
  top = '2.8125rem',
  right = '2.5rem',
  onClickCancel,
}: Props) => {
  const handleCancel = () => {
    if (onClickCancel) {
      onClickCancel();
    }
    setIsDetailPage(true);
  };

  if (isDetailPage)
    return (
      <ButtonWrapper top={top} right={right}>
        <Button height='2.1875rem' onClick={() => setIsDetailPage(false)}>
          <AiOutlineEdit size='1.25rem' />
          수정하기
        </Button>
      </ButtonWrapper>
    );

  return (
    <ButtonWrapper top={top} right={right}>
      <Button
        height='2.1875rem'
        backgroundColor={Theme.colors.G_2}
        onClick={handleCancel}
      >
        <AiOutlineClose size='1.25rem' />
        취소하기
      </Button>
    </ButtonWrapper>
  );
};

export default EditButton;

const ButtonWrapper = styled.div<{ top: string; right: string }>`
  position: absolute;

  z-index: 10;

  top: ${({ top }) => top};
  right: ${({ right }) => right};
`;
