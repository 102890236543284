import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Button from '@/components/common/Button';

import { Theme } from '@/styles/Theme';

interface Props {
  type?: 'text' | 'email' | 'password' | 'number';
  name: string;
  width: string;
  height: string;
  value?: string | number;
  maxLength?: number;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  isOnFocus?: boolean;
  disabled?: boolean;
  label?: string;
  labelFontSize?: string;

  backgroundColor?: string;
  borderRadius?: string;

  buttonName?: string;
  buttonOnclick?: () => void;
  buttonDisabled?: boolean;
}

const Input = ({
  type = 'text',
  name,
  width,
  height,
  value,
  maxLength,
  placeholder,
  onChange,
  onClick,
  isOnFocus = false,
  disabled = false,
  label,
  labelFontSize = '1rem',
  buttonName,
  buttonOnclick,
  buttonDisabled,
  borderRadius,
  backgroundColor,
}: Props) => {
  return (
    <Container>
      {label && (
        <LabelWrapper htmlFor={name} labelFontSize={labelFontSize}>
          {label}
        </LabelWrapper>
      )}
      <InputContainer>
        <Wrapper width={width} onClick={onClick} hasCursor={!!onClick}>
          <InputWrapper
            type={type}
            id={name}
            name={name}
            width={width}
            height={height}
            value={value}
            maxLength={maxLength}
            placeholder={placeholder}
            backgroundColor={backgroundColor}
            borderRadius={borderRadius}
            onChange={onChange}
            disabled={disabled}
            onFocus={(e) => {
              isOnFocus && e.target.select();
            }}
          />
        </Wrapper>
        {buttonName && (
          <Button
            width='5.0625rem'
            height='2.5rem'
            onClick={buttonOnclick}
            disabled={buttonDisabled}
          >
            {buttonName}
          </Button>
        )}
      </InputContainer>
    </Container>
  );
};

export default Input;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
`;

const LabelWrapper = styled.label<{ labelFontSize: string }>`
  font-size: ${({ labelFontSize }) => labelFontSize};
  font-weight: 500;
`;

const Wrapper = styled.div<{ hasCursor?: boolean; width: string }>`
  width: ${({ width }) => width};
  ${({ hasCursor }) =>
    hasCursor &&
    css`
      cursor: pointer;
    `};
`;

const InputWrapper = styled.input<Props>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : Theme.colors.WHITE};
  border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : '0.375rem'};
  box-shadow: 0rem 0rem 0.25rem 0rem rgba(0, 0, 0, 0.25);

  border: none;

  padding: 0 0.9375rem;

  color: ${Theme.colors.BLACK};
  font-size: 1rem;
  font-weight: 400;

  :disabled {
    background-color: ${Theme.colors.G_5};
  }

  ::placeholder {
    color: ${Theme.colors.G_2};
  }

  :focus {
    outline: none;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;

  gap: 0.625rem;
`;
