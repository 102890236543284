import React, { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import type { ErrorResponse } from '@/types/client.types';
import Text from '@/components/common/Text';
import Button from '@/components/common/Button';
import GreyBox from '@/components/common/GreyBox';

import { QUERY_KEY } from '@/constants/queryKey';
import { getGuideline, postGuideline } from '@/apis/report/system';
import type { PostReviewSystemRequestProps } from '@/types/report/system.types';

import { Theme } from '@/styles/Theme';
import * as S from './style';
import { StyledCol } from '@/styles/common';

const GuideBookStatusTable = () => {
  const [isEdit, setEdit] = useState(false);
  const [editableGuideData, setEditableGuideData] =
    useState<PostReviewSystemRequestProps>();
  const params = useParams();
  const inspectionAreaId = Number(params.id);

  const queryClient = useQueryClient();

  const { mutate: editGuidelineData } = useMutation({
    mutationFn: postGuideline,
    onSuccess: (data) => {
      toast.success('정보가 수정되었습니다.');
      queryClient.invalidateQueries([
        QUERY_KEY.REPORT.GUIDELINE,
        inspectionAreaId,
      ]);
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      toast.error(error.response?.data.message);
    },
  });

  const { data: guidelineData } = useQuery({
    queryKey: [QUERY_KEY.REPORT.GUIDELINE, inspectionAreaId],
    queryFn: () => getGuideline(inspectionAreaId),
  });

  useEffect(() => {
    if (guidelineData !== undefined) {
      setEditableGuideData({
        inspectionAreaId: inspectionAreaId,
        ...guidelineData.data,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guidelineData]);

  const handleEditState = () => {
    if (isEdit && editableGuideData !== undefined) {
      editGuidelineData(editableGuideData);
    }
    setEdit(!isEdit);
  };
  const handleAsBuiltDrawing = () => {
    var next = Object.assign({}, editableGuideData);
    next.asBuiltDrawing = !next.asBuiltDrawing;
    if (next !== undefined) setEditableGuideData(next);
  };

  const handleSpecification = () => {
    var next = Object.assign({}, editableGuideData);
    next.specification = !next.specification;
    if (next !== undefined) setEditableGuideData(next);
  };
  const handleCalculation = () => {
    var next = Object.assign({}, editableGuideData);
    next.calculation = !next.calculation;
    if (next !== undefined) setEditableGuideData(next);
  };

  const handleManual = () => {
    var next = Object.assign({}, editableGuideData);
    next.manual = !next.manual;
    if (next !== undefined) setEditableGuideData(next);
  };

  const handleReport = () => {
    var next = Object.assign({}, editableGuideData);
    next.report = !next.report;
    if (next !== undefined) setEditableGuideData(next);
  };

  const handleBeforeChecklist = () => {
    var next = Object.assign({}, editableGuideData);
    next.beforeChecklist = !next.beforeChecklist;
    if (next !== undefined) setEditableGuideData(next);
  };

  const handlePerformanceConfirmation = () => {
    var next = Object.assign({}, editableGuideData);
    next.performanceConfirmation = !next.performanceConfirmation;
    if (next !== undefined) setEditableGuideData(next);
  };

  const handleCautionConfirmation = () => {
    var next = Object.assign({}, editableGuideData);
    next.cautionConfirmation = !next.cautionConfirmation;
    if (next !== undefined) setEditableGuideData(next);
  };

  const handleSuitabilityConfirmation = () => {
    var next = Object.assign({}, editableGuideData);
    next.suitabilityConfirmation = !next.suitabilityConfirmation;
    if (next !== undefined) setEditableGuideData(next);
  };

  return (
    <GreyBox width='56.25rem'>
      {editableGuideData && (
        <S.Container>
          <S.TopContainer>
            <Text fontSize='1.25rem' fontWeight={600}>
              유지관리지침서 구비 현황 점검표
            </Text>

            <Button
              height='2.1875rem'
              backgroundColor={isEdit ? Theme.colors.BLACK : Theme.colors.B_3}
              onClick={() => handleEditState()}
            >
              {isEdit ? '저장하기' : '수정하기'}
            </Button>
          </S.TopContainer>

          <S.FlexContainer>
            <S.Table>
              <colgroup>
                <StyledCol width='10.625rem' />
                <StyledCol width='6.25rem' />
                <StyledCol width='6.25rem' />
                <StyledCol width='3.125rem' />
                <StyledCol width='3.125rem' />
              </colgroup>
              <tbody>
                <tr>
                  <S.TableHeader colSpan={3}>구비서류</S.TableHeader>
                  <S.TableHeader colSpan={1}>유</S.TableHeader>
                  <S.TableHeader colSpan={1}>무</S.TableHeader>
                </tr>
              </tbody>
              <S.InfoContainer>
                <tr>
                  <td rowSpan={3}>• 기계설비 준공도서 </td>
                  <td colSpan={2}>준공도면</td>
                  <S.ToggleButtonContainer>
                    <S.ToggleButtonWrapper
                      isSelected={editableGuideData?.asBuiltDrawing === true}
                      isEditMode={isEdit}
                      onClick={() => isEdit && handleAsBuiltDrawing()}
                    >
                      ✔
                    </S.ToggleButtonWrapper>
                  </S.ToggleButtonContainer>
                  <S.ToggleButtonContainer>
                    <S.ToggleButtonWrapper
                      isSelected={editableGuideData?.asBuiltDrawing === false}
                      isEditMode={isEdit}
                      onClick={() => isEdit && handleAsBuiltDrawing()}
                    >
                      ✔
                    </S.ToggleButtonWrapper>
                  </S.ToggleButtonContainer>
                </tr>

                <tr>
                  <td colSpan={2}>시방서</td>
                  <S.ToggleButtonContainer>
                    <S.ToggleButtonWrapper
                      isSelected={editableGuideData?.specification === true}
                      isEditMode={isEdit}
                      onClick={() => isEdit && handleSpecification()}
                    >
                      ✔
                    </S.ToggleButtonWrapper>
                  </S.ToggleButtonContainer>
                  <S.ToggleButtonContainer>
                    <S.ToggleButtonWrapper
                      isSelected={editableGuideData?.specification === false}
                      isEditMode={isEdit}
                      onClick={() => isEdit && handleSpecification()}
                    >
                      ✔
                    </S.ToggleButtonWrapper>
                  </S.ToggleButtonContainer>
                </tr>

                <tr>
                  <td colSpan={2}>부하 및 장비선정 계산서</td>
                  <S.ToggleButtonContainer>
                    <S.ToggleButtonWrapper
                      isSelected={editableGuideData?.calculation === true}
                      isEditMode={isEdit}
                      onClick={() => isEdit && handleCalculation()}
                    >
                      ✔
                    </S.ToggleButtonWrapper>
                  </S.ToggleButtonContainer>
                  <S.ToggleButtonContainer>
                    <S.ToggleButtonWrapper
                      isSelected={editableGuideData?.calculation === false}
                      isEditMode={isEdit}
                      onClick={() => isEdit && handleCalculation()}
                    >
                      ✔
                    </S.ToggleButtonWrapper>
                  </S.ToggleButtonContainer>
                </tr>
              </S.InfoContainer>

              <S.InfoContainer>
                <tr>
                  <td rowSpan={3}>• 기계설비 시스템 운용 매뉴얼 </td>
                  <td colSpan={2}> 기계설비 시스템 운용 매뉴얼</td>
                  <S.ToggleButtonContainer>
                    <S.ToggleButtonWrapper
                      isSelected={editableGuideData?.manual === true}
                      isEditMode={isEdit}
                      onClick={() => isEdit && handleManual()}
                    >
                      ✔
                    </S.ToggleButtonWrapper>
                  </S.ToggleButtonContainer>
                  <S.ToggleButtonContainer>
                    <S.ToggleButtonWrapper
                      isSelected={editableGuideData?.manual === false}
                      isEditMode={isEdit}
                      onClick={() => isEdit && handleManual()}
                    >
                      ✔
                    </S.ToggleButtonWrapper>
                  </S.ToggleButtonContainer>
                </tr>

                <tr>
                  <td colSpan={2}>
                    기계설비 제조사의 검사서 또는 제조사의 성적서
                  </td>
                  <S.ToggleButtonContainer>
                    <S.ToggleButtonWrapper
                      isSelected={editableGuideData?.report === true}
                      isEditMode={isEdit}
                      onClick={() => isEdit && handleReport()}
                    >
                      ✔
                    </S.ToggleButtonWrapper>
                  </S.ToggleButtonContainer>
                  <S.ToggleButtonContainer>
                    <S.ToggleButtonWrapper
                      isSelected={editableGuideData?.report === false}
                      isEditMode={isEdit}
                      onClick={() => isEdit && handleReport()}
                    >
                      ✔
                    </S.ToggleButtonWrapper>
                  </S.ToggleButtonContainer>
                </tr>
              </S.InfoContainer>
              <S.InfoContainer>
                <tr>
                  <td rowSpan={3}>
                    <div> • 기계설비 사용전 확인표</div>
                    <div>「기계설비 기술기준」별지 제3호서식</div>
                  </td>

                  <S.ToggleButtonContainer colSpan={4}>
                    <S.BoxContainer>
                      <S.ToggleButtonWrapper
                        isSelected={editableGuideData?.beforeChecklist === true}
                        isEditMode={isEdit}
                        onClick={() => isEdit && handleBeforeChecklist()}
                      >
                        해당
                      </S.ToggleButtonWrapper>
                      <S.ToggleButtonWrapper
                        isSelected={
                          editableGuideData?.beforeChecklist === false
                        }
                        isEditMode={isEdit}
                        onClick={() => isEdit && handleBeforeChecklist()}
                      >
                        해당없음
                      </S.ToggleButtonWrapper>
                    </S.BoxContainer>
                  </S.ToggleButtonContainer>
                </tr>
              </S.InfoContainer>

              <S.InfoContainer>
                <tr>
                  <td rowSpan={3}>
                    <div> • 기계설비 성능확인서</div>
                    <div>「기계설비 기술기준」별지 제4호서식</div>
                  </td>

                  <S.ToggleButtonContainer colSpan={4}>
                    <S.BoxContainer>
                      <S.ToggleButtonWrapper
                        isSelected={
                          editableGuideData?.performanceConfirmation === true
                        }
                        isEditMode={isEdit}
                        onClick={() =>
                          isEdit && handlePerformanceConfirmation()
                        }
                      >
                        해당
                      </S.ToggleButtonWrapper>
                      <S.ToggleButtonWrapper
                        isSelected={
                          editableGuideData?.performanceConfirmation === false
                        }
                        isEditMode={isEdit}
                        onClick={() =>
                          isEdit && handlePerformanceConfirmation()
                        }
                      >
                        해당없음
                      </S.ToggleButtonWrapper>
                    </S.BoxContainer>
                  </S.ToggleButtonContainer>
                </tr>
              </S.InfoContainer>

              <S.InfoContainer>
                <tr>
                  <td rowSpan={3}>
                    <div> • 기계설비 안전확인서</div>
                    <div>「기계설비 기술기준」별지 제5호서식</div>
                  </td>

                  <S.ToggleButtonContainer colSpan={4}>
                    <S.BoxContainer>
                      <S.ToggleButtonWrapper
                        isSelected={
                          editableGuideData?.cautionConfirmation === true
                        }
                        isEditMode={isEdit}
                        onClick={() => isEdit && handleCautionConfirmation()}
                      >
                        해당
                      </S.ToggleButtonWrapper>
                      <S.ToggleButtonWrapper
                        isSelected={
                          editableGuideData?.cautionConfirmation === false
                        }
                        isEditMode={isEdit}
                        onClick={() => isEdit && handleCautionConfirmation()}
                      >
                        해당없음
                      </S.ToggleButtonWrapper>
                    </S.BoxContainer>
                  </S.ToggleButtonContainer>
                </tr>
              </S.InfoContainer>

              <S.InfoContainer>
                <tr>
                  <td rowSpan={3}>
                    <div> • 기계설비 사용적합확인서</div>
                    <div>「기계설비 기술기준」별지 제6호서식</div>
                  </td>

                  <S.ToggleButtonContainer colSpan={4}>
                    <S.BoxContainer>
                      <S.ToggleButtonWrapper
                        isSelected={
                          editableGuideData?.suitabilityConfirmation === true
                        }
                        isEditMode={isEdit}
                        onClick={() =>
                          isEdit && handleSuitabilityConfirmation()
                        }
                      >
                        해당
                      </S.ToggleButtonWrapper>
                      <S.ToggleButtonWrapper
                        isSelected={
                          editableGuideData?.suitabilityConfirmation === false
                        }
                        isEditMode={isEdit}
                        onClick={() =>
                          isEdit && handleSuitabilityConfirmation()
                        }
                      >
                        해당없음
                      </S.ToggleButtonWrapper>
                    </S.BoxContainer>
                  </S.ToggleButtonContainer>
                </tr>
              </S.InfoContainer>
            </S.Table>
          </S.FlexContainer>
          <div>
            ※ 「유지관리기준」고시 부칙 제2조(기존 건축물등에 관한 특례)의
            규정에 따라 기존 건축물 등에 대해서는 준공도면, 시스템 운용 매뉴얼을
            제외한 모든 서류를 구비한 것으로 본다. 따라서 기존건축물은 준공도면,
            시스템 운용 매뉴얼은 필히 구비할 필요가 있음
          </div>
        </S.Container>
      )}
    </GreyBox>
  );
};

export default GuideBookStatusTable;
