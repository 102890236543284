import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import {
  getAppendixHistory,
  postAppendixHistory,
  getAppendixHistoryDeviceList,
} from '@/apis/appendix';
import type { PostAppendixHistoryRequestProps } from '@/types/appendix.types';
import type { ErrorResponse } from '@/types/client.types';

import { QUERY_KEY } from '@/constants/queryKey';

import {
  Table,
  TextWrapper,
} from '@/components/work/WorkAppendixReportTable/style';
import Text from '@/components/common/Text';
import Spacer from '@/components/common/Spacer';
import {
  ButtonWrapper,
  StyledTextarea,
} from '@/components/work/WorkReportResultTable/style';
import Button from '@/components/common/Button';
import EditButton from '@/components/common/EditButton';

import { Theme } from '@/styles/Theme';
import * as S from './style';
import { StyledCol, StyledTd } from '@/styles/common';

const WorkAppendixHistoryTable = () => {
  const params = useParams();
  const queryClient = useQueryClient();

  const inspectionAreaId = Number(params.id);

  const [appendixHistoryInfo, setAppendixHistoryInfo] = useState<
    Omit<PostAppendixHistoryRequestProps, 'inspectionAreaId'>
  >({
    resultText: '',
    company: '',
    representatorName: '',
    deviceList: [],
  });
  const [isDetailPage, setIsDetailPage] = useState<boolean>(true);

  const { data: appendixHistoryData } = useQuery({
    queryKey: [QUERY_KEY.APPENDIX_HISTORY, inspectionAreaId],
    queryFn: () => getAppendixHistory(inspectionAreaId),
  });

  const { mutate } = useMutation({
    mutationFn: postAppendixHistory,
    onSuccess: (data) => {
      setIsDetailPage(true);
      toast.success('점검결과 내역서가 수정되었습니다.');
      queryClient.invalidateQueries([
        QUERY_KEY.APPENDIX_HISTORY,
        inspectionAreaId,
      ]);
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      toast.error(error.response?.data.message);
    },
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setAppendixHistoryInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDeviceInfoChange = (
    e:
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const {
      dataset: { templatedeviceid },
      value,
      name,
    } = e.target;

    const handledDeviceList = appendixHistoryInfo.deviceList.map((device) => {
      if (device.templateDeviceId === Number(templatedeviceid)) {
        return {
          ...device,
          [name]: value,
        };
      }
      return device;
    });

    setAppendixHistoryInfo((prev) => ({
      ...prev,
      deviceList: handledDeviceList,
    }));
  };

  const handleDeviceResultReset = async () => {
    try {
      const reponse = await queryClient.fetchQuery(
        [QUERY_KEY.APPENDIX_HISTORY_DEVICELIST, inspectionAreaId],
        () => getAppendixHistoryDeviceList(inspectionAreaId)
      );

      const appendixHistoryDeviceListData = reponse.data;

      const updatedDeviceList = appendixHistoryInfo.deviceList.map((device) => {
        const originalDevice = appendixHistoryDeviceListData.find(
          (d) => d.templateDeviceId === device.templateDeviceId
        );
        if (originalDevice) {
          return {
            ...device,
            result: originalDevice.result,
          };
        }
        return device;
      });

      setAppendixHistoryInfo((prev) => ({
        ...prev,
        deviceList: updatedDeviceList,
      }));
    } catch (error: any) {
      toast.error(error.response?.data.message);
    }
  };

  const handleCancel = () => {
    setIsDetailPage(true);
    if (appendixHistoryData) {
      const {
        history: { resultText, company, representatorName },
        deviceList,
      } = appendixHistoryData.data;

      setAppendixHistoryInfo({
        resultText,
        company,
        representatorName,
        deviceList,
      });
    }
  };

  const handleSubmit = () => {
    mutate({ inspectionAreaId, ...appendixHistoryInfo });
  };

  useEffect(() => {
    if (appendixHistoryData) {
      const {
        history: { resultText, company, representatorName },
        deviceList,
      } = appendixHistoryData.data;

      setAppendixHistoryInfo({
        resultText,
        company,
        representatorName,
        deviceList,
      });
    }
  }, [appendixHistoryData]);

  if (!appendixHistoryData) {
    return null;
  }

  const {
    history: { areaName, address },
  } = appendixHistoryData.data;

  const parsedAddress = address.replace('/', ' ');

  return (
    <S.Container>
      <TextWrapper>
        <Text fontWeight={700} fontSize='1.75rem' textAlign='center'>
          점검결과 내역서
        </Text>
        <EditButton
          top='7.5rem'
          isDetailPage={isDetailPage}
          setIsDetailPage={setIsDetailPage}
          onClickCancel={handleCancel}
        />
      </TextWrapper>
      <Spacer y='1.25rem' />
      <Table>
        <colgroup>
          <StyledCol width='9.375rem' />
          <col />
          <StyledCol width='6.25rem' />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <td>현장 명칭</td>
            <td>{areaName}</td>
            <td>주소</td>
            <td>{parsedAddress}</td>
          </tr>
          <tr>
            <td>
              점검결과
              <br />
              (특기사항)
            </td>
            <td colSpan={3}>
              <StyledTextarea
                height='6.625rem'
                name='resultText'
                value={appendixHistoryInfo.resultText ?? ''}
                onChange={handleInputChange}
                disabled={isDetailPage}
              />
            </td>
          </tr>
          <tr>
            <td>점검자(대표자)</td>
            <td colSpan={3}>
              <S.NameContainer>
                상호(명칭) :
                <StyledTextarea
                  style={{ width: '12.5rem' }}
                  height='2.125rem'
                  name='company'
                  value={appendixHistoryInfo.company ?? ''}
                  onChange={handleInputChange}
                  disabled={isDetailPage}
                />
                성명 :
                <StyledTextarea
                  style={{ width: '12.5rem' }}
                  height='2.125rem'
                  name='representatorName'
                  value={appendixHistoryInfo.representatorName ?? ''}
                  onChange={handleInputChange}
                  disabled={isDetailPage}
                />
              </S.NameContainer>
            </td>
          </tr>
        </tbody>
      </Table>
      <Spacer y='1.25rem' />
      <Table>
        <colgroup>
          <StyledCol width='9.375rem' />
          <StyledCol width='6.25rem' />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <td>구분</td>
            <StyledTd padding='0.25rem 0'>
              <S.ResultHeaderContainer>
                점검결과
                {!isDetailPage && (
                  <Button
                    height='1.5625rem'
                    fontSize='0.8125rem'
                    onClick={handleDeviceResultReset}
                  >
                    불러오기
                  </Button>
                )}
              </S.ResultHeaderContainer>
            </StyledTd>
            <td>조치필요사항</td>
          </tr>
          {appendixHistoryInfo.deviceList.map((device) => (
            <tr key={device.templateDeviceId}>
              <td>{device.deviceName}</td>
              <td>
                <select
                  name='result'
                  data-templatedeviceid={device.templateDeviceId}
                  value={device.result}
                  onChange={handleDeviceInfoChange}
                  disabled={isDetailPage}
                >
                  <option value=''>선택</option>
                  <option value='O'>O</option>
                  <option value='X'>X</option>
                  <option value='/'>/</option>
                </select>
              </td>
              <td>
                <StyledTextarea
                  name='failText'
                  data-templatedeviceid={device.templateDeviceId ?? ''}
                  value={device.failText}
                  onChange={handleDeviceInfoChange}
                  disabled={isDetailPage}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {!isDetailPage && (
        <>
          <Spacer y='1.25rem' />
          <ButtonWrapper>
            <Button
              width='9.375rem'
              height='2.5rem'
              backgroundColor={Theme.colors.G_2}
              onClick={handleCancel}
            >
              취소하기
            </Button>
            <Button width='9.375rem' height='2.5rem' onClick={handleSubmit}>
              수정하기
            </Button>
          </ButtonWrapper>
        </>
      )}
    </S.Container>
  );
};

export default WorkAppendixHistoryTable;
