import styled from '@emotion/styled';

interface Props {
  x?: string;
  y?: string;
}

const Spacer = ({ x = '0.0625rem', y = '0.0625rem' }: Props) => {
  return <Wrapper x={x} y={y} />;
};

export default Spacer;

const Wrapper = styled.span<Props>`
  display: block;

  width: ${({ x }) => x};
  height: ${({ y }) => y};
`;
