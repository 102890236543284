import { useState, useRef, useEffect } from 'react';

const useTimer = (initialMinute: number, onTimerEnd: () => void) => {
  const [time, setTime] = useState<number>(initialMinute * 60);
  const intervalRef = useRef<number | null>(null);

  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current!);
      intervalRef.current = null;
    };
  }, []);

  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const startTimer = () => {
    setTime(initialMinute * 60);

    if (!intervalRef.current) {
      intervalRef.current = window.setInterval(() => {
        setTime((prevTime) => {
          if (prevTime > 0) {
            return prevTime - 1;
          } else {
            clearInterval(intervalRef.current!);
            intervalRef.current = null;
            onTimerEnd();
            return prevTime;
          }
        });
      }, 1000);
    }
  };

  const stopTimer = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  return { time: formatTime(time), startTimer, stopTimer };
};

export default useTimer;
