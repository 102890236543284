import client from '@/apis/client';
import type { Response } from '@/types/client.types';
import type {
  InspectionPlan,
  PostInspectionPlanRequestProps,
} from '@/types/report/plan.types';

// Ⅱ. 성능점검 계획
// 1. 성능점검 대상 및 기계설비 수량, 성능점검 일정, 성능점검 인력
export const getInspectionPlan = (inspectionAreaId: number) =>
  client
    .get<Response<InspectionPlan>>('/report/plan', {
      params: { inspectionAreaId },
    })
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error fetching inspection plan:', error);
      throw error;
    });

export const postReportPlan = (body: PostInspectionPlanRequestProps) =>
  client
    .post('/report/plan/upload', body)
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error posting inspection plan:', error);
      throw error;
    });
