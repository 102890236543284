import { Box, BoxWrapper, SubTitle } from '../style';

import RegPaymentMethod from './RegPaymentMethod';
import UnregPaymentMethod from './UnregPaymentMethod';

import * as styled from './style';
import type { PaymentInfo } from '@/types/company.types';

interface Props {
  paymentData: PaymentInfo;
}

const PaymentMethodInfo = ({ paymentData }: Props) => {
  const isPaymentMethodRegisted = !!paymentData.billingKey;

  return (
    <BoxWrapper>
      <SubTitle>결제 수단</SubTitle>
      <Box>
        {isPaymentMethodRegisted ? (
          <>
            <RegPaymentMethod />
            <styled.PaymentMethodDesc>
              등록하신 결제 카드로 자동 결제가 이루어집니다.
            </styled.PaymentMethodDesc>
          </>
        ) : (
          <>
            <UnregPaymentMethod />
            <styled.PaymentMethodDesc>
              자동 결제에서 사용할 결제 카드를 등록하세요
            </styled.PaymentMethodDesc>
          </>
        )}
      </Box>
    </BoxWrapper>
  );
};
export default PaymentMethodInfo;
