import client from '@/apis/client';
import type { Response } from '@/types/client.types';
import type {
  NoticesPopup,
  GetNoticesRequestProps,
  Notices,
} from '@/types/notices.types';

export const getNoticePopup = () =>
  client
    .get<Response<NoticesPopup[]>>('/notify/show?platform=web')
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error fetching notices popup:', error);
      throw error;
    });

export const getNotices = (body: GetNoticesRequestProps) =>
  client
    .get<Response<Notices>>('/notify/board', {
      params: { ...body },
    })
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error fetching notices:', error);
      throw error;
    });
