import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  padding: 6.25rem 3.125rem;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.9375rem;

  a {
    flex: 1;
  }
`;
