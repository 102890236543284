import * as styled from './style';

import { HeaderGroup, RowModel } from '@tanstack/react-table';

import WorkerPaymentInfoTableBody from './WorkerPaymentInfoTableBody';
import WorkerPaymentInfoTableHeader from './WorkerPaymentInfoTableHeader';

import type { WorkerPaymentInfo } from '@/types/company.types';

interface Props {
  getHeaderGroups: () => HeaderGroup<WorkerPaymentInfo>[];
  getRowModel: () => RowModel<WorkerPaymentInfo>;
}

const WorkerPaymentInfoTable = ({ getHeaderGroups, getRowModel }: Props) => {
  return (
    <styled.TableWrapper>
      <styled.Table>
        <WorkerPaymentInfoTableHeader getHeaderGroups={getHeaderGroups} />
        <WorkerPaymentInfoTableBody getRowModel={getRowModel} />
      </styled.Table>
    </styled.TableWrapper>
  );
};

export default WorkerPaymentInfoTable;
