import { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import styled from '@emotion/styled';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import DaumPostcode from 'react-daum-postcode';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';

import { getInspectionArea, putInspectionArea } from '@/apis/InspectionArea';
import type {
  DeviceInfo,
  PutInspectionAreaRequestProps,
} from '@/types/InspectionArea.types';
import { getWorkerList } from '@/apis/worker';
import type { ErrorResponse } from '@/types/client.types';

import { QUERY_KEY } from '@/constants/queryKey';
import { TOAST_ID } from '@/constants/toastId';

import GreyBox from '@/components/common/GreyBox';
import Spacer from '@/components/common/Spacer';
import Button from '@/components/common/Button';
import Prompt from '@/components/common/Prompt';
import Input from '@/components/common/Input';
import WorkerListTable from '@/components/worker/WorkerListTable';
import WorkEditCategorySelector from '@/components/work/WorkEditCategoryListSelector';
import WorkEditCategoryListTable from '@/components/work/WorkEditCategoryListTable';

import { WorkFormProps } from '@/pages/WorkAddPage';

import { Theme } from '@/styles/Theme';

import * as S from '@/styles/WorkForm';

import 'react-datepicker/dist/react-datepicker.css';

interface Props {
  inspectionAreaId: number;
  setIsDetailPage: React.Dispatch<React.SetStateAction<boolean>>;
}

const WorkEditPage = ({ inspectionAreaId, setIsDetailPage }: Props) => {
  const queryClient = useQueryClient();

  const { data: workInfoData } = useQuery(
    [QUERY_KEY.WORK_INFO, inspectionAreaId],
    {
      queryFn: () => getInspectionArea(inspectionAreaId),
    }
  );

  const [formState, setFormState] = useState<WorkFormProps>({
    name: '',
    mainAddress: '',
    subAddress: '',
    startDate: null,
    endDate: null,
    memo: '',
    reason: '',
  });
  const [isDaumPostCodeOn, setIsDaumPostCodeOn] = useState<boolean>(false);

  const [selectedWorkerList, setSelectedWorkerList] = useState<number[]>([]);
  const [selectedDeviceList, setSelectedDeviceList] = useState<DeviceInfo[]>(
    []
  );

  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  const { data: workerData } = useQuery([QUERY_KEY.WORKERS], {
    queryFn: getWorkerList,
  });

  const { mutate: putWorkModifyMutate } = useMutation({
    mutationFn: putInspectionArea,
    onSuccess: (data) => {
      queryClient.invalidateQueries([QUERY_KEY.DASHBOARD]);
      queryClient.invalidateQueries([QUERY_KEY.WORK_INFO, inspectionAreaId]);
      toast.success('점검 정보 수정이 완료되었습니다.');
      setIsDetailPage(true);
      window.scrollTo({ top: 0 });
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      toast.error(error.response?.data.message);
    },
  });

  useEffect(() => {
    if (workInfoData) {
      const {
        name,
        address,
        worker_list,
        device_list,
        start_date,
        end_date,
        memo,
        reason,
      } = workInfoData.data;

      const [mAddress, sAddress] = address.split('/');
      const selectedWorker = worker_list.map((worker) => worker.id);

      const selectedDeviceData: DeviceInfo[] = device_list
        .map((device) => device.device_info)
        .flat()
        .map((deviceDetail) => {
          return {
            template_device_id: deviceDetail.template_device_id,
            name: deviceDetail.name,
            total: deviceDetail.total,
            selected: deviceDetail.selected,
            info: deviceDetail.info,
          };
        });

      setFormState({
        name: name,
        mainAddress: mAddress,
        subAddress: sAddress ?? '',
        startDate: new Date(start_date),
        endDate: new Date(end_date),
        memo: memo,
        reason: reason,
      });

      setSelectedWorkerList(selectedWorker);
      setSelectedDeviceList(selectedDeviceData);
    }
  }, [workInfoData]);

  const handleDatePicker = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;

    setFormState((prev) => ({ ...prev, startDate: start }));
    setFormState((prev) => ({ ...prev, endDate: end }));
  };

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleWorkEdit = () => {
    if (!formState.name) {
      toast.error('상호(명칭)을 입력해주세요.', {
        toastId: '상호(명칭)',
      });
      return;
    }
    if (!formState.mainAddress) {
      toast.error('현장주소를 입력해주세요.', {
        toastId: '현장주소',
      });
      return;
    }
    if (selectedDeviceList.length < 1) {
      toast.error('점검 대상 설비를 선택해주세요.', {
        toastId: '점검 대상 설비',
      });
      return;
    }

    for (let i = 0; i < selectedDeviceList.length; i++) {
      const { selected, total, name } = selectedDeviceList[i];

      if (selected > total) {
        toast.error(`${name}의 점검수량은 전체수량보다 클 수 없습니다.`, {
          toastId: TOAST_ID.WORK_COUNT,
        });
        return;
      }
    }

    const { name, memo, mainAddress, subAddress, startDate, endDate, reason } =
      formState;

    const data: PutInspectionAreaRequestProps = {
      inspection_area_id: inspectionAreaId,
      name,
      memo,
      reason,
      address: mainAddress + '/' + subAddress,
      start_date: startDate,
      end_date: endDate ?? startDate,
      worker_list: selectedWorkerList,
      device_list: selectedDeviceList,
    };

    setIsSubmit(true);
    putWorkModifyMutate(data);
  };

  // 작성 중 새로고침, 페이지 이탈 방지 (정보 페이지 제외)
  let isWriting = !!(
    formState.name ||
    formState.mainAddress ||
    selectedWorkerList.length > 0 ||
    selectedDeviceList.length > 0
  );

  useEffect(() => {
    const onBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isWriting) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [isWriting]);

  return (
    <GreyBox title='점검지 수정' width='58.125rem'>
      <Prompt
        when={!isSubmit && isWriting}
        message={`페이지에서 나가시겠습니까?\n변경사항이 저장되지 않을 수 있습니다.`}
      />
      <S.Container>
        <Input
          label='상호(명칭)'
          placeholder='상호(명칭)을 입력해주세요.'
          name='name'
          width='25.625rem'
          height='2.5rem'
          labelFontSize='1.25rem'
          value={formState.name}
          onChange={handleInputChange}
        />
        <S.AddressContainer>
          <Input
            label='현장주소'
            name='mainAddress'
            value={formState.mainAddress}
            width='20rem'
            height='2.5rem'
            labelFontSize='1.25rem'
            placeholder='주소를 검색해주세요.'
            disabled
            onClick={() => setIsDaumPostCodeOn((prev) => !prev)}
            buttonName='주소검색'
            buttonOnclick={() => setIsDaumPostCodeOn((prev) => !prev)}
          />
          {isDaumPostCodeOn && (
            <div>
              <DaumPostcode
                autoClose
                onComplete={(data) =>
                  setFormState((prev) => ({
                    ...prev,
                    mainAddress: data.address,
                  }))
                }
                onClose={() => setIsDaumPostCodeOn(false)}
                style={{ width: 410 }}
              />
            </div>
          )}
          <Input
            placeholder='상세주소를 입력해주세요.'
            name='subAddress'
            width='25.625rem'
            height='2.5rem'
            value={formState.subAddress}
            onChange={handleInputChange}
          />
        </S.AddressContainer>
        <S.LabelContainer>
          <S.LabelWrapper>점검기간</S.LabelWrapper>
          <DatePicker
            locale={ko}
            closeOnScroll
            selected={formState.startDate}
            onChange={handleDatePicker}
            startDate={formState.startDate}
            endDate={formState.endDate}
            selectsRange
            placeholderText='yyyy-MM-dd'
            dateFormat='yyyy-MM-dd'
            monthsShown={2}
          />
        </S.LabelContainer>
        <S.LabelContainer>
          <S.LabelWrapper>비고</S.LabelWrapper>
          <S.StyledTextarea
            value={formState.memo}
            onChange={handleInputChange}
            name='memo'
          />
        </S.LabelContainer>
        <S.LabelContainer>
          <S.LabelWrapper>작업자 선택</S.LabelWrapper>
          <WorkerListTable
            workerData={workerData?.data.workerList ?? []}
            width='100%'
            height='22.625rem'
            selectedWorkerList={selectedWorkerList}
            setSelectedWorkerList={setSelectedWorkerList}
            hasButtons={false}
            isEditPage
          />
        </S.LabelContainer>
        <S.LabelContainer>
          <S.LabelWrapper>점검 대상 설비</S.LabelWrapper>
          <WorkEditCategorySelector
            selectedDeviceList={selectedDeviceList}
            setSelectedDeviceList={setSelectedDeviceList}
          />
          <WorkEditCategoryListTable
            selectedDeviceList={selectedDeviceList}
            setSelectedDeviceList={setSelectedDeviceList}
          />
        </S.LabelContainer>
      </S.Container>
      <Spacer y='6.25rem' />
      <Line />
      <Spacer y='1.875rem' />
      <ButtonWrapper>
        <Button width='9.375rem' height='2.5rem' onClick={handleWorkEdit}>
          수정하기
        </Button>
      </ButtonWrapper>
    </GreyBox>
  );
};

export default WorkEditPage;

const Line = styled.div`
  width: 100%;
  height: 0.0625rem;
  background-color: ${Theme.colors.G_2};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
