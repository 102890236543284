interface Props {
  [name: string]: {
    dataStartArr: number[];
    pageBreakArr: number[];
    // template_id는 측정값 id, 배열의 숫자 [row, col]
    // row는 dataStartArr에서 엑셀의 측정값 입력 부분 row 뺀 값
    // 나중에 dataStartArr만 바뀌어도 자동으로 바뀌게 하기 위해서 그렇게 함
    calculateInfoArr?: {
      [template_id: number]: number[];
    }[];
  };
}

// 24.03.26 엑셀 사진 6개 부분
export const rowStartNumTemplateImg6: Props = {
  냉각탑: {
    dataStartArr: [49, 79, 107, 134, 160, 195, 220, 249, 280, 305],
    pageBreakArr: [40, 66, 96, 124, 151, 177, 212, 241, 270, 297],
    calculateInfoArr: [
      {
        0: [20, 1],
        3: [20, 4],
        4: [20, 7],
        5: [20, 10],
      },
      {
        0: [20, 1],
        6: [20, 4],
        7: [20, 7],
        8: [20, 10],
      },
      {
        0: [20, 1],
        9: [20, 4],
        10: [20, 7],
        11: [20, 10],
      },
    ],
  },
  냉동기: {
    dataStartArr: [
      53, 78, 103, 128, 153, 196, 220, 257, 282, 307, 332, 359, 386, 413, 451,
    ],
    pageBreakArr: [
      44, 70, 95, 120, 145, 170, 213, 237, 274, 299, 324, 349, 376, 403, 430,
    ],
    calculateInfoArr: [
      {
        0: [20, 1],
        1: [20, 5],
        2: [20, 9],
      },
    ],
  },
  축열조: {
    dataStartArr: [45, 70, 98, 123, 148, 176, 203],
    pageBreakArr: [36, 62, 87, 115, 140, 169, 193],
    calculateInfoArr: [
      {
        0: [20, 1],
        12: [20, 5],
        13: [20, 9],
      },
    ],
  },
  보일러: {
    dataStartArr: [49, 77, 104, 136, 163, 189, 218, 261, 289, 316, 343],
    pageBreakArr: [40, 66, 94, 121, 153, 180, 206, 235, 278, 306, 333],
  },
  열교환기: {
    dataStartArr: [44, 72, 103, 134, 159, 185],
    pageBreakArr: [35, 61, 89, 126, 151, 176],
    calculateInfoArr: [
      {
        14: [20, 4],
        15: [20, 7],
        16: [20, 10],
        17: [22, 4],
        18: [22, 7],
        19: [22, 10],
      },
    ],
  },
  팽창탱크: {
    dataStartArr: [42, 67, 92, 125],
    pageBreakArr: [33, 59, 84, 113],
    calculateInfoArr: [
      {
        0: [20, 1],
        20: [20, 5],
        21: [20, 9],
      },
    ],
  },
  '펌프(냉·난방)': {
    dataStartArr: [46, 72, 115, 148, 174, 200, 231],
    pageBreakArr: [37, 63, 89, 132, 165, 191, 221],
    calculateInfoArr: [
      {
        0: [20, 1],
        22: [20, 5],
        23: [20, 9],
      },
      {
        24: [20, 4],
        25: [20, 7],
        26: [20, 10],
        27: [22, 4],
        28: [22, 7],
        29: [22, 10],
        30: [24, 4],
        31: [24, 7],
        32: [24, 10],
      },
    ],
  },
  '신재생(지열)': {
    dataStartArr: [40, 68, 94],
    pageBreakArr: [31, 57, 85],
  },
  '신재생(태양열)': {
    dataStartArr: [40, 68, 93],
    pageBreakArr: [31, 57, 85],
  },
  '신재생(연료전지)': {
    dataStartArr: [40, 68, 93],
    pageBreakArr: [31, 57, 85],
  },
  '신재생(대기열)': {
    dataStartArr: [40, 68, 94],
    pageBreakArr: [31, 57, 85],
  },
  '패키지 에어컨': {
    dataStartArr: [44, 69, 95, 120, 145],
    pageBreakArr: [35, 61, 86, 112, 137],
  },
  항온항습기: {
    dataStartArr: [43, 68, 93, 118, 143],
    pageBreakArr: [34, 60, 85, 110, 135],
  },
  공기조화기: {
    dataStartArr: [46, 72, 98, 123, 148, 191, 216, 242],
    pageBreakArr: [37, 63, 89, 115, 140, 181, 208, 233],
    calculateInfoArr: [
      {
        33: [20, 4],
        34: [20, 7],
        35: [20, 10],
        36: [22, 4],
        37: [22, 7],
        38: [22, 10],
        39: [24, 4],
        40: [24, 7],
        41: [24, 10],
        42: [28, 4],
        43: [28, 7],
        44: [28, 10],
        45: [30, 4],
        46: [30, 7],
        47: [30, 10],
        48: [32, 4],
        49: [32, 7],
        50: [32, 10],
      },
      {
        0: [20, 1],
        51: [20, 5],
        52: [20, 9],
      },
    ],
  },
  팬코일유닛: {
    dataStartArr: [45, 73, 98, 123, 148, 174],
    pageBreakArr: [36, 62, 90, 115, 140, 165],
  },
  환기설비: {
    dataStartArr: [46, 75, 100, 126, 152, 178, 211],
    pageBreakArr: [37, 63, 92, 117, 143, 169, 203],
    calculateInfoArr: [
      {
        53: [20, 4],
        54: [20, 7],
        55: [20, 10],
        56: [22, 4],
        57: [22, 7],
        58: [22, 10],
        59: [24, 4],
        60: [24, 7],
        61: [24, 10],
      },
    ],
  },
  필터: {
    dataStartArr: [41, 66, 96],
    pageBreakArr: [32, 58, 87],
    calculateInfoArr: [
      {
        0: [20, 1],
        62: [20, 5],
        63: [20, 9],
      },
    ],
  },
  위생기구설비: {
    dataStartArr: [41, 69, 100, 125],
    pageBreakArr: [32, 58, 92, 117],
    calculateInfoArr: [
      {
        64: [20, 5],
        65: [20, 9],
        66: [22, 5],
        67: [22, 9],
      },
    ],
  },
  '급수·급탕설비': {
    dataStartArr: [41, 68, 93, 119],
    pageBreakArr: [32, 58, 85, 110],
  },
  '고·저수조': {
    dataStartArr: [41, 67, 93, 130],
    pageBreakArr: [32, 58, 84, 110],
  },
  '오·배수 통기 및 우수배수설비': {
    dataStartArr: [42, 70, 95, 121, 146],
    pageBreakArr: [33, 59, 87, 112, 138],
  },
  오수정화설비: {
    dataStartArr: [41, 68, 93, 118],
    pageBreakArr: [32, 58, 85, 110],
  },
  '물 재이용설비': {
    dataStartArr: [43, 68, 93, 119, 144, 168],
    pageBreakArr: [34, 60, 85, 110, 136, 161],
  },
  배관설비: {
    dataStartArr: [41, 67, 110, 136],
    pageBreakArr: [32, 58, 84, 127],
  },
  덕트설비: {
    dataStartArr: [40, 68, 96],
    pageBreakArr: [31, 57, 85],
  },
  보온설비: {
    dataStartArr: [42, 67, 92, 118, 146],
    pageBreakArr: [33, 59, 84, 109, 135],
  },
  자동제어설비: {
    dataStartArr: [45, 70, 95, 120, 146, 172, 198],
    pageBreakArr: [36, 62, 87, 112, 137, 163, 189],
  },
  '방음·방진·내진설비': {
    dataStartArr: [40, 70, 98],
    pageBreakArr: [31, 57, 87],
  },
};

// 24.03.26 엑셀 사진 3개 부분
export const rowStartNumTemplateImg3: Props = {
  냉각탑: {
    dataStartArr: [49, 71, 91, 110, 128, 155, 172, 193, 216, 233],
    pageBreakArr: [40, 80, 119, 137, 164, 206],
    calculateInfoArr: [
      {
        0: [12, 1],
        3: [12, 4],
        4: [12, 7],
        5: [12, 10],
      },
      {
        0: [12, 1],
        6: [12, 4],
        7: [12, 7],
        8: [12, 10],
      },
      {
        0: [12, 1],
        9: [12, 4],
        10: [12, 7],
        11: [12, 10],
      },
    ],
  },
  냉동기: {
    dataStartArr: [
      53, 70, 87, 104, 121, 162, 178, 207, 224, 241, 258, 277, 296, 315, 351,
    ],
    pageBreakArr: [44, 79, 113, 130, 171, 187, 216, 250, 286, 324],
    calculateInfoArr: [
      {
        0: [12, 1],
        1: [12, 5],
        2: [12, 9],
      },
    ],
  },
  축열조: {
    dataStartArr: [45, 62, 82, 99, 116, 136, 155],
    pageBreakArr: [36, 71, 108, 145],
    calculateInfoArr: [
      {
        0: [12, 1],
        12: [12, 5],
        13: [12, 9],
      },
    ],
  },

  보일러: {
    dataStartArr: [49, 69, 88, 112, 131, 149, 170, 208, 228, 247, 266],
    pageBreakArr: [40, 78, 121, 158, 179, 217, 256],
  },
  열교환기: {
    dataStartArr: [44, 64, 87, 110, 127, 145],
    pageBreakArr: [35, 73, 102, 136],
    calculateInfoArr: [
      {
        14: [12, 4],
        15: [12, 7],
        16: [12, 10],
        17: [14, 4],
        18: [14, 7],
        19: [14, 10],
      },
    ],
  },
  팽창탱크: {
    dataStartArr: [42, 59, 76, 101],
    pageBreakArr: [33, 68],
    calculateInfoArr: [
      {
        0: [12, 1],
        20: [12, 5],
        21: [12, 9],
      },
    ],
  },
  '펌프(냉·난방)': {
    dataStartArr: [46, 64, 107, 132, 150, 168, 191],
    pageBreakArr: [37, 73, 116, 159, 181],
    calculateInfoArr: [
      {
        0: [12, 1],
        22: [12, 5],
        23: [12, 9],
      },
      {
        24: [12, 4],
        25: [12, 7],
        26: [12, 10],
        27: [14, 4],
        28: [14, 7],
        29: [14, 10],
        30: [16, 4],
        31: [16, 7],
        32: [16, 10],
      },
    ],
  },
  '신재생(지열)': {
    dataStartArr: [40, 60, 78],
    pageBreakArr: [31, 69],
  },
  '신재생(태양열)': {
    dataStartArr: [40, 60, 77],
    pageBreakArr: [31, 69],
  },
  '신재생(연료전지)': {
    dataStartArr: [40, 60, 77],
    pageBreakArr: [31, 69],
  },
  '신재생(대기열)': {
    dataStartArr: [40, 60, 78],
    pageBreakArr: [31, 69],
  },
  '패키지 에어컨': {
    dataStartArr: [44, 61, 79, 96, 113],
    pageBreakArr: [35, 70, 105],
  },
  항온항습기: {
    dataStartArr: [43, 60, 77, 94, 111],
    pageBreakArr: [34, 69, 103],
  },
  공기조화기: {
    dataStartArr: [46, 64, 82, 99, 116, 151, 168, 186],
    pageBreakArr: [37, 73, 108, 141, 177],
    calculateInfoArr: [
      {
        33: [12, 4],
        34: [12, 7],
        35: [12, 10],
        36: [14, 4],
        37: [14, 7],
        38: [14, 10],
        39: [16, 4],
        40: [16, 7],
        41: [16, 10],
        42: [20, 4],
        43: [20, 7],
        44: [20, 10],
        45: [22, 4],
        46: [22, 7],
        47: [22, 10],
        48: [24, 4],
        49: [24, 7],
        50: [24, 10],
      },
      {
        0: [12, 1],
        51: [12, 5],
        52: [12, 9],
      },
    ],
  },
  팬코일유닛: {
    dataStartArr: [45, 65, 82, 99, 116, 134],
    pageBreakArr: [36, 74, 108],
  },
  환기설비: {
    dataStartArr: [46, 67, 84, 102, 120, 138, 163],
    pageBreakArr: [37, 76, 111, 129, 155],
    calculateInfoArr: [
      {
        53: [12, 4],
        54: [12, 7],
        55: [12, 10],
        56: [14, 4],
        57: [14, 7],
        58: [14, 10],
        59: [16, 4],
        60: [16, 7],
        61: [16, 10],
      },
    ],
  },
  필터: {
    dataStartArr: [41, 58, 80],
    pageBreakArr: [32, 71],
    calculateInfoArr: [
      {
        0: [12, 1],
        62: [12, 5],
        63: [12, 9],
      },
    ],
  },
  위생기구설비: {
    dataStartArr: [41, 61, 84, 101],
    pageBreakArr: [32, 50, 76],
    calculateInfoArr: [
      {
        64: [12, 5],
        65: [12, 9],
        66: [14, 5],
        67: [14, 9],
      },
    ],
  },
  '급수·급탕설비': {
    dataStartArr: [41, 60, 77, 95],
    pageBreakArr: [32, 69],
  },
  '고·저수조': {
    dataStartArr: [41, 59, 77, 106],
    pageBreakArr: [32, 68, 86],
  },
  '오·배수 통기 및 우수배수설비': {
    dataStartArr: [42, 62, 79, 97, 114],
    pageBreakArr: [33, 71, 106],
  },
  오수정화설비: {
    dataStartArr: [41, 60, 77, 94],
    pageBreakArr: [32, 69],
  },
  '물 재이용설비': {
    dataStartArr: [43, 60, 77, 95, 112, 128],
    pageBreakArr: [34, 69, 104],
  },
  배관설비: {
    dataStartArr: [41, 59, 101, 119],
    pageBreakArr: [32, 68, 110],
  },
  덕트설비: {
    dataStartArr: [40, 60, 80],
    pageBreakArr: [31, 69],
  },
  보온설비: {
    dataStartArr: [42, 59, 76, 94, 114],
    pageBreakArr: [33, 68, 103],
  },
  자동제어설비: {
    dataStartArr: [45, 62, 79, 96, 114, 132, 150],
    pageBreakArr: [36, 71, 105, 141],
  },
  '방음·방진·내진설비': {
    dataStartArr: [40, 62, 82],
    pageBreakArr: [31, 71],
  },
};
