import type { WorkerPaymentInfo } from '@/types/company.types';
import { create } from 'zustand';

interface WorkerPaymentListStore {
  workerPaymentList: WorkerPaymentInfo[];
  setWorkerPaymentList: (list: WorkerPaymentInfo[]) => void;
  addWorkerPayment: (worker: WorkerPaymentInfo) => void;
}

export const useWorkerPaymentListStore = create<WorkerPaymentListStore>(
  (set) => ({
    workerPaymentList: [],
    setWorkerPaymentList: (list) => set({ workerPaymentList: list }),
    addWorkerPayment: (workerPayment) =>
      set((state) => ({
        workerPaymentList: [...state.workerPaymentList, workerPayment],
      })),
  })
);
