import { Box } from '../style';

import * as styled from '../style';
import type { PaymentInfo } from '@/types/company.types';

import WorkerPaymentInfoHeader from './WorkerPaymentInfoHeader';
import WorkerPaymentInfoTable from './WorkerPaymentInfoTable';
import useWorkerPaymentInfoTable from '@/hooks/useWorkerPaymentInfoTable';
import { useEffect } from 'react';
import { useSelectedWorkerListStore } from '@/store/selectedWorkerList';
import { useAddWorkerListStore } from '@/store/addWorkerList';
import { useWorkerPaymentListStore } from '@/store/paymentWorkerList';
import Prompt from '@/components/common/Prompt';
import useSubscribeStore from '@/store/subscribeMode';

interface Props {
  paymentData: PaymentInfo;
}

const WorkerPaymentInfoBox = ({ paymentData }: Props) => {
  const { workerList, activeWorkerCount, isApproved, isTrial } = paymentData;
  const { isSubscribeMode, setIsSubscribeMode } = useSubscribeStore();

  useEffect(() => {
    if (isApproved === 0) setIsSubscribeMode(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isApproved]);

  const {
    workerPaymentReactTable: { getHeaderGroups, getRowModel },
  } = useWorkerPaymentInfoTable({
    workerList: workerList,
    isApproved: isApproved,
    isTrial: isTrial,
  });

  const {
    resetSelectedAddWorkerList,
    resetSelectedRestoreWorkerList,
    resetSelectedUnsubscribeWorkerList,
    resetSelectedUndoUnsubscribeWorkerList,
  } = useSelectedWorkerListStore();

  const { addWorkerList, resetAddWorkerList } = useAddWorkerListStore();
  const { setWorkerPaymentList } = useWorkerPaymentListStore();

  useEffect(() => {
    resetAddWorkerList();
    setWorkerPaymentList(workerList);
    resetSelectedAddWorkerList();
  }, [
    isSubscribeMode,
    resetAddWorkerList,
    resetSelectedAddWorkerList,
    setWorkerPaymentList,
    workerList,
  ]);

  useEffect(() => {
    const onBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isSubscribeMode && addWorkerList.length > 0) {
        event.preventDefault();
      }
    };

    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [addWorkerList.length, isSubscribeMode]);

  useEffect(() => {
    return () => {
      resetSelectedAddWorkerList();
      resetSelectedRestoreWorkerList();
      resetSelectedUnsubscribeWorkerList();
      resetSelectedUndoUnsubscribeWorkerList();
    };
  }, [
    resetSelectedAddWorkerList,
    resetSelectedRestoreWorkerList,
    resetSelectedUndoUnsubscribeWorkerList,
    resetSelectedUnsubscribeWorkerList,
  ]);

  return (
    <styled.BoxWrapper>
      <Prompt
        when={isSubscribeMode && addWorkerList.length > 0}
        message={`페이지에서 나가시겠습니까?\n추가한 사용자가 사라집니다.`}
      />
      {isApproved === 1 && (
        <styled.SubTitleWrapper>
          <styled.SubTitle>작업자별 사용 현황 및 변경</styled.SubTitle>
          {/* 서지수 TODO <PaymentHistoryBtn /> */}
        </styled.SubTitleWrapper>
      )}
      {paymentData && (
        <Box>
          <WorkerPaymentInfoHeader
            activeWorkerCount={activeWorkerCount}
            allWorkerCount={workerList.length}
            isTrial={paymentData.isTrial}
            isApproved={isApproved}
          />
          <WorkerPaymentInfoTable
            getHeaderGroups={getHeaderGroups}
            getRowModel={getRowModel}
          />
        </Box>
      )}
    </styled.BoxWrapper>
  );
};

export default WorkerPaymentInfoBox;
