import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Props } from '@/components/worker/WorkerListTable';

import { Theme } from '@/styles/Theme';

export const Container = styled.div<{ width: string }>`
  width: ${({ width }) => width};

  display: flex;
  flex-direction: column;
  gap: 0.625rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TableWrapper = styled.div<Pick<Props, 'width' | 'height'>>`
  position: relative;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  overflow-y: auto;

  border: 0.0625rem solid ${Theme.colors.G_3};
  background-color: ${Theme.colors.WHITE};
`;

export const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: separate;

  thead {
    position: sticky;
    top: 0;
    z-index: 10;

    height: 3.75rem;
    background-color: ${Theme.colors.WHITE};

    font-size: 1rem;
    font-weight: bold;
    color: ${Theme.colors.BLACK};

    tr {
      th {
        border-bottom: 0.125rem solid ${Theme.colors.G_5};
      }
    }
  }

  tbody {
    tr {
      height: 3.75rem;

      background-color: ${Theme.colors.WHITE};

      :hover {
        background-color: ${Theme.colors.G_7};
      }
    }
    td {
      border-bottom: 0.0625rem solid ${Theme.colors.G_5};

      text-align: center;
      vertical-align: middle;

      svg {
        display: inline-block;
      }
    }
  }
`;

export const NoDataText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  text-align: center;
`;

export const StyledTr = styled.tr<{ isActive: boolean }>`
  ${({ isActive }) =>
    isActive &&
    css`
      td {
        color: ${Theme.colors.G_3};
      }
    `}
`;
