import { Theme } from '@/styles/Theme';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

export const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const EditContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
`;

export const Table = styled.table`
  max-width: 56.25rem;
  width: 100%;

  border: 0.0625rem solid ${Theme.colors.BLACK};

  background-color: ${Theme.colors.WHITE};

  text-align: center;

  table-layout: fixed;

  td {
    border: 0.0625rem solid ${Theme.colors.BLACK};

    li {
      list-style-type: none;
      text-indent: 0.625rem;

      &::before {
        content: '•';
        font-size: 108%;
        padding-right: 0.3125rem;
      }
    }
  }

  tr {
    height: 2.5rem;
  }

  tr:first-of-type {
    background-color: ${Theme.colors.G_4};
    font-weight: bold;
  }
`;

export const NumberWrapper = styled.div`
  width: 1.5rem;
  height: 1.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 0.0625rem solid ${Theme.colors.BLACK};
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
`;

export const StyledInput = styled.input<{ height: string }>`
  display: block;

  flex: 1;

  width: 100%;
  height: ${({ height }) => height};
  background-color: rgb(255, 248, 226, 0.7);

  border: none;

  padding: 0.4375rem 0;

  color: ${Theme.colors.BLACK};
  font-size: 1rem;
  font-weight: 400;
  resize: none;

  :disabled {
    pointer-events: none;
    background-color: ${Theme.colors.WHITE};
  }

  ::placeholder {
    color: ${Theme.colors.G_2};
  }

  :focus {
    outline: none;
  }
`;

export const ImgContainer = styled.div`
  position: relative;

  /* width: 18.75rem; */
  height: 18.75rem;
`;

export const ImgWrapper = styled.img`
  display: block;

  width: 100%;
  height: 100%;
`;

export const ImgRemoveIconWrapper = styled.div`
  position: absolute;
  top: 0.3125rem;
  right: 0.3125rem;

  border-radius: 50%;

  background-color: ${Theme.colors.WHITE};
  color: ${Theme.colors.WARNING_RED};

  cursor: pointer;
`;

export const SelectContainer = styled.div`
  max-width: 56.25rem;
  width: 100%;

  display: flex;
  align-items: center;
  border: 0.0625rem solid ${Theme.colors.BLACK};

  div + div {
    border-left: 0.0625rem solid black;
  }
`;

export const Selector = styled.div<{
  isSelected: boolean;
  backgroundColor: string;
}>`
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  gap: 0.625rem;

  width: 18.75rem;
  height: 3.125rem;

  ${({ isSelected, backgroundColor }) =>
    isSelected &&
    css`
      background-color: ${backgroundColor};
      color: ${Theme.colors.WHITE};
    `}
`;
