import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Theme } from '@/styles/Theme';

export const Container = styled.div`
  width: 100%;

  background-color: ${Theme.colors.WHITE};

  border: 0.0625rem solid ${Theme.colors.G_3};

  margin-bottom: 1.25rem;
`;

export const SearchContainer = styled.div`
  width: 100%;
  height: 3.5rem;
  padding: 0 0.8125rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 0.125rem solid ${Theme.colors.G_5};
`;

export const CategoryContainer = styled.div`
  display: flex;

  height: 17.5rem;
`;

export const PrimaryCategoryContainer = styled.div`
  width: 35%;

  display: flex;
  flex-direction: column;

  overflow: auto;

  border-right: 0.125rem solid ${Theme.colors.G_5};
`;

export const SubCategoryContainer = styled.div`
  width: 65%;
  padding: 1.25rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(5, 1fr);
  justify-items: start;
  gap: 0.9375rem;
  overflow: auto;
`;

export const PrimaryCategoryItemContainer = styled.div<{ isActive: boolean }>`
  cursor: pointer;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 1.25rem;

  height: 2.9188rem;

  color: ${({ isActive }) => (isActive ? Theme.colors.B_3 : Theme.colors.G_1)};
  background-color: ${({ isActive }) =>
    isActive ? 'rgba(221, 239, 255, 0.5)' : Theme.colors.WHITE};

  :hover {
    background-color: rgba(221, 239, 255, 0.5);

    color: ${Theme.colors.B_3};
  }
`;

export const SubCategoryWrapper = styled.div<{ isSelected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3.125rem;

  cursor: pointer;

  text-align: center;
  vertical-align: middle;
  height: 2.5rem;
  padding: 0.625rem 1.25rem;

  ${({ isSelected }) =>
    isSelected &&
    css`
      font-weight: 700;
      border: 0.0625rem solid ${Theme.colors.B_4};
      color: ${Theme.colors.B_3};
    `}

  :hover {
    font-weight: 700;
  }
`;
