import { useState, useEffect } from 'react';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { MdOutlineCancel } from 'react-icons/md';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AiOutlineClose, AiOutlineEdit } from 'react-icons/ai';

import type { ErrorResponse } from '@/types/client.types';
import { putCompanyInfo, putWorkerEditAuth } from '@/apis/company';
import type { PutCompanyInfoRequestProps } from '@/types/company.types';

import useCompanyInfo from '@/hooks/useCompanyInfo';

import { QUERY_KEY } from '@/constants/queryKey';

import Input from '@/components/common/Input';
import Text from '@/components/common/Text';
import Button from '@/components/common/Button';
import Spacer from '@/components/common/Spacer';
import {
  ButtonWrapper,
  DotsWrapper,
} from '@/components/work/WorkReportResultTable/style';

import * as S from './style';

import { Theme } from '@/styles/Theme';
import uploadImagePresignedUrl from '@/utils/uploadImagePresignedUrl';

const CompanyProfile = () => {
  const { data: companyInfo } = useCompanyInfo();
  const [isDetailPage, setIsDetailPage] = useState<boolean>(true);

  const [companyRequestInfo, setCompanyRequestInfo] =
    useState<PutCompanyInfoRequestProps>({
      image: [],
      representativeName: '',
      registrationNumber: '',
    });

  const queryClient = useQueryClient();

  useEffect(() => {
    if (companyInfo) {
      const { image, representativeName, registrationNumber } =
        companyInfo.data;

      setCompanyRequestInfo({
        image,
        representativeName,
        registrationNumber,
      });
    }
  }, [companyInfo]);

  const { mutate: putCompanyInfoMutate } = useMutation({
    mutationFn: putCompanyInfo,
    onSuccess: (data) => {
      toast.success('정보가 변경되었습니다.');
      queryClient.invalidateQueries([QUERY_KEY.COMPANY_INFO]);
      setIsDetailPage(true);
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      toast.error(error.response?.data.message);
    },
  });

  const { mutate: workerAuthMutate } = useMutation({
    mutationFn: putWorkerEditAuth,
    onSuccess: (data) => {
      queryClient.invalidateQueries([QUERY_KEY.COMPANY_INFO]);
      toast.success('작업자 권한이 변경되었습니다.');
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      toast.error(error.response?.data.message);
    },
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCompanyRequestInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImgChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const uploadedUrl = await uploadImagePresignedUrl(e.target.files);
      if (uploadedUrl) {
        setCompanyRequestInfo((prev) => ({ ...prev, image: [uploadedUrl] }));
      } else {
        toast.error('이미지 업로드에 실패했습니다');
      }
    } catch (error) {
      toast.error('이미지 업로드 중 에러가 발생했습니다');
      console.error('Error during image upload:', error);
    }
  };

  const handleRemoveImg = () => {
    setCompanyRequestInfo((prev) => ({ ...prev, image: [] }));
  };

  const handleCancel = () => {
    if (companyInfo) {
      const { image, representativeName, registrationNumber } =
        companyInfo.data;

      setCompanyRequestInfo({
        image,
        representativeName,
        registrationNumber,
      });

      setIsDetailPage(true);
    }
  };

  const handleSubmit = () => {
    putCompanyInfoMutate(companyRequestInfo);
  };

  return (
    <>
      {isDetailPage ? (
        <DotsWrapper>
          <Button height='2.1875rem' onClick={() => setIsDetailPage(false)}>
            <AiOutlineEdit size='1.25rem' />
            수정하기
          </Button>
        </DotsWrapper>
      ) : (
        <DotsWrapper>
          <Button
            height='2.1875rem'
            backgroundColor={Theme.colors.G_2}
            onClick={handleCancel}
          >
            <AiOutlineClose size='1.25rem' />
            취소하기
          </Button>
        </DotsWrapper>
      )}
      <S.Container>
        <Text fontSize='1.25rem' fontWeight={700}>
          기본 정보
        </Text>
        <S.ProfileImageContainer>
          <S.FileInput
            type='file'
            id='img_input_profile'
            accept='.jpg, .jpeg, .png'
            onChange={handleImgChange}
          />
          <S.ImgContainer>
            <S.ImgWrapper src={companyRequestInfo.image[0] ?? '/company.svg'} />
            {!isDetailPage && companyRequestInfo.image[0] && (
              <S.ImgRemoveIconWrapper onClick={handleRemoveImg}>
                <MdOutlineCancel size='1.5rem' />
              </S.ImgRemoveIconWrapper>
            )}
          </S.ImgContainer>
          {!isDetailPage && (
            <S.FileLabel htmlFor='img_input_profile'>사진변경</S.FileLabel>
          )}
        </S.ProfileImageContainer>
        <Input
          type='text'
          name='companyName'
          label='회사/기관명'
          width='100%'
          height='2.1875rem'
          labelFontSize='1rem'
          value={companyInfo?.data.companyName}
          disabled
        />
        <Input
          type='text'
          name='email'
          label='이메일'
          width='100%'
          height='2.1875rem'
          labelFontSize='1rem'
          value={companyInfo?.data.email}
          disabled
        />
        <Input
          type='text'
          name='phone'
          label='연락처'
          width='100%'
          height='2.1875rem'
          labelFontSize='1rem'
          value={companyInfo?.data.phone}
          disabled
        />
        <Input
          type='text'
          name='representativeName'
          label='대표자명'
          width='100%'
          height='2.1875rem'
          labelFontSize='1rem'
          value={companyRequestInfo.representativeName}
          disabled={isDetailPage}
          onChange={handleInputChange}
        />
        <Input
          type='text'
          name='registrationNumber'
          label='성능점검업(사업자) 등록번호'
          width='100%'
          height='2.1875rem'
          labelFontSize='1rem'
          value={companyRequestInfo.registrationNumber}
          disabled={isDetailPage}
          onChange={handleInputChange}
        />
        <S.GreyLine />
        <Text fontSize='1.25rem' fontWeight={700}>
          기타 설정
        </Text>
        <S.ButtonContainer>
          <Text fontWeight={500}>작업자가 모든 점검지 수정 가능</Text>
          <S.ToggleWrapper
            isDetailPage={isDetailPage}
            onClick={() => workerAuthMutate(!!!companyInfo?.data.isEditAll)}
            isActive={!!companyInfo?.data.isEditAll}
          >
            <div />
          </S.ToggleWrapper>
        </S.ButtonContainer>
        {!isDetailPage && (
          <>
            <Spacer y='1.25rem' />
            <ButtonWrapper>
              <Button
                width='9.375rem'
                height='2.5rem'
                backgroundColor={Theme.colors.G_2}
                onClick={handleCancel}
              >
                취소하기
              </Button>
              <Button width='9.375rem' height='2.5rem' onClick={handleSubmit}>
                수정하기
              </Button>
            </ButtonWrapper>
          </>
        )}
      </S.Container>
    </>
  );
};
export default CompanyProfile;
