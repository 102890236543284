import type { ErrorResponse } from '@/types/client.types';
import { postPresignedUrlImg } from '@/apis/img';
import { useLoadingStore } from '@/store/loading';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';

const { setLoading, beginRequest, endRequest } = useLoadingStore.getState();

const uploadToS3 = async (url: string, file: File) => {
  await axios({
    method: 'put',
    url: url,
    data: file,
    timeout: 120000,
  });
};

const uploadImagePresignedUrl = async (imgList: FileList | null) => {
  try {
    beginRequest();
    if (imgList && imgList.length > 0) {
      const imgName = imgList[0].name;

      const { data } = await postPresignedUrlImg({ files: [imgName] });
      const url = data[0];

      await uploadToS3(url, imgList[0]);
      return url.split('?')[0];
    }
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      const axiosError: AxiosError<ErrorResponse> = error;
      toast.error(axiosError.response?.data.message);
    } else {
      console.log(error);
    }
  } finally {
    endRequest();
    setLoading(false);
  }
};

export default uploadImagePresignedUrl;
