import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import Modal from '@/components/common/Modal';
import Input from '@/components/common/Input';
import Text from '@/components/common/Text';
import {
  postFacilityMaintenanceOrganization,
  putFacilityMaintenanceOrganization,
} from '@/apis/report/building';
import type { FacilityMaintenanceOrganizationItem } from '@/types/report/building.types';

import type { ErrorResponse } from '@/types/client.types';
import { QUERY_KEY } from '@/constants/queryKey';

import * as S from '../commonStyles';
interface Props {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  inspectionAreaId: number;
  orgWorker: FacilityMaintenanceOrganizationItem | null;
}

const MaintenanceOrganizationAddModal = ({
  inspectionAreaId,
  setIsModalOpen,
  orgWorker,
}: Props) => {
  const [name, setName] = useState<string>(orgWorker ? orgWorker.name : '');
  const [seniorityDate, setSeniorityDate] = useState<string>(
    orgWorker ? orgWorker.seniorityDate : ''
  );
  const [position, setPosition] = useState<string>(
    orgWorker ? orgWorker.position : ''
  );
  const [note, setNote] = useState<string>(orgWorker ? orgWorker.note : '');

  const queryClient = useQueryClient();

  const { mutate: organizationMutate } = useMutation({
    mutationFn: postFacilityMaintenanceOrganization,
    onSuccess: (data) => {
      queryClient.invalidateQueries([
        QUERY_KEY.REPORT.BUILDING.ORGANIZATION,
        inspectionAreaId,
      ]);
      setIsModalOpen(false);
      toast.success('유지관리 조직 정보가 등록되었습니다.');
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      toast.error(error.response?.data.message);
    },
  });

  const { mutate: updateOrganization } = useMutation({
    mutationFn: putFacilityMaintenanceOrganization,
    onSuccess: (data) => {
      queryClient.invalidateQueries([
        QUERY_KEY.REPORT.BUILDING.ORGANIZATION,
        inspectionAreaId,
      ]);
      setIsModalOpen(false);
      toast.success('유지관리 조직 정보가 변경되었습니다.');
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      toast.error(error.response?.data.message);
    },
  });

  const handleConfirmData = () => {
    if (name === '') {
      toast.error('등록 정보를 입력해주세요.');
      return;
    }
    if (orgWorker === null) {
      organizationMutate({
        inspectionAreaId,
        name,
        seniorityDate,
        position,
        note,
      });
    } else {
      updateOrganization({
        id: orgWorker.id,
        name,
        seniorityDate,
        position,
        note,
      });
    }
  };

  return (
    <Modal
      setIsModalOpen={setIsModalOpen}
      onClickConfirm={handleConfirmData}
      width='31.25rem'
      confirmText={orgWorker ? '수정' : '확인'}
    >
      <S.Container>
        <S.InputWrapper>
          <Text> 직책 </Text>
          <Input
            name='직책'
            width='100%'
            height='2.5rem'
            onChange={(e) => {
              setPosition(e.target.value);
            }}
            value={position}
          ></Input>
        </S.InputWrapper>
        <S.InputWrapper>
          <Text> 성명 </Text>
          <Input
            name='성명'
            width='100%'
            height='2.5rem'
            onChange={(e) => {
              setName(e.target.value);
            }}
            value={name}
          ></Input>
        </S.InputWrapper>

        <S.InputWrapper>
          <Text> 법정선임 </Text>
          <Input
            name='법정선임'
            width='100%'
            height='2.5rem'
            onChange={(e) => {
              setSeniorityDate(e.target.value);
            }}
            value={seniorityDate}
          ></Input>
        </S.InputWrapper>

        <S.InputWrapper>
          <Text> 비고 </Text>
          <Input
            name='비고'
            width='100%'
            height='2.5rem'
            onChange={(e) => {
              setNote(e.target.value);
            }}
            value={note}
          ></Input>
        </S.InputWrapper>
      </S.Container>
    </Modal>
  );
};

export default MaintenanceOrganizationAddModal;
