import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useCompanyInfo from '@/hooks/useCompanyInfo';

import { logout } from '@/utils/logout';

import { ROUTER } from '@/constants/constants';

import Box from '@/components/common/Box';
import CardRegisterInfo from '@/components/payment/CardRegisterInfo';
import Footer from '@/components/common/Footer';

import { PageCenter } from '@/styles/common';
import usePaymentInfo from '@/hooks/usePaymentInfo';

const CardRegisterPage = () => {
  const { data: companyInfo } = useCompanyInfo();
  const { data: paymentInfo } = usePaymentInfo();

  const navigate = useNavigate();

  useEffect(() => {
    // 카드 등록과 결제가 된 사람은 페이지 접근 못하게
    if (companyInfo) {
      const {
        data: { isApproved },
      } = companyInfo;
      if (isApproved) {
        navigate(ROUTER.DASHBOARD, { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyInfo]);

  return (
    <>
      {companyInfo && paymentInfo && (
        <>
          <PageCenter>
            <Box
              title={
                companyInfo.data.isTrial ? '무료체험 시작하기' : '작업자 결제'
              }
              onClickBack={logout}
            >
              <CardRegisterInfo
                companyInfoData={companyInfo?.data}
                paymentInfoData={paymentInfo?.data}
              />
            </Box>
          </PageCenter>
          <Footer padding='2.5rem' />
        </>
      )}
    </>
  );
};

export default CardRegisterPage;
