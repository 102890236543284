import styled from '@emotion/styled';
import { Theme } from '@/styles/Theme';
import { TheadTr } from '../WorkerPaymentInfoTableHeader/style';
import type { IsActiveWorker } from '@/types/company.types';

export const Tbody = styled.tbody<{ isApproved: boolean }>`
  display: block;
  max-height: ${({ isApproved }) => (isApproved ? '31.25rem' : '12.5rem')};

  overflow: auto;

  background-color: ${Theme.colors.WHITE};
`;

export const TbodyTr = styled(TheadTr)<IsActiveWorker>`
  :not(:last-child) {
    border-bottom: 0.0625rem solid ${Theme.colors.G_3};
  }
  height: 3.4375rem;

  color: ${({ isActive }) =>
    (isActive === '비활성' || isActive === '생성') && Theme.colors.G_3};

  :hover {
    background-color: ${Theme.colors.G_6};
  }
`;

export const EmptyTr = styled.tr`
  height: 6.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Td = styled.td<{ width: string }>`
  width: ${({ width }) => width};
  padding: 0.625rem 0.3125rem;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
