import { useQuery } from '@tanstack/react-query';

import { QUERY_KEY } from '@/constants/queryKey';
import { getPaymentPrice } from '@/apis/payment';
import type { GetPaymentPriceRequestProps } from '@/types/payment.types';

const usePaymentPrice = ({
  userId = 0,
  newWorkerCount = 0,
  workerCount,
}: GetPaymentPriceRequestProps) => {
  const { data, isLoading, isError } = useQuery(
    [QUERY_KEY.PAYMENT_PRICE, userId, newWorkerCount, workerCount],
    {
      queryFn: () =>
        getPaymentPrice({
          userId,
          newWorkerCount,
          workerCount,
        }),
    }
  );

  if (isLoading || isError) {
    return 0;
  }

  return data.data.price;
};

export default usePaymentPrice;
