import { Theme } from '@/styles/Theme';
import styled from '@emotion/styled';
export const Container = styled.div`
  width: 100%;
  min-height: 42.5rem;
  display: flex;
  gap: 3.125rem;
  padding: 1.25rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;
export const LeftContainer = styled.div`
  width: 100%;
  max-width: 31.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3.125rem;
`;
export const RightContainer = styled.div`
  width: 100%;
  max-width: 31.25rem;
`;
export const BlueBox = styled.div`
  width: 100%;
  max-width: 67.5rem;
  height: 25rem;
  background-color: ${Theme.colors.B_4};
  border-radius: 1.25rem;
`;
export const ImageWrapper = styled.div`
  width: 100%;
  max-width: 67.5rem;
  padding: 1.875rem 1.875rem 0 1.875rem;
  /* background-color: ${Theme.colors.B_2}; */
  background-color: #0a2d65;
  /* border-radius: 1.25rem; */
  box-shadow: 0.1875rem 0.3125rem 0.625rem 0.3125rem rgba(0, 0, 0, 0.3);
`;
export const H1 = styled.h1`
  color: ${Theme.colors.B_3};
  font-size: 3.125rem;
  font-weight: 700;
  line-height: 3.625rem;
`;
