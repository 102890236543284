import { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from '@emotion/styled';
import { MdOutlineCancel } from 'react-icons/md';

import {
  getSignUpTerms,
  postAuthEmail,
  postSendEmail,
  postSignUp,
} from '@/apis/company';
import type {
  PostAuthEmailRequestProps,
  PostSignUpRequestProps,
} from '@/types/company.types';
import type { ErrorResponse } from '@/types/client.types';
import { postImg } from '@/apis/img';

import useTimer from '@/hooks/useTimer';

import {
  INPUT_MESSAGE,
  REGEXP,
  ROUTER,
  SIGNUP_ROUTE,
} from '@/constants/constants';
import { QUERY_KEY } from '@/constants/queryKey';
import { TOAST_ID } from '@/constants/toastId';

import Box from '@/components/common/Box';
import Button from '@/components/common/Button';
import FormInput from '@/components/common/FormInput';
import Text from '@/components/common/Text';
import Input from '@/components/common/Input';

import { StyledForm } from '@/styles/common';
import { Theme } from '@/styles/Theme';

interface SignUpSourceProps {
  label: string;
  value: string;
}

const SignUpModal = () => {
  const {
    control,
    handleSubmit,
    getValues,
    watch,
    setError,
    setValue,
    clearErrors,
  } = useForm<
    PostSignUpRequestProps & Pick<PostAuthEmailRequestProps, 'userCode'>
  >({
    defaultValues: {
      email: '',
      userCode: '',
      company: '',
      phone: '',
      password: '',
      passwordConfirm: '',
    },
  });

  const [isUserCodeOpen, setIsUserCodeOpen] = useState<boolean>(false);
  const [isUserCodeAuthed, setIsUserCodeAuthed] = useState<boolean>(false);

  const [joinToken, setJoinToken] = useState<string>('');

  const [termIds, setTermIds] = useState<number[]>([]);
  const [image, setImage] = useState<string[]>([]);
  const [signUpSource, setSignUpSource] = useState<SignUpSourceProps>({
    label: '',
    value: '',
  });

  const navigate = useNavigate();
  const { time, startTimer, stopTimer } = useTimer(3, () => {
    setError('userCode', { message: '인증코드가 만료되었습니다.' });
  });

  // 인증 이메일 발송
  const { mutate: sendEmailMutate } = useMutation({
    mutationFn: postSendEmail,
    onSuccess: (data) => {
      toast.success('인증 이메일이 발송되었습니다.');
      setIsUserCodeOpen(true);
      setIsUserCodeAuthed(false);

      startTimer();

      setJoinToken(data.data.joinToken);
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      setError('email', { message: error.response?.data.message });
      return;
    },
  });

  // 인증 이메일 인증
  const { mutate: authEmailMutate } = useMutation({
    mutationFn: postAuthEmail,
    onSuccess: (data) => {
      toast.success('이메일이 인증되었습니다.');
      clearErrors(['userCode']);
      setIsUserCodeAuthed(true);

      stopTimer();
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      setError('userCode', { message: error.response?.data.message });
    },
  });

  // 회원가입
  const { mutate: signUpMutate } = useMutation({
    mutationFn: postSignUp,
    onSuccess: (data) => {
      window.scrollTo(0, 0);
      navigate(ROUTER.LOGIN);
      toast.success('회원가입이 완료되었습니다.');
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      if (error.response?.data.statusCode === 409) {
        setError('company', { message: error.response?.data.message });
        return;
      }

      toast.error(error.response?.data.message);
    },
  });

  const { data: termsData } = useQuery({
    queryKey: [QUERY_KEY.TERMS],
    queryFn: getSignUpTerms,
  });

  const handleEmailSend = () => {
    const email = getValues('email');

    if (email.length < 1) {
      setError('email', { message: INPUT_MESSAGE.REQUIRED });
      return;
    }

    if (!REGEXP.EMAIL.test(email)) {
      setError('email', { message: INPUT_MESSAGE.EMAIL.MESSAGE });
      return;
    }
    clearErrors(['email', 'userCode']);
    setValue('userCode', '');
    sendEmailMutate(email);
  };

  const handleUserCode = () => {
    const email = getValues('email');
    const userCode = getValues('userCode');

    if (userCode.length < 1) {
      setError('userCode', { message: INPUT_MESSAGE.USER_CODE.PLACEHOLDER });
      return;
    }

    authEmailMutate({
      email,
      userCode,
      joinToken,
    });
  };

  const handleCheckbox = (id: number) => {
    if (termIds.includes(id)) {
      const filteredArr = termIds.filter((value) => value !== id);
      setTermIds(filteredArr);
      return;
    }
    setTermIds((prev) => [...prev, id]);
  };

  const { mutate: imgMutate } = useMutation({
    mutationFn: postImg,
    onSuccess: (data) => {
      setImage([data.data[0]]);
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      toast.error(error.response?.data.message);
    },
  });

  // 엑세스토큰이 없어서 presigned url 적용 못 함
  const handleImgChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const files = e.target.files;
      const formData = new FormData();

      formData.append('files', files[0]);

      imgMutate(formData);
    }
  };

  const onSubmitHandler: SubmitHandler<PostSignUpRequestProps> = (data) => {
    const { email, company, phone, password, passwordConfirm } = data;

    if (!isUserCodeAuthed) {
      setError('email', { message: '이메일을 인증해주세요.' });
      return;
    }

    if (!signUpSource.value) {
      toast.error('회원가입 경로를 선택해주세요.', {
        toastId: TOAST_ID.SIGN_UP_ROUTE,
      });
      return;
    }

    if (termIds.length !== termsData?.data.terms.length) {
      toast.error('약관동의를 체크해주세요.', {
        toastId: TOAST_ID.SIGN_UP,
      });
      return;
    }

    signUpMutate({
      email,
      company,
      phone,
      password,
      passwordConfirm,
      image,
      signup_source: signUpSource.value,
    });
  };

  return (
    <Box title='회원가입'>
      <StyledForm onSubmit={handleSubmit(onSubmitHandler)}>
        <EmailContainer>
          <FormInput
            control={control}
            rules={{
              required: INPUT_MESSAGE.REQUIRED,
              pattern: {
                value: REGEXP.EMAIL,
                message: INPUT_MESSAGE.EMAIL.MESSAGE,
              },
            }}
            name='email'
            width='20rem'
            height='2.5rem'
            placeholder={INPUT_MESSAGE.EMAIL.PLACEHOLDER}
            label={INPUT_MESSAGE.EMAIL.LABEL}
            disabled={isUserCodeAuthed}
            buttonName={isUserCodeOpen ? '재전송' : '인증하기'}
            buttonOnclick={handleEmailSend}
          />
          {isUserCodeOpen && (
            <FormInput
              control={control}
              rules={{
                required: INPUT_MESSAGE.REQUIRED,
              }}
              name='userCode'
              width='20rem'
              height='2.5rem'
              placeholder={INPUT_MESSAGE.USER_CODE.PLACEHOLDER}
              disabled={isUserCodeAuthed}
              buttonName='확인'
              buttonOnclick={handleUserCode}
              buttonDisabled={isUserCodeAuthed}
              time={time}
            />
          )}
        </EmailContainer>
        <FormInput
          control={control}
          rules={{
            required: INPUT_MESSAGE.REQUIRED,
            minLength: {
              value: 2,
              message: INPUT_MESSAGE.COMPANY.MIN,
            },
          }}
          name='company'
          width='25.625rem'
          height='2.5rem'
          placeholder={INPUT_MESSAGE.COMPANY.PLACEHOLDER}
          label={INPUT_MESSAGE.COMPANY.LABEL}
        />
        <EmailContainer>
          <Text textAlign='left' fontSize='1rem' fontWeight={500}>
            회사/기관명 로고 (선택)
          </Text>
          <ProfileImageContainer>
            <FileInput
              type='file'
              id='img_input_profile'
              accept='.jpg, .jpeg, .png'
              onChange={handleImgChange}
            />
            <ImgContainer>
              <ImgWrapper src={image[0] ?? '/company.svg'} />
              {image[0] && (
                <ImgRemoveIconWrapper onClick={() => setImage([])}>
                  <MdOutlineCancel size='1.5rem' />
                </ImgRemoveIconWrapper>
              )}
            </ImgContainer>
            <FileLabel htmlFor='img_input_profile'>사진변경</FileLabel>
          </ProfileImageContainer>
        </EmailContainer>
        <FormInput
          control={control}
          rules={{
            required: INPUT_MESSAGE.REQUIRED,
            pattern: {
              value: REGEXP.NUMBER,
              message: INPUT_MESSAGE.PHONE.MESSAGE,
            },
          }}
          name='phone'
          width='25.625rem'
          height='2.5rem'
          placeholder={INPUT_MESSAGE.PHONE.PLACEHOLDER}
          label={INPUT_MESSAGE.PHONE.LABEL}
          subscription={INPUT_MESSAGE.PHONE.SUBSCRIPTION}
        />
        <PasswordContainer>
          <FormInput
            control={control}
            rules={{
              required: INPUT_MESSAGE.REQUIRED,
              pattern: {
                value: REGEXP.PASSWORD,
                message: INPUT_MESSAGE.PASSWORD.MESSAGE,
              },
              minLength: {
                value: 8,
                message: INPUT_MESSAGE.PASSWORD.MIN,
              },
              maxLength: {
                value: 30,
                message: INPUT_MESSAGE.PASSWORD.MAX,
              },
            }}
            type='password'
            name='password'
            width='25.625rem'
            height='2.5rem'
            placeholder={INPUT_MESSAGE.PASSWORD.PLACEHOLDER}
            label={INPUT_MESSAGE.PASSWORD.LABEL}
            subscription={INPUT_MESSAGE.PASSWORD.SUBSCRIPTION}
          />
          <FormInput
            control={control}
            rules={{
              required: INPUT_MESSAGE.REQUIRED,
              validate: (value) =>
                value === watch('password') ||
                INPUT_MESSAGE.CONFIRM_PASSWORD.MESSAGE,
            }}
            type='password'
            name='passwordConfirm'
            width='25.625rem'
            height='2.5rem'
            placeholder={INPUT_MESSAGE.CONFIRM_PASSWORD.PLACEHOLDER}
          />
        </PasswordContainer>

        <AgreeContainer>
          <Text textAlign='left' fontSize='1rem' fontWeight={500}>
            회원가입 경로
          </Text>
          <RouteContainer>
            {SIGNUP_ROUTE.map((value) => (
              <AgreeInputContainer key={value}>
                <input
                  onChange={() => setSignUpSource({ label: value, value })}
                  checked={
                    signUpSource.label === value && signUpSource.value === value
                  }
                  type='checkbox'
                  id={value}
                />
                <InputLabel htmlFor={value}>{value}</InputLabel>
              </AgreeInputContainer>
            ))}
            <AgreeInputContainer>
              <input
                onChange={() => setSignUpSource({ label: '기타', value: '' })}
                checked={signUpSource.label === '기타'}
                type='checkbox'
                id='기타'
              />
              <InputLabel htmlFor='기타'>기타 </InputLabel>
              {signUpSource.label === '기타' && (
                <Input
                  width='9.375rem'
                  height='1.5rem'
                  name='기타'
                  onChange={(e) =>
                    setSignUpSource({ label: '기타', value: e.target.value })
                  }
                />
              )}
            </AgreeInputContainer>
          </RouteContainer>
        </AgreeContainer>
        <AgreeContainer>
          <Text fontSize='1rem' fontWeight={500}>
            약관동의
          </Text>
          {termsData &&
            termsData.data.terms.map((term) => (
              <AgreeInputContainer key={term.id}>
                <input
                  onChange={() => handleCheckbox(term.id)}
                  checked={termIds.includes(term.id)}
                  type='checkbox'
                />
                <Link to={term.url} target='_blank' rel='noreferrer'>
                  <Text color={Theme.colors.B_3}>{term.name}</Text>
                </Link>
              </AgreeInputContainer>
            ))}
        </AgreeContainer>

        <Button width='25.625rem' height='3.25rem' type='submit'>
          회원가입
        </Button>
      </StyledForm>
    </Box>
  );
};

export default SignUpModal;

const EmailContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  gap: 0.9375rem;
`;

const PasswordContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 0.9375rem;
`;

const RouteContainer = styled.div`
  display: grid;
  gap: 0.375rem;
  grid-template-columns: repeat(3, 8.25rem);
`;

const InputLabel = styled.label`
  cursor: pointer;
`;

const AgreeContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 0.375rem;
`;

const AgreeInputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.375rem;
`;

const ProfileImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.9375rem;
  align-items: center;
`;

const ImgContainer = styled.div`
  position: relative;
`;

const ImgWrapper = styled.img`
  width: 9.375rem;
  height: 9.375rem;
  padding: 0.3125rem;
  background-color: ${Theme.colors.WHITE};
  object-fit: contain;
  border-radius: 50%;
`;

const FileInput = styled.input`
  display: none;
`;

const FileLabel = styled.label`
  width: 5.3125rem;
  height: 2rem;
  background-color: ${Theme.colors.WHITE};
  color: ${Theme.colors.BLACK};
  border: 0.0625rem solid ${Theme.colors.G_4};
  font-weight: 400;
  border-radius: 0.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ImgRemoveIconWrapper = styled.div`
  position: absolute;
  top: 0.3125rem;
  right: 0.3125rem;

  border-radius: 50%;

  background-color: ${Theme.colors.WHITE};
  color: ${Theme.colors.WARNING_RED};

  cursor: pointer;
`;
