import styled from '@emotion/styled';
import { Theme } from '@/styles/Theme';

export const Table = styled.table`
  background-color: ${Theme.colors.WHITE};
  text-align: center;

  td {
    height: 2.5rem;
    border: 0.0625rem solid ${Theme.colors.BLACK};
  }

  tr:first-of-type {
    background-color: ${Theme.colors.G_5};
  }
`;

export const IconWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${Theme.colors.G_5 + 80};
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  margin-bottom: 1.25rem;
  justify-content: space-between;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 1.25rem;
`;

export const InfoContainer = styled.tbody`
  td {
    background-color: ${Theme.colors.WHITE};
    font-size: 0.9375rem;
  }

  td:nth-of-type(3) {
    width: 3.75rem;
  }

  tr:first-of-type {
    td:first-of-type {
      background-color: ${Theme.colors.G_5};
      padding: 0.3125rem;
    }
  }
`;
export const ToggleButtonWrapper = styled.div<{ isSelected: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ isSelected }) =>
    isSelected ? Theme.colors.B_6 : Theme.colors.WHITE};
  color: ${({ isSelected }) =>
    isSelected ? Theme.colors.B_3 : Theme.colors.G_2};

  font-size: 0.875rem;
`;
