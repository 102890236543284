import client from '@/apis/client';
import type { Response } from '@/types/client.types';
import type {
  DeleteWorkerListRequestProps,
  GetWorkerListResponseProps,
  PostRegisterTrialWorkerRequestProps,
  PostWorkerModifyRequestProps,
  PostWorkerRegisterRequestProps,
} from '@/types/worker.types';

export const postWorkerRegister = (body: PostWorkerRegisterRequestProps) =>
  client
    .post('/user/worker/registration', body)
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error posting worker register:', error);
      throw error;
    });

export const getWorkerList = () =>
  client
    .get<Response<GetWorkerListResponseProps>>('/user/worker/listup')
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error fetching worker list:', error);
      throw error;
    });

export const deleteWorkerList = (body: DeleteWorkerListRequestProps) =>
  client
    .delete('/user/worker/delete', { data: body })
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error deleting worker:', error);
      throw error;
    });

export const getWorkerInfoById = (id: number) =>
  client
    .get<Response<PostWorkerRegisterRequestProps>>('/user/worker/info', {
      params: { id },
    })
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error fetching worker info:', error);
      throw error;
    });

export const postWorkerInfoModification = (
  body: PostWorkerModifyRequestProps
) =>
  client
    .post('/user/worker/update', body)
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error updating worker info:', error);
      throw error;
    });

export const postRegisterTrialWorker = (
  body: PostRegisterTrialWorkerRequestProps
) =>
  client
    .post('/user/worker/trial/add', body)
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error posting trial worker:', error);
      throw error;
    });
