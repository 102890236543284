import React, { useState } from 'react';
import { AxiosError } from 'axios';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import type { ErrorResponse } from '@/types/client.types';
import {
  deleteInspectionEquipment,
  putInspectionEquipment,
} from '@/apis/report/equipment';
import type { InspectionEquipmentItem } from '@/types/report/equipment.types';

import { QUERY_KEY } from '@/constants/queryKey';

import Modal from '@/components/common/Modal';
import Button from '@/components/common/Button';

import { Theme } from '@/styles/Theme';

import * as S from './style';
import uploadImagePresignedUrl from '@/utils/uploadImagePresignedUrl';

interface Props {
  item: InspectionEquipmentItem;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const EquipmenEdittModal = ({ item, setIsModalOpen }: Props) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [image, setImage] = useState<string>(item.image);
  const [name, setName] = useState<string>(item.name);
  const [model, setModel] = useState<string>(item.model);
  const [manufacturingCompany, setManufacturingCompany] = useState<string>(
    item.manufacturingCompany
  );
  const [isLegal, setIsLegal] = useState<boolean>(
    item.isLegalRegistration === 1
  );

  const queryClient = useQueryClient();

  const handleConfirmEdit = () => {
    handleEquipmentUpdate();
  };

  const { mutate: editMutate } = useMutation({
    mutationFn: putInspectionEquipment,
    onSuccess: (data) => {
      toast.success('장비 정보가 변경되었습니다.');
      queryClient.invalidateQueries([QUERY_KEY.REPORT.EQUIPMENT]);
      setIsModalOpen(false);
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      toast.error(error.response?.data.message);
    },
  });

  const { mutate: deleteMutate } = useMutation({
    mutationFn: deleteInspectionEquipment,
    onSuccess: (data) => {
      toast.success('장비 정보가 삭제되었습니다');
      queryClient.invalidateQueries([QUERY_KEY.REPORT.EQUIPMENT]);
      setIsModalOpen(false);
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      toast.error(error.response?.data.message);
    },
  });

  const handleImgChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const uploadedUrl = await uploadImagePresignedUrl(e.target.files);
      if (uploadedUrl) {
        setImage(uploadedUrl);
      } else {
        toast.error('이미지 업로드에 실패했습니다');
      }
    } catch (error) {
      toast.error('이미지 업로드 중 에러가 발생했습니다');
      console.error('Error during image upload:', error);
    }
  };

  const handleEquipmentUpdate = () => {
    if (image.length > 0) {
      editMutate({
        id: item.id,
        name: name,
        image: image,
        manufacturingCompany: manufacturingCompany,
        model: model,
        isLegalRegistration: isLegal ? 1 : 0,
      });
    }
  };

  const handleEquipmentDelete = () => {
    /* TODO tkr API 연결 */
    deleteMutate(item.id);
  };

  const handleEditCancel = () => {
    setIsEdit(false);
    setName(item.name);
    setImage(item.image);
    setModel(item.model);
    setManufacturingCompany(item.manufacturingCompany);
  };

  return (
    <Modal
      width='43.75rem'
      closeText='닫기'
      confirmText='수정하기'
      setIsModalOpen={setIsModalOpen}
      hasButton={isEdit}
      onClickConfirm={handleConfirmEdit}
    >
      <S.Container>
        <S.TopContainer>
          <S.EditButtonContainer>
            {isEdit ? (
              <Button
                backgroundColor={Theme.colors.G_2}
                height='2.1875rem'
                onClick={handleEditCancel}
              >
                <AiOutlineEdit size='1.25rem' />
                취소하기
              </Button>
            ) : (
              <>
                <Button height='2.1875rem' onClick={() => setIsEdit(true)}>
                  <AiOutlineEdit size='1.25rem' />
                  수정하기
                </Button>
                <Button
                  backgroundColor={Theme.colors.WARNING_RED}
                  height='2.1875rem'
                  onClick={handleEquipmentDelete}
                >
                  <AiOutlineDelete size='1.25rem' />
                  삭제하기
                </Button>
              </>
            )}
          </S.EditButtonContainer>
        </S.TopContainer>
        <S.FlexContainer>
          <S.ImageContainer>
            <S.FileInput
              type='file'
              id='img_input_equipment'
              accept='.jpg, .jpeg, .png'
              onChange={handleImgChange}
            />
            <S.ImageWrapper src={image}></S.ImageWrapper>
            {isEdit ? (
              <S.FileLabel htmlFor='img_input_equipment'>사진변경</S.FileLabel>
            ) : (
              <></>
            )}
          </S.ImageContainer>
          <S.InformationContainer>
            <S.NameWrapper>
              <h1>장비명</h1>
              {isEdit ? (
                <S.StyledInput
                  height='2.5rem'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              ) : (
                name
              )}
            </S.NameWrapper>
            <S.InfoWrapper>
              <h1>모델명</h1>
              {isEdit ? (
                <S.StyledTextarea
                  height='2.5rem'
                  value={model}
                  onChange={(e) => setModel(e.target.value)}
                />
              ) : (
                model
              )}
            </S.InfoWrapper>
            <S.InfoWrapper>
              <h1>제조사</h1>
              {isEdit ? (
                <S.StyledTextarea
                  height='2.5rem'
                  value={manufacturingCompany}
                  onChange={(e) => setManufacturingCompany(e.target.value)}
                />
              ) : (
                manufacturingCompany
              )}
            </S.InfoWrapper>
            <S.InfoWrapper>
              <h1>법정 등록 장비</h1>
              <S.LegalWrapper>
                {isEdit || isLegal ? (
                  <S.CheckBoxWrapper>
                    {<span>등록</span>}
                    {isEdit ? (
                      <S.StyledCheckbox
                        selected={isLegal}
                        onClick={() => setIsLegal(true)}
                      >
                        ✔
                      </S.StyledCheckbox>
                    ) : (
                      <></>
                    )}
                  </S.CheckBoxWrapper>
                ) : (
                  <></>
                )}

                {isEdit || !isLegal ? (
                  <S.CheckBoxWrapper>
                    {<span>미등록</span>}
                    {isEdit ? (
                      <S.StyledCheckbox
                        selected={!isLegal}
                        onClick={() => setIsLegal(false)}
                      >
                        ✔
                      </S.StyledCheckbox>
                    ) : (
                      <></>
                    )}
                  </S.CheckBoxWrapper>
                ) : (
                  <></>
                )}
              </S.LegalWrapper>
            </S.InfoWrapper>
          </S.InformationContainer>
        </S.FlexContainer>
      </S.Container>
    </Modal>
  );
};

export default EquipmenEdittModal;
