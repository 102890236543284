import styled from '@emotion/styled';
import { Theme } from '@/styles/Theme';
export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 1.875rem;
`;

export const StyledTab = styled.div<{ isSelected?: boolean }>`
  color: ${Theme.colors.B_3};
  font-weight: 600;
  text-decoration: ${({ isSelected }) => (isSelected ? 'underline' : 'none')};
  color: ${({ isSelected }) =>
    isSelected ? Theme.colors.B_3 : Theme.colors.G_1};
  cursor: pointer;
`;

export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  font-size: 1.125rem;
`;
