import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export interface UsePaginationProps {
  page: number;
  totalCount: number;
  size: number;
}

const usePagination = ({ page, totalCount, size }: UsePaginationProps) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const MAX_PAGE = Math.ceil(totalCount / size);
  const NUMBER_PER_PAGE = 5;

  const indexOfFirst =
    page % NUMBER_PER_PAGE === 0
      ? Math.floor((page - 1) / NUMBER_PER_PAGE) * NUMBER_PER_PAGE + 1
      : Math.floor(page / NUMBER_PER_PAGE) * NUMBER_PER_PAGE + 1;

  const indexOfLast =
    indexOfFirst + NUMBER_PER_PAGE - 1 < MAX_PAGE
      ? indexOfFirst + NUMBER_PER_PAGE - 1
      : MAX_PAGE;

  const pageNumberArray = [...Array(indexOfLast - indexOfFirst + 1)].map(
    (_, i) => indexOfFirst + i
  );

  const handleNumberClick = (e: React.MouseEvent<HTMLElement>) => {
    const selectNumber = Number((e.target as HTMLElement).textContent);

    if (page === selectNumber) return;

    searchParams.set('page', String(selectNumber));
    setSearchParams(searchParams);
    navigate({ search: searchParams.toString() });
  };

  const handleRightArrowClick = () => {
    if (page === MAX_PAGE) return;
    const clickedNumber =
      Math.floor((page + NUMBER_PER_PAGE - 1) / NUMBER_PER_PAGE) *
        NUMBER_PER_PAGE +
      1;

    searchParams.set('page', String(clickedNumber));
    setSearchParams(searchParams);
    navigate({ search: searchParams.toString() });
  };

  const handleLeftArrowClick = () => {
    if (page === 1) return;

    const clickedNumber =
      Math.floor((page - NUMBER_PER_PAGE - 1) / NUMBER_PER_PAGE) *
        NUMBER_PER_PAGE +
      NUMBER_PER_PAGE;

    searchParams.set('page', String(clickedNumber));
    setSearchParams(searchParams);
    navigate({ search: searchParams.toString() });
  };

  const isFirstList = pageNumberArray.includes(1) && true;
  const isLastList = pageNumberArray.includes(MAX_PAGE) && true;

  return {
    pageNumberArray,
    handleNumberClick,
    handleRightArrowClick,
    handleLeftArrowClick,
    isFirstList,
    isLastList,
  };
};

export default usePagination;
