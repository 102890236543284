import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { getWorkerList } from '@/apis/worker';

import { QUERY_KEY } from '@/constants/queryKey';

import GreyBox from '@/components/common/GreyBox';
import WorkerListTable from '@/components/worker/WorkerListTable';

const WorkerPage = () => {
  const [selectedWorkerList, setSelectedWorkerList] = useState<number[]>([]);

  const { data: workerData } = useQuery([QUERY_KEY.WORKERS], {
    queryFn: getWorkerList,
  });

  return (
    <GreyBox title='작업자 정보 관리' maxWidth='58.125rem' minWidth='34.375rem'>
      <WorkerListTable
        isWorkerPage
        workerData={workerData?.data.workerList ?? []}
        selectedWorkerList={selectedWorkerList}
        setSelectedWorkerList={setSelectedWorkerList}
      />
    </GreyBox>
  );
};

export default WorkerPage;
