import { useState } from 'react';
import { AiOutlineEdit } from 'react-icons/ai';

import type {
  PostWorkerModifyRequestProps,
  WorkerInfo,
} from '@/types/worker.types';

import WorkerModifyModal from '@/components/worker/WorkerModifyModal';
import Text from '@/components/common/Text';

import { Theme } from '@/styles/Theme';

import * as S from './style';
import { StyledTd } from '@/styles/common';

export interface Props {
  width?: string;
  height?: string;
  hasButtons?: boolean;
  isDetailPage?: boolean;
  isWorkerPage?: boolean;
  isEditPage?: boolean;
  workerData: WorkerInfo[];
  selectedWorkerList: number[];
  setSelectedWorkerList: React.Dispatch<React.SetStateAction<number[]>>;
}

const WorkerListTable = ({
  width = '100%',
  height = '33.875rem',
  hasButtons = true,
  isDetailPage = false,
  isWorkerPage = false,
  isEditPage = false,
  workerData,
  selectedWorkerList,
  setSelectedWorkerList,
}: Props) => {
  // const [isWorkerAddModalOpen, setIsWorkerAddModalOpen] =
  //   useState<boolean>(false);
  // const [isWorkerDeleteModalOpen, setIsWorkerDeleteModalOpen] =
  //   useState<boolean>(false);
  const [isWorkerModifyModalOpen, setIsWorkerModifyModalOpen] =
    useState<boolean>(false);

  const [workerInfo, setWorkerInfo] = useState<PostWorkerModifyRequestProps>({
    id: 0,
    name: '',
    password: '',
    memo: '',
    noteNumber: '',
    grade: '',
  });

  const handleModifyWorker = (info: WorkerInfo) => {
    setWorkerInfo(info);
    setIsWorkerModifyModalOpen(true);
  };

  const activeWorkerList = workerData.filter(
    (worker) => worker.isActive !== '비활성'
  );
  const activeWorkerIdList = activeWorkerList.map((worker) => worker.id);
  const activeWorkerCount = activeWorkerList.length;

  const handleCheckBox = (id: number) => {
    if (selectedWorkerList.includes(id)) {
      const filteredArr = selectedWorkerList.filter((value) => value !== id);
      setSelectedWorkerList(filteredArr);
      return;
    }
    setSelectedWorkerList((prev) => [...prev, id]);
  };

  const handleCheckBoxAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    if (!checked) {
      setSelectedWorkerList([]);
      return;
    }

    setSelectedWorkerList(activeWorkerIdList);
  };

  // const handleDeleteWorker = () => {
  //   if (selectedWorkerList.length < 1) {
  //     toast.error('삭제할 작업자를 한명이상 선택해주세요.', {
  //       toastId: TOAST_ID.WORKER_LIST_TABLE,
  //     });
  //     return;
  //   }
  //   setIsWorkerDeleteModalOpen(true);
  // };

  // const { data: companyInfo } = useCompanyInfo();

  // const handleWorkerAddButton = () => {
  //   if (companyInfo) {
  //     if (notDeletedWorkerCount < companyInfo.data.workerCount) {
  //       setIsWorkerAddModalOpen(true);
  //       return;
  //     }
  //     toast.error('등록 가능한 작업자 수를 초과하였습니다.', {
  //       toastId: 'worker_add_error',
  //     });
  //   }
  // };

  // const queryClient = useQueryClient();

  // const { mutate: recoverWorkerListMutate } = useMutation({
  //   mutationFn: deleteWorkerList,
  //   onSuccess: (data) => {
  //     queryClient.invalidateQueries([QUERY_KEY.WORKERS]);
  //     toast.success('작업자 복구가 완료되었습니다.');
  //   },
  //   onError: (error: AxiosError<ErrorResponse>) => {
  //     toast.error(error.response?.data.message, {
  //       toastId: error.response?.data.message,
  //     });
  //   },
  // });

  return (
    <S.Container width={width}>
      {/* {isWorkerAddModalOpen && (
        <WorkerAddModal setIsModalOpen={setIsWorkerAddModalOpen} />
      )}
      {isWorkerDeleteModalOpen && (
        <WorkerDeleteModal
          workerList={selectedWorkerList}
          setSelectedWorkerList={setSelectedWorkerList}
          setIsModalOpen={setIsWorkerDeleteModalOpen}
        />
      )} */}
      {isWorkerModifyModalOpen && (
        <WorkerModifyModal
          workerInfo={workerInfo}
          setIsModalOpen={setIsWorkerModifyModalOpen}
        />
      )}
      {hasButtons && (
        <>
          <Text textAlign='right' color={Theme.colors.G_1}>
            작업자 수 : {`${activeWorkerCount} / ${workerData.length}`}
          </Text>
          {/* <S.ButtonContainer>
            <Button
              height='2.125rem'
              onClick={handleDeleteWorker}
              border={`0.0625rem solid ${Theme.colors.G_3}`}
              color={Theme.colors.BLACK}
              backgroundColor={Theme.colors.WHITE}
            >
              <BsTrash size='1.25rem' />
              선택항목 삭제
            </Button>
            <S.ButtonContainer>
              <input
                type='checkbox'
                checked={isShowDeletedWorkerList === 1}
                onChange={handleShowDeletedWorkerList}
              />
              <Text margin='0 0.625rem 0 0.3125rem' fontSize='1.0625rem'>
                삭제된 작업자 보기
              </Text>
              <Button height='2.125rem' onClick={handleWorkerAddButton}>
                <HiPlusSm size='1.25rem' />
                작업자 추가
              </Button>
            </S.ButtonContainer> 
          </S.ButtonContainer> */}
        </>
      )}
      <S.TableWrapper width={width} height={height}>
        <S.Table>
          <thead>
            <tr>
              {!isDetailPage && !isWorkerPage && (
                <StyledTd width='3.125rem'>
                  <input
                    type='checkbox'
                    onChange={handleCheckBoxAll}
                    checked={
                      workerData.length > 0 &&
                      JSON.stringify(selectedWorkerList) ===
                        JSON.stringify(activeWorkerIdList)
                    }
                  />
                </StyledTd>
              )}
              <th>아이디</th>
              <th>비밀번호</th>
              <th>등급구분</th>
              <th>수첩발급번호</th>
              <th>비고</th>
              {!isDetailPage && <th style={{ width: '6.25rem' }}>수정</th>}
            </tr>
          </thead>
          <tbody>
            {workerData &&
              workerData.map((worker, idx) => (
                <S.StyledTr key={idx} isActive={worker.isActive === '비활성'}>
                  {!isDetailPage && !isWorkerPage && (
                    <td>
                      <input
                        type='checkbox'
                        onChange={() => handleCheckBox(worker.id)}
                        disabled={
                          worker.isActive === '비활성' &&
                          !selectedWorkerList.includes(worker.id)
                        }
                        checked={selectedWorkerList.includes(worker.id)}
                      />
                    </td>
                  )}
                  <td>{worker.name}</td>
                  <td>{worker.password}</td>
                  <td>{worker.grade}</td>
                  <td>{worker.noteNumber}</td>
                  <td>{worker.memo}</td>
                  {!isDetailPage && (
                    <td>
                      {/* {!!worker.deleted ? (
                        !isEditPage && (
                          <Button
                            onClick={() =>
                              recoverWorkerListMutate({
                                workerList: [worker.id],
                                deleted: false,
                              })
                            }
                            width='5rem'
                            height='2.1875rem'
                            backgroundColor={Theme.colors.GREEN}
                          >
                            복구하기
                          </Button>
                        )
                      ) : (
                        <AiOutlineEdit
                          size='1.5rem'
                          cursor='pointer'
                          onClick={() => handleModifyWorker(worker)}
                        />
                      )} */}
                      <AiOutlineEdit
                        size='1.5rem'
                        cursor='pointer'
                        onClick={() => handleModifyWorker(worker)}
                      />
                    </td>
                  )}
                </S.StyledTr>
              ))}
          </tbody>
        </S.Table>
        {workerData && workerData.length < 1 && (
          <S.NoDataText>
            <Text fontWeight={500} fontSize='1.375rem' color={Theme.colors.G_2}>
              작업자를 등록해주세요.
            </Text>
          </S.NoDataText>
        )}
      </S.TableWrapper>
    </S.Container>
  );
};

export default WorkerListTable;
