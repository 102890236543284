import client from '@/apis/client';
import type { Response } from '@/types/client.types';
import type { GetReportOutlineResponseProps } from '@/types/report/outline.types';

// Ⅰ. 성능점검 개요
// 3. 점검대상
export const getReportOutline = (inspectionAreaId: number) =>
  client
    .get<Response<GetReportOutlineResponseProps>>('/report/outline/show', {
      params: { inspectionAreaId },
    })
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error fetching report outline:', error);
      throw error;
    });
