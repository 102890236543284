import { useQuery } from '@tanstack/react-query';

import { QUERY_KEY } from '@/constants/queryKey';
import { getWorkerList } from '@/apis/worker';

const useGetWorkerList = () => {
  const query = useQuery([QUERY_KEY.WORKERS], {
    queryFn: getWorkerList,
  });

  return query;
};

export default useGetWorkerList;
