import { Theme } from '@/styles/Theme';
import * as S from './style';
import Text from '@/components/common/Text';

const Section3 = () => {
  return (
    <S.Container>
      <S.TextWrapper>
        <Text
          fontSize='1.875rem'
          fontWeight={600}
          color={Theme.colors.DG_1}
          textAlign='center'
        >
          작업자가 현장에서 입력한 데이터를
          <br />
          사무실에서 바로 확인해보세요
        </Text>
        <Text
          fontSize='1.25rem'
          fontWeight={400}
          color={Theme.colors.DG_2}
          lineHeight='1.5rem'
          textAlign='center'
        >
          별도의 외부 프로그램에 의존하지 않고도
          <br />
          작업 흐름을 원활하게 관리할 수 있습니다
        </Text>
      </S.TextWrapper>
      <S.ImageWrapper>
        <img src='/landingImage/image1.png' alt='image1_img' />
      </S.ImageWrapper>
    </S.Container>
  );
};
export default Section3;
