import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import styled from '@emotion/styled';
import { ko } from 'date-fns/esm/locale';
import ReactDatePicker from 'react-datepicker';

import { getInspectionArea } from '@/apis/InspectionArea';
import type { DeviceInfo } from '@/types/InspectionArea.types';
import type { TargetResult } from '@/types/template.types';
import type { WorkerInfo } from '@/types/worker.types';

import { QUERY_KEY } from '@/constants/queryKey';
import { REGEXP } from '@/constants/constants';

import GreyBox from '@/components/common/GreyBox';
import Text from '@/components/common/Text';
import WorkDetailCategoryListTable from '@/components/work/WorkDetailCategoryListTable';
import Input from '@/components/common/Input';
import WorkerListTable from '@/components/worker/WorkerListTable';
import { TableWrapper } from '@/components/work/WorkAddCategoryListTable/style';

import { WorkFormProps } from '@/pages/WorkAddPage';

import { Theme } from '@/styles/Theme';

import * as S from '@/styles/WorkForm';
import { StyledTd } from '@/styles/common';

interface Props {
  inspectionAreaId: number;
}

const WorkDetailPage = ({ inspectionAreaId }: Props) => {
  const { data: workInfoData } = useQuery(
    [QUERY_KEY.WORK_INFO, inspectionAreaId],
    {
      queryFn: () => getInspectionArea(inspectionAreaId),
    }
  );

  const [formState, setFormState] = useState<WorkFormProps>({
    name: '',
    mainAddress: '',
    subAddress: '',
    startDate: null,
    endDate: null,
    memo: '',
    reason: '',
  });

  const [selectedWorkerList, setSelectedWorkerList] = useState<number[]>([]);
  const [selectedDeviceList, setSelectedDeviceList] = useState<DeviceInfo[]>(
    []
  );
  const [workerData, setWorkerData] = useState<WorkerInfo[]>([]);
  const [targetResultData, setTargetResultData] = useState<TargetResult[]>([]);
  const [isFiltered, setIsFiltered] = useState<boolean>(false);

  useEffect(() => {
    if (workInfoData) {
      const {
        name,
        address,
        worker_list,
        device_list,
        start_date,
        end_date,
        memo,
        reason,
      } = workInfoData.data;

      const [mAddress, sAddress] = address.split('/');
      const selectedWorker = worker_list.map((worker) => worker.id);

      const selectedDeviceData: DeviceInfo[] = device_list
        .map((device) => device.device_info)
        .flat()
        .map((deviceDetail) => {
          return {
            template_device_id: deviceDetail.template_device_id,
            name: deviceDetail.name,
            total: deviceDetail.total,
            selected: deviceDetail.selected,
            info: deviceDetail.info,
          };
        });

      const targetResultData: TargetResult[] = device_list
        .map((device) => device.device_info)
        .flat()
        .map((deviceDetail, idx) => {
          return {
            seq: idx + 1,
            content: deviceDetail.name,
            result: deviceDetail.result,
            done: deviceDetail.done,
            total: deviceDetail.selected,
            status: deviceDetail.status,
          };
        })
        .filter((device) => device.total > 0);

      setFormState({
        name: name,
        mainAddress: mAddress,
        subAddress: sAddress ?? '',
        startDate: new Date(start_date),
        endDate: new Date(end_date),
        memo: memo,
        reason: reason,
      });

      setSelectedWorkerList(selectedWorker);
      setWorkerData(worker_list);
      setSelectedDeviceList(selectedDeviceData);
      setTargetResultData(targetResultData);
    }
  }, [workInfoData]);

  useEffect(() => {
    if (isFiltered) {
      const arr = selectedDeviceList.filter((device) => device.selected > 0);
      setSelectedDeviceList(arr);
      return;
    }

    if (workInfoData) {
      const selectedDeviceData: DeviceInfo[] = workInfoData.data.device_list
        .map((device) => device.device_info)
        .flat()
        .map((deviceDetail) => {
          return {
            template_device_id: deviceDetail.template_device_id,
            name: deviceDetail.name,
            total: deviceDetail.total,
            selected: deviceDetail.selected,
            info: deviceDetail.info,
          };
        });
      setSelectedDeviceList(selectedDeviceData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFiltered]);

  const memoParsedHtml = formState.memo.replace(
    REGEXP.URL,
    (url) => `<a href="${url}" target="_blank">${url}</a>`
  );

  return (
    <GreyBox title='점검지 정보' width='58.125rem'>
      <S.Container>
        <Input
          label='상호(명칭)'
          placeholder='상호(명칭)을 입력해주세요.'
          name='name'
          width='25.625rem'
          height='2.5rem'
          labelFontSize='1.25rem'
          value={formState.name}
          disabled
        />
        <S.AddressContainer>
          <Input
            label='현장주소'
            name='mainAddress'
            value={formState.mainAddress}
            width='25.625rem'
            height='2.5rem'
            labelFontSize='1.25rem'
            placeholder='주소를 검색해주세요.'
            disabled
          />
          <Input
            placeholder='상세주소를 입력해주세요.'
            name='subAddress'
            width='25.625rem'
            height='2.5rem'
            value={formState.subAddress}
            disabled
          />
        </S.AddressContainer>
        <S.LabelContainer>
          <S.LabelWrapper>점검기간</S.LabelWrapper>
          <ReactDatePicker
            locale={ko}
            closeOnScroll
            selected={formState.startDate}
            onChange={() => {}}
            startDate={formState.startDate}
            endDate={formState.endDate}
            selectsRange
            placeholderText='yyyy-MM-dd'
            dateFormat='yyyy-MM-dd'
            monthsShown={2}
            disabled
          />
        </S.LabelContainer>
        <S.LabelContainer>
          <S.LabelWrapper>비고</S.LabelWrapper>
          <MemoWrapper
            dangerouslySetInnerHTML={{
              __html: memoParsedHtml,
            }}
          />
        </S.LabelContainer>
        <S.LabelContainer>
          <S.LabelWrapper>작업자 선택</S.LabelWrapper>
          <WorkerListTable
            isDetailPage
            workerData={workerData}
            width='100%'
            height='22.625rem'
            selectedWorkerList={selectedWorkerList}
            setSelectedWorkerList={setSelectedWorkerList}
            hasButtons={false}
          />
        </S.LabelContainer>

        <S.LabelContainer>
          <S.LabelWrapper>설비 점검 현황</S.LabelWrapper>
          <TableWrapper width='100%'>
            <S.StyledTable>
              <thead>
                <tr>
                  <th>번호</th>
                  <th>대상설비</th>
                  <th>점검현황</th>
                  <th>점검결과</th>
                </tr>
              </thead>
              <tbody>
                {targetResultData
                  .filter((item) => item.result !== '/')
                  .map((result, idx) => (
                    <tr key={result.seq}>
                      <td>{idx + 1}</td>
                      <td>{result.content}</td>
                      <StyledTd fontWeight={500}>
                        {result.status === 'before' && '점검전'}
                        {result.status === 'in-progress' && '점검중'}
                        {result.status === 'done' && '점검완료'}
                        {` (${result.done} / ${result.total})`}
                      </StyledTd>
                      {result.result === 'O' && (
                        <StyledTd color={Theme.colors.B_3} fontWeight={500}>
                          적합
                        </StyledTd>
                      )}
                      {result.result === 'X' && (
                        <StyledTd
                          color={Theme.colors.WARNING_RED}
                          fontWeight={500}
                        >
                          미흡
                        </StyledTd>
                      )}
                      {result.result === '-' && (
                        <StyledTd fontWeight={500}>점검전</StyledTd>
                      )}
                    </tr>
                  ))}
              </tbody>
            </S.StyledTable>
          </TableWrapper>
        </S.LabelContainer>
        <S.LabelContainer>
          <FilteringContainer>
            <S.LabelWrapper>점검 대상 설비</S.LabelWrapper>
            <FilteringWrapper>
              <input
                type='checkbox'
                checked={isFiltered}
                onChange={(e) => setIsFiltered(e.target.checked)}
              />
              <Text fontWeight={500}>점검수량 0대인 설비 제외</Text>
            </FilteringWrapper>
          </FilteringContainer>
          <WorkDetailCategoryListTable
            selectedDeviceList={selectedDeviceList}
          />
        </S.LabelContainer>
        <Input
          label='수정 사유'
          name='reason'
          width='25.625rem'
          height='2.5rem'
          labelFontSize='1.25rem'
          value={formState.reason}
          disabled
        />
      </S.Container>
    </GreyBox>
  );
};

export default WorkDetailPage;

const FilteringContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FilteringWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const MemoWrapper = styled.div`
  width: 25.625rem;
  height: 15.625rem;

  overflow-y: auto;

  background-color: ${Theme.colors.G_5};
  border-radius: 0.375rem;
  box-shadow: 0rem 0rem 0.25rem 0rem rgba(0, 0, 0, 0.25);

  border: none;

  padding: 0.9375rem;

  color: ${Theme.colors.BLACK};
  font-size: 1rem;
  font-weight: 400;
  resize: none;

  white-space: pre-wrap;

  a {
    color: ${Theme.colors.B_3};
    text-decoration: underline;
  }
`;
