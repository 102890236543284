import type { PostWorkerRegisterRequestProps } from '@/types/worker.types';
import { create } from 'zustand';

interface SelectedWorkerListStore {
  selectedAddWorkerList: PostWorkerRegisterRequestProps[];
  toggleAddWorker: (
    addWorker: PostWorkerRegisterRequestProps,
    isTrial?: 1 | 0
  ) => void;
  resetSelectedAddWorkerList: () => void;

  selectedRestoreWorkerList: number[];
  toggleRestoreWorker: (id: number) => void;
  resetSelectedRestoreWorkerList: () => void;

  selectedUnsubscribeWorkerList: number[];
  toggleUnsubscribeWorker: (id: number) => void;
  resetSelectedUnsubscribeWorkerList: () => void;

  selectedUndoUnsubscribeWorkerList: number[];
  toggleUndoUnsubscribeWorker: (id: number) => void;
  resetSelectedUndoUnsubscribeWorkerList: () => void;
}

export const useSelectedWorkerListStore = create<SelectedWorkerListStore>(
  (set) => ({
    selectedAddWorkerList: [],
    toggleAddWorker: (addWorker, isTrial = 0) =>
      set((state) => {
        const isAlreadySelected = state.selectedAddWorkerList.some(
          (selectedAddWorker) => selectedAddWorker.name === addWorker.name
        );

        if (isAlreadySelected) {
          return {
            selectedAddWorkerList: state.selectedAddWorkerList.filter(
              (selectedAddWorker) => selectedAddWorker.name !== addWorker.name
            ),
          };
        }

        if (isTrial === 1 && state.selectedAddWorkerList.length >= 3) {
          return {
            selectedAddWorkerList: [...state.selectedAddWorkerList],
          };
        }

        return {
          selectedAddWorkerList: [...state.selectedAddWorkerList, addWorker],
        };
      }),
    resetSelectedAddWorkerList: () => set({ selectedAddWorkerList: [] }),

    selectedRestoreWorkerList: [],
    toggleRestoreWorker: (id) =>
      set((state) => ({
        selectedRestoreWorkerList: state.selectedRestoreWorkerList.some(
          (selectedRestoreWorkerId) => selectedRestoreWorkerId === id
        )
          ? state.selectedRestoreWorkerList.filter(
              (selectedRestoreWorkerId) => selectedRestoreWorkerId !== id
            )
          : [...state.selectedRestoreWorkerList, id],
      })),
    resetSelectedRestoreWorkerList: () =>
      set({ selectedRestoreWorkerList: [] }),

    selectedUnsubscribeWorkerList: [],
    toggleUnsubscribeWorker: (id) =>
      set((state) => ({
        selectedUnsubscribeWorkerList: state.selectedUnsubscribeWorkerList.some(
          (selectedUnsubscribeWorkerId) => selectedUnsubscribeWorkerId === id
        )
          ? state.selectedUnsubscribeWorkerList.filter(
              (selectedUnsubscribeWorkerId) =>
                selectedUnsubscribeWorkerId !== id
            )
          : [...state.selectedUnsubscribeWorkerList, id],
      })),
    resetSelectedUnsubscribeWorkerList: () =>
      set({ selectedUnsubscribeWorkerList: [] }),

    selectedUndoUnsubscribeWorkerList: [],
    toggleUndoUnsubscribeWorker: (id) =>
      set((state) => ({
        selectedUndoUnsubscribeWorkerList:
          state.selectedUndoUnsubscribeWorkerList.some(
            (selectedUndoUnsubscribeWorkerId) =>
              selectedUndoUnsubscribeWorkerId === id
          )
            ? state.selectedUndoUnsubscribeWorkerList.filter(
                (selectedUndoUnsubscribeWorkerId) =>
                  selectedUndoUnsubscribeWorkerId !== id
              )
            : [...state.selectedUndoUnsubscribeWorkerList, id],
      })),
    resetSelectedUndoUnsubscribeWorkerList: () =>
      set({ selectedUndoUnsubscribeWorkerList: [] }),
  })
);
