import React, { useState } from 'react';
import styled from '@emotion/styled';

import WorkDetailPage from '@/components/work/WorkDetailPage';
import WorkEditPage from '@/components/work/WorkEditPage';
import EditButton from '@/components/common/EditButton';
import { useParams } from 'react-router-dom';
import InspectionHistoryBtn from '@/components/work/InspectionHistoryBtn/InspectionHistoryBtn';

const WorkPage = () => {
  const params = useParams();
  const inspectionAreaId = Number(params.id);

  const [isDetailPage, setIsDetailPage] = useState<boolean>(true);

  return (
    <Container>
      <InspectionHistoryBtn inspectionAreaId={inspectionAreaId} />
      <EditButton
        isDetailPage={isDetailPage}
        setIsDetailPage={setIsDetailPage}
      />
      {isDetailPage ? (
        <WorkDetailPage inspectionAreaId={inspectionAreaId} />
      ) : (
        <WorkEditPage
          setIsDetailPage={setIsDetailPage}
          inspectionAreaId={inspectionAreaId}
        />
      )}
    </Container>
  );
};

export default WorkPage;

const Container = styled.section`
  position: relative;
`;
