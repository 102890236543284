import client from '@/apis/client';
import type { Response } from '@/types/client.types';
import type {
  DeviceReport,
  PutDeviceReportRequestProps,
  InspectionItem,
  PutInspectionItemRequestProps,
  GetMethodStandardResponseProps,
  PutReportStatusRequestProps,
} from '@/types/deviceReport.types';

// 성능점검표
export const getDeviceReport = (reportId: number) =>
  client
    .get<Response<DeviceReport>>('/v2/work/report/result', {
      params: { reportId },
    })
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error fetching device report:', error);
      throw error;
    });

export const putDeviceReport = (body: PutDeviceReportRequestProps) =>
  client
    .put('/v2/work/report/result/modify', body)
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error updating device report:', error);
      throw error;
    });

// 점검항목
export const getInspectionItem = (itemId: number) =>
  client
    .get<Response<InspectionItem>>('/v2/work/report/item/result', {
      params: { itemId },
    })
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error fetching inspection item:', error);
      throw error;
    });

export const putInspectionItem = (body: PutInspectionItemRequestProps) =>
  client
    .put('/v2/work/report/item/result/modify', body)
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error updating inspection item:', error);
      throw error;
    });

// 점검기준
export const getMethodStandard = (itemId: number) =>
  client
    .get<Response<GetMethodStandardResponseProps>>(
      '/template/method-standard',
      {
        params: { itemId },
      }
    )
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error fetching method standard:', error);
      throw error;
    });

// 설비 점검현황 수정 API
export const putReportStatus = (body: PutReportStatusRequestProps) =>
  client
    .put('/v2/work/report/status/modify', body)
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error updating report status:', error);
      throw error;
    });
