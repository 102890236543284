import { Theme } from '@/styles/Theme';
import styled from '@emotion/styled';
export const Container = styled.div`
  width: 100%;
  background-color: ${Theme.colors.B_2};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.875rem;
  padding: 6.25rem 3.125rem;
`;
export const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.875rem;
  justify-content: center;
  align-content: center;
`;
export const ButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  align-items: center;
  color: ${Theme.colors.WHITE};
`;
export const TextWrapper = styled.div`
  font-size: 1.25rem;
  display: flex;
  gap: 0.3125rem;
  align-items: center;
  justify-content: center;
  padding-right: 0.4375rem;
  color: ${Theme.colors.G_4};
`;
