import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

interface PopupState {
  id: number;
  isPopupVisible: boolean;
  hidePopupUntil: string | null;
}

interface PopupStoreState {
  popups: PopupState[];
  setPopupVisible: (id: number, isPopupVisible: boolean) => void;
  setHidePopupUntil: (id: number, hidePopupUntil: string | null) => void;
  checkVisibility: (activeNoticeIds: number[]) => void;
}

export const usePopupStore = create(
  persist<PopupStoreState>(
    (set, get) => ({
      popups: [],
      setPopupVisible: (id, isPopupVisible) =>
        set((state) => ({
          popups: state.popups.map((popup) =>
            popup.id === id ? { ...popup, isPopupVisible } : popup
          ),
        })),
      setHidePopupUntil: (id, hidePopupUntil) =>
        set((state) => ({
          popups: state.popups.map((popup) =>
            popup.id === id ? { ...popup, hidePopupUntil } : popup
          ),
        })),
      checkVisibility: (activeNoticeIds: number[]) => {
        set((state) => {
          // 기존 팝업 상태에서 activeNoticeIds에 해당하는 팝업이 없으면 추가
          const popupsToUpdate = activeNoticeIds.map((id) => {
            const existingPopup = state.popups.find((popup) => popup.id === id);
            if (!existingPopup) {
              // 새로운 팝업을 기본 가시성으로 추가
              return { id, isPopupVisible: true, hidePopupUntil: null };
            } else {
              // 기존 팝업 가시성 업데이트 로직 (필요한 경우)
              return existingPopup;
            }
          });

          // 필터링 및 업데이트 로직은 유지
          const filteredAndUpdatedPopups = popupsToUpdate
            .filter((popup) => activeNoticeIds.includes(popup.id))
            .map((popup) => {
              const now = new Date();
              const hideUntilDate = popup.hidePopupUntil
                ? new Date(popup.hidePopupUntil)
                : null;
              const isPopupVisible = !hideUntilDate || now >= hideUntilDate;
              return { ...popup, isPopupVisible };
            });

          return { popups: filteredAndUpdatedPopups };
        });
      },
    }),
    {
      name: 'popupState',
      storage: createJSONStorage(() => localStorage),
    }
  )
);
