import { useAddWorkerListStore } from '@/store/addWorkerList';
import { useSelectedWorkerListStore } from '@/store/selectedWorkerList';
import useSubscribeStore from '@/store/subscribeMode';
import type { WorkerPaymentInfo } from '@/types/company.types';

interface Props {
  rowData: WorkerPaymentInfo;
  isTrial: 1 | 0;
}

const WorkerManageCheckBox = ({ rowData, isTrial }: Props) => {
  const { id, name, isActive } = rowData;
  const {
    selectedAddWorkerList,
    toggleAddWorker,
    selectedRestoreWorkerList,
    toggleRestoreWorker,
    selectedUnsubscribeWorkerList,
    toggleUnsubscribeWorker,
    selectedUndoUnsubscribeWorkerList,
    toggleUndoUnsubscribeWorker,
  } = useSelectedWorkerListStore();

  const { findAddWorkerByName } = useAddWorkerListStore();
  const { isSubscribeMode } = useSubscribeStore();

  const isChecked =
    selectedRestoreWorkerList.includes(id) ||
    selectedAddWorkerList.some(
      (selectedAddWorker) => selectedAddWorker.name === name
    ) ||
    selectedUnsubscribeWorkerList.includes(id) ||
    selectedUndoUnsubscribeWorkerList.includes(id);

  const selectWorkerHandler = () => {
    if (isActive === '활성') {
      toggleUnsubscribeWorker(id);
    } else if (isActive === '비활성') {
      toggleRestoreWorker(id);
    } else if (isActive === '비활성 예정') {
      toggleUndoUnsubscribeWorker(id);
    } else if (isActive === '생성') {
      const addWorker = findAddWorkerByName(name);
      if (addWorker) {
        const { id, ...addWorkerWithoutId } = addWorker;
        toggleAddWorker(addWorkerWithoutId, isTrial);
      }
    }
  };

  return (
    <input
      type='checkbox'
      checked={isChecked}
      onChange={selectWorkerHandler}
      disabled={
        isSubscribeMode
          ? !(isActive === '생성' || isActive === '비활성')
          : (isActive !== '비활성 예정' ||
              selectedUnsubscribeWorkerList.length > 0) &&
            (isActive !== '활성' ||
              selectedUndoUnsubscribeWorkerList.length > 0)
      }
    />
  );
};

export default WorkerManageCheckBox;
