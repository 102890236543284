import { useRef, useCallback } from 'react';

const useDebounce = <F extends (...args: any[]) => any>(
  callback: F,
  delay: number
): F => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const debouncedFunction = useCallback(
    (...args: Parameters<F>) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay]
  ) as F;

  return debouncedFunction;
};

export default useDebounce;
