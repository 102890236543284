import Modal from '@/components/common/Modal';
import Spacer from '@/components/common/Spacer';
import Text from '@/components/common/Text';
import * as styled from './style';
import { Theme } from '@/styles/Theme';
import usePaymentInfo from '@/hooks/usePaymentInfo';
import useCompanyInfo from '@/hooks/useCompanyInfo';
import type { PostPaymentResultRequestProps } from '@/types/payment.types';
import type { PostWorkerRegisterRequestProps } from '@/types/worker.types';
import { format } from 'date-fns';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postPaymentResult } from '@/apis/payment';
import { toast } from 'react-toastify';
import { QUERY_KEY } from '@/constants/queryKey';
import { AxiosError } from 'axios';
import type { ErrorResponse } from '@/types/client.types';
import { useSelectedWorkerListStore } from '@/store/selectedWorkerList';
import { useAddWorkerListStore } from '@/store/addWorkerList';
import useSubscribeStore from '@/store/subscribeMode';

interface Props {
  setIsShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedAddWorkerList: PostWorkerRegisterRequestProps[];
  selectedRestoreWorkerList: number[];
}

const SubscribeWorkersModal = ({
  setIsShowModal,
  selectedAddWorkerList,
  selectedRestoreWorkerList,
}: Props) => {
  const { data: companyData } = useCompanyInfo();
  const { companyId, companyName, email, phone } = companyData?.data || {};

  const { data: paymentData } = usePaymentInfo();
  const { pricePerWorker, workerList } = paymentData?.data || {};

  const selectedAddWorkerCount = selectedAddWorkerList.length;
  const selectedRestoreWorkerCount = selectedRestoreWorkerList.length;
  const totalSelectedWorkerCount =
    selectedAddWorkerCount + selectedRestoreWorkerCount;
  const totalPrice = totalSelectedWorkerCount * pricePerWorker!;

  const queryClient = useQueryClient();
  const pay_oid = `pay_oid_${companyId}_${new Date().getTime()}`;

  const {
    resetSelectedAddWorkerList,
    resetSelectedRestoreWorkerList,
    resetSelectedUnsubscribeWorkerList,
    resetSelectedUndoUnsubscribeWorkerList,
  } = useSelectedWorkerListStore();

  const { resetAddWorkerList } = useAddWorkerListStore();
  const { setIsSubscribeMode } = useSubscribeStore();

  const { mutate: paymentMutate } = useMutation({
    mutationFn: postPaymentResult,
    onSuccess: () => {
      setIsShowModal(false);
      toast.success('선택 작업자가 추가/복구 되었습니다.');
      queryClient.invalidateQueries([QUERY_KEY.PAYMENT_INFO]);
      queryClient.invalidateQueries([QUERY_KEY.COMPANY_INFO]);
      queryClient.invalidateQueries([QUERY_KEY.CARD_INFO]);

      resetSelectedAddWorkerList();
      resetSelectedRestoreWorkerList();
      resetSelectedUnsubscribeWorkerList();
      resetSelectedUndoUnsubscribeWorkerList();
      resetAddWorkerList();
      setIsSubscribeMode(false);
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      toast.error(error.response?.data.message);
    },
  });

  const callback = (response: any) => {
    const { PCD_PAY_MSG, PCD_PAY_RST } = response;
    const date = format(new Date(), 'yyyy-MM-dd');
    alert(PCD_PAY_MSG);

    if (PCD_PAY_RST === 'success') {
      const body: PostPaymentResultRequestProps = {
        callbackData: response,
        data: {
          new_card: 1,
          total_price: totalPrice,
          date: date,
          pay_oid: pay_oid,
          worker_list: {
            add: selectedAddWorkerList,
            restore: selectedRestoreWorkerList,
          },
        },
      };
      paymentMutate(body);
      return;
    }
  };

  const onPaymentClick = () => {
    if (!window.PaypleCpayAuthCheck) return;
    const data = {
      clientKey: process.env.REACT_APP_PAYPLE_CLIENTKEY,
      PCD_PAY_TYPE: 'card',
      PCD_PAY_WORK: 'PAY',
      PCD_CARD_VER: '01',
      PCD_PAY_GOODS: '블루멘토 작업자 결제',
      PCD_PAY_TOTAL: totalPrice,
      PCD_PAY_OID: pay_oid,
      PCD_PAYER_NO: companyId,
      PCD_PAYER_NAME: companyName,
      PCD_PAYER_HP: phone,
      PCD_PAYER_EMAIL: email,
      PCD_RST_URL: '/example',
      callbackFunction: callback,
    };
    window.PaypleCpayAuthCheck(data);
  };

  const isAddWorker = selectedAddWorkerCount > 0;
  const isRestoreWorker = selectedRestoreWorkerCount > 0;

  return (
    <Modal
      title={`선택 작업자 ${isAddWorker ? '추가' : ''}${
        isAddWorker && isRestoreWorker ? '/' : ''
      }${isRestoreWorker ? '복구' : ''}`}
      onClickConfirm={onPaymentClick}
      setIsModalOpen={setIsShowModal}
    >
      <styled.DataContainer>
        {isAddWorker && (
          <>
            <styled.DataLine>
              <Text>추가 작업자 수</Text>
              <Text>{selectedAddWorkerCount}명</Text>
            </styled.DataLine>
            <styled.DataLine>
              <Text>추가 작업자</Text>
              <Text>
                {selectedAddWorkerList
                  .map((selectedAddWorker) => selectedAddWorker.name)
                  .join(', ')}
              </Text>
            </styled.DataLine>
          </>
        )}
        <Spacer y='1.25rem' />
        {workerList &&
          workerList?.length > 0 &&
          selectedRestoreWorkerCount > 0 && (
            <>
              <styled.DataLine>
                <Text>복구 작업자 수</Text>
                <Text>{selectedRestoreWorkerCount}명</Text>
              </styled.DataLine>
              <styled.DataLine>
                <Text>복구 작업자</Text>
                <Text>
                  {selectedRestoreWorkerList
                    .map(
                      (selectedRestoreWorker) =>
                        workerList?.find(
                          (worker) => worker.id === selectedRestoreWorker
                        )?.name
                    )
                    .join(', ')}
                </Text>
              </styled.DataLine>
            </>
          )}
        <Spacer y='1.25rem' />
        <styled.DataLine>
          <Text>총 선택 작업자 수</Text>
          <Text>{totalSelectedWorkerCount}명</Text>
        </styled.DataLine>
        <styled.DataLine>
          <Text>작업자별 결제 금액</Text>
          <Text>{pricePerWorker?.toLocaleString('ko-KR')}원</Text>
        </styled.DataLine>
        <Spacer y='1.25rem' />
        <styled.DataLine>
          <Text fontWeight={600}>총 결제 금액</Text>
          <Text fontWeight={600}>{totalPrice.toLocaleString()}원</Text>
        </styled.DataLine>
        <Text
          color={Theme.colors.G_1}
          margin='1.5625rem 0 .1875rem 0'
          fontSize='0.9375rem'
        >
          - 추가/복구한 작업자는 추가 결제 후 즉시 반영됩니다.
          <br />
          <Text color={Theme.colors.G_1} fontSize='0.9375rem'>
            - 다음 결제일부터 해당 작업자의 결제 금액이 결제됩니다.
          </Text>
          <br />-{' '}
          <styled.A
            href='https://coal-throne-3b3.notion.site/806f4653d0854585beb5ef87f7f1a1f7?pvs=4'
            target='_blank'
            rel='noreferrer'
            style={{ textDecoration: 'underline' }}
          >
            결제 및 환불 처리 약관
          </styled.A>
          을 확인해주세요.
          <br />
        </Text>
      </styled.DataContainer>
    </Modal>
  );
};

export default SubscribeWorkersModal;
