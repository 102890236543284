import Modal from '@/components/common/Modal';
import Spacer from '@/components/common/Spacer';
import Text from '@/components/common/Text';
import usePutUnsubscribeWorker from '@/hooks/usePutUnsubscribeWorker';

interface Props {
  setIsShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedWorkers: number[];
  resetSelectedUnsubscribeWorkerList?: VoidFunction;
}

const UnsubscribeWorkersModal = ({
  setIsShowModal,
  selectedWorkers,
  resetSelectedUnsubscribeWorkerList,
}: Props) => {
  const { mutate: unsubscribeWorker } = usePutUnsubscribeWorker();

  const onUnsubscribeClick = () => {
    unsubscribeWorker({ workerId: selectedWorkers });
    setIsShowModal(false);
    resetSelectedUnsubscribeWorkerList && resetSelectedUnsubscribeWorkerList();
  };

  return (
    <Modal
      setIsModalOpen={setIsShowModal}
      isWarning
      onClickConfirm={onUnsubscribeClick}
    >
      <Spacer y='1.875rem' />
      <Text fontSize='1.375rem' fontWeight={700}>
        선택한 작업자 사용을 중단하시겠습니까?
      </Text>
      <Text>(다음 결제일 전까지 사용 가능)</Text>
      <Spacer y='2.5rem' />
    </Modal>
  );
};

export default UnsubscribeWorkersModal;
