import styled from '@emotion/styled';
import { Theme } from '@/styles/Theme';

export const Table = styled.table`
  background-color: ${Theme.colors.WHITE};
  width: 100%;

  td {
    height: 2.5rem;
    border: 0.0625rem solid ${Theme.colors.BLACK};
    text-align: center;
  }
`;

export const TableHeader = styled.td`
  background-color: ${Theme.colors.G_5};
  text-align: center;
  font-weight: 500;
`;

export const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 0.1875rem;
  align-items: center;
  margin-top: 0.625rem;
`;

export const InfoContainer = styled.tbody`
  tr:first-of-type {
    td:first-of-type {
      background-color: ${Theme.colors.G_5};
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;
