import client from '@/apis/client';
import type { Response } from '@/types/client.types';
import type {
  AppendixReport,
  PostAppendixReportRequestProps,
  AppendixHistoryDevice,
  AppendixHistory,
  PostAppendixHistoryRequestProps,
  GetAppendixExcelDataResponseProps,
} from '@/types/appendix.types';

// 별지 4호 결과보고서 API
export const getAppendixReport = (inspectionAreaId: number) =>
  client
    .get<Response<AppendixReport>>('/appendix/report', {
      params: { inspectionAreaId },
    })
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error fetching appendix report:', error);
      throw error;
    });

export const postAppendixReport = (body: PostAppendixReportRequestProps) =>
  client
    .post('appendix/report/upload', body)
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error posting appendix report:', error);
      throw error;
    });

// 별지 4호 점검결과 내역서 API
export const getAppendixHistory = (inspectionAreaId: number) =>
  client
    .get<Response<AppendixHistory>>('/appendix/history', {
      params: { inspectionAreaId },
    })
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error fetching appendix history:', error);
      throw error;
    });

export const postAppendixHistory = (body: PostAppendixHistoryRequestProps) =>
  client
    .post('appendix/history/upload', body)
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error posting appendix history:', error);
      throw error;
    });

export const getAppendixHistoryDeviceList = (inspectionAreaId: number) =>
  client
    .get<Response<AppendixHistoryDevice[]>>('/appendix/history/reset', {
      params: { inspectionAreaId },
    })
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error fetching appendix history device list:', error);
      throw error;
    });

// 엑셀데이터 불러오기
export const getAppendixExcelData = (inspectionAreaId: number) =>
  client
    .get<Response<GetAppendixExcelDataResponseProps>>('/appendix/export', {
      params: { inspectionAreaId },
    })
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error fetching appendix excel data:', error);
      throw error;
    });
