import { Link } from 'react-router-dom';

import { ROUTER } from '@/constants/constants';

import Text from '@/components/common/Text';
import Button from '@/components/common/Button';

import * as S from './style';
import { Theme } from '@/styles/Theme';

const Section1 = () => {
  return (
    <S.Container>
      <S.LeftContainer>
        <S.H1>
          새로운 <br />
          성능점검의 시작
        </S.H1>
        <Text
          color={Theme.colors.G_1}
          fontSize='1.375rem'
          lineHeight='1.6875rem'
        >
          이젠 외부 프로그램에 의존하지않고
          <br />
          점검결과를 주고 받으세요.
          <br />
          불필요한 업무 과정을 감소시킬 수 있습니다.
        </Text>
        <Link to={ROUTER.LOGIN}>
          <Button
            fontSize='1.25rem'
            backgroundColor='rgb(65,157,255)'
            width='100%'
          >
            시작하기
          </Button>
        </Link>
      </S.LeftContainer>
      <S.RightContainer>
        <S.ImageWrapper>
          <img src='/landingImage/hand.png' alt='hand_img' />
        </S.ImageWrapper>
      </S.RightContainer>
    </S.Container>
  );
};
export default Section1;
