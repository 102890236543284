import type { ErrorResponse } from '@/types/client.types';
import { postRegisterTrialWorker } from '@/apis/worker';
import { QUERY_KEY } from '@/constants/queryKey';
import { useAddWorkerListStore } from '@/store/addWorkerList';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

const usePostTrialWorker = () => {
  const queryClient = useQueryClient();
  const { resetAddWorkerList } = useAddWorkerListStore();

  return useMutation({
    mutationFn: postRegisterTrialWorker,
    onSuccess: () => {
      resetAddWorkerList();
      queryClient.invalidateQueries([QUERY_KEY.COMPANY_INFO]);
      queryClient.invalidateQueries([QUERY_KEY.PAYMENT_INFO]);
      toast.success('신규 작업자가 추가되었습니다.');
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      toast.error(error.response?.data.message);
    },
  });
};

export default usePostTrialWorker;
