import styled from '@emotion/styled';

import { Theme } from '@/styles/Theme';

interface Props {
  color?: string;
  fontSize?: string;
  fontWeight?: number;
  hasUnderline?: boolean;
  margin?: string;
  textAlign?: string;
  lineHeight?: string;
  children: React.ReactNode;
}

const Text = ({
  color = Theme.colors.BLACK,
  fontSize = '1rem',
  fontWeight = 400,
  hasUnderline = false,
  margin,
  textAlign = 'start',
  lineHeight,
  children,
}: Props) => {
  return (
    <Wrapper
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
      hasUnderline={hasUnderline}
      margin={margin}
      textAlign={textAlign}
      lineHeight={lineHeight}
    >
      {children}
    </Wrapper>
  );
};

export default Text;

const Wrapper = styled.span<Props>`
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  text-decoration-line: ${({ hasUnderline }) => hasUnderline && 'underline'};
  text-underline-position: under;
  text-align: ${({ textAlign }) => textAlign};
  margin: ${({ margin }) => margin};
  line-height: ${({ lineHeight }) => lineHeight};
`;
