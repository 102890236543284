import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';

import { postLogin } from '@/apis/auth';
import type { PostLoginRequestProps } from '@/types/auth.types';
import type { ErrorResponse } from '@/types/client.types';

import { useAccessTokenStore, useRefreshTokenStore } from '@/store/auth';

import { INPUT_MESSAGE, REGEXP, ROUTER } from '@/constants/constants';

import Button from '@/components/common/Button';
import FormInput from '@/components/common/FormInput';
import Box from '@/components/common/Box';
import Text from '@/components/common/Text';
import Spacer from '@/components/common/Spacer';

import { StyledForm } from '@/styles/common';
import { Theme } from '@/styles/Theme';

const LoginModal = () => {
  const navigate = useNavigate();

  const { setAccessToken } = useAccessTokenStore();
  const { setRefreshToken } = useRefreshTokenStore();

  const { control, handleSubmit } = useForm<PostLoginRequestProps>({
    defaultValues: { email: '', password: '' },
  });

  const { mutate } = useMutation({
    mutationFn: postLogin,
    onSuccess: (data) => {
      const { accessToken, refreshToken } = data.data;
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);
      toast.success('로그인 되었습니다.');
      navigate(ROUTER.DASHBOARD);
    },
    onError: async (error: AxiosError<ErrorResponse>) => {
      toast.error(error.response?.data.message, { toastId: 'login-error' });
    },
  });

  const onSubmitHandler: SubmitHandler<PostLoginRequestProps> = async (
    data
  ) => {
    const { email, password } = data;

    mutate({ email, password });
  };

  return (
    <Box>
      <StyledForm onSubmit={handleSubmit(onSubmitHandler)}>
        <Spacer y='0.625rem' />
        <Link to={ROUTER.LANDING}>
          <LoginPageLogo src='/logo.svg' alt='main_logo' />
        </Link>
        <FormInput
          control={control}
          rules={{
            required: INPUT_MESSAGE.REQUIRED,
            pattern: {
              value: REGEXP.EMAIL,
              message: INPUT_MESSAGE.EMAIL.MESSAGE,
            },
          }}
          name='email'
          width='25.625rem'
          height='2.5rem'
          placeholder={INPUT_MESSAGE.EMAIL.PLACEHOLDER}
          label={INPUT_MESSAGE.EMAIL.LABEL}
        />
        <FormInput
          control={control}
          rules={{
            required: INPUT_MESSAGE.REQUIRED,
            minLength: {
              value: 8,
              message: INPUT_MESSAGE.PASSWORD.MIN,
            },
            maxLength: {
              value: 30,
              message: INPUT_MESSAGE.PASSWORD.MAX,
            },
          }}
          type='password'
          name='password'
          width='25.625rem'
          height='2.5rem'
          placeholder={INPUT_MESSAGE.PASSWORD.PLACEHOLDER}
          label={INPUT_MESSAGE.PASSWORD.LABEL}
        />
        <ButtonContainer>
          <Button width='25.625rem' height='3.25rem' type='submit'>
            로그인
          </Button>
          <Link to={ROUTER.FIND_PWD.EMAIL}>
            <Text color={Theme.colors.B_3}>비밀번호 재설정</Text>
          </Link>
        </ButtonContainer>
        <BottomContainer>
          <Line />
          <Text color={Theme.colors.G_1}>또는</Text>
          <Line />
        </BottomContainer>
        <SignUpContainer>
          <Text fontSize='1.125rem'>계정이 없으신가요?</Text>
          <Link to={ROUTER.SIGNUP}>
            <Text fontSize='1.125rem' color={Theme.colors.B_3}>
              블루멘토 가입
            </Text>
          </Link>
        </SignUpContainer>
      </StyledForm>
    </Box>
  );
};

export default LoginModal;

const LoginPageLogo = styled.img`
  width: 18.75rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  gap: 0.625rem;
`;

const BottomContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Line = styled.div`
  width: 10.875rem;
  border: 0.0625rem solid ${Theme.colors.G_2};
`;

const SignUpContainer = styled.div`
  display: flex;

  gap: 0.375rem;
`;
