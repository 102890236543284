import { Outlet } from 'react-router-dom';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { AiFillSetting } from 'react-icons/ai';
import { BsFillPeopleFill } from 'react-icons/bs';
import { BiSolidDashboard } from 'react-icons/bi';
import { MdPayment } from 'react-icons/md';
import { FaBuildingUser } from 'react-icons/fa6';

import useCompanyInfo from '@/hooks/useCompanyInfo';

import { ROUTER } from '@/constants/constants';

import { useGnbStore } from '@/store/gnb';

import Footer from '@/components/common/Footer';

import { Theme } from '@/styles/Theme';
import FoldGnbIcon from './FoldGnbIcon';
import GnbItem from './GnbItem';
import GnbLogoutBtn from './GnbLogoutBtn';
import GnbTitle from './GnbTitle';
import GnbProfile from './GnbProfile';

const GNB = () => {
  const { data: companyInfo } = useCompanyInfo();
  const { isFoldGnb } = useGnbStore();

  return (
    <>
      <Container>
        <FoldGnbIcon />

        <GNBLeftContainer isGnbFold={isFoldGnb}>
          <GNBLeftTopContainer>
            <Link to={ROUTER.LANDING}>
              <CompanyImg src='/logo.svg' alt='main_logo' />
            </Link>

            <GnbProfile />

            <TabContainer>
              <GnbTitle>점검지</GnbTitle>
              <LinkContainer>
                <GnbItem name='점검지 관리' router={ROUTER.DASHBOARD}>
                  <BiSolidDashboard />
                </GnbItem>
                <GnbItem name='작업자 정보 관리' router={ROUTER.WORKER}>
                  <BsFillPeopleFill />
                </GnbItem>
                {/* <GnbItem name='알림' router={ROUTER.NOTIFICATION}>
                  <BsFillPeopleFill />
                </GnbItem> */}
                {/* <GnbItem name='저장소' router={ROUTER.STORAGE}>
                  <BsFillPeopleFill />
                </GnbItem> */}
              </LinkContainer>
            </TabContainer>

            <TabContainer>
              <GnbTitle>설정</GnbTitle>
              <LinkContainer>
                <GnbItem name='결제 관리' router={ROUTER.PAYMENT}>
                  <MdPayment />
                </GnbItem>
                <GnbItem name='기본정보' router={ROUTER.MYCOMPANY}>
                  <AiFillSetting />
                </GnbItem>
              </LinkContainer>
            </TabContainer>

            {companyInfo?.data.isAdmin && (
              <TabContainer>
                <GnbTitle>관리자 기능</GnbTitle>
                <LinkContainer>
                  <GnbItem name='사용자 관리' router={ROUTER.ADMIN.USER_MANAGE}>
                    <FaBuildingUser />
                  </GnbItem>
                </LinkContainer>
              </TabContainer>
            )}
          </GNBLeftTopContainer>

          <GnbLogoutBtn />
        </GNBLeftContainer>

        <ChildrenWrapper isGnbFold={isFoldGnb}>
          <Outlet />
        </ChildrenWrapper>
        <Footer padding='2.5rem 2.5rem 2.5rem 15rem' />
      </Container>
    </>
  );
};

export default GNB;

const Container = styled.header`
  position: relative;
  height: 100%;
`;

const GNBLeftContainer = styled.div<{ isGnbFold: boolean }>`
  transform: ${({ isGnbFold }) =>
    isGnbFold ? 'translateX(-100%)' : 'translateX(0)'};
  transition: transform 0.4s ease-in-out;

  z-index: 200;

  position: fixed;
  top: 0;
  left: 0;

  width: 12.5rem;
  height: 100%;

  padding: 1.25rem 0;

  background-color: ${Theme.colors.G_5};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  overflow: auto;
`;

const GNBLeftTopContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 2.5rem;

  margin-bottom: 2.5rem;
`;

const CompanyImg = styled.img`
  width: 10rem;
`;

const ChildrenWrapper = styled.section<{ isGnbFold: boolean }>`
  padding: ${({ isGnbFold }) =>
    isGnbFold ? '2.5rem' : '2.5rem 2.5rem 2.5rem 15rem'};

  /* padding: 2.5rem 2.5rem 2.5rem 15rem; */

  min-height: 100%;
`;

const TabContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 0.625rem;
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
