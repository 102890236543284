import client from '@/apis/client';
import type { Response } from '@/types/client.types';
import type {
  ReviewSystem,
  PostReviewSystemRequestProps,
} from '@/types/report/system.types';

// Ⅴ. 성능점검 시 검토사항
// 1. 기계설비 시스템 검토
export const getGuideline = (inspectionAreaId: number) =>
  client
    .get<Response<ReviewSystem>>('/report/guideline', {
      params: { inspectionAreaId },
    })
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error fetching guideline:', error);
      throw error;
    });

export const postGuideline = (body: PostReviewSystemRequestProps) =>
  client
    .post('/report/guideline/upload', body)
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error posting guideline:', error);
      throw error;
    });
