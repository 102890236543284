import styled from '@emotion/styled';
import { Theme } from '@/styles/Theme';
export const Container = styled.div`
  width: 100%;
  max-width: 43.75rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.875rem;
`;

export const BoxWrapper = styled.div`
  width: 100%;
`;

export const Title = styled.div`
  font-size: 3.125rem;
  font-weight: 700;
  margin-bottom: 3.125rem;
`;
export const Countcontainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
export const PayBoxContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;
export const PayBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  padding: 2.5rem 1.875rem;
  border: 0.0625rem solid ${Theme.colors.G_3};
  border-radius: 0.625rem;
`;
export const BoxTitle = styled.div`
  font-size: 1.875rem;
  font-weight: 700;
  margin-bottom: 0.3125rem;
  color: ${Theme.colors.B_3};
`;
export const BoxDesc = styled.div`
  font-size: 1rem;
  font-weight: 500;
  color: ${Theme.colors.G_1};
  margin-bottom: 1.875rem;
`;
export const BoxPrice = styled.div`
  font-size: 1.75rem;
  font-weight: 700;
  margin-bottom: 1.875rem;
`;
export const BoxTextContainer = styled.div`
  font-size: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.9375rem;
`;
export const BoxText = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.9375rem;
  align-items: center;
  font-size: 1.0625rem;
  font-weight: 500;
  color: ${Theme.colors.G_1};
`;
export const Line = styled.div`
  width: 100%;
  height: 0.0625rem;
  background-color: ${Theme.colors.G_5};
  margin: 2.5rem 0;
`;
export const BuyButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3.4375rem;
  border-radius: 0.4375rem;
  background-color: ${Theme.colors.B_3};
  color: ${Theme.colors.WHITE};
  font-size: 1.25rem;
  font-weight: 500;
  margin-top: 1.875rem;
  cursor: pointer;
`;

export const ContainerBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.25rem;
`;

export const InfoBox = styled.div`
  width: 18.75rem;
  padding: 0 1.25rem;
  position: relative;

  :first-of-type:after {
    position: absolute;
    top: 0;
    right: 0;
    content: '';
    display: block;
    height: 100%;
    width: 0.0625rem;
    background-color: ${Theme.colors.G_5};

    margin-right: -0.625rem;
  }

  :nth-of-type(2) {
    margin-left: 0.625rem;
  }
`;

export const HeightLine = styled.div`
  width: 0.0625rem;
  height: 100%;
  background-color: ${Theme.colors.G_5};
`;
