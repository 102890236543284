import {
  getBuildingInfo,
  getInspectionTarget,
  getFacilityMaintenanceOrganization,
  getFacilityMaintainer,
  getPreservationMethod,
} from '@/apis/report/building';
import { copyTemplate } from '@/utils/excelExport/excelExportUtils';
import * as ExcelJS from 'exceljs';

export const exportReportStatus = async (
  workbook: ExcelJS.Workbook,
  inspectionAreaId: number
) => {
  const response = await fetch('/report/3. 대상 건축물 현황.xlsx', {
    cache: 'no-store',
  });
  const arrayBuffer = await response.arrayBuffer();
  const newWorkBook = new ExcelJS.Workbook();
  await newWorkBook.xlsx.load(arrayBuffer);

  // 복사 하려는 워크시트
  const copySheet = newWorkBook.getWorksheet(1);
  // 복사 받을 워크시트
  let mainSheet = workbook.addWorksheet('Ⅲ. 대상 건축물 현황');

  copyTemplate(mainSheet, copySheet, [43, 71, 105]);

  // 데이터 넣어주는 부분
  const buildingInfoData = await getBuildingInfo(inspectionAreaId);
  const maintenanceWorkerData = await getFacilityMaintainer(inspectionAreaId);
  const maintenanceOrganizationData = await getFacilityMaintenanceOrganization(
    inspectionAreaId
  );
  const inspectionTargetData = await getInspectionTarget(inspectionAreaId);
  const preservationMethodData = await getPreservationMethod(inspectionAreaId);

  if (
    mainSheet &&
    buildingInfoData &&
    maintenanceWorkerData &&
    maintenanceOrganizationData &&
    inspectionTargetData &&
    preservationMethodData
  ) {
    const {
      buildingStatus: {
        buildingName,
        floorArea,
        households,
        purpose,
        qualification,
        standardDate,
      },
      managerStatus: { managerName, address, phoneNumber },
    } = buildingInfoData.data;

    // 건축물 현황
    mainSheet.getCell('B48').value = buildingName;
    if (households) {
      mainSheet.getCell('D48').value = `${floorArea}\n${households}`;
    } else {
      mainSheet.getCell('D48').value = floorArea;
    }
    mainSheet.getCell('F48').value = purpose;
    mainSheet.getCell('H48').value = qualification;
    mainSheet.getCell('I48').value = standardDate;

    // 관리주체
    mainSheet.getCell('C51').value = managerName;
    mainSheet.getCell('C52').value = address.replace('/', ' ');
    mainSheet.getCell('C53').value = phoneNumber;

    // 기계설비 유지관리자 현황 및 법정교육 이수 현황
    maintenanceWorkerData.data.facilityMaintainerList.forEach((worker, idx) => {
      // 2명 까지만
      if (idx < 2) {
        const {
          category,
          name,
          grade,
          seniorityDate,
          trainingCompletionDate,
          refresherTrainingDate,
        } = worker;
        mainSheet.getCell(57 + idx, 1).value = category;
        mainSheet.getCell(57 + idx, 2).value = name;
        mainSheet.getCell(57 + idx, 3).value = grade;
        mainSheet.getCell(57 + idx, 4).value = seniorityDate;
        mainSheet.getCell(57 + idx, 6).value = trainingCompletionDate;
        mainSheet.getCell(57 + idx, 8).value = refresherTrainingDate;
      }
    });

    maintenanceOrganizationData.data.maintenanceOrganization.forEach(
      (organization, idx) => {
        // 6명 까지만
        if (idx < 6) {
          const { position, name, seniorityDate, note } = organization;

          mainSheet.getCell(62 + idx, 1).value = position;
          mainSheet.getCell(62 + idx, 3).value = name;
          mainSheet.getCell(62 + idx, 4).value = seniorityDate;
          mainSheet.getCell(62 + idx, 7).value = note;
        }
      }
    );

    // 기계설비 성능점검 대상 현황표 부분
    const year = new Date().getFullYear();

    mainSheet.eachRow((row, rowNumber) => {
      if (rowNumber >= 76 && rowNumber <= 103) {
        const excelDeviceName = row.getCell(2).value;
        row.getCell(7).value = year;

        // array에서 일치하는 deviceName 찾기
        const matchedDeviceName =
          inspectionTargetData.data.inspectionDeviceList.find(
            (device) => device.name === excelDeviceName
          );

        if (matchedDeviceName) {
          const { isInstalled, selected } = matchedDeviceName;
          row.getCell(4).value = isInstalled
            ? '■ 설치 □ 미설치'
            : '□ 설치 ■ 미설치';
          row.getCell(6).value = selected;
        }
      }

      if (rowNumber > 103) {
        return;
      }
    });

    // // 신재생 에너지는 총합으로 보여줘야 함
    // let newEnergyInspectionAmount = 0;

    // inspectionTargetData.data.inspectionDeviceList.forEach((device) => {
    //   if (device.name.includes('신재생')) {
    //     newEnergyInspectionAmount += device.selected;
    //   }
    // });

    // if (newEnergyInspectionAmount > 0) {
    //   mainSheet.getCell('D83').value = '■ 설치 □ 미설치';
    //   mainSheet.getCell('F83').value = newEnergyInspectionAmount;
    // } else {
    //   mainSheet.getCell('D83').value = '□ 설치 ■ 미설치';
    //   mainSheet.getCell('F83').value = newEnergyInspectionAmount;
    // }

    // 유지관리 점검표 작성 및 보존 방법 부분
    const { nameList, hasDigitalFile, hasPrint, preservationPeriod } =
      preservationMethodData.data;

    nameList.forEach((list, idx) => {
      mainSheet.getCell(111 + idx, 4).value = list.position;
      mainSheet.getCell(111 + idx, 7).value = list.name;
    });

    if (hasDigitalFile) {
      mainSheet.getCell(113, 4).value = '■ 해당';
      mainSheet.getCell(113, 7).value = '□ 해당없음';
    } else {
      mainSheet.getCell(113, 4).value = '□ 해당';
      mainSheet.getCell(113, 7).value = '■ 해당없음';
    }

    if (hasPrint) {
      mainSheet.getCell(114, 4).value = '■ 해당';
      mainSheet.getCell(114, 7).value = '□ 해당없음';
    } else {
      mainSheet.getCell(114, 4).value = '□ 해당';
      mainSheet.getCell(114, 7).value = '■ 해당없음';
    }

    mainSheet.getCell(115, 4).value = preservationPeriod;
  }
};
