import { useState } from 'react';

import Button from '@/components/common/Button';
import GreyBox from '@/components/common/GreyBox';
import WorkAppendixHistoryTable from '@/components/work/WorkAppendixHistoryTable';
import WorkAppendixReportTable from '@/components/work/WorkAppendixReportTable';
import { DotsWrapper } from '@/components/work/WorkReportResultTable/style';

import { Theme } from '@/styles/Theme';

const WorkAppendixPage = () => {
  const [isReportTableRendered, setIsReportTableRendered] =
    useState<boolean>(true);

  return (
    <GreyBox title='별지 4호' width='58.125rem'>
      <DotsWrapper>
        <Button
          backgroundColor={Theme.colors.GREEN}
          onClick={() => setIsReportTableRendered((prev) => !prev)}
        >
          {isReportTableRendered ? '점검결과 내역서 보기' : '결과보고서 보기'}
        </Button>
      </DotsWrapper>
      {isReportTableRendered ? (
        <WorkAppendixReportTable />
      ) : (
        <WorkAppendixHistoryTable />
      )}
    </GreyBox>
  );
};

export default WorkAppendixPage;
