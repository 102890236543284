import React, { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import type { ErrorResponse } from '@/types/client.types';
import Text from '@/components/common/Text';

import Button from '@/components/common/Button';
import * as S from './style';
import { Theme } from '@/styles/Theme';

import GreyBox from '@/components/common/GreyBox';

import { QUERY_KEY } from '@/constants/queryKey';
import {
  getPerformanceImprovement,
  postPerformanceImprovement,
} from '@/apis/report/improvePlan';
import type { PerformanceImprovementItem } from '@/types/report/improvePlan.types';
import { StyledCol } from '@/styles/common';

interface PerformanceImproveCategoryItem {
  category: string;
  deviceList: PerformanceImprovementItem[];
}

const NonconformAndImproveTable = () => {
  const _ = require('lodash');

  const [isEdit, setEdit] = useState(false);
  const [editableImproveData, setEditableImproveData] =
    useState<Map<number, PerformanceImprovementItem>>();
  const queryClient = useQueryClient();
  const params = useParams();
  const inspectionAreaId = Number(params.id);

  const { data: improveData } = useQuery({
    queryKey: [QUERY_KEY.REPORT.IMPROVEMENT, inspectionAreaId],
    queryFn: () => getPerformanceImprovement(inspectionAreaId),
  });

  const { mutate: updatePerformanceData } = useMutation({
    mutationFn: postPerformanceImprovement,
    onSuccess: (data) => {
      toast.success('정보가 수정되었습니다.');
      queryClient.invalidateQueries([
        QUERY_KEY.REPORT.IMPROVEMENT,
        inspectionAreaId,
      ]);
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      toast.error(error.response?.data.message);
    },
  });

  useEffect(() => {
    let newState = new Map();
    improveData?.data.forEach((element: PerformanceImprovementItem) => {
      newState.set(element.templateDeviceId, element);
    });
    setEditableImproveData(newState);
  }, [improveData]);

  const handleUpdate = () => {
    if (editableImproveData === undefined) return;

    const request = Array.from(editableImproveData.values()).map(
      (item: PerformanceImprovementItem, index: number) => {
        return {
          templateDeviceId: item.templateDeviceId,
          insufficiencies: item.insufficiencies,
          improvement: item.improvement,
        };
      }
    );

    updatePerformanceData({
      inspectionAreaId: inspectionAreaId,
      improvementList: request,
    });
    setEdit(!isEdit);
  };

  const handleInsufficiencies = (id: number, value: string) => {
    setEditableImproveData((prev) => {
      let device = editableImproveData?.get(id);
      const newState = new Map(prev);
      if (device !== undefined) {
        device.insufficiencies = value;
        newState?.set(id, device);
      }
      return newState;
    });
  };

  const handleImprovement = (id: number, value: string) => {
    setEditableImproveData((prev) => {
      let device = editableImproveData?.get(id);
      const newState = new Map(prev);
      if (device !== undefined) {
        device.improvement = value;
        newState?.set(id, device);
      }
      return newState;
    });
  };

  return (
    <GreyBox width='58.125rem'>
      <S.TopContainer>
        <Text fontSize='1.25rem' fontWeight={600}>
          성능점검표에 따른 부적합 및 개선사항
        </Text>
        <Button
          backgroundColor={isEdit ? Theme.colors.BLACK : Theme.colors.B_3}
          onClick={() => {
            if (isEdit) handleUpdate();
            else setEdit(true);
          }}
        >
          {isEdit ? '저장하기' : '수정하기'}
        </Button>
      </S.TopContainer>

      <S.Container>
        <S.Table>
          <colgroup>
            <StyledCol width='9.375rem' />
            <StyledCol width='12.5rem' />
            <StyledCol width='12.5rem' />
            <StyledCol width='12.5rem' />
          </colgroup>
          <tbody>
            <tr>
              <S.TableHeader colSpan={2}>점검대상 기계설비</S.TableHeader>
              <S.TableHeader>미흡 사항</S.TableHeader>
              <S.TableHeader>개선 사항</S.TableHeader>
            </tr>
          </tbody>

          {improveData &&
            improveData.data &&
            editableImproveData &&
            _.chain(Array.from(editableImproveData.values()))
              .groupBy('category')
              .map(function (v: PerformanceImprovementItem, i: number) {
                return {
                  category: _.get(_.find(v, 'category'), 'category'),
                  deviceList: _.chain(v).value(),
                };
              })
              .value()
              .map((item: PerformanceImproveCategoryItem, index: number) => {
                return (
                  <S.InfoContainer>
                    {item.deviceList.map((device, index) => (
                      <>
                        <tr key={index}>
                          {index === 0 ? (
                            <td rowSpan={item.deviceList.length}>
                              {item.category}
                            </td>
                          ) : (
                            <></>
                          )}
                          <td>{device.templateDeviceName}</td>
                          <td>
                            {isEdit ? (
                              <S.StyledTextarea
                                name='미흡사항'
                                height='3.125rem'
                                value={
                                  editableImproveData.get(
                                    device.templateDeviceId
                                  )?.insufficiencies
                                }
                                onChange={(e) => {
                                  handleInsufficiencies(
                                    device.templateDeviceId,
                                    e.target.value
                                  );
                                }}
                              ></S.StyledTextarea>
                            ) : (
                              device.insufficiencies
                            )}
                          </td>
                          <td>
                            {isEdit ? (
                              <S.StyledTextarea
                                name='개선사항'
                                height='3.125rem'
                                value={
                                  editableImproveData.get(
                                    device.templateDeviceId
                                  )?.improvement
                                }
                                onChange={(e) => {
                                  handleImprovement(
                                    device.templateDeviceId,
                                    e.target.value
                                  );
                                }}
                              ></S.StyledTextarea>
                            ) : (
                              device.improvement
                            )}
                          </td>
                        </tr>
                      </>
                    ))}
                  </S.InfoContainer>
                );
              })}
        </S.Table>
      </S.Container>
    </GreyBox>
  );
};

export default NonconformAndImproveTable;
