import * as ExcelJS from 'exceljs';

import type { ExcelTotalReport } from '@/types/InspectionArea.types';
import { copyTemplate } from '@/utils/excelExport/excelExportUtils';

// 기계설비 성능 점검표 작성
export const exportPrimaryTable = async (
  totalReport: ExcelTotalReport,
  workbook: ExcelJS.Workbook
) => {
  const response = await fetch('/report/4.1 성능점검표.xlsx', {
    cache: 'no-store',
  });
  const arrayBuffer = await response.arrayBuffer();
  const newWorkBook = new ExcelJS.Workbook();
  await newWorkBook.xlsx.load(arrayBuffer);

  // 복사 하려는 워크시트
  const copySheet = newWorkBook.getWorksheet(1);
  // 복사 받을 워크시트
  let mainSheet = workbook.addWorksheet('Ⅳ. 성능점검표');

  copyTemplate(mainSheet, copySheet, [54, 108]);

  // 데이터 넣어주는 부분
  if (mainSheet) {
    const { name, address, result_list, start_date, end_date, worker_list } =
      totalReport;

    mainSheet.getCell('B113').value = name;
    mainSheet.getCell('E113').value = address.replace('/', ' ');

    const [startYear, startMonth, startDate] = start_date.split('-');
    const [endYear, endMonth, endDate] = end_date.split('-');

    mainSheet.getCell(
      'A131'
    ).value = `      점검기간 :      ${startYear}년 ${startMonth}월 ${startDate}일부터      ${endYear}년 ${endMonth}월 ${endDate}일까지`;
    mainSheet.getCell('A132').value = `점검자 :      ${worker_list.join(
      ', '
    )}      (서 명)`;

    // column B의 셀들을 순회
    mainSheet.eachRow((row, rowNumber) => {
      const leftCellContent = row.getCell(2).value; // B 컬럼의 값
      const rightCellContent = row.getCell(6).value; // F 컬럼의 값

      // array에서 일치하는 content 찾기
      const leftMatchingItem = result_list.find(
        (item: any) => item.content === leftCellContent
      );

      const rightMatchingItem = result_list.find(
        (item: any) => item.content === rightCellContent
      );

      // 일치하는 항목이 있다면 column C의 값을 update
      if (leftMatchingItem) {
        row.getCell(3).value = '[ ✓ ]';
        row.getCell(4).value = leftMatchingItem.result; // C 컬럼의 값 설정
      }
      if (rightMatchingItem) {
        row.getCell(7).value = '[ ✓ ]';
        row.getCell(8).value = rightMatchingItem.result; // C 컬럼의 값 설정
      }
    });
  }
};
