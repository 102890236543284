import styled from '@emotion/styled';
import { Theme } from '@/styles/Theme';

export const Table = styled.table`
  background-color: ${Theme.colors.WHITE};

  td {
    height: 3.125rem;
    border: 0.0625rem solid ${Theme.colors.BLACK};
    text-align: center;
  }
`;

export const TopContainer = styled.div`
  display: flex;
  margin-bottom: 1.25rem;
  justify-content: space-between;
`;

export const TableHeader = styled.td`
  background-color: ${Theme.colors.G_5};
  text-align: center;
  font-weight: 500;
`;

export const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 0.1875rem;
  align-items: center;
  margin-top: 0.625rem;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const InfoContainer = styled.tbody`
  tr:first-of-type {
    td:first-of-type {
      background-color: ${Theme.colors.G_5};
    }
  }
`;

export const StyledTextarea = styled.textarea<{
  height?: string;
  textAlign?: string;
}>`
  display: block;

  width: 100%;
  height: ${({ height }) => height};
  background-color: rgb(255, 248, 226, 0.7);

  border: none;

  padding: 0.3125rem;

  color: ${Theme.colors.BLACK};
  font-size: 1rem;
  font-weight: 400;
  text-align: ${({ textAlign }) => textAlign};
  resize: none;

  :disabled {
    background-color: ${Theme.colors.WHITE};
  }

  ::placeholder {
    color: ${Theme.colors.G_2};
  }

  :focus {
    outline: none;
  }
`;
