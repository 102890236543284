import React, { useState } from 'react';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { AiOutlineFileImage } from 'react-icons/ai';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postInspectionEquipment } from '@/apis/report/equipment';
import type { ErrorResponse } from '@/types/client.types';

import { QUERY_KEY } from '@/constants/queryKey';

import Modal from '@/components/common/Modal';
import Input from '@/components/common/Input';

import { Theme } from '@/styles/Theme';

import * as S from './style';
import uploadImagePresignedUrl from '@/utils/uploadImagePresignedUrl';

interface Props {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const EquipmentAddModal = ({ setIsModalOpen }: Props) => {
  const [image, setImage] = useState<string>('');
  const [name, setName] = useState<string>('');

  const [model, setModel] = useState<string>('');
  const [manufacturingCompany, setManufacturingCompany] = useState<string>('');
  const [isLegal, setIsLegal] = useState<boolean>(true);

  const queryClient = useQueryClient();

  const { mutate: postMutate } = useMutation({
    mutationFn: postInspectionEquipment,
    onSuccess: (data) => {
      queryClient.invalidateQueries([QUERY_KEY.REPORT.EQUIPMENT]);
      toast.success('장비가 등록되었습니다.');
      setIsModalOpen(false);
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      toast.error(error.response?.data.message);
    },
  });

  const handleImgChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const uploadedUrl = await uploadImagePresignedUrl(e.target.files);
      if (uploadedUrl) {
        setImage(uploadedUrl);
      } else {
        toast.error('이미지 업로드에 실패했습니다');
      }
    } catch (error) {
      toast.error('이미지 업로드 중 에러가 발생했습니다');
      console.error('Error during image upload:', error);
    }
  };

  const handleConfirmEdit = () => {
    if (name === '' || model === '' || image === '') {
      toast.error('장비 정보를 입력해주세요.');
      return;
    }

    postMutate({
      name,
      image,
      manufacturingCompany,
      model,
      isLegalRegistration: isLegal ? 1 : 0,
    });
  };

  return (
    <Modal
      width='43.75rem'
      closeText='닫기'
      confirmText='등록하기'
      setIsModalOpen={setIsModalOpen}
      hasButton={true}
      onClickConfirm={handleConfirmEdit}
    >
      <S.Container>
        <S.FlexContainer>
          <S.ImageContainer>
            <S.FileInput
              type='file'
              id='img_input_equipment'
              accept='.jpg, .jpeg, .png'
              onChange={handleImgChange}
            />

            {image !== '' ? (
              <S.ImageWrapper src={image}></S.ImageWrapper>
            ) : (
              <S.StyledEmptyImage htmlFor='img_input_equipment'>
                <AiOutlineFileImage
                  size='50px'
                  color={Theme.colors.G_2}
                ></AiOutlineFileImage>
              </S.StyledEmptyImage>
            )}

            {image !== '' ? (
              <S.FileLabel htmlFor='img_input_equipment'>사진변경</S.FileLabel>
            ) : (
              <></>
            )}
          </S.ImageContainer>
          <S.InformationContainer>
            <S.InfoWrapper>
              <h1>장비명</h1>
              <Input
                name='장비명'
                width='100%'
                height='2.5rem'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </S.InfoWrapper>
            <S.InfoWrapper>
              <h1>모델명</h1>
              <Input
                name='모델명'
                width='100%'
                height='2.5rem'
                value={model}
                onChange={(e) => setModel(e.target.value)}
              />
            </S.InfoWrapper>
            <S.InfoWrapper>
              <h1>제조사</h1>
              <Input
                name='제조사'
                width='100%'
                height='2.5rem'
                value={manufacturingCompany}
                onChange={(e) => setManufacturingCompany(e.target.value)}
              />
            </S.InfoWrapper>
            <S.InfoWrapper>
              <h1>법정 등록 장비</h1>
              <S.LegalWrapper>
                <S.CheckBoxWrapper>
                  {<span>등록</span>}
                  <S.StyledCheckbox
                    selected={isLegal}
                    onClick={() => setIsLegal(true)}
                  >
                    ✔
                  </S.StyledCheckbox>
                </S.CheckBoxWrapper>
                <S.CheckBoxWrapper>
                  {<span>미등록</span>}
                  <S.StyledCheckbox
                    selected={!isLegal}
                    onClick={() => setIsLegal(false)}
                  >
                    ✔
                  </S.StyledCheckbox>
                </S.CheckBoxWrapper>
              </S.LegalWrapper>
            </S.InfoWrapper>
          </S.InformationContainer>
        </S.FlexContainer>
      </S.Container>
    </Modal>
  );
};

export default EquipmentAddModal;
