import Text from '@/components/common/Text';
import * as styled from './style';
import { Theme } from '@/styles/Theme';
import {
  UnsubscribeWorkersBtn,
  SubscribeWorkersBtn,
  UndoUnsubscribeWorkersBtn,
} from './ManegeBtns';
import Button from '@/components/common/Button';
import { useSelectedWorkerListStore } from '@/store/selectedWorkerList';
import useSubscribeStore from '@/store/subscribeMode';

interface Props {
  activeWorkerCount: number;
  allWorkerCount: number;
  isTrial: 0 | 1;
  isApproved: 0 | 1;
}

const WorkerPaymentInfoHeader = ({
  activeWorkerCount,
  allWorkerCount,
  isTrial,
  isApproved,
}: Props) => {
  const { isSubscribeMode, setIsSubscribeMode } = useSubscribeStore();
  const {
    selectedAddWorkerList,
    selectedRestoreWorkerList,
    selectedUnsubscribeWorkerList,
    selectedUndoUnsubscribeWorkerList,
  } = useSelectedWorkerListStore();

  return (
    <styled.TableHeaderWrapper>
      {isApproved === 1 && (
        <Text textAlign='right' color={Theme.colors.G_1}>
          {` 작업자 수: ${activeWorkerCount} / ${allWorkerCount}`}
        </Text>
      )}
      <styled.BtnWrapper>
        {isApproved === 1 && (
          <Button
            height='2.125rem'
            onClick={() => setIsSubscribeMode(!isSubscribeMode)}
            backgroundColor={
              isSubscribeMode ? Theme.colors.WHITE : Theme.colors.B_3_light
            }
            color={isSubscribeMode ? Theme.colors.BLACK : Theme.colors.WHITE}
            border={
              isSubscribeMode ? `0.0625rem solid ${Theme.colors.G_3}` : ''
            }
          >
            {isSubscribeMode ? '취소' : '+ 작업자 추가/복구'}
          </Button>
        )}

        {isSubscribeMode ? (
          <>
            {isApproved === 1 &&
              (selectedAddWorkerList.length > 0 ||
                selectedRestoreWorkerList.length > 0) && (
                <SubscribeWorkersBtn isTrial={isTrial} />
              )}
          </>
        ) : (
          <>
            {selectedUnsubscribeWorkerList.length > 0 && (
              <UnsubscribeWorkersBtn />
            )}
            {selectedUndoUnsubscribeWorkerList.length > 0 && (
              <UndoUnsubscribeWorkersBtn />
            )}
          </>
        )}
      </styled.BtnWrapper>
      {isTrial === 1 && isApproved === 0 && (
        <Text>
          무료 체험은 작업자 최대 3명까지 추가 가능합니다. 추가할 3명만 선택해
          주세요.
        </Text>
      )}
    </styled.TableHeaderWrapper>
  );
};

export default WorkerPaymentInfoHeader;
