import { create } from 'zustand';

interface LoadingStoreState {
  loading: boolean;
  activeRequests: number;
  timeoutID: NodeJS.Timeout | null;
  setLoading: (loading: boolean) => void;
  beginRequest: () => void;
  endRequest: () => void;
}

export const useLoadingStore = create<LoadingStoreState>((set) => ({
  loading: false,
  activeRequests: 0,
  timeoutID: null,
  setLoading: (loading: boolean) => set({ loading }),
  beginRequest: () => {
    set((state) => {
      if (state.activeRequests === 0) {
        const id = setTimeout(() => {
          set({ loading: true });
        }, 1000);
        return { timeoutID: id, activeRequests: 1 };
      }
      return { activeRequests: state.activeRequests + 1 };
    });
  },
  endRequest: () => {
    set((state) => {
      if (state.activeRequests === 1) {
        if (state.timeoutID) {
          clearTimeout(state.timeoutID);
          return { loading: false, activeRequests: 0, timeoutID: null };
        }
      }
      return { activeRequests: state.activeRequests - 1 };
    });
  },
}));
