import { getBuildingInfo } from '@/apis/report/building';
import { QUERY_KEY } from '@/constants/queryKey';

import { useQuery } from '@tanstack/react-query';

import { useParams } from 'react-router-dom';

import * as S from './style';
import Text from '@/components/common/Text';

const BuildingInfoTable = () => {
  const params = useParams();
  const inspectionAreaId = Number(params.id);

  const { data: buildingInfoData } = useQuery({
    queryKey: [QUERY_KEY.REPORT.BUILDING.INFO, inspectionAreaId],
    queryFn: () => getBuildingInfo(inspectionAreaId),
  });

  return (
    <S.Container>
      <Text fontWeight={600} fontSize='1.25rem'>
        건축물 현황
      </Text>
      <S.BuildingTable>
        <colgroup>
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
        </colgroup>
        <tbody>
          {buildingInfoData && buildingInfoData.data && (
            <>
              <tr>
                <td>건축물명</td>
                <td>연면적</td>
                <td>용도</td>
                <td>선임인(등급)</td>
                <td>성능점검 기준일</td>
              </tr>
              <tr>
                <td>{buildingInfoData.data.buildingStatus.buildingName}</td>
                <td>{buildingInfoData.data.buildingStatus.floorArea}</td>
                <td>{buildingInfoData.data.buildingStatus.purpose}</td>
                <td>{buildingInfoData.data.buildingStatus.qualification}</td>
                <td>{buildingInfoData.data.buildingStatus.standardDate}</td>
              </tr>
            </>
          )}
        </tbody>
      </S.BuildingTable>
      <Text fontWeight={600} fontSize='1.25rem'>
        관리주체
      </Text>
      <S.ManagerTable>
        <colgroup>
          <col />
          <col />
        </colgroup>
        <tbody>
          {buildingInfoData && buildingInfoData.data && (
            <>
              <tr>
                <td></td>
                <td>소유자(입주자대표회의, 관리단)</td>
              </tr>
              <tr>
                <td>성명(명칭)</td>
                <td>{buildingInfoData.data.managerStatus.managerName}</td>
              </tr>
              <tr>
                <td>주소</td>
                <td>
                  {buildingInfoData.data.managerStatus.address.replace(
                    '/',
                    ' '
                  )}
                </td>
              </tr>
              <tr>
                <td>전화/팩스</td>
                <td>{buildingInfoData.data.managerStatus.phoneNumber}</td>
              </tr>
            </>
          )}
        </tbody>
      </S.ManagerTable>
    </S.Container>
  );
};

export default BuildingInfoTable;
