import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AiOutlineRight } from 'react-icons/ai';
import { GrPowerReset } from 'react-icons/gr';
import { BsCheckAll } from 'react-icons/bs';

import { getPrimaryCategory, getSubCategory } from '@/apis/template';
import type { PostWorkDeviceInfo } from '@/types/InspectionArea.types';
import type { TargetCategory } from '@/types/template.types';

import { QUERY_KEY } from '@/constants/queryKey';

import Button from '@/components/common/Button';

import { Theme } from '@/styles/Theme';

import * as S from './style';

interface Props {
  selectedDeviceList: PostWorkDeviceInfo[];
  setSelectedDeviceList: React.Dispatch<
    React.SetStateAction<PostWorkDeviceInfo[]>
  >;
}

const WorkAddCategoryListSelector = ({
  selectedDeviceList,
  setSelectedDeviceList,
}: Props) => {
  const [selectedPrimaryCategory, setSelectedPrimaryCategory] =
    useState<number>(0);
  const [allCategory, setAllCategory] = useState<TargetCategory[]>([]);

  const { data: primaryCategoryData } = useQuery([QUERY_KEY.PRIMARY_CATEGORY], {
    queryFn: getPrimaryCategory,
  });

  const { data: subCategoryData } = useQuery(
    [QUERY_KEY.SUB_CATEGORY, selectedPrimaryCategory],
    {
      queryFn: () => getSubCategory(selectedPrimaryCategory),
    }
  );

  const handleSubCategory = (category: TargetCategory) => {
    const isSelected = selectedDeviceList.some(
      (c) => c.template_device_id === category.id
    );

    if (isSelected) {
      const answer = window.confirm(
        category.name +
          '에 대해 입력된 값이 모두 사라집니다.\n정말 삭제하시겠습니까?'
      );

      if (answer) {
        const filteredList = selectedDeviceList.filter(
          (item) => item.template_device_id !== category.id
        );
        setSelectedDeviceList(filteredList);
      }
      return;
    }

    setSelectedDeviceList((prev) => [
      ...prev,
      {
        template_device_id: category.id,
        name: category.name,
        total: 0,
        selected: 0,
        info: [],
      },
    ]);
  };

  useEffect(() => {
    if (selectedPrimaryCategory === 0 && subCategoryData) {
      setAllCategory(subCategoryData.data.result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subCategoryData]);

  const handleCheckAll = () => {
    const answer = window.confirm(
      '기존 데이터가 없어집니다.\n정말 전체 설비 선택을 하시겠습니까?'
    );
    if (answer) {
      const arr: PostWorkDeviceInfo[] = allCategory.map((category) => {
        return {
          template_device_id: category.id,
          name: category.name,
          total: 0,
          selected: 0,
          info: [],
        };
      });
      setSelectedDeviceList(arr);
    }
  };

  const handleClearList = () => {
    const answer = window.confirm('정말로 초기화 하시겠습니까?');
    if (answer) {
      setSelectedDeviceList([]);
    }
  };

  return (
    <S.Container>
      <S.SearchContainer>
        <Button
          onClick={handleCheckAll}
          backgroundColor={Theme.colors.WHITE}
          color={Theme.colors.G_1}
          border={`0.0625rem solid ${Theme.colors.G_3}`}
          height='2rem'
          fontWeight={400}
        >
          <BsCheckAll size='1.25rem' />
          전체 설비 선택
        </Button>
        <Button
          onClick={handleClearList}
          backgroundColor={Theme.colors.WHITE}
          color={Theme.colors.G_1}
          border={`0.0625rem solid ${Theme.colors.G_3}`}
          height='2rem'
          fontWeight={400}
        >
          <GrPowerReset />
          초기화
        </Button>
      </S.SearchContainer>
      <S.CategoryContainer>
        <S.PrimaryCategoryContainer>
          <S.PrimaryCategoryItemContainer
            isActive={selectedPrimaryCategory === 0}
            onClick={() => setSelectedPrimaryCategory(0)}
          >
            <div>전체 설비</div>
            {selectedPrimaryCategory === 0 && <AiOutlineRight />}
          </S.PrimaryCategoryItemContainer>
          {primaryCategoryData &&
            primaryCategoryData.data.result.map((category) => (
              <S.PrimaryCategoryItemContainer
                key={category.id}
                isActive={selectedPrimaryCategory === category.id}
                onClick={() => setSelectedPrimaryCategory(category.id)}
              >
                <div>{category.name}</div>
                {selectedPrimaryCategory === category.id && <AiOutlineRight />}
              </S.PrimaryCategoryItemContainer>
            ))}
        </S.PrimaryCategoryContainer>
        <S.SubCategoryContainer>
          {subCategoryData &&
            subCategoryData.data.result.map((subCategory) => (
              <S.SubCategoryWrapper
                key={subCategory.id}
                isSelected={selectedDeviceList.some(
                  (c) => c.template_device_id === subCategory.id
                )}
                onClick={() => handleSubCategory(subCategory)}
              >
                {subCategory.name}
              </S.SubCategoryWrapper>
            ))}
        </S.SubCategoryContainer>
      </S.CategoryContainer>
    </S.Container>
  );
};

export default WorkAddCategoryListSelector;
