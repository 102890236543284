import useGetWorkerList from '@/hooks/useGetWorkerList';
import { useNavigate } from 'react-router-dom';

interface Props {
  setSelectedWorker: React.Dispatch<React.SetStateAction<number | null>>;
}

const WorkerDropdown = ({ setSelectedWorker }: Props) => {
  const { data: workerData } = useGetWorkerList();
  const navigate = useNavigate();

  const selectWorkerHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    navigate('');
    if (e.target.value) {
      setSelectedWorker(Number(e.target.value));
    } else {
      setSelectedWorker(null);
    }
  };

  return (
    <select
      name='worker-history'
      id='worker-select'
      onChange={selectWorkerHandler}
    >
      <option value=''>전체 수정 내역</option>
      {workerData?.data.workerList.map((worker) => (
        <option key={worker.id} value={worker.id}>
          {worker.name}
        </option>
      ))}
    </select>
  );
};

export default WorkerDropdown;
