import styled from '@emotion/styled';

import { MANUAL_S3_LINK } from '@/constants/constants';

import Text from '@/components/common/Text';
import Button from '@/components/common/Button';

import { Theme } from '@/styles/Theme';

const Footer = ({ padding }: { padding: string }) => {
  return (
    <Wrapper padding={padding}>
      <LeftContainer>
        <LinkContainer>
          <a href='http://www.lsmk.kr/' target='_blank' rel='noreferrer'>
            <Text fontWeight={700}>회사소개</Text>
          </a>
          <a
            href='https://www.notion.so/3e37a1b12e9b469ea255bc0960193a2c'
            target='_blank'
            rel='noreferrer'
          >
            <Text fontWeight={700}>개인정보처리방침</Text>
          </a>
          <a
            href='https://www.notion.so/ee88ac4097dc4955a819f009b424bfaa'
            target='_blank'
            rel='noreferrer'
          >
            <Text fontWeight={700}>이용약관</Text>
          </a>
          <a
            href='https://www.notion.so/20c1b8408cfa40fc84e61f0b23a5ab75'
            target='_blank'
            rel='noreferrer'
          >
            <Text fontWeight={700}>결제 및 환불 규정</Text>
          </a>
        </LinkContainer>

        <CompanyContainer>
          <FooterImg
            width='2.25rem'
            height='2.25rem'
            src='/logo192.png'
            alt='company_logo'
          />
          <Text color={Theme.colors.B_4} fontSize='1.9375rem' fontWeight={700}>
            1670-1204
          </Text>
          <Text fontSize='0.75rem'>평일 09:00 ~ 18:00 (주말&공휴일 제외)</Text>
        </CompanyContainer>

        <Text fontSize='0.75rem' color={Theme.colors.G_1}>
          주식회사 LSMK (대표이사: 송동정) | 주소 : 서울특별시 송파구 문정동
          642-3 SK-V1 GL메트로시티 A907호
          <br />
          사업자등록번호 : 496-88-02094 | 통신판매업신고번호 :
          2021-서울송파-3572호 | 직업정보제공사업 신고번호
          <br />© 2021 LSMK Corp.
        </Text>
      </LeftContainer>
      <RightContainer>
        <Text color={Theme.colors.G_1}>
          점검자용 앱을 이용하시려면 클릭해서 앱을 설치하세요.
        </Text>
        <StoreContainer>
          <a
            href='https://play.google.com/store/apps/details?id=com.lsmk.BlueMentor'
            target='_blank'
            rel='noreferrer'
          >
            <FooterImg
              width='11.5625rem'
              src='/googleplay.png'
              alt='google_play'
            />
          </a>
          <a
            href='https://apps.apple.com/kr/app/%EB%B8%94%EB%A3%A8%EB%A9%98%ED%86%A0/id6475704951'
            target='_blank'
            rel='noreferrer'
          >
            <FooterImg
              width='11.5625rem'
              src='/applestore.png'
              alt='app_store'
            />
          </a>
        </StoreContainer>
        <Text color={Theme.colors.G_1}>클릭해서 사용 방법을 확인하세요.</Text>
        <StoreContainer>
          <Button
            type='button'
            width='9.375rem'
            fontSize='1rem'
            borderRadius='0.625rem'
            border={`0.0625rem solid ${Theme.colors.G_5}`}
            color={Theme.colors.G_1}
            fontWeight={500}
            backgroundColor={Theme.colors.WHITE}
            onClick={() => window.open(MANUAL_S3_LINK.MAIN, '_blank')}
          >
            전체 기능 설명서
          </Button>
          <Button
            type='button'
            width='9.375rem'
            fontSize='1rem'
            borderRadius='0.625rem'
            border={`0.0625rem solid ${Theme.colors.G_5}`}
            color={Theme.colors.G_1}
            fontWeight={500}
            backgroundColor={Theme.colors.WHITE}
            onClick={() => window.open(MANUAL_S3_LINK.APP, '_blank')}
          >
            앱 기능 설명서
          </Button>
          <Button
            type='button'
            width='9.375rem'
            fontSize='1rem'
            borderRadius='0.625rem'
            border={`0.0625rem solid ${Theme.colors.G_5}`}
            color={Theme.colors.G_1}
            fontWeight={500}
            backgroundColor={Theme.colors.WHITE}
            onClick={() => window.open(MANUAL_S3_LINK.WEB, '_blank')}
          >
            웹 기능 설명서
          </Button>
        </StoreContainer>
      </RightContainer>
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled.footer<{ padding: string }>`
  margin-top: 6.25rem;
  width: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.875rem;

  background-color: ${Theme.colors.G_6};

  padding: ${({ padding }) => padding};
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1 1 18.75rem;

  gap: 1.25rem;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 18.75rem;

  gap: 0.9375rem;
`;

const LinkContainer = styled.nav`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.875rem;
`;

const CompanyContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
`;

const FooterImg = styled.img<{ width?: string; height?: string }>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;

const StoreContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
`;
