import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getInspectionArea } from '@/apis/InspectionArea';

import { QUERY_KEY } from '@/constants/queryKey';

import * as S from './style';
import { proportionConverter, unitConverter } from '@/utils/templateConveter';
import { StyledCol } from '@/styles/common';

const ReportDeviceCountTable = () => {
  const params = useParams();
  const inspectionAreaId = Number(params.id);

  const { data: workStatusData } = useQuery({
    queryKey: [QUERY_KEY.WORK_INFO, inspectionAreaId],
    queryFn: () => getInspectionArea(inspectionAreaId),
  });

  return (
    <S.Container>
      <S.Table>
        <colgroup>
          <StyledCol width='12.5rem' />
          <StyledCol width='12.5rem' />
          <StyledCol width='4.375rem' />
          <StyledCol width='4.375rem' />
          <StyledCol width='4.375rem' />
          <StyledCol width='4.375rem' />
        </colgroup>
        <tbody>
          <tr>
            <S.TableHeader rowSpan={2}>구분</S.TableHeader>
            <S.TableHeader rowSpan={2}>점검대상</S.TableHeader>
            <S.TableHeader colSpan={4}>분류</S.TableHeader>
          </tr>
          <tr>
            <S.TableHeader>단위</S.TableHeader>
            <S.TableHeader>전체수량</S.TableHeader>
            <S.TableHeader>점검수량</S.TableHeader>
            <S.TableHeader>필요 비율</S.TableHeader>
          </tr>
        </tbody>
        {workStatusData &&
          workStatusData.data &&
          workStatusData.data.device_list.map((template, idx) => {
            return (
              <S.InfoContainer key={idx}>
                {template.device_info.map((device, idx) => (
                  <tr key={idx}>
                    {idx === 0 ? (
                      <td rowSpan={template.device_info.length}>
                        {template.primary}
                      </td>
                    ) : (
                      <></>
                    )}
                    <>
                      <td>{device.name}</td>
                      <td>{unitConverter(device.name)}</td>
                      <td>{device.total}</td>
                      <td>{device.selected}</td>
                      <td>{proportionConverter(device.name)}</td>
                    </>
                  </tr>
                ))}
              </S.InfoContainer>
            );
          })}
      </S.Table>
    </S.Container>
  );
};

export default ReportDeviceCountTable;
