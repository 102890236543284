import * as ExcelJS from 'exceljs';

import { rowStartNumTemplateImg6 } from '@/constants/excel';
import type { ExcelDeviceList } from '@/types/InspectionArea.types';
import { addImageToSheet } from '@/utils/excelExport/excelExportUtils';

export const templateImg6ExportReportTable = async (
  data: ExcelDeviceList[],
  workbook: ExcelJS.Workbook,
  worksheet: ExcelJS.Worksheet
) => {
  // const worksheet = workbook.getWorksheet(idx + 6);

  if (worksheet) {
    for (let i = 0; i < data.length; i++) {
      const { device_info, item_list } = data[i];

      const {
        name,
        worker_list,
        start_date,
        end_date,
        device_num,
        location,
        detail_one,
        detail_two,
        memo,
        selected_image,
      } = device_info;

      const colOffset = 12 * i;
      // 각 점검항목 갯수가 다르기 때문에
      const rowOffset = item_list.length;

      const date = `${start_date.replaceAll('-', '.')}~${end_date
        .replaceAll('-', '.')
        .slice(5)}`;

      // 제목, 점검자, 점검일자, 설치위치
      worksheet.getCell(1, 1 + colOffset).value = `${name}${
        device_num ? `(${device_num})` : ''
      } 성능점검표 ${data.length > 1 ? `#${i + 1}` : ''}`;
      worksheet.getCell(3, 3 + colOffset).value = worker_list.join(', ');
      worksheet.getCell(3, 7 + colOffset).value = date;
      worksheet.getCell(3, 11 + colOffset).value = location;

      // 점검 항목
      item_list.forEach((item: any, idx: number) => {
        worksheet.getCell(5 + idx, 10 + colOffset).value = `${idx + 1}. ${
          item.content
        }`;
        worksheet.getCell(5 + idx, 11 + colOffset).value = item.result;
      });

      // 미흡사항, 조치필요사항
      worksheet.getCell(5 + rowOffset, 3 + colOffset).value = `<미흡사항>\n${
        detail_one ?? ''
      }`;
      worksheet.getCell(
        8 + rowOffset,
        3 + colOffset
      ).value = `<조치필요사항>\n${detail_two ?? ''}`;

      // 현황사진
      const imagePromises = selected_image?.map(
        async (imgUrl: string, idx: number) => {
          if (imgUrl) {
            const imageId = await addImageToSheet(imgUrl, workbook);

            if (idx === 0) {
              worksheet.addImage(imageId, {
                tl: { col: 0.1 + 2 + colOffset, row: 0.3 + 10 + rowOffset },
                ext: { width: 255, height: 200 },
              });
            }

            if (idx === 1) {
              worksheet.addImage(imageId, {
                tl: { col: 0.1 + 7 + colOffset, row: 0.3 + 10 + rowOffset },
                ext: { width: 255, height: 200 },
              });
            }
          }
        }
      );

      await Promise.all(imagePromises || []);

      // 비고
      worksheet.getCell(21 + rowOffset, 3 + colOffset).value = memo;

      // 하단 점검 결과 부분
      const dataStartArr =
        rowStartNumTemplateImg6[name as keyof typeof rowStartNumTemplateImg6]
          .dataStartArr;

      // 측정값 위치 정보 부분
      const calculateInfoArr =
        rowStartNumTemplateImg6[name as keyof typeof rowStartNumTemplateImg6]
          ?.calculateInfoArr;

      let calculateCount = 0;

      for (let j = 0; j < item_list.length; j++) {
        const { image, result_text, result, value } = item_list[j];

        // 측정값 넣는 부분
        if (value.length > 0 && calculateInfoArr) {
          const fixedRowPos = dataStartArr[j];
          const calculateInfo = calculateInfoArr[calculateCount];

          if (calculateInfo) {
            // calculateInfo의 key=0은 장비번호 정보
            if (calculateInfo.hasOwnProperty(0)) {
              const [rowGap, col] = calculateInfo[0];

              worksheet.getCell(rowGap + fixedRowPos, col + colOffset).value =
                device_num;
            }

            value.forEach((info) => {
              if (calculateInfo.hasOwnProperty(info.template_id)) {
                const [rowGap, col] = calculateInfo[info.template_id];

                worksheet.getCell(rowGap + fixedRowPos, col + colOffset).value =
                  info.value;
              }
            });

            calculateCount++;
          }
        }

        worksheet.getCell(
          dataStartArr[j] + 16,
          1 + colOffset
        ).value = `사유: ${result_text}`;

        // 적합일 때, 사유 비어있으면 자동으로 '점검기준 충족' 넣어주기
        if (result === 'O' && result_text.length < 1) {
          worksheet.getCell(
            dataStartArr[j] + 16,
            1 + colOffset
          ).value = `사유: 점검기준 충족`;
        }

        if (result === '/') {
          for (let k = 0; k < 6; k++) {
            if (k < 3) {
              worksheet.getCell(dataStartArr[j], 4 * k + 1 + colOffset).value =
                '해당사항없음';
            } else {
              worksheet.getCell(
                dataStartArr[j] + 8,
                4 * (k - 3) + 1 + colOffset
              ).value = '해당사항없음';
            }
          }
        }

        for (let i = 0; i < image.length; i++) {
          const imageId = await addImageToSheet(image[i], workbook);

          if (i < 3) {
            worksheet.addImage(imageId, {
              tl: {
                col: 0.1 + i * 4 + colOffset,
                row: 0.3 + dataStartArr[j] - 1,
              },
              ext: { width: 203, height: 167 },
            });
          } else {
            worksheet.addImage(imageId, {
              tl: {
                col: 0.1 + (i - 3) * 4 + colOffset,
                row: 0.3 + dataStartArr[j] + 7,
              },
              ext: { width: 203, height: 167 },
            });
          }
        }
      }
    }
  }
};
