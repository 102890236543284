import * as S from './style';
import Text from '@/components/common/Text';
import { Theme } from '@/styles/Theme';
import { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { Box, BoxWrapper, SubTitle } from '../style';
import type { PaymentInfo } from '@/types/company.types';
import { dateFormat, dDayFormat } from '@/utils/convertDateFormat';
import Spacer from '@/components/common/Spacer';

interface Props {
  paymentData: PaymentInfo;
}

const UserPaymentInfo = ({ paymentData }: Props) => {
  const [isDetailOpen, setIsDetailOpen] = useState<boolean>(true);

  const {
    activeWorkerCount,
    firstPaymentDate,
    isTrial,
    nearPaymentDate,
    numberOfNearPayments,
    pricePerWorker,
  } = paymentData;

  return (
    <BoxWrapper>
      <SubTitle>사용 현황</SubTitle>
      <Box>
        <S.FlexContainer gap='0.5rem'>
          <Text fontSize='1.25rem' fontWeight={500} color={Theme.colors.DG_1}>
            현재 {activeWorkerCount}명의 작업자 계정 사용 중
          </Text>
          {isTrial === 1 && (
            <S.TrialLabel>
              남은 무료 체험 기간 {dDayFormat(firstPaymentDate)}일
            </S.TrialLabel>
          )}
        </S.FlexContainer>
        {nearPaymentDate && (
          <Text fontSize='1.625rem' fontWeight={700} margin='-0.9375rem 0 0 0'>
            {`${dateFormat(nearPaymentDate)}(D-${dDayFormat(
              nearPaymentDate
            )}) `}
            <Text fontSize='1.625rem' fontWeight={700} color={Theme.colors.B_3}>
              {`${(pricePerWorker * numberOfNearPayments).toLocaleString(
                'ko-KR'
              )}원 `}
            </Text>
            결제 예정
          </Text>
        )}

        <div>
          <S.FlexContainer
            cursor='pointer'
            onClick={() => setIsDetailOpen(!isDetailOpen)}
          >
            <Text color={Theme.colors.DG_1}>
              세부사항 {isDetailOpen ? '숨기기' : '보기'}
            </Text>
            <S.IconWrapper paddingTop='0.1875rem'>
              <IoIosArrowDown
                size='1.1875rem'
                color={Theme.colors.G_1}
                style={{
                  transform: `rotate(${isDetailOpen ? '180deg' : '0'})`,
                }}
              />
            </S.IconWrapper>
          </S.FlexContainer>
          <S.DetailContainer isActive={isDetailOpen}>
            <S.InnerDetailContainer>
              <S.DetailLine>
                <Text>결제 시작일</Text>
                <Text>{dateFormat(firstPaymentDate)}</Text>
              </S.DetailLine>
              <S.DetailLine>
                <Text>작업자별 결제 금액</Text>
                <Text>{pricePerWorker.toLocaleString('ko-KR')}원</Text>
              </S.DetailLine>
              <Spacer y='1rem' />
              {nearPaymentDate && (
                <>
                  <S.DetailLine>
                    <Text>다음 결제일</Text>
                    <Text>{dateFormat(nearPaymentDate)}</Text>
                  </S.DetailLine>
                  <S.DetailLine>
                    <Text>다음 결제 작업자 수</Text>
                    <Text>{numberOfNearPayments}명</Text>
                  </S.DetailLine>
                  <S.DetailLine>
                    <Text>다음 결제 금액</Text>
                    <Text>
                      {(pricePerWorker * numberOfNearPayments).toLocaleString(
                        'ko-KR'
                      )}
                      원
                    </Text>
                  </S.DetailLine>
                </>
              )}
            </S.InnerDetailContainer>
          </S.DetailContainer>
        </div>
      </Box>
    </BoxWrapper>
  );
};

export default UserPaymentInfo;
