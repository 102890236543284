import { Theme } from '@/styles/Theme';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  position: relative;
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  top: 30%;
  left: 45%;

  button {
    :hover {
      background-color: green;
    }
  }
`;

export const Container = styled.div<{ isDeleted: boolean }>`
  position: relative;

  width: 100%;
  height: 7.5rem;

  background-color: ${Theme.colors.WHITE};
  border: 0.0625rem solid ${Theme.colors.G_2};
  border-radius: 0.75rem;

  padding: 0 1.25rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.625rem;

  ${({ isDeleted }) =>
    isDeleted &&
    css`
      pointer-events: none;
      opacity: 0.3;
    `}
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.9375rem;

  cursor: pointer;

  flex: 1;
`;

export const RightContainer = styled.div`
  width: 21.875rem;

  margin-right: 1.25rem;

  display: flex;
  flex-direction: column;
  gap: 0.1875rem;
`;

export const TextHide = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StatusChip = styled.div<{ backgroundColor: string }>`
  width: 5.125rem;
  height: 2.1875rem;
  border-radius: 0.4375rem;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ backgroundColor }) => backgroundColor};

  font-weight: bold;
  color: ${Theme.colors.WHITE};
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;

  span {
    flex: 1;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
  }
`;
