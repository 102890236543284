import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { AxiosError } from 'axios';

import MaintenanceOrganizationAddModal from '@/components/report/building/MaintenanceOrganizationAddModal';
import Text from '@/components/common/Text';
import Button from '@/components/common/Button';
import { Theme } from '@/styles/Theme';

import { QUERY_KEY } from '@/constants/queryKey';
import type { ErrorResponse } from '@/types/client.types';

import {
  getFacilityMaintenanceOrganization,
  deleteFacilityMaintenanceOrganization,
} from '@/apis/report/building';
import type { FacilityMaintenanceOrganizationItem } from '@/types/report/building.types';

import * as S from './style';

const MaintenanceOrganizationTable = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [orgWorkers, setOrgWorkers] = useState<
    FacilityMaintenanceOrganizationItem[]
  >([]);

  const [editWorker, setEditWorker] =
    useState<FacilityMaintenanceOrganizationItem | null>(null);

  const params = useParams();
  const inspectionAreaId = Number(params.id);

  const queryClient = useQueryClient();

  const { data: organizationData } = useQuery({
    queryKey: [QUERY_KEY.REPORT.BUILDING.ORGANIZATION, inspectionAreaId],
    queryFn: () => getFacilityMaintenanceOrganization(inspectionAreaId),
  });

  const { mutate: deleteOrgWorker } = useMutation({
    mutationFn: deleteFacilityMaintenanceOrganization,
    onSuccess: (data) => {
      toast.success('관리자 정보가 삭제되었습니다.');
      queryClient.invalidateQueries([
        QUERY_KEY.REPORT.BUILDING.ORGANIZATION,
        inspectionAreaId,
      ]);
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      toast.error(error.response?.data.message);
    },
  });

  useEffect(() => {
    if (organizationData?.data !== undefined) {
      let newState = new Map();
      organizationData?.data.maintenanceOrganization.forEach((element) => {
        newState.set(element.id, false);
      });
      setOrgWorkers([...organizationData.data.maintenanceOrganization]);
      setEditWorker(null);
    }
  }, [organizationData]);

  useEffect(() => {
    if (isModalOpen === false) {
      setEditWorker(null);
    }
  }, [isModalOpen]);

  const handleDelete = (id: number) => {
    deleteOrgWorker(id);
  };

  const handleUpdate = (id: number) => {
    let worker = orgWorkers.find((item) => item.id === id);

    if (worker !== undefined) {
      setEditWorker(worker);
      setIsModalOpen(true);
    }
  };

  const handleAddWorkerButton = () => {
    // 2명 까지만
    if (orgWorkers.length < 6) {
      setIsModalOpen(true);
      return;
    }
    toast.error('6명까지 등록 가능합니다.', {
      toastId: '6명까지 등록 가능합니다.',
    });
  };

  return (
    <S.Container>
      {isModalOpen && (
        <>
          <MaintenanceOrganizationAddModal
            inspectionAreaId={inspectionAreaId}
            setIsModalOpen={setIsModalOpen}
            orgWorker={editWorker}
          />
        </>
      )}
      <S.TextWrapper>
        <Text fontSize='1.25rem' fontWeight={600}>
          기계설비 유지관리 조직 (6명 까지 등록 가능)
        </Text>
        <Button height='2.1875rem' onClick={handleAddWorkerButton}>
          추가하기
        </Button>
      </S.TextWrapper>
      <S.Table>
        <colgroup>
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <td>직책</td>
            <td>성명</td>
            <td>법정선임</td>
            <td>비고</td>
            <td>수정</td>
            <td>삭제</td>
          </tr>
          {organizationData &&
            organizationData.data &&
            organizationData.data.maintenanceOrganization.map(
              (worker, index) => (
                <tr key={index}>
                  <td>{worker.position}</td>
                  <td>{worker.name}</td>
                  <td>{worker.seniorityDate}</td>
                  <td>{worker.note}</td>
                  <td>
                    <S.IconWrapper
                      onClick={() => {
                        handleUpdate(worker.id);
                      }}
                    >
                      <AiOutlineEdit></AiOutlineEdit>
                    </S.IconWrapper>
                  </td>
                  <td>
                    <S.IconWrapper
                      onClick={() => {
                        handleDelete(worker.id);
                      }}
                    >
                      <AiOutlineDelete
                        color={Theme.colors.WARNING_RED}
                      ></AiOutlineDelete>
                    </S.IconWrapper>
                  </td>
                </tr>
              )
            )}
        </tbody>
      </S.Table>
    </S.Container>
  );
};

export default MaintenanceOrganizationTable;
