import GreyBox from '@/components/common/GreyBox';
import CompanyProfile from '@/components/profile/CompanyProfile';

const MyCompanyPage = () => {
  return (
    <GreyBox maxWidth='28.125rem' minWidth='28.125rem'>
      <CompanyProfile />
    </GreyBox>
  );
};

export default MyCompanyPage;
