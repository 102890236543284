import styled from '@emotion/styled';
import { Theme } from '@/styles/Theme';

export const Table = styled.table`
  background-color: ${Theme.colors.WHITE};

  td {
    padding: 0.625rem;
    height: 2.5rem;
    border: 0.0625rem solid ${Theme.colors.BLACK};
  }
`;

export const TableHeader = styled.td`
  background-color: ${Theme.colors.G_5};
  text-align: center;
  font-weight: 500;
`;

export const ToggleButtonContainer = styled.td`
  padding: 0 !important;
  div:last-of-type {
    border: none;
  }
`;

export const IconWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${Theme.colors.G_5 + 80};
  }
`;

export const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 0.1875rem;
  align-items: center;
  margin-top: 0.625rem;
`;

export const FlexContainer = styled.div`
  display: flex;
  gap: 0.1875rem;
  flex-direction: column;
  margin-top: 0.625rem;
`;

export const BoxContainer = styled.div`
  width: 50%;
  display: flex;
  height: 100%;

  div {
    border-right: 0.0625rem solid ${Theme.colors.BLACK};
  }
`;

export const InfoContainer = styled.tbody`
  tr:first-of-type {
    td:first-of-type {
      background-color: ${Theme.colors.G_5};
    }
  }
`;

export const TopContainer = styled.div`
  display: flex;
  margin-bottom: 1.25rem;
  justify-content: space-between;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

export const ToggleButtonWrapper = styled.div<{
  isSelected: boolean;
  isEditMode?: boolean;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ isSelected }) =>
    isSelected ? Theme.colors.B_6 : Theme.colors.WHITE};
  color: ${({ isSelected }) =>
    isSelected ? Theme.colors.B_3 : Theme.colors.G_3};
  cursor: ${({ isEditMode }) => (isEditMode ? 'pointer' : 'not-allowed')};
`;
