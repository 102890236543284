import React from 'react';

import GreyBox from '@/components/common/GreyBox';
import Text from '@/components/common/Text';

const LegalBasis = () => {
  return (
    <GreyBox width='58.125rem' title='성능점검 개요'>
      <Text fontWeight={600} fontSize='1.25rem'>
        2. 법적근거
      </Text>
      <br />
      <br />
      • 기계설비법(법률 제17453호)
      <br />
      <br />
      • 기계설비법 시행령(시행 2021. 2. 2. 대통령령)
      <br />
      <br />
      • 기계설비법 시행규칙(시행 2021. 2. 2. 국토교통부령)
      <br />
      <br />• 기계설비 유지관리기준(시행 2021. 8. 9. 국토교통부 고시
      제2021-1013호)
    </GreyBox>
  );
};

export default LegalBasis;
