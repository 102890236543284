import { Global, css } from '@emotion/react';

const GlobalStyle = () => (
  <Global
    styles={css`
      * {
        margin: 0;
        padding: 0;
        font: inherit;
        color: inherit;
      }
      *,
      :after,
      :before {
        box-sizing: border-box;
        flex-shrink: 0;
      }
      :root {
        -webkit-tap-highlight-color: transparent;
        -webkit-text-size-adjust: 100%;
        text-size-adjust: 100%;
        line-height: 1.5;
        overflow-wrap: break-word;
        word-break: break-word;
        tab-size: 4;
      }
      html,
      body {
        height: 100%;
        font-family: 'Noto Sans KR', sans-serif;
        font-weight: 400;
      }
      #root {
        height: 100%;
      }
      img,
      picture,
      video,
      canvas,
      svg {
        display: block;
        max-width: 100%;
      }
      button {
        background: none;
        border: 0;
        cursor: pointer;
      }
      a {
        text-decoration: none;
      }
      table {
        border-collapse: collapse;
        border-spacing: 0;
      }
      input {
        border: none;
      }

      input[type='checkbox'] {
        width: 1.25rem;
        height: 1.25rem;

        cursor: pointer;
      }
      ul {
        list-style: none;
      }

      @media (max-width: 600px) {
        * {
          font-size: 14px;
        }
      }

      ::-webkit-scrollbar {
        width: 0.5rem;
        height: 0.5rem;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #fafafa;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        border-radius: 0.5rem;
        background: #c1c1c1;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #7c7c7c;
      }

      .hidden-on-load {
        visibility: hidden;
      }
    `}
  />
);

export default GlobalStyle;
