import { Theme } from '@/styles/Theme';
import styled from '@emotion/styled';

export const DataContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.875rem 0;
`;

export const DataLine = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.3125rem;
  border-bottom: 0.0625rem solid ${Theme.colors.G_4};
  padding: 0.4375rem;
`;

export const A = styled.a`
  text-decoration: underline;
  :hover {
    color: ${Theme.colors.B_4};
  }
`;
