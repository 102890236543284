import { Theme } from '@/styles/Theme';
import * as S from './style';
import Text from '@/components/common/Text';
import Button from '@/components/common/Button';
import { FiArrowDown } from 'react-icons/fi';
import { MANUAL_S3_LINK } from '@/constants/constants';
const Section2 = () => {
  return (
    <S.Container id='header_part2' className='header'>
      <Text fontSize='1.5625rem' fontWeight={600} color={Theme.colors.WHITE}>
        웹 앱의 기능이 궁금하신가요?
      </Text>
      <S.ButtonContainer>
        <S.ButtonBox>
          <S.TextWrapper>
            <FiArrowDown size='1.25rem' />
            현장작업자용
          </S.TextWrapper>
          <Button
            type='button'
            width='14.375rem'
            fontSize='1.125rem'
            borderRadius='0.625rem'
            color={Theme.colors.B_2}
            fontWeight={900}
            backgroundColor={Theme.colors.WHITE}
            onClick={() => window.open(MANUAL_S3_LINK.APP, '_blank')}
          >
            앱 기능 설명서
          </Button>
        </S.ButtonBox>
        <S.ButtonBox>
          <S.TextWrapper>
            <FiArrowDown size='1.25rem' />
            관리자용
          </S.TextWrapper>
          <Button
            type='button'
            width='14.375rem'
            fontSize='1.125rem'
            borderRadius='0.625rem'
            color={Theme.colors.B_2}
            fontWeight={900}
            backgroundColor={Theme.colors.WHITE}
            onClick={() => window.open(MANUAL_S3_LINK.WEB, '_blank')}
          >
            웹 기능 설명서
          </Button>
        </S.ButtonBox>
      </S.ButtonContainer>
      <Text
        fontSize='1.5625rem'
        fontWeight={600}
        color={Theme.colors.WHITE}
        margin='3.75rem 0 0 0'
      >
        사용방법이 궁금하신가요?
      </Text>
      <S.ButtonContainer>
        <Button
          type='button'
          width='14.375rem'
          fontSize='1.125rem'
          borderRadius='0.625rem'
          color={Theme.colors.B_2}
          fontWeight={900}
          backgroundColor={Theme.colors.WHITE}
          onClick={() => window.open(MANUAL_S3_LINK.MAIN, '_blank')}
        >
          전체 기능 설명서
        </Button>
        <Button
          type='button'
          width='14.375rem'
          fontSize='1.125rem'
          borderRadius='0.625rem'
          color={Theme.colors.B_2}
          fontWeight={900}
          backgroundColor={Theme.colors.WHITE}
          onClick={() => window.open(MANUAL_S3_LINK.VIDEO, '_blank')}
        >
          동영상 설명서
        </Button>
      </S.ButtonContainer>
      <Text
        fontSize='1.5625rem'
        fontWeight={600}
        color={Theme.colors.WHITE}
        margin='3.75rem 0 0 0'
      >
        완성된 결과물이 궁금하신가요?
      </Text>
      <Button
        type='button'
        width='14.375rem'
        fontSize='1.125rem'
        borderRadius='0.625rem'
        color={Theme.colors.B_2}
        fontWeight={900}
        backgroundColor={Theme.colors.WHITE}
        onClick={() => window.open(MANUAL_S3_LINK.REPORT, '_blank')}
      >
        최종 보고서 예시
      </Button>
    </S.Container>
  );
};
export default Section2;
