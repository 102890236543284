import * as ExcelJS from 'exceljs';
import { rowStartNumTemplateImg6 } from '@/constants/excel';
import type { ExcelDeviceList } from '@/types/InspectionArea.types';
import {
  cellAddressToNumbers,
  updateFormula,
} from '@/utils/excelExport/excelExportUtils';
import { templateImg6ExportReportTable } from '@/utils/excelExport/templateImg6/templateImg6ExportReportTable';

export const templateImg6CopySheet = async (
  data: ExcelDeviceList[],
  idx: number,
  workbook: ExcelJS.Workbook
) => {
  const name = data[0].device_info.name;

  // 템플릿 엑셀파일들 불러오기
  const response = await fetch(`/excel_template_img6/${name}.xlsx`, {
    cache: 'no-store',
  });
  const arrayBuffer = await response.arrayBuffer();
  const newWorkBook = new ExcelJS.Workbook();
  await newWorkBook.xlsx.load(arrayBuffer);

  // 복사 하려는 워크시트
  const sourceSheet = newWorkBook.getWorksheet(1);

  if (sourceSheet) {
    // 붙여넣기 받아야 하는 워크시트
    let newSheet = workbook.addWorksheet(`${idx + 1}.${name}`);
    newSheet.properties.defaultColWidth = 6.6; // 이렇게 해야 기본 width가 6이 됨

    // 병합된 셀 정보 복사
    // @ts-ignore
    sourceSheet.model.merges.forEach((mergedCell) => {
      for (let i = 0; i < data.length; i++) {
        const [topLeft, bottomRight] = mergedCell.split(':');
        const [t, l] = cellAddressToNumbers(topLeft);
        const [b, r] = cellAddressToNumbers(bottomRight);
        newSheet.mergeCells(t, l + 12 * i, b, r + 12 * i);
      }
    });

    // 주어진 data 길이만큼 열을 복사
    for (let i = 0; i < data.length; i++) {
      copyCells(sourceSheet, newSheet, i);
    }

    // 타겟 워크시트에 이미지를 추가합니다.
    const images = sourceSheet.getImages();
    images.forEach((img) => {
      for (let i = 0; i < data.length; i++) {
        const colOffset = 12 * i;

        const newRange = {
          tl: {
            col: img.range.tl.nativeCol + colOffset + 0.1,
            row: img.range.tl.nativeRow + 0.3,
          },
          br: {
            col: img.range.br.nativeCol + colOffset + 0.6,
            row: img.range.br.nativeRow + 0.7,
          },
        };

        const imageBuffer = newWorkBook.getImage(Number(img.imageId)).buffer;

        const imageId = workbook.addImage({
          buffer: imageBuffer,
          extension: 'png',
        });
        //@ts-ignore
        newSheet.addImage(imageId, newRange);
      }
    });

    // A4 양식으로 페이지 세팅
    newSheet.pageSetup = {
      paperSize: 9,
      scale: 100,
    };
    newSheet.views = [{ style: 'pageBreakPreview' }];

    // 페이지 분리 시작점
    const pageBreakArr =
      rowStartNumTemplateImg6[name as keyof typeof rowStartNumTemplateImg6]
        .pageBreakArr;

    for (let i = 0; i < pageBreakArr.length; i++) {
      const row = pageBreakArr[i];
      newSheet.getRow(row).addPageBreak();
    }

    // 데이터 넣어주는 부분
    await templateImg6ExportReportTable(data, workbook, newSheet);
  }
};

const copyCells = (
  sourceSheet: ExcelJS.Worksheet,
  targetSheet: ExcelJS.Worksheet,
  offset: number
) => {
  const colOffset = 12 * offset; // A~L의 12 컬럼

  sourceSheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
    // 행의 높이 복사
    if (row.height) {
      targetSheet.getRow(rowNumber).height = row.height;
    }
    row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
      if (colNumber <= 12) {
        // A~L 열만 복사
        let newCell = targetSheet.getCell(rowNumber, colNumber + colOffset);

        // 수식 업데이트, 병합된 셀인지 확인
        if (cell.isMerged) {
          const masterCell = cell.master;

          // 마스터 셀인 경우에만 처리
          if (cell.address === masterCell.address) {
            if (masterCell.type === ExcelJS.ValueType.Formula) {
              // 수식이 있는 경우
              newCell.value = {
                formula: updateFormula(masterCell.formula, colOffset),
                result: masterCell.result,
              };
            } else {
              // 수식이 없는 경우
              newCell.value = masterCell.value;
            }
          }
          // 마스터 셀이 아니면 처리하지 않음
        } else {
          // 병합되지 않은 셀의 경우 값을 복사
          newCell.value = cell.value;
        }

        newCell.style = { ...cell.style };
        newCell.font = { ...cell.font };
        newCell.border = { ...cell.border };
        newCell.alignment = {
          ...cell.alignment,
          // 셀에 맞춤
          shrinkToFit: true,
          // 자동 줄 바꿈
          // wrapText: false,
        };
      }
    });
  });
};
