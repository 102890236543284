import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import styled from '@emotion/styled';
import { AiFillNotification, AiOutlineClose } from 'react-icons/ai';

import { getNoticePopup } from '@/apis/notices';

import { usePopupStore } from '@/store/popup';

import { QUERY_KEY } from '@/constants/queryKey';

import Text from '@/components/common/Text';

import { Theme } from '@/styles/Theme';

const NoticePopup = () => {
  const { popups, setPopupVisible, setHidePopupUntil, checkVisibility } =
    usePopupStore();

  const { data: noticeData } = useQuery({
    queryKey: [QUERY_KEY.NOTICE_POPUP],
    queryFn: getNoticePopup,
  });

  useEffect(() => {
    if (noticeData) {
      const activeNoticeIds = noticeData.data.map((notice) => notice.id);
      checkVisibility(activeNoticeIds);
    }
  }, [noticeData, checkVisibility]);

  const handleCloseUntilDate = (id: number) => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    setHidePopupUntil(id, tomorrow.toISOString());

    // const inTenSeconds = new Date(new Date().getTime() + 10000);
    // setHidePopupUntil(id, inTenSeconds.toISOString());
    setPopupVisible(id, false);
  };

  const handleClose = (id: number) => {
    setHidePopupUntil(id, null);
    setPopupVisible(id, false);
  };

  const visiblePopups =
    noticeData?.data.filter((notice) =>
      popups.find((popup) => popup.id === notice.id && popup.isPopupVisible)
    ) ?? [];

  return (
    <>
      {visiblePopups.map((notice, index) => {
        const { id, start_date, title, content, image } = notice;

        return (
          <Container key={index} index={index}>
            <TopContainer>
              <AiFillNotification size='1.5rem' color={Theme.colors.B_3} />
              <Text
                margin='0 0.625rem 0 0'
                fontSize='1.125rem'
                fontWeight={700}
              >
                공지사항
              </Text>
              <AiOutlineClose
                size='1.5rem'
                onClick={() => handleClose(id)}
                style={{
                  position: 'absolute',
                  right: '0.9375rem',
                  cursor: 'pointer',
                }}
              />
            </TopContainer>
            <TitleContainer>
              <Text fontWeight={600} fontSize='1.0625rem'>
                {title}
              </Text>
              <Text
                textAlign='right'
                fontSize='0.875rem'
                fontWeight={500}
                color={Theme.colors.G_2}
              >
                {start_date}
              </Text>
            </TitleContainer>
            <ContentContainer>
              <Text fontSize='0.9375rem'>{content}</Text>
              {image.length > 0 && (
                <ImgContainer>
                  {image.map((img, idx) => (
                    <img key={idx} src={img} alt={`notice_img_${idx}`} />
                  ))}
                </ImgContainer>
              )}
            </ContentContainer>
            <BottomContainer>
              <button onClick={() => handleCloseUntilDate(id)}>
                <Text
                  fontWeight={600}
                  fontSize='0.9375rem'
                  color={Theme.colors.G_2}
                >
                  하루 동안 보지 않기
                </Text>
              </button>
              <button onClick={() => handleClose(id)}>
                <Text fontWeight={600} fontSize='0.9375rem'>
                  닫기
                </Text>
              </button>
            </BottomContainer>
          </Container>
        );
      })}
    </>
  );
};

export default NoticePopup;

const Container = styled.div<{ index: number }>`
  z-index: 300;

  position: fixed;
  top: 5%;
  left: ${({ index }) => 10 + index * 23}%;

  display: flex;
  flex-direction: column;

  width: 26.25rem;

  box-shadow: rgb(18, 21, 24, 0.5) 0rem 0rem 0.625rem;
  background-color: ${Theme.colors.WHITE};
  border-radius: 0.9375rem;
`;

const TopContainer = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3125rem;

  height: 3.125rem;
  border-bottom: 0.0625rem solid ${Theme.colors.G_3};
`;

const BottomContainer = styled.div`
  border-top: 0.0625rem solid ${Theme.colors.G_3};

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1.25rem;
  height: 3.125rem;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;

  padding: 1.25rem 1.375rem 1.25rem 1.75rem;
`;

// 왼쪽 5 오른쪽 2

const ContentContainer = styled.div`
  flex: 1;

  padding: 0 1.375rem 1.25rem 1.875rem;

  width: 100%;
  height: 100%;
  max-height: 25rem;

  overflow-y: auto;
  white-space: pre-wrap;
`;

const ImgContainer = styled.div`
  margin-top: 0.625rem;

  display: flex;
  flex-direction: column;
  gap: 0.625rem;
`;
