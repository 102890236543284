import { Theme } from '@/styles/Theme';
import styled from '@emotion/styled';
interface ContainerProps {
  isBorder?: boolean;
}
export const Container = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  background-color: ${Theme.colors.WHITE};
  padding: 1.5625rem 1.875rem 1.125rem;
  border-radius: 0.625rem;
  border: ${({ isBorder }) =>
    isBorder && `0.0625rem solid ${Theme.colors.G_3}`};
  justify-content: center;
`;
export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.9375rem;
  align-items: center;
  justify-content: center;
`;
export const Input = styled.input`
  height: 3.125rem;
  width: 9.375rem;
  background-color: ${Theme.colors.G_6};
  border: 0.0625rem solid ${Theme.colors.G_3};
  font-size: 1.5625rem;
  text-align: center;
  :focus {
    outline: 0.0625rem solid ${Theme.colors.G_2};
  }
`;
export const IconWrapper = styled.div`
  cursor: pointer;
  opacity: 0.6;
  :hover {
    opacity: 1;
  }
`;
