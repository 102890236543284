import styled from '@emotion/styled';
import { Theme } from '@/styles/Theme';

export const Table = styled.table`
  background-color: ${Theme.colors.WHITE};
  text-align: center;

  td {
    width: 25rem;
    height: 2.5rem;
    border: 0.0625rem solid ${Theme.colors.BLACK};
  }

  tr:first-of-type {
    background-color: ${Theme.colors.G_5};
  }

  tr:last-of-type {
    td:last-of-type {
      background-color: ${Theme.colors.WHITE};
    }
  }
`;

export const IconWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${Theme.colors.G_5 + 80};
  }
`;

export const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 0.1875rem;
  align-items: center;
  margin-top: 0.625rem;
`;

export const ModifyButtonWrapper = styled.div`
  margin-top: 1.25rem;

  width: 100%;
  display: flex;
  justify-content: center;
`;

export const FlexContainer = styled.div`
  display: flex;
  gap: 0.1875rem;
  flex-direction: column;
  margin-top: 0.625rem;
`;

export const InfoContainer = styled.tbody`
  tr:first-of-type {
    background-color: ${Theme.colors.WHITE};
  }
  td:first-of-type {
    background-color: ${Theme.colors.G_5};
  }
`;

export const TopContainer = styled.div`
  display: flex;
  margin-bottom: 1.25rem;
  justify-content: space-between;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const ToggleButtonWrapper = styled.div<{ isSelected: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ isSelected }) =>
    isSelected ? Theme.colors.B_6 : Theme.colors.WHITE};
  color: ${({ isSelected }) =>
    isSelected ? Theme.colors.B_3 : Theme.colors.B_1};
  cursor: ${({ isSelected }) => (isSelected ? '' : 'pointer')};
`;
