import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import styled from '@emotion/styled';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import DaumPostcode from 'react-daum-postcode';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';

import { postInspectionArea } from '@/apis/InspectionArea';
import type {
  PostInspectionAreaRequestProps,
  PostWorkDeviceInfo,
} from '@/types/InspectionArea.types';
import type { ErrorResponse } from '@/types/client.types';
import { getWorkerList } from '@/apis/worker';

import { TOAST_ID } from '@/constants/toastId';
import { QUERY_KEY } from '@/constants/queryKey';
import { ROUTER } from '@/constants/constants';

import GreyBox from '@/components/common/GreyBox';
import Spacer from '@/components/common/Spacer';
import Button from '@/components/common/Button';
import Prompt from '@/components/common/Prompt';
import Input from '@/components/common/Input';
import WorkerListTable from '@/components/worker/WorkerListTable';
import WorkAddCategoryListSelector from '@/components/work/WorkAddCategoryListSelector';
import WorkAddCategoryListTable from '@/components/work/WorkAddCategoryListTable';

import { Theme } from '@/styles/Theme';

import * as S from '@/styles/WorkForm';

import 'react-datepicker/dist/react-datepicker.css';

export interface WorkFormProps {
  name: string;
  mainAddress: string;
  subAddress: string;
  startDate: Date | null;
  endDate: Date | null;
  memo: string;
  reason: string;
}

const WorkAddPage = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const date = new Date();
  const tomorrow = new Date(date);
  tomorrow.setDate(date.getDate() + 1);

  const [formState, setFormState] = useState<WorkFormProps>({
    name: '',
    mainAddress: '',
    subAddress: '',
    startDate: date,
    endDate: tomorrow,
    memo: '',
    reason: '',
  });
  const [isDaumPostCodeOn, setIsDaumPostCodeOn] = useState<boolean>(false);
  const [selectedWorkerList, setSelectedWorkerList] = useState<number[]>([]);
  const [selectedDeviceList, setSelectedDeviceList] = useState<
    PostWorkDeviceInfo[]
  >([]);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  const { data: workerData } = useQuery([QUERY_KEY.WORKERS], {
    queryFn: getWorkerList,
  });

  const { mutate: addWorkMutate } = useMutation({
    mutationFn: postInspectionArea,
    onSuccess: (data) => {
      queryClient.invalidateQueries([QUERY_KEY.DASHBOARD]);
      toast.success('점검지 등록이 완료되었습니다.');
      navigate(ROUTER.DASHBOARD);
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      toast.error(error.response?.data.message);
    },
  });

  const handleDatePicker = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;

    setFormState((prev) => ({ ...prev, startDate: start }));
    setFormState((prev) => ({ ...prev, endDate: end }));
  };

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleWorkSubmit = () => {
    if (!formState.name) {
      toast.error('상호(명칭)을 입력해주세요.', {
        toastId: '상호(명칭)',
      });
      return;
    }
    if (!formState.mainAddress) {
      toast.error('현장주소를 입력해주세요.', {
        toastId: '현장주소',
      });
      return;
    }
    if (selectedDeviceList.length < 1) {
      toast.error('점검 대상 설비를 선택해주세요.', {
        toastId: '점검 대상 설비',
      });
      return;
    }

    for (let i = 0; i < selectedDeviceList.length; i++) {
      const { selected, total, name } = selectedDeviceList[i];

      if (selected > total) {
        toast.error(`${name}의 점검수량은 전체수량보다 클 수 없습니다.`, {
          toastId: TOAST_ID.WORK_COUNT,
        });
        return;
      }
    }

    const { name, memo, mainAddress, subAddress, startDate, endDate } =
      formState;

    const data: PostInspectionAreaRequestProps = {
      name,
      memo,
      address: mainAddress + '/' + subAddress,
      start_date: startDate,
      end_date: endDate ?? startDate,
      worker_list: selectedWorkerList,
      device_list: selectedDeviceList,
    };

    setIsSubmit(true);
    addWorkMutate(data);
  };

  // 작성 중 새로고침, 페이지 이탈 방지 (정보 페이지 제외)
  let isWriting = !!(
    formState.name ||
    formState.mainAddress ||
    selectedWorkerList.length > 0 ||
    selectedDeviceList.length > 0
  );

  useEffect(() => {
    const onBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isWriting) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [isWriting]);

  return (
    <GreyBox title='점검지 등록' width='58.125rem'>
      <Prompt
        when={!isSubmit && isWriting}
        message={`페이지에서 나가시겠습니까?\n변경사항이 저장되지 않을 수 있습니다.`}
      />
      <S.Container>
        <Input
          label='상호(명칭)'
          placeholder='상호(명칭)을 입력해주세요.'
          name='name'
          width='25.625rem'
          height='2.5rem'
          labelFontSize='1.25rem'
          value={formState.name}
          onChange={handleInputChange}
        />
        <S.AddressContainer>
          <Input
            label='현장주소'
            name='mainAddress'
            value={formState.mainAddress}
            width='20rem'
            height='2.5rem'
            labelFontSize='1.25rem'
            placeholder='주소를 검색해주세요.'
            disabled
            onClick={() => setIsDaumPostCodeOn((prev) => !prev)}
            buttonName='주소검색'
            buttonOnclick={() => setIsDaumPostCodeOn((prev) => !prev)}
          />
          {isDaumPostCodeOn && (
            <div>
              <DaumPostcode
                autoClose
                onComplete={(data) =>
                  setFormState((prev) => ({
                    ...prev,
                    mainAddress: data.address,
                  }))
                }
                onClose={() => setIsDaumPostCodeOn(false)}
                style={{ width: 410 }}
              />
            </div>
          )}
          <Input
            placeholder='상세주소를 입력해주세요.'
            name='subAddress'
            width='25.625rem'
            height='2.5rem'
            value={formState.subAddress}
            onChange={handleInputChange}
          />
        </S.AddressContainer>
        <S.LabelContainer>
          <S.LabelWrapper>점검기간</S.LabelWrapper>
          <DatePicker
            locale={ko}
            closeOnScroll
            selected={formState.startDate}
            onChange={handleDatePicker}
            startDate={formState.startDate}
            endDate={formState.endDate}
            selectsRange
            placeholderText='yyyy-MM-dd'
            dateFormat='yyyy-MM-dd'
            monthsShown={2}
          />
        </S.LabelContainer>
        <S.LabelContainer>
          <S.LabelWrapper>비고</S.LabelWrapper>
          <S.StyledTextarea
            value={formState.memo}
            onChange={handleInputChange}
            name='memo'
          />
        </S.LabelContainer>
        <S.LabelContainer>
          <S.LabelWrapper>작업자 선택</S.LabelWrapper>
          <WorkerListTable
            workerData={workerData?.data.workerList ?? []}
            width='100%'
            height='22.625rem'
            selectedWorkerList={selectedWorkerList}
            setSelectedWorkerList={setSelectedWorkerList}
            hasButtons={false}
            isEditPage
          />
        </S.LabelContainer>
        <S.LabelContainer>
          <S.LabelWrapper>점검 대상 설비</S.LabelWrapper>
          <WorkAddCategoryListSelector
            selectedDeviceList={selectedDeviceList}
            setSelectedDeviceList={setSelectedDeviceList}
          />
          <WorkAddCategoryListTable
            selectedDeviceList={selectedDeviceList}
            setSelectedDeviceList={setSelectedDeviceList}
          />
        </S.LabelContainer>
      </S.Container>
      <Spacer y='6.25rem' />
      <Line />
      <Spacer y='1.875rem' />
      <ButtonWrapper>
        <Button width='9.375rem' height='2.5rem' onClick={handleWorkSubmit}>
          등록하기
        </Button>
      </ButtonWrapper>
    </GreyBox>
  );
};

export default WorkAddPage;

const Line = styled.div`
  width: 100%;
  height: 0.0625rem;
  background-color: ${Theme.colors.G_2};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
