import styled from '@emotion/styled';

export const PageCenter = styled.div`
  width: 100%;
  min-height: 100%;

  padding: 1.25rem 0;

  overflow: auto;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 1.875rem;
`;

export const StyledH1 = styled.h1`
  font-size: 1.875rem;
  font-weight: 700;
`;

export const LoadingBackground = styled.div`
  position: fixed;

  width: 100%;
  height: 100%;

  left: 0;
  top: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 1001;

  background: rgba(3, 14, 32, 0.36);
`;

export const StyledCol = styled.col<{ width: string }>`
  width: ${({ width }) => width};
`;

interface StyledTdProps {
  width?: string;
  height?: string;
  fontWeight?: number;
  color?: string;
  borderTop?: string;
  borderRight?: string;
  borderBottom?: string;
  textAlign?: string;
  padding?: string;
  whiteSpace?: string;
  verticalAlign?: string;
}

export const StyledTd = styled.td<StyledTdProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};

  color: ${({ color }) => color};
  font-weight: ${({ fontWeight }) => fontWeight};
  text-align: ${({ textAlign }) => textAlign};

  white-space: ${({ whiteSpace }) => whiteSpace};
  vertical-align: ${({ verticalAlign }) => verticalAlign};

  border-top: ${({ borderTop }) => borderTop};
  border-right: ${({ borderRight }) => borderRight};
  border-bottom: ${({ borderBottom }) => borderBottom};

  padding: ${({ padding }) => padding};
`;
