import {
  Control,
  FieldPath,
  FieldValues,
  RegisterOptions,
  useController,
} from 'react-hook-form';
import styled from '@emotion/styled';

import Text from '@/components/common/Text';
import Button from '@/components/common/Button';

import { Theme } from '@/styles/Theme';

type Props<T extends FieldValues> = {
  type?: 'text' | 'email' | 'password';
  width: string;
  height: string;
  placeholder?: string;
  disabled?: boolean;
  label?: string;
  subscription?: string;
  time?: string;

  buttonName?: string;
  buttonOnclick?: () => void;
  buttonDisabled?: boolean;

  control: Control<T>;
  name: FieldPath<T>;
  rules?: Omit<
    RegisterOptions<T>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
};

const FormInput = <T extends FieldValues>({
  type = 'text',
  width,
  height,
  placeholder,
  disabled = false,
  label,
  subscription,
  time,
  control,
  name,
  rules,
  buttonName,
  buttonOnclick,
  buttonDisabled,
}: Props<T>) => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ name, rules, control });

  return (
    <Container>
      {label && <LabelWrapper htmlFor={name}>{label}</LabelWrapper>}
      {subscription && (
        <Text color={Theme.colors.G_1} fontSize='0.9375rem'>
          {subscription}
        </Text>
      )}
      <InputContainer>
        <InputWrapper
          type={type}
          id={name}
          name={name}
          width={width}
          height={height}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          isError={!!error}
        />
        {!buttonDisabled && time && (
          <TimerWrapper>
            <Text fontWeight={500} color={Theme.colors.WARNING_RED}>
              {time}
            </Text>
          </TimerWrapper>
        )}
        {buttonName && (
          <Button
            width='5.0625rem'
            height='2.5rem'
            onClick={buttonOnclick}
            disabled={buttonDisabled}
          >
            {buttonName}
          </Button>
        )}
      </InputContainer>
      {error && (
        <Text fontSize='0.9375rem' color={Theme.colors.WARNING_RED}>
          {error.message}
        </Text>
      )}
    </Container>
  );
};

export default FormInput;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
`;

const LabelWrapper = styled.label`
  font-size: 1rem;
  font-weight: 500;
`;

const InputWrapper = styled.input<{
  width: string;
  height: string;
  isError: boolean;
}>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-color: ${Theme.colors.WHITE};
  border-radius: 0.375rem;
  box-shadow: 0rem 0rem 0.25rem 0rem rgba(0, 0, 0, 0.25);

  border: ${({ isError }) =>
    isError && `0.0625rem solid ${Theme.colors.WARNING_RED}`};

  padding: 0 0.9375rem;

  color: ${Theme.colors.BLACK};
  font-size: 1rem;
  font-weight: 400;

  :disabled {
    background-color: ${Theme.colors.G_5};
  }

  ::placeholder {
    color: ${Theme.colors.G_2};
  }

  :focus {
    outline: none;
  }
`;

const InputContainer = styled.div`
  position: relative;

  display: flex;
  align-items: center;

  gap: 0.625rem;
`;

const TimerWrapper = styled.div`
  position: absolute;
  top: 20%;
  right: 25%;
`;
