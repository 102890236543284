import Modal from '@/components/common/Modal';
import Spacer from '@/components/common/Spacer';
import Text from '@/components/common/Text';
import usePutUndoUnsubscribeWorker from '@/hooks/usePutUndoUnsubscribeWorker';

interface Props {
  setIsShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedWorkers: number[];
  resetSelectedUndoUnsubscribeWorkerList?: VoidFunction;
}

const UndoUnsubscribeWorkersModal = ({
  setIsShowModal,
  selectedWorkers,
  resetSelectedUndoUnsubscribeWorkerList,
}: Props) => {
  const { mutate: undoUnsubscribeWorker } = usePutUndoUnsubscribeWorker();

  const onUndoUnsubscribeClick = () => {
    undoUnsubscribeWorker({ workerId: selectedWorkers });
    setIsShowModal(false);
    resetSelectedUndoUnsubscribeWorkerList &&
      resetSelectedUndoUnsubscribeWorkerList();
  };

  return (
    <Modal
      setIsModalOpen={setIsShowModal}
      isWarning
      onClickConfirm={onUndoUnsubscribeClick}
    >
      <Spacer y='1.875rem' />
      <Text fontSize='1.375rem' fontWeight={700}>
        선택한 작업자를 계속 사용하시겠습니까?
      </Text>
      <Text>(다음 결제일 자동 결제 예정)</Text>
      <Spacer y='2.5rem' />
    </Modal>
  );
};

export default UndoUnsubscribeWorkersModal;
