import { Theme } from '@/styles/Theme';
import * as S from './style';
import Text from '@/components/common/Text';
import { MANUAL_S3_LINK } from '@/constants/constants';

const Section5 = () => {
  return (
    <S.Container>
      <S.ImageWrapper>
        <img src='/landingImage/report.png' alt='report_img' />
      </S.ImageWrapper>
      <S.TextContainer>
        <Text
          fontSize='1.25rem'
          color={Theme.colors.GREEN}
          fontWeight={700}
          margin='0 0 0.625rem 0'
        >
          기계설비 성능점검 보고서
        </Text>
        <Text
          fontSize='1.875rem'
          color={Theme.colors.BLACK}
          fontWeight={700}
          lineHeight='2.5rem'
          margin='0 0 1.875rem 0'
        >
          클릭 한 번으로
          <br />
          보고서가 자동으로 작성됩니다.
        </Text>
        <Text
          fontSize='1.0625rem'
          lineHeight='1.5625rem'
          margin='0 0 0.9375rem 0'
        >
          대시보드에서 보고서 다운로드 버튼을
          <br />
          클릭하면 자동으로 양식에 맞는 보고서가 작성됩니다.
          <br />
          보고서 작성에 들어가는 시간을 줄여보세요!
        </Text>
        <a target='_blank' href={MANUAL_S3_LINK.REPORT} rel='noreferrer'>
          <Text hasUnderline fontSize='1.0625rem' color={Theme.colors.B_3}>
            최종 보고서 예시 확인하기
          </Text>
        </a>
      </S.TextContainer>
    </S.Container>
  );
};
export default Section5;
