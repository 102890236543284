import { useState } from 'react';
import styled from '@emotion/styled';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

import type { NoticesItem } from '@/types/notices.types';

import Text from '@/components/common/Text';

import { Theme } from '@/styles/Theme';

import { platformFormat } from '@/utils/convertStringFormat';

interface Props {
  updateInfo: NoticesItem;
}

const UpdateInfoItem = ({ updateInfo }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { title, content, image, startDate, important, platform } = updateInfo;

  return (
    <Container>
      <TopContainer
        isImportant={important}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <TitleContainer>
          {important === 1 && <ImportantPoint />}
          <TitleText isOpen={isOpen}>
            {platformFormat(platform)}
            {title}
          </TitleText>
        </TitleContainer>
        <TimeContainer>
          <Text color={Theme.colors.G_2}>{startDate}</Text>
          {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </TimeContainer>
      </TopContainer>
      {isOpen && (
        <BottomContainer>
          {content}
          {image.length > 0 && (
            <ImgContainer>
              {image.map((img, idx) => (
                <StyledImg
                  loading='lazy'
                  key={idx}
                  src={img}
                  alt={`notice_img_${idx}`}
                />
              ))}
            </ImgContainer>
          )}
        </BottomContainer>
      )}
    </Container>
  );
};

export default UpdateInfoItem;

const Container = styled.li``;

interface TitleProps {
  isImportant: 0 | 1;
}

const TopContainer = styled.div<TitleProps>`
  cursor: pointer;

  width: 100%;
  padding: 1.25rem;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0.625rem;

  border-bottom: 0.0625rem solid ${Theme.colors.G_3};
  background-color: ${({ isImportant }) =>
    isImportant === 1 ? Theme.colors.B_6 : Theme.colors.WHITE};
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;

  width: 80%;
`;

const TitleText = styled.span<{ isOpen: boolean }>`
  width: 95%;
  overflow: hidden;
  white-space: ${({ isOpen }) => (isOpen ? 'wrap' : 'nowrap')};
  text-overflow: ellipsis;
  word-break: break-all;

  font-size: 1.125rem;
  font-weight: 900;
`;

const ImportantPoint = styled.div`
  border: 0.25rem solid ${Theme.colors.B_3_light};
  border-radius: 50%;
`;

const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
`;

const BottomContainer = styled.div`
  width: 100%;
  padding: 1.25rem;

  border-bottom: 0.0625rem solid ${Theme.colors.G_2};
  background-color: ${Theme.colors.G_7};

  white-space: pre-line;
`;

const ImgContainer = styled.div`
  margin-top: 1.875rem;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.625rem;
`;

const StyledImg = styled.img`
  width: 100%;
  max-width: 21.875rem;
`;
