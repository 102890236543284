import styled from '@emotion/styled';
import { ReactComponent as SortUp } from '@/assets/icons/sort-up.svg';
import { ReactComponent as SortDown } from '@/assets/icons/sort-down.svg';

interface Props {
  isSorted: boolean;
  desc?: boolean;
}

const SortIcon = ({ isSorted, desc }: Props) => {
  return (
    <>
      <SortIconWrapper>
        <SortUpIcon
          className={isSorted ? (desc ? '' : 'active') : ''}
          viewBox='0 -250 320 512'
        />
        <SortDownIcon
          className={isSorted ? (desc ? 'active' : '') : ''}
          viewBox='0 250 320 512'
        />
      </SortIconWrapper>
    </>
  );
};

export default SortIcon;

export const SortIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SortUpIcon = styled(SortUp)`
  width: 0.8125rem;
  height: 0.8125rem;
  fill: #bbbbbb;
  &.active {
    fill: #1d1e23;
  }
`;

export const SortDownIcon = styled(SortDown)`
  width: 0.8125rem;
  height: 0.8125rem;
  fill: #bbbbbb;
  &.active {
    fill: #1d1e23;
  }
`;
