import styled from '@emotion/styled';
import { Theme } from '@/styles/Theme';

export const FlexContainer = styled.div`
  display: flex;
  gap: 1.25rem;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25rem;

  div {
    display: flex;
    justify-content: space-around;
    gap: 0.3125rem;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 1.875rem;
`;

export const EmptyImageWrapper = styled.label`
  border-radius: 0.625rem;
  background-color: ${Theme.colors.G_5};
  height: 25rem;
  cursor: pointer;
  :hover {
    background-color: ${Theme.colors.G_4};
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

export const FileInput = styled.input`
  display: none;
`;

export const StyledImage = styled.img`
  background-color: ${Theme.colors.WHITE};
  object-fit: cover;
  width: 100%;
  height: 37.5rem;
`;

export const ImageWrapper = styled.label`
  position: relative;
`;

export const TitleWrapper = styled.label`
  display: flex;
  justify-content: start;
  gap: 0.625rem;
  width: 100%;
  div:first-of-type {
    flex: 1;
  }
`;

export const StyledDeleteButton = styled.label`
  position: absolute;

  background-color: ${Theme.colors.G_2};
  color: ${Theme.colors.WHITE};
  border-radius: 0.25rem;
  padding: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9375rem;
  right: 0;
  top: 0;
  :hover {
    background-color: ${Theme.colors.G_1};
  }
  cursor: pointer;
`;
