import type { InspectionLastHistory } from '@/types/deviceReport.types';
import LastEditedHistoryText from '@/components/inspectionHistory/LastEditedHistory/LastEditedHistoryText';
import * as styled from '@/components/inspectionHistory/LastEditedHistory/style';

const LastEditedHistory = ({
  workerName,
  updatedAt,
}: InspectionLastHistory) => {
  return (
    <>
      {workerName && updatedAt && (
        <styled.LastHistoryWrapper>
          <styled.LastHistoryLabel>마지막 수정</styled.LastHistoryLabel>
          <span> | </span>
          <LastEditedHistoryText
            workerName={workerName}
            updatedAt={updatedAt}
          />
        </styled.LastHistoryWrapper>
      )}
    </>
  );
};

export default LastEditedHistory;
