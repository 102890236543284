import React, { useState, useEffect } from 'react';
import * as S from './style';
import Text from '@/components/common/Text';
import Button from '@/components/common/Button';
import Input from '@/components/common/Input';
import { Theme } from '@/styles/Theme';
import GreyBox from '@/components/common/GreyBox';
import EditButton from '@/components/common/EditButton';
import {
  getPreservationMethod,
  postPreservationMethod,
} from '@/apis/report/building';
import type { PreservationMethodInfo } from '@/types/report/building.types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { QUERY_KEY } from '@/constants/queryKey';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import type { ErrorResponse } from '@/types/client.types';

const MaintenanceInspectionRuleTable = () => {
  const params = useParams();
  const inspectionAreaId = Number(params.id);

  const [methodInfo, setMethodInfo] = useState<PreservationMethodInfo>({
    nameList: [],
    hasDigitalFile: false,
    hasPrint: false,
    preservationPeriod: '',
  });
  const [isDetailPage, setIsDetailPage] = useState(true);

  const queryClient = useQueryClient();

  const { data: preservationData } = useQuery({
    queryKey: [QUERY_KEY.REPORT.BUILDING.PRESERVATION, inspectionAreaId],
    queryFn: () => getPreservationMethod(inspectionAreaId),
  });

  const { mutate: mutatePreservationMethod } = useMutation({
    mutationFn: postPreservationMethod,
    onSuccess: (data) => {
      queryClient.invalidateQueries([
        QUERY_KEY.REPORT.BUILDING.PRESERVATION,
        inspectionAreaId,
      ]);
      toast.success('수정하였습니다.');
      setIsDetailPage(true);
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      toast.error(error.response?.data.message);
    },
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    const { name, value } = e.target;

    const copiedNameList = methodInfo.nameList.map((list, index) => {
      if (index === idx) {
        return {
          ...list,
          [name]: value,
        };
      }
      return list;
    });

    setMethodInfo((prev) => ({ ...prev, nameList: copiedNameList }));
  };

  const handleModify = () => {
    mutatePreservationMethod({ inspectionAreaId, ...methodInfo });
  };

  const handleEditCancel = () => {
    if (preservationData) {
      if (preservationData.data.nameList.length < 1) {
        const initialData = [
          { position: '책임기계설비유지관리자', name: '' },
          { position: '보조기계설비유지관리자', name: '' },
        ];
        setMethodInfo({ ...preservationData.data, nameList: initialData });
        return;
      }
      setMethodInfo(preservationData.data);
    }
  };

  useEffect(() => {
    if (preservationData) {
      if (preservationData.data.nameList.length < 1) {
        const initialData = [
          { position: '책임기계설비유지관리자', name: '' },
          { position: '보조기계설비유지관리자', name: '' },
        ];
        setMethodInfo({ ...preservationData.data, nameList: initialData });
        return;
      }
      setMethodInfo(preservationData.data);
    }
  }, [preservationData]);

  return (
    <GreyBox width='58.125rem'>
      <EditButton
        isDetailPage={isDetailPage}
        setIsDetailPage={setIsDetailPage}
        onClickCancel={handleEditCancel}
      />
      <S.Container>
        <S.TopContainer>
          <Text fontSize='1.25rem' fontWeight={600}>
            유지관리 점검표 작성 및 보존 방법
          </Text>
        </S.TopContainer>

        <S.TextWrapper>
          <Text fontSize='0.625rem'>●</Text>
          <Text fontSize='1rem'>
            「기계설비 유지관리기준」의 규정에 따라 기계설비 유지관리 업무는
            선임/위탁된 유자격자인 유지관리자에 의해
          </Text>
        </S.TextWrapper>
        <Text fontSize='1rem'>작성 및 보존･관리되어야 함</Text>
        <S.FlexContainer>
          <Text fontSize='1.25rem' fontWeight={600}>
            점검기록의 보존 방법
          </Text>

          <S.Table>
            <colgroup>
              <col />
              <col />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <td rowSpan={methodInfo.nameList.length + 1}>담당자</td>
                <td>직책</td>
                <td>이름</td>
              </tr>
              {methodInfo.nameList.map((item, index) => (
                <tr key={index}>
                  <td>
                    {!isDetailPage ? (
                      <Input
                        name='position'
                        height='2.5rem'
                        width='100%'
                        backgroundColor={Theme.colors.YELLOW}
                        value={item.position}
                        onChange={(e) => handleChange(e, index)}
                      />
                    ) : (
                      item.position
                    )}
                  </td>
                  <td>
                    {!isDetailPage ? (
                      <Input
                        name='name'
                        height='2.5rem'
                        width='100%'
                        backgroundColor={Theme.colors.YELLOW}
                        value={item.name}
                        onChange={(e) => handleChange(e, index)}
                      />
                    ) : (
                      item.name
                    )}
                  </td>
                </tr>
              ))}
            </tbody>

            <S.InfoContainer>
              <tr>
                <td>디지털파일</td>
                <td>
                  <S.ToggleButtonWrapper
                    isSelected={methodInfo.hasDigitalFile}
                    onClick={() =>
                      !isDetailPage &&
                      setMethodInfo((prev) => ({
                        ...prev,
                        hasDigitalFile: !prev.hasDigitalFile,
                      }))
                    }
                  >
                    해당
                  </S.ToggleButtonWrapper>
                </td>
                <td>
                  <S.ToggleButtonWrapper
                    isSelected={!methodInfo.hasDigitalFile}
                    onClick={() =>
                      !isDetailPage &&
                      setMethodInfo((prev) => ({
                        ...prev,
                        hasDigitalFile: !prev.hasDigitalFile,
                      }))
                    }
                  >
                    해당없음
                  </S.ToggleButtonWrapper>
                </td>
              </tr>
              <tr>
                <td>인쇄물</td>
                <td>
                  <S.ToggleButtonWrapper
                    isSelected={methodInfo.hasPrint}
                    onClick={() =>
                      !isDetailPage &&
                      setMethodInfo((prev) => ({
                        ...prev,
                        hasPrint: !prev.hasPrint,
                      }))
                    }
                  >
                    해당
                  </S.ToggleButtonWrapper>
                </td>
                <td>
                  <S.ToggleButtonWrapper
                    isSelected={!methodInfo.hasPrint}
                    onClick={() =>
                      !isDetailPage &&
                      setMethodInfo((prev) => ({
                        ...prev,
                        hasPrint: !prev.hasPrint,
                      }))
                    }
                  >
                    해당없음
                  </S.ToggleButtonWrapper>
                </td>
              </tr>
            </S.InfoContainer>
            <tbody>
              <tr>
                <td>보존기관</td>
                <td colSpan={2}>
                  {!isDetailPage ? (
                    <Input
                      name='preservationPeriod'
                      height='2.5rem'
                      width='100%'
                      placeholder='10년(권장)'
                      backgroundColor={Theme.colors.YELLOW}
                      value={methodInfo.preservationPeriod}
                      onChange={(e) => {
                        setMethodInfo((prev) => ({
                          ...prev,
                          preservationPeriod: e.target.value,
                        }));
                      }}
                    />
                  ) : (
                    methodInfo.preservationPeriod
                  )}
                </td>
              </tr>
            </tbody>
          </S.Table>
        </S.FlexContainer>
        {!isDetailPage && (
          <S.ModifyButtonWrapper>
            <Button width='9.375rem' onClick={handleModify}>
              수정하기
            </Button>
          </S.ModifyButtonWrapper>
        )}
      </S.Container>
    </GreyBox>
  );
};

export default MaintenanceInspectionRuleTable;
