import * as styled from './style';

import type { RowModel } from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';

import type { WorkerPaymentInfo } from '@/types/company.types';
import { AddWorkersBtn } from '../../WorkerPaymentInfoHeader/ManegeBtns';
import usePaymentInfo from '@/hooks/usePaymentInfo';
import useSubscribeStore from '@/store/subscribeMode';

interface Props {
  getRowModel: () => RowModel<WorkerPaymentInfo>;
}

const WorkerPaymentInfoTableBody = ({ getRowModel }: Props) => {
  const { data: paymentData } = usePaymentInfo();
  const { isSubscribeMode } = useSubscribeStore();

  return (
    <>
      {paymentData && (
        <styled.Tbody isApproved={paymentData?.data.isApproved === 1}>
          {isSubscribeMode && (
            <AddWorkersBtn
              isTrial={paymentData?.data.isTrial}
              activeWorkerCount={paymentData?.data.activeWorkerCount}
            />
          )}
          {getRowModel().rows.length > 0 ? (
            getRowModel()
              .rows.filter((row) => {
                const isActive = row.getValue('isActive');
                return isSubscribeMode
                  ? isActive === '생성' || isActive === '비활성'
                  : true;
              })
              .map((row) => (
                <styled.TbodyTr
                  key={row.id}
                  isActive={row.getValue('isActive')}
                >
                  {row.getVisibleCells().map((cell) => (
                    <styled.Td
                      key={cell.id}
                      width={`${cell.column.getSize() / 16}rem`}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </styled.Td>
                  ))}
                </styled.TbodyTr>
              ))
          ) : (
            <styled.EmptyTr>
              <td>작업자를 추가해주세요.</td>
            </styled.EmptyTr>
          )}
        </styled.Tbody>
      )}
    </>
  );
};

export default WorkerPaymentInfoTableBody;
