import { Theme } from '@/styles/Theme';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Container = styled.div`
  position: fixed;
  top: 0;
  width: 100%;

  z-index: 99;
`;

export const HeaderContainer = styled.nav`
  width: 100%;
  height: 5rem;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.625rem;

  padding: 0.9375rem 1.875rem;
  background-color: ${Theme.colors.WHITE};
  box-shadow: rgba(0, 0, 0, 0.25) 0 0.1875rem 0.6875rem 0;
`;
export const LogoImg = styled.img`
  width: 10rem;
`;
export const ItemContainer = styled.div`
  flex: 1;
  display: flex;
  gap: 2.1875rem;
`;
export const Item = styled.div`
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
`;

export const EventContainer = styled.div`
  position: relative;

  width: 100%;
  height: 3.125rem;
  background-color: ${Theme.colors.B_1};

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MainWrapper = styled.div`
  cursor: pointer;
`;

export const MainContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 0.9375rem;

  cursor: pointer;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const ScrollLink = styled.div<{ isActive: boolean }>`
  cursor: pointer;

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${Theme.colors.B_3_light};
      font-weight: 700;
    `}
`;

export const ScrollLinkContainer = styled.div`
  margin-left: 3.125rem;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1.25rem;

  @media (max-width: 860px) {
    display: none;
  }
`;
