import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import styled from '@emotion/styled';

import { getNotices } from '@/apis/notices';

import { QUERY_KEY } from '@/constants/queryKey';

import GreyBox from '@/components/common/GreyBox';
import Pagination from '@/components/common/Pagination';
import Spacer from '@/components/common/Spacer';
import UpdateInfoItem from '@/components/landing/UpdateInfoSection/UpdateInfoItem';

import { Theme } from '@/styles/Theme';

const UpdateInfoSection = () => {
  const limit = 5;
  const [searchParams] = useSearchParams();

  const page = Number(searchParams.get('page') ?? 1);

  const { data: updateInfoData } = useQuery([QUERY_KEY.UPDATE_INFO, page], {
    queryFn: () => getNotices({ platform: 'web', page, limit }),
  });

  return (
    <GreyBox title='공지사항' maxWidth='58.125rem'>
      {updateInfoData ? (
        <>
          <UpdateInfoContainer>
            {updateInfoData.data.formattedNotices.map((updateInfo) => (
              <UpdateInfoItem key={updateInfo.id} updateInfo={updateInfo} />
            ))}
          </UpdateInfoContainer>
          <Spacer y='1.875rem' />
          <Pagination
            page={page}
            size={limit}
            totalCount={updateInfoData?.data.totalCount}
          />
        </>
      ) : (
        <EmptyBlock />
      )}
    </GreyBox>
  );
};

export default UpdateInfoSection;

const UpdateInfoContainer = styled.ul`
  border-top: 0.0625rem solid ${Theme.colors.G_2};
`;

const EmptyBlock = styled.div`
  width: 100%;
  height: 25.375rem;

  background-color: ${Theme.colors.WHITE};
`;
