import { Theme } from '@/styles/Theme';

export const ROUTER = {
  LANDING: '/',
  LOGIN: '/login',
  SIGNUP: '/sign-up',
  FIND_PWD: {
    EMAIL: '/find-pwd/email',
    CHANGE_PWD: '/find-pwd/change-pwd',
  },
  DASHBOARD: '/dashboard',
  MYCOMPANY: '/mycompany',
  WORKER: '/worker',
  NOTIFICATION: '/notification',
  STORAGE: '/storage',
  PAYMENT: '/payment',
  CARD_REGISTER: '/card-register',
  WORK: {
    DETAIL: '/dashboard/work',
    INSPECTION_HISTORY: 'dashboard/work/history',
    ADD: '/dashboard/work/add',
    EDIT: '/dashboard/work/edit',
    REPORT_DETAIL: '/dashboard/work/report',
    REPORT_EDIT: '/dashboard/work/report/edit',
    APPENDIX: '/dashboard/work/appendix',
  },

  //admin
  ADMIN: {
    USER_MANAGE: '/admin/user-manage',
    // 서지수 TODO 사용자 접속 현황 통계
    // USER_STATISTICS: '/admin/user-statistics',
  },
};

export const REGEXP = {
  EMAIL: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  PASSWORD:
    /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*()._-])[a-zA-Z0-9!@#$%^&*()._-]{8,30}$/,
  NUMBER: /^\d+$/g,
  URL: /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi,
};

export const SERVICE_NAME = 'Blue Mentor';

export const INPUT_MESSAGE = {
  REQUIRED: '필수 입력 항목입니다.',
  EMAIL: {
    PLACEHOLDER: '이메일을 입력해주세요.',
    MESSAGE: '올바른 이메일을 입력해주세요.',
    LABEL: '이메일',
  },
  PASSWORD: {
    PLACEHOLDER: '비밀번호를 입력해주세요.',
    MESSAGE: '영문, 숫자, 기호를 포함해주세요.',
    MIN: '8자 이상 입력해주세요.',
    MAX: '30자 미만 입력해주세요.',
    SUBSCRIPTION: '영문, 숫자, 기호를 포함한 8자 이상 비밀번호를 입력해주세요.',
    LABEL: '비밀번호',
  },
  CONFIRM_PASSWORD: {
    PLACEHOLDER: '비밀번호를 재입력해주세요.',
    MESSAGE: '비밀번호가 맞지 않습니다.',
  },
  USER_CODE: {
    PLACEHOLDER: '인증코드를 입력해주세요.',
  },
  COMPANY: {
    PLACEHOLDER: '회사/기관명을 입력해주세요',
    MIN: '2자 이상 입력해주세요.',
    LABEL: '회사/기관명',
  },
  PHONE: {
    PLACEHOLDER: '대표 핸드폰 번호를 입력해주세요.',
    MESSAGE: '숫자만 입력해주세요.',
    SUBSCRIPTION: '01012345678 형식으로 입력해주세요.',
    LABEL: '핸드폰 번호',
  },
  NAME: {
    PLACEHOLDER: '작업자 이름(아이디)를 입력해주세요.',
    LABEL: '작업자 이름(아이디)',
  },
  MEMO: {
    PLACEHOLDER: '비고를 입력해주세요.',
    LABEL: '비고(선택)',
  },
  NOTE_NUMBER: {
    PLACEHOLDER: '수첩발급번호를 입력해주세요.',
    LABEL: '수첩발급번호(선택)',
  },
  GRADE: {
    PLACEHOLDER: '등급구분을 입력해주세요.',
    LABEL: '등급구분(선택)',
  },
};

export const SIGNUP_ROUTE = [
  '검색',
  '이메일',
  '팩스',
  '광고, 뉴스 기사',
  '전시회',
  '지인 소개',
];

export const WORK_STATUS_LIST = [
  { name: '전체', status: 'all', color: Theme.colors.BLACK },
  { name: '예정', status: 'before', color: Theme.colors.G_3 },
  { name: '진행중', status: 'in-progress', color: Theme.colors.B_4 },
  { name: '완료', status: 'done', color: Theme.colors.GREEN },
];

export const ORDERING_LIST = [
  { name: '최신순', seq: 'DESC' },
  { name: '오래된순', seq: 'ASC' },
];

export const MANUAL_S3_LINK = {
  WEB: 'https://bm-image.s3.ap-northeast-2.amazonaws.com/bm_web_manual.pdf',
  APP: 'https://bm-image.s3.ap-northeast-2.amazonaws.com/bm_app_manual.pdf',
  MAIN: 'https://bm-image.s3.ap-northeast-2.amazonaws.com/bm_main_manual.pdf',
  VIDEO: 'https://bm-image.s3.ap-northeast-2.amazonaws.com/bm_video_manual.mp4',
  REPORT:
    'https://bm-image.s3.ap-northeast-2.amazonaws.com/LSMK%EA%B1%B4%EB%AC%BC+%EA%B8%B0%EA%B3%84%EC%84%A4%EB%B9%84+%EC%84%B1%EB%8A%A5%EC%A0%90%EA%B2%80+%EB%B3%B4%EA%B3%A0%EC%84%9C.xlsx',
};

export const TRIAL_PREIOD = 21;
export const PURCHASABLE_WORKER = 50;
