export const unitConverter = (templateName: string): string => {
  /*층*/
  const floor = ['팬코일유닛'];
  /*식:거리*/
  const dist = [
    '신재생(지열)',
    '신재생(태양열)',
    '신재생(연료전지)',
    '신재생(대기열)',
    '위생기구설비',
    '급수·급탕설비',
    '고·저수조',
    '오·배수 통기 및 우수배수설비',
    '오수정화설비',
    '물 재이용설비',
    '배관설비',
    '덕트설비',
    '보온설비',
    '자동제어설비',
    '방음·방진·내진설비',
  ];

  if (floor.includes(templateName)) {
    return '층';
  } else if (dist.includes(templateName)) {
    return '식';
  }
  return '대';
};

export const proportionConverter = (templateName: string): string => {
  const percent100 = [
    '축열조',
    '팽창탱크',
    '필터',
    '신재생(지열)',
    '신재생(태양열)',
    '신재생(연료전지)',
    '신재생(대기열)',
  ];
  const percent50 = ['냉동기', '냉각탑', '보일러', '열교환기'];

  const percent20 = [
    '펌프(냉·난방)',
    '패키지 에어컨',
    '공기조화기',
    '항온항습기',
    '팬코일유닛',
    '환기설비',
  ];

  if (templateName === '덕트설비') return 'vav,cav 유닛포함';
  else if (templateName === '자동제어설비') return '감시반,기계,공조실포함';

  if (percent20.includes(templateName)) {
    return '20%';
  } else if (percent50.includes(templateName)) {
    return '50%';
  } else if (percent100.includes(templateName)) {
    return '100%';
  }

  return '';
};
