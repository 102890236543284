import { useState } from 'react';
import styled from '@emotion/styled';

import Header from '@/components/landing/Header';
// import Section1 from '@/components/landing/Section1';
import Section2 from '@/components/landing/Section2';
import Section3 from '@/components/landing/Section3';
import Section4 from '@/components/landing/Section4';
import Section5 from '@/components/landing/Section5';
import Section6 from '@/components/landing/Section6';
import Popup from '@/components/common/Popup';
import LandingSwiper from '@/components/landing/LandingSwiper';
import UpdateInfoSection from '@/components/landing/UpdateInfoSection';

const LandingPage = () => {
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(true);
  const [isUpdatePage, setIsUpdatePage] = useState<boolean>(false);

  return (
    <>
      {isPopupOpen && (
        <Popup
          width='19.0625rem'
          height='35.9375rem'
          imgSrc='/popupImg.png'
          setIsPopupOpen={setIsPopupOpen}
        />
      )}
      <Container>
        <Header isUpdatePage={isUpdatePage} setIsUpdatePage={setIsUpdatePage} />
        {isUpdatePage ? (
          <UpdateContainer className='header'>
            <UpdateInfoSection />
          </UpdateContainer>
        ) : (
          <div>
            <LandingSwiper />
            {/* <Section1 /> */}
            <Section3 />
            <Section4 />
            <Section2 />
            <Section5 />
            <Section6 />
          </div>
        )}
      </Container>
    </>
  );
};
export default LandingPage;

export const UpdateContainer = styled.div`
  margin-top: 1.25rem;
`;

export const Container = styled.div`
  margin-top: 6.875rem;

  width: 100%;
  min-width: 18.75rem;
  display: flex;
  flex-direction: column;
`;
