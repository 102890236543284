import Pagination from '@/components/common/Pagination';
import * as styled from '@/components/inspectionHistory/HistoryList/style';
import useGetInspectionHistory from '@/hooks/useGetInspectionHistory';
import { useParams, useSearchParams } from 'react-router-dom';

interface Props {
  selectedWorker: number | null;
}

const HistoryList = ({ selectedWorker }: Props) => {
  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get('page') ?? 1);

  const params = useParams();
  const inspectionAreaId = Number(params.id);

  const { data: historyList } = useGetInspectionHistory({
    inspectionAreaId: inspectionAreaId,
    workerId: selectedWorker,
    page: page,
    limit: 20,
  });

  if (!historyList) {
    return null;
  }

  const { logList } = historyList.data;

  if (logList.length === 0) {
    return (
      <styled.EmptyHistoryList>수정 내역이 없습니다.</styled.EmptyHistoryList>
    );
  }

  return (
    <>
      <styled.HistoryUl>
        {logList.map((history) => (
          <styled.HistoryLi key={history.id}>
            <styled.HistoryInfoWrapper>
              <styled.HistoryContent>{history.content}</styled.HistoryContent>
              <styled.HistoryCreatedAt>
                {history.createdAt}
              </styled.HistoryCreatedAt>
            </styled.HistoryInfoWrapper>
          </styled.HistoryLi>
        ))}
      </styled.HistoryUl>
      <Pagination
        page={page}
        totalCount={historyList.data.totalCount}
        size={20}
      />
    </>
  );
};

export default HistoryList;
