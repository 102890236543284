import { useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { AiOutlineClose, AiOutlineRight } from 'react-icons/ai';

import { useAccessTokenStore } from '@/store/auth';

import { ROUTER } from '@/constants/constants';

import Button from '@/components/common/Button';
import Text from '@/components/common/Text';
import Modal from '@/components/common/Modal';
import Spacer from '@/components/common/Spacer';

import { Theme } from '@/styles/Theme';
import * as S from './style';

interface Props {
  isUpdatePage: boolean;
  setIsUpdatePage: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header = ({ isUpdatePage, setIsUpdatePage }: Props) => {
  const { accessToken } = useAccessTokenStore();
  const [isEventOpen, setIsEventOpen] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [activeSection, setActiveSection] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const onClickNavigate = () => {
    window.scrollTo(0, 0);
    navigate(ROUTER.SIGNUP);
  };

  const handleClickScroll = (id: string) => {
    setSearchParams('');
    setIsUpdatePage(false);

    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        const offset = -130;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.scrollY + offset;

        window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
      }
    }, 200);
  };

  const handleClickUpdatePage = () => {
    setIsUpdatePage(true);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.6,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    }, options);

    const sectionRefs = document.querySelectorAll('.header');

    Object.values(sectionRefs).forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      Object.values(sectionRefs).forEach((ref) => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
    };
  }, [isUpdatePage]);

  return (
    <>
      {isModalOpen && (
        <Modal setIsModalOpen={setIsModalOpen} onClickConfirm={onClickNavigate}>
          <Spacer y='1.25rem' />
          <Text fontSize='1.125rem' textAlign='center'>
            회원가입 시{' '}
            <Text fontSize='1.125rem' fontWeight={700}>
              총 3주 무료체험
            </Text>{' '}
            기간이 제공됩니다!
            <br />
            회원가입을 진행하시겠습니까?
          </Text>
          <Spacer y='1.25rem' />
        </Modal>
      )}
      <S.Container>
        {isEventOpen && (
          <S.EventContainer>
            <S.MainWrapper onClick={() => setIsModalOpen(true)}>
              <S.MainContainer>
                <Text fontSize='1rem' color={Theme.colors.WHITE}>
                  <Text fontSize='1.25rem'>🎉</Text> 고객 감사 기념 무료체험
                  연장
                </Text>
                <Text
                  margin='0 0 0 0.25rem'
                  fontSize='1.125rem'
                  fontWeight={700}
                  color='#f4ea08'
                >
                  기존 1주 + 추가 2주 제공!
                </Text>
                <AiOutlineRight
                  color={Theme.colors.WHITE}
                  size='1.125rem'
                  cursor='pointer'
                />
              </S.MainContainer>
            </S.MainWrapper>
            <S.IconWrapper onClick={() => setIsEventOpen(false)}>
              <AiOutlineClose color={Theme.colors.WHITE} size='1.5rem' />
            </S.IconWrapper>
          </S.EventContainer>
        )}
        <S.HeaderContainer>
          <Link to={ROUTER.LANDING}>
            <S.LogoImg src='/logo.svg' alt='main_logo' />
          </Link>
          <S.ScrollLinkContainer>
            <S.ScrollLink
              onClick={() => handleClickScroll('header_part1')}
              isActive={activeSection === 'header_part1'}
            >
              서비스 소개
            </S.ScrollLink>
            <S.ScrollLink
              onClick={() => handleClickScroll('header_part2')}
              isActive={activeSection === 'header_part2'}
            >
              서비스 사용 가이드
            </S.ScrollLink>
            <S.ScrollLink
              onClick={() => handleClickScroll('header_part3')}
              isActive={activeSection === 'header_part3'}
            >
              예상 요금 안내
            </S.ScrollLink>
            <S.ScrollLink
              isActive={isUpdatePage}
              onClick={handleClickUpdatePage}
            >
              공지사항
            </S.ScrollLink>
          </S.ScrollLinkContainer>

          <S.ItemContainer>
            {/* <S.Item>서비스 이용 가이드</S.Item>
        <S.Item>요금 안내</S.Item>
        <S.Item>자주 묻는 질문</S.Item> */}
          </S.ItemContainer>
          {accessToken ? (
            <Link to={ROUTER.DASHBOARD}>
              <Button
                type='button'
                width='8.75rem'
                height='2rem'
                border='0.0625rem solid black'
                borderRadius='1.25rem'
                backgroundColor='white'
                color='black'
                fontSize='0.8125rem'
              >
                대시보드로 이동
              </Button>
            </Link>
          ) : (
            <Link to={ROUTER.LOGIN}>
              <Button
                type='button'
                width='8.75rem'
                height='2rem'
                border='0.0625rem solid black'
                borderRadius='1.25rem'
                backgroundColor='white'
                color='black'
                fontSize='0.8125rem'
              >
                로그인
              </Button>
            </Link>
          )}
        </S.HeaderContainer>
      </S.Container>
    </>
  );
};
export default Header;
