import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Theme } from '@/styles/Theme';

export const TopContainer = styled.div`
  width: 100%;
  height: 3.5625rem;

  padding: 0 1.25rem;
  border-radius: 0.3125rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: ${Theme.colors.WHITE};
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
`;

export const StatusFilter = styled.div<{ isSelected: boolean }>`
  cursor: pointer;
  font-size: 1.0625rem;
  color: ${Theme.colors.G_2};

  ${({ isSelected }) =>
    isSelected &&
    css`
      font-weight: 700;
      color: ${Theme.colors.BLACK};
    `}
`;

export const OrderingWrapper = styled.div`
  cursor: pointer;

  position: relative;

  height: 2.1875rem;
  padding: 0 0.625rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.3125rem;

  span {
    padding-bottom: 0.1875rem;
  }

  border: 0.0625rem solid ${Theme.colors.G_3};
  border-radius: 0.25rem;
  background-color: ${Theme.colors.WHITE};

  :hover {
    background-color: ${Theme.colors.G_6};
  }
`;

export const OrderdingContainer = styled.div<{ isOpen: boolean }>`
  transition: opacity 0.2s ease, transform 0.2s ease;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transform: translateY(${({ isOpen }) => (isOpen ? '0' : '-0.625rem')});
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};

  position: absolute;

  top: 2.8125rem;
  right: -0.125rem;

  border: 0.0625rem solid ${Theme.colors.G_3};
  border-radius: 0.25rem;
  background-color: ${Theme.colors.WHITE};

  z-index: 10;

  div:last-of-type {
    border-bottom: none;
  }
`;

export const OrderingItem = styled.div`
  width: 6.875rem;
  height: 2.8125rem;

  padding: 0 1.25rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 0.0625rem solid ${Theme.colors.G_3};

  :hover {
    font-weight: 700;
    color: ${Theme.colors.B_3};
  }
`;

export const InputContainer = styled.div`
  margin-left: 1.25rem;

  position: relative;
`;

export const StyledInput = styled.input`
  padding: 0.625rem 2.5rem;

  width: 17.0625rem;
  height: 2.375rem;

  border: 0.0625rem solid ${Theme.colors.G_3};
  border-radius: 1.25rem;

  font-size: 0.875rem;
  font-weight: 400;
  color: ${Theme.colors.BLACK};

  ::placeholder {
    font-size: 0.875rem;
    font-weight: 400;
    color: ${Theme.colors.G_2};
  }

  :focus {
    outline: none;
  }
`;
