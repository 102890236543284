import client from '@/apis/client';
import type { Response } from '@/types/client.types';
import type {
  InspectionEquipmentInfo,
  InspectionEquipmentItem,
} from '@/types/report/equipment.types';

// Ⅱ. 성능점검 계획
// 2. 성능점검 장비 API
export const getInspectionEquipment = () =>
  client
    .get<Response<InspectionEquipmentItem[]>>('/report/plan/equip')
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error fetching inspection equipment:', error);
      throw error;
    });

export const postInspectionEquipment = (body: InspectionEquipmentInfo) =>
  client
    .post('/report/plan/equip/upload', body)
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error posting inspection equipment:', error);
      throw error;
    });

export const putInspectionEquipment = (body: InspectionEquipmentItem) =>
  client
    .put('/report/plan/equip/mod', body)
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error updating inspection equipment:', error);
      throw error;
    });

export const deleteInspectionEquipment = (equipmentId: number) =>
  client
    .delete('/report/plan/equip/del', { data: { equipId: equipmentId } })
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error deleting inspection equipment:', error);
      throw error;
    });
