import { useQuery } from '@tanstack/react-query';

import { getCompanyInfo } from '@/apis/company';

import { QUERY_KEY } from '@/constants/queryKey';

const useCompanyInfo = () => {
  const query = useQuery([QUERY_KEY.COMPANY_INFO], {
    queryFn: getCompanyInfo,
  });

  return query;
};

export default useCompanyInfo;
