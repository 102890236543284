import styled from '@emotion/styled';
import { Theme } from '@/styles/Theme';

import useCompanyInfo from '@/hooks/useCompanyInfo';

import Text from '@/components/common/Text';
import DaysUntilNextPayment from '@/components/common/GNB/GnbProfile/DaysUntilNextPayment';

const GnbProfile = () => {
  const { data: companyInfo } = useCompanyInfo();

  return (
    <ProfileContainer>
      {companyInfo && (
        <>
          <ImgWrapper src={companyInfo.data.image[0] ?? '/company.svg'} />
          <Text textAlign='center' fontSize='1rem' fontWeight={500}>
            {companyInfo.data.companyName}
          </Text>
          <DaysUntilNextPayment period={companyInfo.data.period} />
        </>
      )}
    </ProfileContainer>
  );
};

export default GnbProfile;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 0.9375rem;
`;

const ImgWrapper = styled.img`
  width: 6.25rem;
  height: 6.25rem;

  padding: 0.3125rem;

  background-color: ${Theme.colors.WHITE};

  object-fit: contain;

  border-radius: 50%;
  /* box-shadow: 0rem 0rem 0.25rem 0rem rgba(0, 0, 0, 0.25); */
  /* border: 0.0625rem solid ${Theme.colors.G_2}; */
`;
