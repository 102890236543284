import React, { useState } from 'react';

import PersistingPeriodAgeTable from '@/components/report/review/plan/PersistingPeriodAgeTable';

import * as S from '../../commonStyles';
import NonconformAndImproveTable from '@/components/report/review/plan/NonconformAndImproveTable';
import YearDetailImproveTable from '@/components/report/review/plan/YearDetailImproveTable';

const SystemImprovePlanTab = [
  {
    name: '1) 기계설비 내구연한에 따른 노후도',
    component: <PersistingPeriodAgeTable />,
  },

  {
    name: '2) 성능점검표에 따른 부적합 및 개선사항',
    component: <NonconformAndImproveTable />,
  },
  {
    name: '3) 성능개선 필요성 및 연도별 세부개선계획',
    component: <YearDetailImproveTable />,
  },
];

const ImprovePlanGroup = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  return (
    <S.Container>
      <S.TabContainer>
        {SystemImprovePlanTab.map((tab, index) => (
          <S.StyledTab
            key={index}
            isSelected={selectedTab === index}
            onClick={() => {
              setSelectedTab(index);
            }}
          >
            {tab.name}
          </S.StyledTab>
        ))}
      </S.TabContainer>

      {SystemImprovePlanTab[selectedTab].component}
    </S.Container>
  );
};

export default ImprovePlanGroup;
