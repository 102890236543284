import React from 'react';

import GreyBox from '@/components/common/GreyBox';
import Text from '@/components/common/Text';

const ReportOutline = () => {
  return (
    <GreyBox title='성능점검 개요' width='58.125rem'>
      <Text fontWeight={600} fontSize='1.25rem'>
        1. 성능점검 개요
      </Text>
      <br />
      <br />
      • 기계설비는 설치환경, 운전조건, 유지·관리상태 등에 따라 성능과 수명이
      달라지며 특히 동일한 조건에서 운전하는 설비라 하더라도 관리 및 유지·보수의
      영향이 매우 큼.
      <br />
      <br />
      • 사업장 자체적으로 예방점검 및 유지관리 활동을 하고 있으나, 관리 및
      유지·보수의 중요 성을 감안해 볼 때 기계설비에 관한 정확한 자료를 확보하기
      위해서는 계측장비에 의한 명 확하고 객관적인 진단이 필요함.
      <br />
      <br />
      • 또한 주기적인 진단에 의한 데이터를 바탕으로 주요 기계설비의 노후화 및
      수명을 예측 관리하여 주요설비의 치명적인 사고를 미연에 방지하고, 고장,
      예상 설비의 보수 및 교체를 사전에 유도함과 동시에 에너지 관리의 효율화를
      도모하고자 함.
      <br />
      <br />• 기계설비본부에서는 계측장비와 축적된 성능점검 경험을 토대로
      기계설비의 운전상황의 적정성 여부와 안전상태 등을 종합적으로 진단, 도출된
      문제점을 개선하고 향후 기계설비 효율적 운용 및 유지·관리 에너지절감에
      유용한 자료를 제공하고자 함.
    </GreyBox>
  );
};

export default ReportOutline;
