import styled from '@emotion/styled';
import { Theme } from '@/styles/Theme';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 16.875rem;
  height: 10.625rem;

  margin: 0 auto;
  padding: 1.25rem;

  border-radius: 0.5rem;
`;

export const PaymentMethodDesc = styled.span`
  font-size: 0.875rem;
  text-align: center;
  color: ${Theme.colors.G_1};
`;
