import { Theme } from '@/styles/Theme';
import type { IsActiveWorker } from '@/types/company.types';
import { useState } from 'react';
import styled from '@emotion/styled';
import UnsubscribeWorkersModal from '../../../ManageWorkerPaymentModal/UnsubscribeWorkersModal';
import UndoUnsubscribeWorkersModal from '../../../ManageWorkerPaymentModal/UndoUnsubscribeWorkersModal';
import SubscribeWorkersModal from '../../../ManageWorkerPaymentModal/SubscribeWorkersModal';

interface Props extends IsActiveWorker {
  workerId: string;
}

const WorkerActiveManageBtn = ({ workerId, isActive }: Props) => {
  const [isShowActiveManageModal, setIsShowActiveManageModal] = useState(false);

  return (
    <>
      {isShowActiveManageModal && (
        <>
          {isActive === '활성' ? (
            <UnsubscribeWorkersModal
              setIsShowModal={setIsShowActiveManageModal}
              selectedWorkers={[Number(workerId)]}
            />
          ) : isActive === '비활성 예정' ? (
            <UndoUnsubscribeWorkersModal
              setIsShowModal={setIsShowActiveManageModal}
              selectedWorkers={[Number(workerId)]}
            />
          ) : (
            isActive === '비활성' && (
              <SubscribeWorkersModal
                setIsShowModal={setIsShowActiveManageModal}
                selectedAddWorkerList={[]}
                selectedRestoreWorkerList={[Number(workerId)]}
              />
            )
          )}
        </>
      )}
      {isActive !== '생성' && (
        <ManageBtn
          isActive={isActive}
          onClick={() => setIsShowActiveManageModal(true)}
        >
          {isActive === '활성'
            ? '중단'
            : isActive === '비활성'
            ? '복구'
            : isActive === '비활성 예정' && '중단 취소'}
        </ManageBtn>
      )}
    </>
  );
};

export default WorkerActiveManageBtn;

export const ManageBtn = styled.button<IsActiveWorker>`
  background-color: ${({ isActive }) =>
    isActive === '활성'
      ? Theme.colors.WHITE
      : isActive === '비활성'
      ? Theme.colors.GREEN
      : isActive === '비활성 예정' && Theme.colors.WARNING_RED};
  border: ${({ isActive }) =>
    isActive === '활성' && `0.0625rem solid ${Theme.colors.G_3}`};
  padding: 0.3125rem 0.625rem;
  color: ${({ isActive }) =>
    isActive === '활성' ? Theme.colors.BLACK : Theme.colors.WHITE};
  border-radius: 0.5rem;
`;
