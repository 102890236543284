import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AiOutlineFileImage } from 'react-icons/ai';

import Input from '@/components/common/Input';
import Button from '@/components/common/Button';
import Text from '@/components/common/Text';
import GreyBox from '@/components/common/GreyBox';

import type { ErrorResponse } from '@/types/client.types';

import { QUERY_KEY } from '@/constants/queryKey';

import {
  getEnergyConsumption,
  postEnergyConsumption,
} from '@/apis/report/energy';
import type { EnergyConsumptionItem } from '@/types/report/energy.types';

import { Theme } from '@/styles/Theme';
import * as S from './style';
import uploadImagePresignedUrl from '@/utils/uploadImagePresignedUrl';

const EnergyReviewTable = () => {
  const [isEdit, setEdit] = useState<boolean>(false);
  const [editableEnergy, setEditableEnergy] = useState<EnergyConsumptionItem[]>(
    []
  );
  const queryClient = useQueryClient();
  const params = useParams();
  const inspectionAreaId = Number(params.id);

  const { mutate: energyMutate } = useMutation({
    mutationFn: postEnergyConsumption,
    onSuccess: (data) => {
      toast.success('에너지 점검 데이터가 수정되었습니다.');
      queryClient.invalidateQueries([
        QUERY_KEY.REPORT.ENERGY,
        inspectionAreaId,
      ]);
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      toast.error(error.response?.data.message);
    },
  });

  const { data: energyData } = useQuery({
    queryKey: [QUERY_KEY.REPORT.ENERGY, inspectionAreaId],
    queryFn: () => getEnergyConsumption(inspectionAreaId),
  });

  useEffect(() => {
    if (energyData && energyData.data) {
      let temp: EnergyConsumptionItem[] = [];
      if (energyData.data.length > 0) {
        for (let i = 0; i < energyData.data.length; i++) {
          temp.push({
            image: energyData.data[i].image,
            explanation: energyData.data[i].explanation,
          });
        }
      }

      setEditableEnergy([...temp]);
    }
  }, [energyData]);

  const handleImgChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const uploadedUrl = await uploadImagePresignedUrl(e.target.files);
      if (uploadedUrl) {
        updateEnergyImage(uploadedUrl);
      } else {
        toast.error('이미지 업로드에 실패했습니다');
      }
    } catch (error) {
      toast.error('이미지 업로드 중 에러가 발생했습니다');
      console.error('Error during image upload:', error);
    }
  };

  const handleExplanation = (index: number, value: string) => {
    let copiedData = [...editableEnergy];
    if (index < editableEnergy.length) {
      copiedData[index].explanation = value;
      setEditableEnergy(copiedData);
    }
  };

  const deleteEnergyItem = (index: number) => {
    let copiedData = [...editableEnergy];
    copiedData.splice(index, 1);
    setEditableEnergy([...copiedData]);
  };

  const updateEnergyImage = (image: string) => {
    let copiedData = [...editableEnergy];
    copiedData[editableEnergy.length - 1].image = image;
    setEditableEnergy(copiedData);
  };

  const addEnergyImage = () => {
    if (editableEnergy.length >= 4) {
      toast.error('첨부 가능한 사진은 최대 4장입니다.');
      return;
    }
    if (editableEnergy.filter((it) => it.image === '').length > 0) {
      toast.error('첨부되지 않은 이미지가 있습니다.');
      return;
    }
    let copiedData = [...editableEnergy, { image: '', explanation: '' }];
    setEditableEnergy(copiedData);
  };

  const handleConfirmData = () => {
    if (editableEnergy.filter((it) => it.image === '').length > 0) {
      toast.error('첨부되지 않은 이미지가 있습니다.');
      return;
    }
    energyMutate({
      inspectionAreaId: inspectionAreaId,
      imageList: editableEnergy,
    });
    setEdit(false);
  };

  const handleCancelEdit = () => {
    if (energyData === undefined) return;
    setEditableEnergy([...energyData?.data]);
    setEdit(false);
  };

  return (
    <GreyBox width='58.125rem'>
      {energyData && energyData.data && editableEnergy && (
        <>
          <S.FlexContainer>
            <Text fontSize='1.25rem' fontWeight={600}>
              에너지 사용량 검토
            </Text>
            <div>
              {isEdit ? (
                <Button
                  backgroundColor={Theme.colors.G_2}
                  onClick={() => {
                    handleCancelEdit();
                  }}
                >
                  취소하기
                </Button>
              ) : (
                <></>
              )}

              <Button
                backgroundColor={isEdit ? Theme.colors.BLACK : Theme.colors.B_3}
                onClick={() => {
                  if (isEdit) handleConfirmData();
                  else setEdit(true);
                }}
              >
                {isEdit
                  ? '저장하기'
                  : editableEnergy.length === 0
                  ? '추가하기'
                  : '수정하기'}
              </Button>
            </div>
          </S.FlexContainer>

          <S.Container>
            {editableEnergy.map((v, index) => (
              <S.ImageContainer key={index}>
                <S.TitleWrapper>
                  {isEdit ? (
                    <Button
                      backgroundColor={Theme.colors.WARNING_RED}
                      height='2.5rem'
                      onClick={() => deleteEnergyItem(index)}
                    >
                      삭제
                    </Button>
                  ) : (
                    <></>
                  )}

                  <Input
                    disabled={!isEdit || (v.image !== '' && !isEdit)}
                    name='에너지 사용량'
                    width='100%'
                    height='2.5rem'
                    placeholder='이미지 제목을 입력하세요'
                    value={v.explanation}
                    onChange={(e) => {
                      handleExplanation(index, e.target.value);
                    }}
                  ></Input>
                </S.TitleWrapper>

                <S.FileInput
                  type='file'
                  id='img_input_energy1'
                  accept='.jpg, .jpeg, .png'
                  disabled={!isEdit}
                  onChange={(e) => {
                    handleImgChange(e);
                  }}
                />

                {v.image === '' ? (
                  <>
                    <S.EmptyImageWrapper htmlFor='img_input_energy1'>
                      <AiOutlineFileImage
                        size='3.75rem'
                        color={Theme.colors.G_2}
                      ></AiOutlineFileImage>
                      <Text>이미지 불러오기</Text>
                    </S.EmptyImageWrapper>
                  </>
                ) : (
                  <>
                    <S.ImageWrapper>
                      {v.image ? (
                        <S.StyledImage src={v.image}></S.StyledImage>
                      ) : (
                        <></>
                      )}
                    </S.ImageWrapper>
                  </>
                )}
              </S.ImageContainer>
            ))}
            {isEdit ? (
              <Button
                backgroundColor={Theme.colors.B_2}
                onClick={addEnergyImage}
              >
                추가하기
              </Button>
            ) : (
              <></>
            )}
          </S.Container>
        </>
      )}
    </GreyBox>
  );
};

export default EnergyReviewTable;
