import styled from '@emotion/styled';
import { useQuery } from '@tanstack/react-query';
import { Link, useSearchParams } from 'react-router-dom';
import { HiPlusSm } from 'react-icons/hi';

import { getInspectionAreaList } from '@/apis/InspectionArea';

import { QUERY_KEY } from '@/constants/queryKey';
import { ROUTER } from '@/constants/constants';

import GreyBox from '@/components/common/GreyBox';
import Pagination from '@/components/common/Pagination';
import Spacer from '@/components/common/Spacer';
import Text from '@/components/common/Text';
import DashboardItem from '@/components/dashboard/DashboardItem';
import DashboardFilterBar from '@/components/dashboard/DashboardFilterBar';
import Button from '@/components/common/Button';

import { Theme } from '@/styles/Theme';

const DashboardPage = () => {
  const [searchParams] = useSearchParams();

  const status = searchParams.get('status') ?? 'all';
  const page = Number(searchParams.get('page') ?? 1);
  const seq = searchParams.get('seq') ?? 'DESC';
  const deleted = Number(searchParams.get('deleted') ?? 0);
  const q = searchParams.get('q') ?? '';
  const size = 5;

  const { data: workListData } = useQuery(
    [QUERY_KEY.DASHBOARD, status, page, seq, deleted, q],
    {
      queryFn: () =>
        getInspectionAreaList({ status, page, limit: size, seq, deleted, q }),
    }
  );

  return (
    <GreyBox title='점검지 관리' maxWidth='77.5rem' minWidth='59.375rem'>
      <ButtonWrapper>
        <Link to={ROUTER.WORK.ADD}>
          <Button
            height='2.1875rem'
            padding='0.625rem 0.9375rem 0.625rem 0.625rem'
          >
            <HiPlusSm size='1.25rem' />
            점검지 등록
          </Button>
        </Link>
      </ButtonWrapper>
      <DashboardFilterBar />
      <Spacer y='1.25rem' />
      <WorksContainer>
        {workListData &&
          (workListData.data.totalCount > 0 ? (
            <FlexContainer>
              <div>
                <InfoContainer>
                  <Text
                    fontSize='1.0625rem'
                    fontWeight={500}
                    color={Theme.colors.G_1}
                  >
                    점검지 수 : {workListData.data.totalCount}개
                  </Text>
                  <Text
                    fontSize='1.0625rem'
                    fontWeight={500}
                    color={Theme.colors.G_1}
                  >
                    {page} / {Math.ceil(workListData.data.totalCount / 5)}{' '}
                    페이지
                  </Text>
                </InfoContainer>
                <Spacer y='0.625rem' />
                <DashboardItemContainer>
                  {workListData.data.inspectionAreaList.map((work) => (
                    <DashboardItem key={work.id} {...work} />
                  ))}
                </DashboardItemContainer>
                <Spacer y='1.875rem' />
              </div>
              <Pagination
                page={page}
                size={size}
                totalCount={workListData.data.totalCount}
              />
            </FlexContainer>
          ) : (
            <NoDataWrapper>
              <Text
                fontWeight={500}
                fontSize='1.375rem'
                color={Theme.colors.G_2}
              >
                조건에 맞는 시설물이 없습니다.
              </Text>
            </NoDataWrapper>
          ))}
      </WorksContainer>
    </GreyBox>
  );
};

export default DashboardPage;

const ButtonWrapper = styled.div`
  position: absolute;
  right: 2.5rem;
  top: 2.8125rem;
`;

const DashboardItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const WorksContainer = styled.div`
  height: 46.3438rem;
`;

const NoDataWrapper = styled.div`
  height: inherit;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const FlexContainer = styled.div`
  height: inherit;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
