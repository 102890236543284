import GreyBox from '@/components/common/GreyBox';
import {
  PaymentMethodInfo,
  UserPaymentInfo,
  WorkerPaymentInfoBox,
} from '@/components/newPayment';

import usePaymentInfo from '@/hooks/usePaymentInfo';

const PaymentManagementPage = () => {
  const { data: paymentData } = usePaymentInfo();

  return (
    <GreyBox title='결제 관리' maxWidth='53.125rem' minWidth='34.375rem'>
      {paymentData && (
        <>
          <UserPaymentInfo paymentData={paymentData.data} />
          <PaymentMethodInfo paymentData={paymentData.data} />
          <WorkerPaymentInfoBox paymentData={paymentData.data} />
        </>
      )}
    </GreyBox>
  );
};

export default PaymentManagementPage;
