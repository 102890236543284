import React, { useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import { BsChevronCompactLeft } from 'react-icons/bs';

import { exportWorkExcel } from '@/utils/excelExport/exportWorkExcel';

import Button from '@/components/common/Button';
import Spacer from '@/components/common/Spacer';
import Text from '@/components/common/Text';
import Modal from '@/components/common/Modal';
import WorkDeleteModal from '@/components/work/WorkDeleteModal';
import EquipmentTable from '@/components/report/equipment/EquipmentTable';
import ReportPlanBlock from '@/components/report/reportPlan/ReportPlanBlock';
import BuildingAndMaintenanceGroup from '@/components/report/building/BuildingAndMaintenanceGroup';
import TargetDeviceTable from '@/components/report/building/TargetDeviceTable';
import MaintenanceInspectionRuleTable from '@/components/report/building/MaintenanceInspectionRuleTable';
import ImprovePlanGroup from '@/components/report/review/plan/improvePlanGroup';
import EnergyReviewTable from '@/components/report/review/energy/EnergyReveiwTable';
import SystemTableGroup from '@/components/report/review/system/SystemTableGroup';
import ReportOutline from '@/components/report/outline/ReportOutline';
import LegalBasis from '@/components/report/outline/LegalBasis';
import InspectionTarget from '@/components/report/outline/InspectionTarget';

import WorkAppendixPage from '@/pages/WorkAppendixPage';
import WorkPage from '@/pages/WorkPage';

import { Theme } from '@/styles/Theme';

export const REPORT_TABS = [
  {
    name: 'Ⅰ. 성능점검 개요',
    subTabs: [
      {
        name: '1. 성능점검 개요',
        component: <ReportOutline />,
      },
      {
        name: '2. 법적근거',
        component: <LegalBasis />,
      },
      {
        name: '3. 점검대상',
        component: <InspectionTarget />,
      },
    ],
  },
  {
    name: 'Ⅱ. 성능점검 계획',
    subTabs: [
      {
        name: '1. 성능점검 대상 및 기계설비 수량, 성능점검 일정, 성능점검 인력',
        component: <ReportPlanBlock />,
      },
      {
        name: '2. 성능점검 장비',
        component: <EquipmentTable />,
      },
    ],
  },
  {
    name: 'Ⅲ. 대상 건축물 현황',
    subTabs: [
      {
        name: '1. 점검대상 건축물 및 유지관리자 선임 현황',
        component: <BuildingAndMaintenanceGroup />,
      },
      {
        name: '2. 점검대상 기계설비의 종류 및 항목',
        component: <TargetDeviceTable />,
      },
      {
        name: '3. 유지관리 점검표 작성 및 보존 방법',
        component: <MaintenanceInspectionRuleTable />,
      },
    ],
  },
  {
    name: 'Ⅳ. 기계설비 성능점검 세부내역',
    subTabs: [
      {
        name: '1. 기계설비 성능점검 대상 점검표',
        component: <WorkPage />,
      },
      {
        name: '2. 성능점검 결과보고서',
        component: <WorkAppendixPage />,
      },
    ],
  },
  {
    name: 'Ⅴ. 성능점검 시 검토사항',
    subTabs: [
      {
        name: '1. 기계설비 시스템 검토',
        component: <SystemTableGroup />,
      },
      {
        name: '2. 성능개선 계획수립',
        component: <ImprovePlanGroup />,
      },

      {
        name: '3. 에너지 사용량 검토',
        component: <EnergyReviewTable />,
      },
    ],
  },
];

const ReportPage = () => {
  const { id } = useParams();
  const inspectionAreaId = Number(id);

  // 처음 들어오면 점검지 정보 보이게
  const [mainTab, setMainTab] = useState<number>(3);
  const [subTab, setSubTab] = useState<number>(0);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isExcelModalOpen, setIsExcelModalOpen] = useState<boolean>(false);
  const [isOriginalExcel, setIsOriginalExcel] = useState<boolean>(true);
  const [isSubTabFolded, setIsSubTabFolded] = useState<boolean>(false);

  const ActiveComponent = REPORT_TABS[mainTab].subTabs[subTab].component;

  const handleMainTab = (index: number) => {
    setSubTab(0);
    setMainTab(index);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const handleSubTab = (index: number) => {
    setSubTab(index);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const handleModalOpen = (isOriginal: boolean) => {
    setIsOriginalExcel(isOriginal);
    setIsExcelModalOpen(true);
  };

  const handleClickExcelDownload = async (imgCountTemplate: number) => {
    setIsExcelModalOpen(false);

    await exportWorkExcel(inspectionAreaId, imgCountTemplate, isOriginalExcel);
  };

  return (
    <div>
      {isExcelModalOpen && (
        <Modal
          setIsModalOpen={setIsExcelModalOpen}
          title={
            isOriginalExcel
              ? '엑셀 전체 보고서 최대 사진 수 선택'
              : '엑셀 성능점검표 최대 사진 수 선택'
          }
          hasButton={false}
        >
          <Spacer y='1.875rem' />
          <Text>
            엑셀 보고서의{' '}
            <Text fontWeight={700}>점검 항목 당 최대 사진 수</Text>를
            선택해주세요.
          </Text>
          <Text>
            (모든 점검 항목의 최대 사진 수가 통일되어 다운로드 됩니다.)
          </Text>
          <Spacer y='1.875rem' />
          <ExcelButtonContainer>
            <Button
              backgroundColor={Theme.colors.G_5}
              color={Theme.colors.BLACK}
              width='12.5rem'
              height='3.125rem'
              onClick={() => handleClickExcelDownload(3)}
            >
              3장
            </Button>
            <Button
              backgroundColor={Theme.colors.B_3}
              width='12.5rem'
              height='3.125rem'
              onClick={() => handleClickExcelDownload(6)}
            >
              6장
            </Button>
          </ExcelButtonContainer>
        </Modal>
      )}
      {isDeleteModalOpen && (
        <WorkDeleteModal
          setIsModalOpen={setIsDeleteModalOpen}
          inspectionAreaId={inspectionAreaId}
        />
      )}

      <ReportTabItemContainer>
        {REPORT_TABS.map((tab, index) => (
          <ReportTabItemWrapper
            key={index}
            isSelected={index === mainTab}
            onClick={() => handleMainTab(index)}
          >
            {tab.name}
          </ReportTabItemWrapper>
        ))}
      </ReportTabItemContainer>
      <ChildrenContainer>
        <ChildrenWrapper>{ActiveComponent}</ChildrenWrapper>
        {isSubTabFolded ? (
          <FoldedSubTabContainer onClick={() => setIsSubTabFolded(false)}>
            <BsChevronCompactLeft size='3rem' color={Theme.colors.G_2} />
          </FoldedSubTabContainer>
        ) : (
          <SubTabContainer>
            <div>
              <Text fontSize='1.125rem' fontWeight={600}>
                {REPORT_TABS[mainTab].name}
              </Text>
              <Spacer y='0.625rem' />
              <SubTabColumn>
                {REPORT_TABS[mainTab].subTabs.map((tab, index) => (
                  <SubTabWrapper
                    key={index}
                    isSelected={index === subTab}
                    onClick={() => handleSubTab(index)}
                  >
                    {tab.name}
                  </SubTabWrapper>
                ))}
              </SubTabColumn>
            </div>
            <ButtonContainer>
              <Button width='100%' onClick={() => handleModalOpen(true)}>
                전체 보고서 다운로드
              </Button>
              <Button width='100%' onClick={() => handleModalOpen(false)}>
                성능점검표 다운로드 (기존 방식)
              </Button>
              <Button
                backgroundColor={Theme.colors.WARNING_RED}
                width='100%'
                onClick={() => setIsDeleteModalOpen(true)}
              >
                점검지 삭제
              </Button>
              <FoldTextWrapper onClick={() => setIsSubTabFolded(true)}>
                <Text textAlign='center' hasUnderline fontWeight={600}>
                  숨기기
                </Text>
              </FoldTextWrapper>
            </ButtonContainer>
          </SubTabContainer>
        )}
      </ChildrenContainer>
    </div>
  );
};

export default ReportPage;

export const ReportTabItemContainer = styled.div`
  display: flex;
  align-items: center;

  position: fixed;
  top: 0;

  z-index: 100;
`;

export const ReportTabItemWrapper = styled.div<{ isSelected: boolean }>`
  cursor: pointer;

  background-color: ${Theme.colors.G_6};
  color: ${Theme.colors.G_1};
  font-size: 1.125rem;
  font-weight: 500;

  padding: 0.9375rem 1.25rem;

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: #e9f3fd;
      color: ${Theme.colors.B_3_light};
      font-weight: 700;
    `};
`;

export const ChildrenContainer = styled.div`
  display: flex;

  margin-top: 2.5rem;
`;

export const ChildrenWrapper = styled.section``;

export const SubTabContainer = styled.section`
  z-index: 100;
  position: fixed;
  right: 5%;

  border-radius: 0.75rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0rem 0.1875rem 0.5rem;

  width: 21.875rem;
  height: 31.25rem;
  padding: 1.25rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  background-color: white;
`;

export const SubTabColumn = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 0.9375rem;

  gap: 0.625rem;
`;

export const SubTabWrapper = styled.div<{ isSelected: boolean }>`
  cursor: pointer;

  ${({ isSelected }) =>
    isSelected &&
    css`
      font-weight: 500;
      color: ${Theme.colors.B_3_light};
    `};
`;

export const ButtonContainer = styled.div`
  gap: 0.9375rem;

  width: 100%;

  display: flex;
  flex-direction: column;
`;

export const ExcelButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
`;

export const FoldedSubTabContainer = styled.div`
  cursor: pointer;
  padding-left: 0.3125rem;

  z-index: 100;
  position: fixed;
  right: -18.75rem;

  border-radius: 0.75rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0rem 0.1875rem 0.5rem;

  width: 21.875rem;
  height: 31.25rem;

  background-color: white;

  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const FoldTextWrapper = styled.div`
  margin-top: 0.625rem;

  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
`;
