import { useState } from 'react';
import { Link } from 'react-router-dom';

import usePaymentPrice from '@/hooks/usePaymentPrice';

import { ROUTER } from '@/constants/constants';

import PurchaseCount from '@/components/payment/PurchaseCount';
import Text from '@/components/common/Text';
import { BoxText } from '@/components/payment/CardRegisterInfo';
import Button from '@/components/common/Button';
import {
  BoxDesc,
  BoxPrice,
  BoxTextContainer,
  Container,
  ContainerBox,
  InfoBox,
  Line,
  PayBox,
  PayBoxContainer,
} from '@/components/payment/CardRegisterInfo/style';

import { Theme } from '@/styles/Theme';

import * as S from './style';

const Section6 = () => {
  const [count, setCount] = useState(1);
  const price = usePaymentPrice({ workerCount: count });

  return (
    <S.Container id='header_part3' className='header'>
      <Container>
        <Text fontSize='1.875rem' fontWeight={700}>
          예상 요금 안내
        </Text>
        <PurchaseCount count={count} setCount={setCount} />
        <PayBoxContainer>
          <PayBox>
            <ContainerBox>
              <InfoBox>
                <Text
                  fontSize='1.875rem'
                  fontWeight={700}
                  color={Theme.colors.B_2}
                >
                  월간 결제
                </Text>
                <BoxDesc>월간 결제 시, 다음 요금이 부과됩니다.</BoxDesc>
                <BoxPrice>
                  {price.toLocaleString('ko-KR')}원
                  <Text color={Theme.colors.G_2} fontSize='0.875rem'>
                    (부가세 10% 포함)
                  </Text>
                </BoxPrice>
                <BoxTextContainer>
                  <Text fontSize='1.0625rem' fontWeight={700}>
                    사용가능 기능
                  </Text>
                  <BoxText>점검지 관리 기능</BoxText>
                  <BoxText>작업자 관리 기능</BoxText>
                  <BoxText>웹/앱 보고서 작성 기능</BoxText>
                  <BoxText>보고서 다운로드 기능</BoxText>
                </BoxTextContainer>
              </InfoBox>
              <InfoBox>
                <Text
                  fontSize='1.875rem'
                  fontWeight={700}
                  color={Theme.colors.B_3}
                >
                  연간 결제
                </Text>
                <BoxDesc>연간 결제 시, 별도 문의가 필요합니다.</BoxDesc>
                <BoxPrice>별도 문의</BoxPrice>
                <BoxTextContainer>
                  <Text fontSize='1.0625rem' fontWeight={700}>
                    추가제공 혜택
                  </Text>
                  <BoxText>1:1 서비스 기술지원</BoxText>
                </BoxTextContainer>
              </InfoBox>
            </ContainerBox>

            <Line />
            <span>
              서비스를 아직 사용하지 않은 경우, 구매일로부터 3일 이내에
              청약철회권을 행사할 수 있습니다.
              <a
                href='https://www.notion.so/806f4653d0854585beb5ef87f7f1a1f7?pvs=4'
                target='_blank'
                rel='noreferrer'
              >
                <Text color={Theme.colors.B_3} hasUnderline={true}>
                  <br />
                  취소와 환불에 관해 자세히 알아보기
                </Text>
              </a>
            </span>
          </PayBox>
        </PayBoxContainer>
        <S.ButtonContainer>
          <Link to={ROUTER.LOGIN}>
            <Button
              width='100%'
              fontSize='1.25rem'
              backgroundColor={Theme.colors.B_3_light}
            >
              시작하기
            </Button>
          </Link>
          <Button
            onClick={() => window.ChannelIO?.('showMessenger')}
            width='9.375rem'
            fontSize='1.25rem'
            border={`0.0625rem solid ${Theme.colors.G_1}`}
            color={Theme.colors.G_1}
            backgroundColor={Theme.colors.WHITE}
          >
            문의하기
          </Button>
        </S.ButtonContainer>
      </Container>
    </S.Container>
  );
};

export default Section6;
