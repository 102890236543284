import { create } from 'zustand';

interface SubscribeStore {
  isSubscribeMode: boolean;
  setIsSubscribeMode: (value: boolean) => void;
}

const useSubscribeStore = create<SubscribeStore>((set) => ({
  isSubscribeMode: false,
  setIsSubscribeMode: (value) => set({ isSubscribeMode: value }),
}));

export default useSubscribeStore;
