import styled from '@emotion/styled';
import { Theme } from '@/styles/Theme';

export const Container = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1.875rem;
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;
  gap: 3.125rem;
`;

export const EditButtonContainer = styled.div`
  display: flex;
  gap: 0.625rem;
  height: 2.5rem;
`;

export const TopContainer = styled.div`
  display: flex;
  justify-content: start;
  height: 3.75rem;
`;

export const ImageContainer = styled.div`
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 0.625rem;
  height: 12.5rem;
`;

export const ImageWrapper = styled.img`
  background-color: ${Theme.colors.WHITE};
  object-fit: cover;
  width: 100%;
  height: 11.25rem;
`;

export const FileInput = styled.input`
  display: none;
`;

export const FileLabel = styled.label`
  width: 100%;
  height: 2.5rem;
  background-color: ${Theme.colors.WHITE};
  color: ${Theme.colors.BLACK};
  border: 0.0625rem solid ${Theme.colors.G_4};
  font-weight: 400;
  border-radius: 0.375rem;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
`;

export const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  h1 {
    font-size: 1.125rem;
    font-weight: 600;
  }

  h2 {
    font-size: 1.25rem;
    font-weight: 400;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  height: 4.375rem;
`;

export const LegalWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
`;

export const StyledInput = styled.input<{ height: string }>`
  display: block;

  flex: 1;

  width: 100%;
  height: ${({ height }) => height};
  background-color: rgb(255, 248, 226, 0.7);

  border: none;

  padding: 0.4375rem 0;

  color: ${Theme.colors.BLACK};
  font-size: 1rem;
  font-weight: 400;
  resize: none;

  :disabled {
    pointer-events: none;
    background-color: ${Theme.colors.WHITE};
  }

  ::placeholder {
    color: ${Theme.colors.G_2};
  }

  :focus {
    outline: none;
  }
`;

export const StyledCheckbox = styled.button<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Theme.colors.WHITE};
  background-color: ${({ selected }) =>
    selected ? Theme.colors.B_3 : Theme.colors.WHITE};
  border-radius: 0.375rem;
  border: 0.125rem solid ${Theme.colors.B_3};
  font-size: 1rem;
  font-weight: 400;
  width: 1.5625rem;
  height: 1.5625rem;
`;

export const StyledTextarea = styled.textarea<{
  height?: string;
  textAlign?: string;
}>`
  display: block;

  width: 100%;
  height: ${({ height }) => height};
  background-color: rgb(255, 248, 226, 0.7);

  border: none;

  padding: 0.3125rem;

  color: ${Theme.colors.BLACK};
  font-size: 1rem;
  font-weight: 400;
  text-align: ${({ textAlign }) => textAlign};
  resize: none;

  :disabled {
    background-color: ${Theme.colors.WHITE};
  }

  ::placeholder {
    color: ${Theme.colors.G_2};
  }

  :focus {
    outline: none;
  }
`;
export const StyledEmptyImage = styled.label`
  background-color: ${Theme.colors.G_5};
  height: 100%;
  width: 100%;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  :hover {
    background-color: ${Theme.colors.G_4};
  }
`;
