import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from '@emotion/styled';

import Modal from '@/components/common/Modal';
import FormInput from '@/components/common/FormInput';
import Text from '@/components/common/Text';
import Button from '@/components/common/Button';

import type { AddWorkerInfo } from '@/types/worker.types';

import { INPUT_MESSAGE } from '@/constants/constants';

import { StyledForm } from '@/styles/common';
import { Theme } from '@/styles/Theme';
import { useAddWorkerListStore } from '@/store/addWorkerList';
import { useWorkerPaymentListStore } from '@/store/paymentWorkerList';
import { useSelectedWorkerListStore } from '@/store/selectedWorkerList';
import { toast } from 'react-toastify';

interface Props {
  setIsShowAddWorkersModal: React.Dispatch<React.SetStateAction<boolean>>;
  isTrial: 0 | 1;
}

const AddWorkersModal = ({ setIsShowAddWorkersModal, isTrial }: Props) => {
  const { control, handleSubmit } = useForm<AddWorkerInfo>({
    defaultValues: {
      id: Math.random(),
      name: '',
      password: '',
      memo: '',
      noteNumber: '',
      grade: '',
    },
  });

  const { addWorker } = useAddWorkerListStore();
  const { addWorkerPayment } = useWorkerPaymentListStore();
  const { toggleAddWorker } = useSelectedWorkerListStore();

  const onSubmitHandler: SubmitHandler<AddWorkerInfo> = (data) => {
    addWorker(data);
    addWorkerPayment({
      id: data.id,
      isActive: '생성',
      name: data.name,
      nextPaymentDate: '',
      usePeriod: null,
    });
    const { id, ...dataWithoutId } = data;
    toggleAddWorker(dataWithoutId, isTrial);
    setIsShowAddWorkersModal(false);
    toast.success(
      "작업자가 생성되었습니다. 추가/복구할 작업자를 선택 후 '추가/복구 완료' 버튼을 눌러주세요."
    );
  };

  return (
    <Modal setIsModalOpen={setIsShowAddWorkersModal} hasButton={false}>
      <Container>
        <TitleContainer>
          <Text fontSize='1.375rem' fontWeight={700}>
            작업자 추가
          </Text>
          <Text color={Theme.colors.G_2}>새로운 작업자를 추가합니다.</Text>
          <Text color={Theme.colors.G_2}>
            (작업자 추가 후, 아이디 수정은 불가합니다.)
          </Text>
        </TitleContainer>

        <StyledForm onSubmit={handleSubmit(onSubmitHandler)}>
          <FormInput
            control={control}
            rules={{ required: INPUT_MESSAGE.REQUIRED }}
            name='name'
            width='25.625rem'
            height='2.5rem'
            placeholder={INPUT_MESSAGE.NAME.PLACEHOLDER}
            label={INPUT_MESSAGE.NAME.LABEL}
          />
          <FormInput
            control={control}
            rules={{ required: INPUT_MESSAGE.REQUIRED }}
            name='password'
            width='25.625rem'
            height='2.5rem'
            placeholder={INPUT_MESSAGE.PASSWORD.PLACEHOLDER}
            label={INPUT_MESSAGE.PASSWORD.LABEL}
          />
          <FormInput
            control={control}
            name='grade'
            width='25.625rem'
            height='2.5rem'
            placeholder={INPUT_MESSAGE.GRADE.PLACEHOLDER}
            label={INPUT_MESSAGE.GRADE.LABEL}
          />
          <FormInput
            control={control}
            name='noteNumber'
            width='25.625rem'
            height='2.5rem'
            placeholder={INPUT_MESSAGE.NOTE_NUMBER.PLACEHOLDER}
            label={INPUT_MESSAGE.NOTE_NUMBER.LABEL}
          />
          <FormInput
            control={control}
            name='memo'
            width='25.625rem'
            height='2.5rem'
            placeholder={INPUT_MESSAGE.MEMO.PLACEHOLDER}
            label={INPUT_MESSAGE.MEMO.LABEL}
          />

          <ButtonContainer>
            <Button
              backgroundColor={Theme.colors.G_5}
              color={Theme.colors.BLACK}
              width='12.5rem'
              height='3.125rem'
              onClick={() => setIsShowAddWorkersModal(false)}
            >
              취소
            </Button>
            <Button
              type='submit'
              backgroundColor={Theme.colors.B_3}
              width='12.5rem'
              height='3.125rem'
            >
              확인
            </Button>
          </ButtonContainer>
        </StyledForm>
      </Container>
    </Modal>
  );
};

export default AddWorkersModal;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  gap: 1.875rem;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
`;
