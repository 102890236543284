import * as ExcelJS from 'exceljs';

import { getInspectionPlan } from '@/apis/report/plan';
import {
  addImageToSheet,
  copyRangeToNewLocation,
  copyTemplate,
} from '@/utils/excelExport/excelExportUtils';
import { getInspectionEquipment } from '@/apis/report/equipment';

export const exportReportPlan = async (
  workbook: ExcelJS.Workbook,
  inspectionAreaId: number
) => {
  const response = await fetch('/report/2. 성능점검 계획.xlsx', {
    cache: 'no-store',
  });
  const arrayBuffer = await response.arrayBuffer();
  const newWorkBook = new ExcelJS.Workbook();
  await newWorkBook.xlsx.load(arrayBuffer);

  // 복사 하려는 워크시트
  const copySheet = newWorkBook.getWorksheet(1);
  // 복사 받을 워크시트
  let mainSheet = workbook.addWorksheet('II. 성능점검 계획');

  copyTemplate(mainSheet, copySheet, [43, 75, 118]);

  // // 데이터 넣어주는 부분
  const planData = await getInspectionPlan(inspectionAreaId);

  if (mainSheet && planData) {
    const {
      inspectionAreaName,
      inspectionDeviceInfo,
      date: { formattedDate, inspectionPeriod },
      workerList,
    } = planData.data;

    // 1. 성능점검 대상 설비 및 수량
    mainSheet.getCell('A44').value = inspectionAreaName;

    // 전체 수량, 점검 수량 넣어주는 부분
    mainSheet.eachRow((row, rowNumber) => {
      if (rowNumber >= 49 && rowNumber <= 73) {
        const excelDeviceName = row.getCell(2).value;

        // array에서 일치하는 deviceName 찾기
        const matchedDeviceName = inspectionDeviceInfo.find(
          (device) => device.templateDeviceName === excelDeviceName
        );

        if (matchedDeviceName) {
          // 전체 수량, 점검 수량
          row.getCell(5).value = matchedDeviceName.totalAmount;
          row.getCell(6).value = matchedDeviceName.inspectionAmount;
        }
      }

      if (rowNumber > 73) {
        return;
      }
    });

    // 신재생 에너지는 총합으로 보여줘야 함
    let newEnergyTotalAmount = 0;
    let newEnergyInspectionAmount = 0;

    inspectionDeviceInfo.forEach((device) => {
      if (device.templateDeviceName.includes('신재생')) {
        newEnergyTotalAmount += device.totalAmount;
        newEnergyInspectionAmount += device.inspectionAmount;
      }
    });

    mainSheet.getCell('E56').value = newEnergyTotalAmount;
    mainSheet.getCell('F56').value = newEnergyInspectionAmount;

    // 2. 성능점검 일정
    mainSheet.getCell(
      'A77'
    ).value = `${formattedDate} (총 ${inspectionPeriod}일)`;

    // 3. 성능점검 투입 인력
    workerList.forEach((worker, idx) => {
      const categoryCell = mainSheet.getCell(96 + idx, 1);
      const categoryCell2 = mainSheet.getCell(96 + idx, 2);
      const nameCell = mainSheet.getCell(96 + idx, 3);
      const nameCell2 = mainSheet.getCell(96 + idx, 4);
      const nameCell3 = mainSheet.getCell(96 + idx, 5);
      const gradeCell = mainSheet.getCell(96 + idx, 6);
      const gradeCell2 = mainSheet.getCell(96 + idx, 7);

      categoryCell.value = worker.category;
      nameCell.value = worker.name;
      gradeCell.value = worker.grade;

      categoryCell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      categoryCell2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      nameCell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      nameCell2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      nameCell3.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      gradeCell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      gradeCell2.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });
  }

  // 4. 성능점검 장비 리스트
  const equipmentData = await getInspectionEquipment();

  if (mainSheet && equipmentData) {
    const equipmentList = equipmentData.data;
    const itemsPerPage = 5;
    const rowsPerItem = 7; // 각 장비 정보를 위한 행 수
    const initialRow = 123; // 첫 번째 장비 데이터 입력 시작 행
    const firstPageGap = 5; // 첫 페이지 이후의 간격
    const subsequentPageGap = 8; // 이후 페이지 간격

    // 데이터가 여러 페이지에 걸쳐 있을 경우, 필요한 페이지 복사 먼저 수행
    for (
      let pageIndex = 1;
      pageIndex * itemsPerPage < equipmentList.length;
      pageIndex++
    ) {
      copyRangeToNewLocation(mainSheet, 122, 157, 6);
    }

    // 데이터 입력
    for (let i = 0; i < equipmentList.length; i++) {
      const { name, manufacturingCompany, model, isLegalRegistration, image } =
        equipmentList[i];
      const pageIndex = Math.floor(i / itemsPerPage);
      let rowIndex;

      if (pageIndex === 0) {
        // 첫 페이지의 경우
        rowIndex = initialRow + (i % itemsPerPage) * rowsPerItem;
      } else {
        // 이후 페이지의 경우
        rowIndex =
          initialRow +
          itemsPerPage * rowsPerItem +
          firstPageGap +
          (pageIndex - 1) * (itemsPerPage * rowsPerItem + subsequentPageGap) +
          (i % itemsPerPage) * rowsPerItem;
      }

      mainSheet.getCell(rowIndex, 1).value = `${i + 1}. ${name}`;
      mainSheet.getCell(
        rowIndex,
        7
      ).value = `제조사: ${manufacturingCompany}\n모델명: ${model}${
        isLegalRegistration ? '\n*성능점검업 법정등록장비' : ''
      }`;

      if (image) {
        const imageId = await addImageToSheet(image, workbook);
        mainSheet.addImage(imageId, {
          tl: { col: 0.1 + 2, row: 0.1 + rowIndex - 1 },
          ext: { width: 260, height: 150 },
        });
      }
    }
  }
};
