import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getInspectionArea } from '@/apis/InspectionArea';

import Text from '@/components/common/Text';
import GreyBox from '@/components/common/GreyBox';
import { QUERY_KEY } from '@/constants/queryKey';

import * as S from './style';
import { StyledCol } from '@/styles/common';

interface WorkStatusProps {
  title: string;
  content: string;
}
const WorkStatusTable = ({ title, content }: WorkStatusProps) => {
  const params = useParams();
  const inspectionAreaId = Number(params.id);

  const { data: workStatusData } = useQuery({
    queryKey: [QUERY_KEY.WORK_INFO, inspectionAreaId],
    queryFn: () => getInspectionArea(inspectionAreaId),
  });

  return (
    <GreyBox width='58.125rem' height='100%'>
      <S.Container>
        <S.TopContainer>
          <Text fontSize='1.25rem' fontWeight={600}>
            {title}
          </Text>
        </S.TopContainer>
        <S.FlexContainer>
          <S.Table>
            <colgroup>
              <StyledCol width='12.5rem' />
              <StyledCol width='18.75rem' />
              <StyledCol width='12.5rem' />
            </colgroup>
            <tbody>
              <S.TableHeader>구분</S.TableHeader>
              <S.TableHeader>대상설비</S.TableHeader>
              <S.TableHeader>{content}</S.TableHeader>
            </tbody>
            {workStatusData &&
              workStatusData.data &&
              workStatusData.data.device_list.map((template, idx) => {
                return (
                  <S.InfoContainer key={idx}>
                    {template.device_info.map((device, idx) => (
                      <tr key={idx}>
                        {idx === 0 ? (
                          <td rowSpan={template.device_info.length}>
                            {template.primary}
                          </td>
                        ) : (
                          <></>
                        )}
                        <>
                          <td>{device.name}</td>
                          <td>{device.selected === 0 ? '/' : device.result}</td>
                        </>
                      </tr>
                    ))}
                  </S.InfoContainer>
                );
              })}
          </S.Table>
        </S.FlexContainer>
      </S.Container>
    </GreyBox>
  );
};

export default WorkStatusTable;
