import Button from '@/components/common/Button';
import { useState } from 'react';
import AddWorkersModal from './AddWorkersModal';
import { Theme } from '@/styles/Theme';
import styled from '@emotion/styled';

interface Props {
  isTrial: 0 | 1;
  activeWorkerCount: number;
}

const AddWorkersBtn = ({ isTrial, activeWorkerCount }: Props) => {
  const [isShowAddWorkersModal, setIsShowAddWorkersModal] = useState(false);

  // 무료체험인데 작업자가 3명 이상일 때는 추가 불가능
  const isAddable = !(isTrial && activeWorkerCount >= 3);

  const addWorkers = () => {
    if (isAddable) setIsShowAddWorkersModal(true);
  };

  return (
    <ButtonTr>
      <ButtonTd>
        <BtnWrapper>
          {isShowAddWorkersModal && (
            <AddWorkersModal
              setIsShowAddWorkersModal={setIsShowAddWorkersModal}
              isTrial={isTrial}
            />
          )}
          <Button
            height='2.125rem'
            onClick={addWorkers}
            disabled={!isAddable}
            backgroundColor={
              isAddable ? Theme.colors.B_3_light : Theme.colors.G_6
            }
          >
            + 작업자 생성
          </Button>

          {!isAddable && (
            <AlertBox>
              무료 체험 계정은 최대 3명의 작업자까지 추가 가능합니다. 변경을
              원하시면 블루멘토로 연락주세요.
            </AlertBox>
          )}
        </BtnWrapper>
      </ButtonTd>
    </ButtonTr>
  );
};

export default AddWorkersBtn;

export const ButtonTr = styled.tr`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border-bottom: 0.0625rem solid ${Theme.colors.G_3};
  height: 3.4375rem;

  text-align: left;
`;

export const ButtonTd = styled.td`
  padding: 0.625rem 0.3125rem;
`;

export const BtnWrapper = styled.div`
  position: relative;
`;

export const AlertBox = styled.div`
  font-size: 0.875rem;
  position: absolute;
  background-color: ${Theme.colors.B_4};
  padding: 0.5rem;
  border-radius: 0.3125rem;
  top: -0.5rem;
  left: -23.125rem;
  width: 22.5rem;

  ::after {
    display: block;
    content: '';
    position: absolute;
    top: 1.125rem;
    right: -0.4375rem;
    width: 0rem;
    height: 0rem;
    border-bottom: 0.5rem solid transparent;
    border-left: 0.5rem solid ${Theme.colors.B_4};
    border-right: 0.5rem solid none;
    border-top: 0.5rem solid transparent;
  }
`;
