import React, { useState } from 'react';
import * as S from '../../commonStyles';
import GuideBookStatusTable from '@/components/report/review/system/GuideBookStatusTable';
import WorkStatusTable from '@/components/report/review/system/WorkStatusTable';

const SystemReviewTab = [
  {
    name: '1) 유지관리지침서의 적정성',
    component: <GuideBookStatusTable />,
  },
  {
    name: '2) 기계설비 시스템의 작동 상태 (점검결과와 동일)',
    component: (
      <WorkStatusTable
        title='기계설비 시스템 작동상태 점검표'
        content='작동상태'
      />
    ),
  },
  {
    name: '3) 점검대상 현황표 상의 설계값과 측정값 일치 여부 (점검결과와 동일)',
    component: (
      <WorkStatusTable
        title='현황표 상의 설계값과 측정값 일치 여부 점검표'
        content='점검 결과'
      />
    ),
  },
];

const SystemTableGroup = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  return (
    <S.Container>
      <S.TabContainer>
        {SystemReviewTab.map((tab, index) => (
          <S.StyledTab
            key={index}
            isSelected={selectedTab === index}
            onClick={() => {
              setSelectedTab(index);
            }}
          >
            {tab.name}
          </S.StyledTab>
        ))}
      </S.TabContainer>

      {SystemReviewTab[selectedTab].component}
    </S.Container>
  );
};

export default SystemTableGroup;
