import { Theme } from '@/styles/Theme';
import styled from '@emotion/styled';

export const BuildingTable = styled.table`
  background-color: ${Theme.colors.WHITE};
  text-align: center;

  td {
    width: 11.25rem;
    height: 2.5rem;
    border: 0.0625rem solid ${Theme.colors.BLACK};
  }

  tr:first-of-type {
    background-color: ${Theme.colors.G_5};
    height: 3.125rem;
  }
`;

export const ManagerTable = styled.table`
  background-color: ${Theme.colors.WHITE};
  text-align: center;

  td {
    width: 11.25rem;
    height: 2.5rem;
    border: 0.0625rem solid ${Theme.colors.BLACK};
  }

  tr:first-of-type {
    background-color: ${Theme.colors.G_5};
    height: 3.125rem;
  }
  td:first-of-type {
    width: 30%;
    background-color: ${Theme.colors.G_5};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;
