import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

interface AccessTokenStoreState {
  accessToken: string;
  setAccessToken: (accessToken: string) => void;
}

interface RefreshTokenStoreState {
  refreshToken: string;
  setRefreshToken: (refreshToken: string) => void;
}

export const useAccessTokenStore = create(
  persist<AccessTokenStoreState>(
    (set) => ({
      accessToken: '',
      setAccessToken: (accessToken) => set({ accessToken }),
    }),
    {
      name: 'accessToken',
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export const useRefreshTokenStore = create(
  persist<RefreshTokenStoreState>(
    (set) => ({
      refreshToken: '',
      setRefreshToken: (refreshToken) => set({ refreshToken }),
    }),
    {
      name: 'refreshToken',
      storage: createJSONStorage(() => localStorage),
    }
  )
);
