import { Theme } from '@/styles/Theme';
import styled from '@emotion/styled';

export const Container = styled.div``;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Table = styled.table`
  width: 100%;

  border-top: 0.125rem solid ${Theme.colors.BLACK};
  border-bottom: 0.125rem solid ${Theme.colors.BLACK};

  text-align: center;

  tr {
    height: 2.5rem;
  }

  td {
    background-color: ${Theme.colors.WHITE};
    border: 0.0625rem solid ${Theme.colors.BLACK};
  }
`;

export const QualificationContainer = styled.div<{ isEditPage: boolean }>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;

  background-color: ${({ isEditPage }) =>
    isEditPage && 'rgb(255, 248, 226, 0.7)'};
`;

export const QualificationCheckboxContainer = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;

  cursor: pointer;
`;
