export const dateFormat = (date: string, format: '년월일' | '.' = '년월일') => {
  if (date) {
    if (date === '1000-01-01') return '';
    const year = date.slice(0, 4);
    const month = date.slice(5, 7);
    const day = date.slice(8, 10);
    if (format === '년월일') {
      return `${year}년 ${Number(month)}월 ${Number(day)}일`;
    } else if (format === '.') {
      return `${year}.${month}.${day}`;
    }
  } else {
    return '';
  }
};

export const dDayFormat = (date: string) => {
  const targetDate = new Date(date);
  const today = new Date();

  // 시간을 00:00:00으로 설정
  const targetDateAtMidnight = new Date(
    targetDate.getFullYear(),
    targetDate.getMonth(),
    targetDate.getDate()
  );
  const todayDateAtMidnight = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  if (targetDateAtMidnight === todayDateAtMidnight) {
    return 'D-day';
  } else {
    const dayDiff =
      targetDateAtMidnight.getTime() - todayDateAtMidnight.getTime();
    const dDay = Math.floor(dayDiff / (1000 * 60 * 60 * 24));

    return dDay;
  }
};
