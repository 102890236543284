import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from '@/constants/queryKey';
import { getInspectionHistory } from '@/apis/InspectionArea';
import type { GetInspectionHistoryRequestProps } from '@/types/InspectionArea.types';

const useGetInspectionHistory = (body: GetInspectionHistoryRequestProps) => {
  const query = useQuery(
    [
      QUERY_KEY.INSPECTION_HISTORY,
      body.inspectionAreaId,
      body.workerId,
      body.page,
      body.limit,
    ],
    {
      queryFn: () => getInspectionHistory(body),
    }
  );

  return query;
};

export default useGetInspectionHistory;
