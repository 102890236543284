import client from '@/apis/client';
import type { Response } from '@/types/client.types';
import type {
  GetInspectionAreaRequestProps,
  InspectionAreaList,
  GetInspectionHistoryRequestProps,
  GetInspectionHistoryResponseProps,
  GetInspectionAreaInfoResponseProps,
  PostInspectionAreaRequestProps,
  PutInspectionAreaRequestProps,
  DeleteInspectionAreaRequestProps,
  GetWorkExcelDataResponseProps,
} from '@/types/InspectionArea.types';

export const getInspectionAreaList = (body: GetInspectionAreaRequestProps) =>
  client
    .get<Response<InspectionAreaList>>('/v2/work/list', {
      params: body,
    })
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error fetching inspection area list:', error);
      throw error;
    });

export const getInspectionHistory = (body: GetInspectionHistoryRequestProps) =>
  client
    .get<Response<GetInspectionHistoryResponseProps>>('/v2/work/log', {
      params: { ...body },
    })
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error fetching inspection history:', error);
      throw error;
    });

export const getInspectionArea = (inspectionAreaId: number) =>
  client
    .get<Response<GetInspectionAreaInfoResponseProps>>('/v2/work/info', {
      params: { inspectionAreaId },
    })
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error fetching inspection info:', error);
      throw error;
    });

export const postInspectionArea = (body: PostInspectionAreaRequestProps) =>
  client
    .post('/v2/work/register', body)
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error posting inspection info:', error);
      throw error;
    });

export const putInspectionArea = (body: PutInspectionAreaRequestProps) =>
  client
    .put('/v2/work/modify', body)
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error updating inspection info:', error);
      throw error;
    });

export const deleteInspectionArea = (body: DeleteInspectionAreaRequestProps) =>
  client
    .delete('/v2/work/delete', { data: body })
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error deleting inspection:', error);
      throw error;
    });

// 보고서(엑셀 export) 데이터 조회 API
export const getExcelData = (inspectionAreaId: number) =>
  client
    .get<Response<GetWorkExcelDataResponseProps>>('/v2/work/export', {
      params: { inspectionAreaId },
    })
    .then((res) => res.data)
    .catch((error) => {
      console.error('Error fetching excel data:', error);
      throw error;
    });
